import React, { useEffect, useRef } from 'react';
import useCartState from './useCartState';
import useProductPrice from './useProductPrice';
import { setCartDataAnalytics } from '../metrics/track-params';
import useTrackCustomMetric from './useTrackCustomMetric';
import { priceWasFetched } from '../metrics/metrics-helpers';
import { usePageViewTriggered } from './usePageView';

let cartReadyTriggered;

export default () => {
    const trackCustomMetric = useTrackCustomMetric();
    const pageViewTriggered = usePageViewTriggered();
    const { cartInfo, cartId } = useCartState();
    const { items } = cartInfo || {};

    let cartItemKeys = '';
    const products = Array.isArray(items) && items.reduce( (allProducts, item) => {
        const { ceId, qty, pNum } = item || {};
        if(ceId && ceId.length > 0){
            cartItemKeys += `-${pNum}-${qty}-`;
            allProducts.push({
                sku: pNum,
                catentryId: ceId
            })
        }
        return allProducts
    }, [])
    const { prices } = useProductPrice(products)
    const priceSkuKeys = prices && Object.keys(prices).join('-');
    const cartDataReady = Array.isArray(products) && products.every(prd => {
        let { sku, catentryId } = prd || {};
        let fetched = priceWasFetched(prices, sku, catentryId)
        return fetched;
    })

    useEffect(() => {
        try{
            setCartDataAnalytics(cartInfo, cartId, prices);
            if(!cartReadyTriggered && cartDataReady && cartId && pageViewTriggered){
                trackCustomMetric('cartProductsReady');
                cartReadyTriggered = true;
            }
        }catch(e){}
    }, [cartItemKeys, cartId, priceSkuKeys, cartDataReady, pageViewTriggered])
}