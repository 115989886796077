import { RECEIVE_PAGE_VIEW_URL, RECEIVE_IMPRESSIONS, RECEIVE_CUSTOM_METRICS, RECEIVE_DELAYED_IMPRESSION_URL, RECEIVE_PAGE_VIEW_WITH_DELAYED_METRICS } from './track-params';
import { RECEIVE_SLUG_INFO } from '../page/page-actions';

/**
 * This reducer acts as a recorder for events that trigger (pageView, impressions, etc.) to allow components and hooks to 
 * communicate with one another wherever they are in the component tree and know when/how to trigger events.
 * 
 * This is needed because metrics may be determined from different components, and the timing of the events and the grouping of the metrics can vary:
 *  - Timing of events can vary across templates. For example, some impressions trigger only with page view, while others after page view. For correct timing of these impressions,
 *    The metrics.pageView state is used to determine if page view event has already triggered.
 *  - Grouping of metrics can vary across templates. For example, impressions may be batched together if they trigger for lazy loaded components if these components render within 
 *    a short amount of time.
 */
export default (state = {}, action) => {
    const {type} = action || {};
    const {vanityUrl, componentKey, batchKey, impressions, customMetrics, impressionGroupSize, forPageView, markTrackedProductsCallback } = (action && action.payload) || {};
    switch(type){
        case RECEIVE_SLUG_INFO:
            // clear state when switching pages
            const { slugInfo } = action || {};
            const newVanityUrl = slugInfo && slugInfo.vanityUrl;
            const currentPageViewUrl = state && state.pageView && state.pageView.vanityUrl;
            const isNewPage = currentPageViewUrl !== newVanityUrl;
            return isNewPage ? {} : state;
        // tracks the last page where the pageView event triggered so it doesn't trigger twice when component unmounts and mounts 
        case RECEIVE_PAGE_VIEW_URL:
            return {
                ...state || {}, 
                pageView: {
                    vanityUrl
                }
            }
        // used to track the last page where delayed impressions (triggers once after page view) were tracked.
        case RECEIVE_DELAYED_IMPRESSION_URL:
            return {
                ...state || {}, 
                delayedImpressions: {
                    vanityUrl
                }
            }
        case RECEIVE_PAGE_VIEW_WITH_DELAYED_METRICS:
            return {
                ...state || {},
                pageViewWithDelayedMetrics: vanityUrl
            }
        // records impressions that  
        case RECEIVE_IMPRESSIONS:
            // if there is a limit on number of impressions per dataLayer calls, only add impressions up to the limit
            let currentImpressions = state && state.impressions && state.impressions[vanityUrl] && state.impressions[vanityUrl][componentKey];
            let limitDifference = impressionGroupSize - ((currentImpressions && currentImpressions.length) || 0)
            let hasLimit = typeof impressionGroupSize === 'number' && impressionGroupSize > 0 && forPageView && batchKey;
            let hasReachedPageViewImpressionLimit = hasLimit && currentImpressions && currentImpressions.length >= impressionGroupSize;
            if(hasReachedPageViewImpressionLimit){
                return state;
            }

            let limitedImpressions = hasLimit && impressions.slice(0, limitDifference);
            let addedImpressions = limitedImpressions || impressions || []
            if(typeof markTrackedProductsCallback === 'function'){ 
                markTrackedProductsCallback(addedImpressions);
            }
            return {
                ...state || {},
                impressions: {
                    ...(state && state.impressions) || {},
                    [vanityUrl]: {
                        ...(state && state.impressions && state.impressions[vanityUrl]) || {},
                        [componentKey]: (
                            batchKey 
                            ? (
                                [
                                    ...currentImpressions || [], 
                                    ...addedImpressions
                                ] 
                            ) : impressions
                        )
                    }
                }
            }
        case RECEIVE_CUSTOM_METRICS:
            return {
                ...state || {},
                customMetrics: {
                    ...state && state.customMetrics,
                    ...customMetrics || {}
                }
            }
        default:
            return state;
    }
}