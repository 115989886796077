/**
 * Add batch functionality to the HPServices api
 * TODO: make this more generic to work for potentially any API
 */
const MAX_BATCH_SIZE = 50;
const BATCH_TIMEOUT = 300;
const ACTION_ORDER = 'cupids';

class BatchRequests {
    constructor() {
        this.queue = [];
        this.request = null;
        this.requestTimeout = null;
        this.requestInterval = null;
        this.idleCount = 0;
        this.batchTimeout = BATCH_TIMEOUT;
        this.requestParams = {};
        this.id = Math.floor(Math.random() * 100);
    }

    execute() {
        let { action, catentryId, modelId, retry, cache } = this.requestParams;
        //splice current queue to avoid resolving requests that come in during execution
        let execQueu = this.queue.splice(0, this.queue.length);

        //combine all requestParams into single request
        action = Object.keys(action)
            .sort((a, b) => ACTION_ORDER.indexOf(a) - ACTION_ORDER.indexOf(b))
            .join('');
        catentryId = Object.keys(catentryId).sort();
        modelId = Object.keys(modelId).sort();

        //execute aggregate request, respond to all pending promises
        this.request &&
            this.request(action, catentryId, modelId, retry, cache)
                .then(resp => {
                    while (execQueu.length > 0) {
                        let p = execQueu.pop();

                        p.resolve(resp);
                    }
                })
                .catch(error => {
                    while (execQueu.length > 0) {
                        let p = execQueu.pop();
                        p.reject(error);
                    }
                });
        this.requestParams = {};
    }

    delayExecute(batchSize) {
        setTimeout(() => {
            //if no request do nothing
            if (this.queue.length === 0) {
                return;
            }

            //if there are more request now than before, wait again
            if (this.queue.length > batchSize && this.queue.length < MAX_BATCH_SIZE) {
                this.delayExecute(this.queue.length);
            } else {
                this.execute();
            }
        }, this.batchTimeout);
    }

    enqueu(promise, args) {
        const { action, catentryId, modelId, retry, cache } = args;

        //push promise to queue
        this.queue.push(promise);

        //aggregate request
        this.requestParams.action = action.split('').reduce((r, a) => {
            r[a] = 1;
            return r;
        }, this.requestParams.action || {});
        this.requestParams.catentryId = catentryId.reduce((r, c) => {
            r[c] = 1;
            return r;
        }, this.requestParams.catentryId || {});
        this.requestParams.modelId = modelId.reduce((r, m) => {
            r[m] = 1;
            return r;
        }, this.requestParams.modelId || {});
        this.requestParams.retry = retry;
        this.requestParams.cache = cache;
        this.delayExecute(this.queue.length);
    }

    batch(context, method) {
        this.request = method.bind(context);
        return (action = '', catentryId = [], modelId = [], retry = 0, cache) => {
            return new Promise((resolve, reject) => {
                //add each new request to the queue
                this.enqueu({ resolve, reject }, { action, catentryId, modelId, retry, cache });
            });
        };
    }
}

export default BatchRequests;
