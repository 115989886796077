export const debugPage = () => {
    if (typeof window === undefined || !window.store) {
        return null;
    }
    const { slugInfo, proxyHost } = window.store.getState();
    const { errors } = slugInfo || {};
    const errorList = (errors || []).filter(e => !!e);
    if (errorList.length > 0) {
        console.error(
            `ETR API (${proxyHost}) Component Level Errors:`,
            errorList
                .map((e, idx) => {
                    return `${idx + 1}) ${e.componentKey}: ${e.message}`;
                })
                .join('\n')
        );
    }
};
