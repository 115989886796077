import { mapAccessories } from './util';

class Component {
    constructor(apiConfig, http) {
        const { siteInfo } = apiConfig;
        const { storeId } = siteInfo;
        this.apiConfig = apiConfig;
        this.http = http;
        this.path = `/wcs/resources/store/${storeId}/component`;
    }

    get(template, componentKey, params, config, debug) {
        return this.http(`${this.path}/${template}/${componentKey}`, { params, method: 'get', ...config }).then(
            resp => {
                const { data, status } = resp;
                //attempt to parse response
                if (status === 200 && data && data !== '') {
                    return debug ? resp : data;
                } else {
                    return Promise.reject({});
                }
            }
        );
    }

    getAccessories = (query, config) => {
        return this.get('product', 'accessories', query, config).then(response => {
            const { accessories } = response || {};
            return mapAccessories(accessories);
        });
    };

    getAnalyticsData(template, vanityUrl, config) {
        return this.get('analytics', template, { path: vanityUrl }, config);
    }

    getAddToCartPopup(vanityUrl, config) {
        return this.get('product', 'atcpopup', { path: vanityUrl }, config);
    }

    getOffers(productIds, config) {
        if (!productIds || !productIds.length) {
            return Promise.resolve([]);
        }
        return this.get('product', 'getspecialoffers', { productIds: productIds.join(',') }, config);
    }
}

export default Component;
