import React from 'react';

import { useSelector } from 'react-redux';

import { Helpers } from '../core';
import { getAudienceIds } from './util';

export const getClientData = ({ pStoreID, AOID, JumpID }) => {
    let Custom = {};
    const audienceID = getAudienceIds();
    if (AOID) Custom.AOID = AOID;
    if (JumpID) Custom.JumpID = JumpID;
    if (pStoreID) Custom.custom = pStoreID;
    if (audienceID) Custom.audience_id = audienceID;

    // this is needed since the react-hawksearch library doesn't allow specifying the Custom field and so we are recreating the logic here instead.
    let clientData = {
        // PreviewBuckets: VisitorTargets || [],
        Custom,
    };

    try {
        clientData.UserAgent = navigator.userAgent;
    } catch (e) {}

    let visitorId = Helpers.getCookie('hawk_visitor_id');
    let visitId = Helpers.getCookie('hawk_visit_id');
    if (visitorId) clientData.VisitorId = visitorId;
    if (visitId) clientData.VisitId = visitId;

    return clientData;
};

/**
 * Gets ClientData parameter used for hawksearch request using Redux and the URL
 */
export default () => {
    // const hawksearchContext = useHawkSearch();
    // const {VisitorTargets} = (hawksearchContext && hawksearchContext.searchResults) || {};

    // determine clientData
    const pStoreID = useSelector(store => {
        try {
            let profilePStoreId = store.userData.profileData.personData.pStoreID;
            return (
                profilePStoreId.toLowerCase() === store.router.location.query.pStoreID.toLowerCase() && profilePStoreId
            );
        } catch (e) {}

        return null;
    });

    const AOID = useSelector(store => {
        try {
            return Helpers.getCaseInsensitiveQueryStringValue(store.router.location.search, 'aoid');
        } catch (e) {}
        return '';
    });

    const JumpID = useSelector(store => {
        try {
            return Helpers.getCaseInsensitiveQueryStringValue(store.router.location.search, 'jumpid');
        } catch (e) {}
        return '';
    });

    let clientData = getClientData({ AOID, JumpID, pStoreID });

    return clientData;
};
