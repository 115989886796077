import { useSelector } from 'react-redux';

import { Helpers } from '../core/src/helpers';
const { memoize } = Helpers;

const deviceSelector = memoize(
    (ui, deps, customBreakpoint) => {
        const customDevice = customBreakpoint && (ui.customBreakpoints || {})[customBreakpoint];
        return {
            device: customDevice || ui.device,
            width: ui.width,
            height: ui.height,
            customBreakpoints: ui.customBreakpoints,
            isBot: ui.isBot,
            innerHeight: ui.innerHeight,
            innerWidth: ui.innerWidth,
        };
    },
    (ui, deps, customBreakpoint) => {
        const { device: defaultDevice, width, height, customBreakpoints } = ui;
        const customDevice = customBreakpoint && (customBreakpoints || {})[customBreakpoint];
        const device = customDevice || defaultDevice;
        //memoize based on the dependency list values
        if (deps && deps.length > 0) {
            return deps.reduce((r, k) => {
                //use override device if one is set as the cache key
                if (k === 'device') {
                    return r + device;
                }
                return r + ui[k];
            }, '');
        }
        //otherwise default memoize on device with and height
        return `${device}${width}${height}`;
    }
);

/**
 * Returns the device info state
 * @name useCartState
 * @returns {deviceInfo}
 */
export default function useDeviceInfo(deps, customBreakpoint) {
    return useSelector(state => deviceSelector(state.ui, deps, customBreakpoint));
}
