import merge from 'lodash/merge';
import { LOCATION_CHANGE } from 'connected-react-router';

import {
    MANAGE_ORDER_LOCK,
    TOGGLE_SERVICE_TIMEOUT,
    RECEIVE_UTILITY,
    RECEIVE_USER_IDLE,
    NULL_UTIL_STATE,
} from './utility-actions';
import { Helpers } from '../core';

const NULL_STOREDATA_STATE = {
    labels: {
        add2Cart: 'Add to cart',
        add2Quote: 'Add to quote',
        addMsg: 'Just added to cart',
        backOrder: 'Back-order',
        cartCheckout: 'Checkout',
        cartSubtotal: 'Sub-total',
        cartTaxFee: 'before tax & fees',
        cartTxt: 'View all items',
        cartView: 'view cart & check out',
        comingSoon: 'Coming soon',
        customizeBtn: 'Customize & buy',
        customizeOnlyBtn: 'Customize',
        emptyCart: 'There are no items in your cart',
        invLeft: 'Left!',
        invOutOfStock: 'Out of stock',
        mcchq: 'QTY',
        poboOOSMsg: 'Pre-orders sold out',
        poboShipMsg: 'Estimated shipping date:',
        preOrder: 'Pre-order now',
        viewSupplies: 'View supplies',
    },
};

/**
 * trigger custom event when user data is received for third party vendors
 */
const triggerUserEvent = state => {
    try {
        const { profileData } = state;
        dispatchEvent(
            new CustomEvent('userReceived', {
                bubbles: true,
                detail: { profileData },
            })
        );
    } catch (e) {}
};

const decodeLabels = (data = {}) => {
    const labels = data.labels || {};
    Object.keys(labels).forEach(k => {
        try {
            labels[k] = Helpers.decodeHtml(labels[k]);
        } catch (e) {}
    });
    return data;
};

export const StoreDataReducer = (state = NULL_STOREDATA_STATE, action) => {
    Object.freeze(state);
    switch (action.type) {
        case RECEIVE_UTILITY:
            return merge({}, state, decodeLabels(action.utilityInfo.storeData));
        default:
            return state;
    }
};

const setLoginRedirectCookie = () => {
    try {
        //set session cookie with current location for HP ID login redirect
        //replace ';' used on vwa pages with encoded version since ';' is the delimeter used by cookies
        Helpers.setCookie(
            'HPIDLandingPage',
            document.location.href.replace(/;/gi, encodeURIComponent(';')),
            0.02,
            undefined,
            undefined,
            '.hp.com'
        );
    } catch (e) {}
};

const UtilityReducer = (state = NULL_UTIL_STATE, action) => {
    Object.freeze(state);
    const newState = merge({}, state);
    switch (action.type) {
        case RECEIVE_UTILITY:
            newState.profileData = action.utilityInfo.profileData;
            if (action.updatedFromService === true) {
                newState.updatedFromService = true;
                if (newState.userFetchFailed) delete newState.userFetchFailed;
                triggerUserEvent(newState);
            }
            if (action.utilityInfo.userFetchFailed) {
                newState.userFetchFailed = action.utilityInfo.userFetchFailed;
            }
            return newState;
        case MANAGE_ORDER_LOCK:
            newState.callCenter.orderLock = action.orderLock;
            return newState;
        case TOGGLE_SERVICE_TIMEOUT:
            newState.serviceTimeout = action.toggle;
            return newState;
        case RECEIVE_USER_IDLE:
            newState.userIdle = action.userIdle;
            return newState;
        case LOCATION_CHANGE:
            //set HPID cookie on locations change
            setLoginRedirectCookie();
            return state;
        default:
            return state;
    }
};

export default UtilityReducer;
