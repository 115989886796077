import { RECEIVE_DEALS, RECEIVE_DEALS_ERROR, DEALS_HYDRATING, DEALS_HYDRATED } from './deals-actions';

const NULL_STATE = {};
const DealReducer = (state = NULL_STATE, action) => {
    Object.freeze(state);
    switch (action.type) {
        case RECEIVE_DEALS:
            return Object.assign({}, state, action.deals);
        default:
            return state;
    }
};

export default DealReducer;
