export const RECEIVE_ADD_ONS = 'RECEIVE_ADD_ONS';

export const receiveAddons = (sku, addOns) => ({
	type: RECEIVE_ADD_ONS,
	sku,
    addOns
});


export const setAddOns = (sku, addOns) => (dispatch, getState) => {
    return dispatch(receiveAddons(sku, addOns));
};
