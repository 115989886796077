import axios from 'axios';

import BatchRequest from './batch';
import { BreadcrumbAPI } from './breadcrumb';
import { CartAPI } from './cart';
import { CallCenterAPI } from './call-center';
import CTOAPI from './cto';
import CTOServletAPI from './cto-servlet';
import CompatData from './compat-data';
import ServicesAPI from './services';
import DealsAPI from './intra-day-deals-api';
import SSOCheck from './sso-check';
import { useRelativeLink, contextParameterInterceptor, appOriginInterceptor, isNode } from '../util';

// ISNODE is boolean false in production frontend, which makes all URLs relative; boolean true during SSR;
// when it's true, all API URLs get prefixed with production domain to make sure HPServices data is retrievable;
// thanks to endsWith(..., "hp.com") checks, URLs stay prefixed even on local environments when ISNODE might be
// accidentally missing and document.location.hostname resolves to "localhost"
const basename = process.env.BASENAME;
const batchServices = new BatchRequest();
//domain name for local dev for remote ETR APIs
const DEFAULT_DOMAIN = `https://${process.env.DOMAIN || 'www.hp.com'}`;

const _EtrAPI = function () {
    let self = this;
    let config = {
        siteInfo: {
            storeId: 10151,
            catalogId: 10051,
            langId: -1,
        },
    };
    let httpInstance = axios.create({
        //TODO: During uat3 testing for local dev easier to have the default be uat3 rather than live Services endpoint
        baseURL: useRelativeLink() ? `${basename}/` : `${DEFAULT_DOMAIN}${basename}/`,
        //set a longer timeout for browser side call
        timeout: isNode ? 3000 : 60000 * 2,
    });
    let etrWebAppInstance = axios.create({
        baseURL: useRelativeLink() ? '/webapp/wcs/stores/servlet/' : `${DEFAULT_DOMAIN}/webapp/wcs/stores/servlet/`,
        //set a longer timeout for browser side call
        timeout: isNode ? 3000 : 60000 * 2,
    });
    let etrWCSInstance = axios.create({
        baseURL: useRelativeLink() ? '/wcs/resources/store/' : `${DEFAULT_DOMAIN}/wcs/resources/store/`,
        //set a longer timeout for browser side call
        timeout: isNode ? 3000 : 60000 * 2,
    });

    httpInstance.interceptors.request.use(contextParameterInterceptor);
    httpInstance.interceptors.request.use(appOriginInterceptor);
    etrWebAppInstance.interceptors.request.use(contextParameterInterceptor);
    etrWCSInstance.interceptors.request.use(contextParameterInterceptor);

    self.cartWCS = new CartAPI(config, etrWebAppInstance);
    self.cart = new CartAPI(config, httpInstance);
    self.cto = new CTOAPI(config, httpInstance);
    self.ctoServlet = new CTOServletAPI(config, etrWebAppInstance);
    self.compatData = new CompatData(config, etrWCSInstance);
    self.services = new ServicesAPI(config, httpInstance);
    self.callCenter = new CallCenterAPI(config, httpInstance);
    self.breadcrumb = new BreadcrumbAPI(config, httpInstance);
    self.intraDayDeals = new DealsAPI(config, etrWebAppInstance);
    self.httpInstance = httpInstance;
    self.ssoCheck = SSOCheck;

    //update services api to batch requests
    self.services.hpServices = batchServices.batch(self.services, self.services.hpServices);
};

export const EtrAPI = new _EtrAPI();
