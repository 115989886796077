import { EtrAPI } from './core/src/etr-api-client';
import { Helpers } from './core/src/helpers';

/**
 * Prefetch HP services data before hydrating the client, set a max wait time since HPService is slow
 * @param {*} initialStore
 * @param {*} maxWait
 */
const prefetchHPService = (initialStore, maxWait = 1000) => {
    //check for prefetch price hint
    const { productData = {} } = initialStore;
    const { productInfo = {} } = productData;
    const { productPrices = {} } = productInfo;
    const { prefetchPrice = [] } = productPrices;
    let priceIds = [];
    if (prefetchPrice && prefetchPrice.length) {
        priceIds = prefetchPrice;
        //delete prefetch price hint
        delete initialStore.productData.productInfo.productPrices.prefetchPrice;
    }
    return new Promise((resolve, reject) => {
        let finished = false;
        setTimeout(() => {
            if (!finished) {
                finished = true;
                //abort prefetch to avoid weird race conditions
                EtrAPI.services.abortPrefetch();
                resolve({ message: 'max wait exceeded' });
            }
        }, maxWait);
        const action = `cu${priceIds && priceIds.length > 0 ? 'pid' : ''}s`;
        try {
            //set price settings from store, for pre-fetch
            EtrAPI.services.setPriceSettings(initialStore.siteConfig.priceSettings);
        } catch (e) {}

        return EtrAPI.services
            .hpServices(action, priceIds, [], 0, true)
            .then(resp => {
                finished = true;
                const { profileData } = resp.data || {};
                EtrAPI.ssoCheck(initialStore.siteConfig, { profileData });
                resolve(resp);
            })
            .catch(reject);
    });
};

export const fetchPrices = (dispatch, getState) => products => {
    try {
        const priceIds = products.map(p => Helpers.getCatEntryId(p));
        return EtrAPI.services.hpServices('pid', priceIds, [], 0).then(({ data }) => {
            const { serviceData } = data;
            //map service data back to products
            return products.reduce((r, p) => {
                const { sku } = p;
                const catEntryID = Helpers.getCatEntryId(p);
                if (sku in serviceData) {
                    r.push(serviceData[sku]);
                } else {
                    //if sku id not available, find the service data based on the catEntryID
                    let skuId;
                    Object.keys(serviceData).forEach(key => {
                        const priceData = serviceData[key];
                        if (priceData.itemId === catEntryID) {
                            skuId = key;
                        }
                    });
                    if (skuId) {
                        r.push(serviceData[skuId]);
                    } else {
                        r.push({});
                    }
                }
                return r;
            }, []);
        });
    } catch (e) {
        return Promise.reject(e);
    }
};

export default prefetchHPService;
