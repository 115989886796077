import { RECEIVE_PDP_INFO, RECEIVE_PDP_ERROR, RECEIVE_UPSELL, CLEAR_PDP_INFO } from './pdp-actions';

const NULL_PRODUCT = {
    name: '',
    attributes: {},
    images: [],
    rating: 0,
    offers: [],
    ksps: [],
    modelId: [],
    showAccTab: false,
    variants: [],
    disclaimerTab: undefined,
};

/**
 * Carry over pdp upsell if new product was in previous products updsell list
 * @param {*} newPDP
 * @param {*} prevPDP
 */
const checkUpsell = (newPDP, prevPDP) => {
    const prodInUpsell = prevPDP.upsell.productsList.some(prod => prod.sku === newPDP.sku);
    if (prodInUpsell) {
        return Object.assign(NULL_PRODUCT, newPDP, { upsell: prevPDP.upsell });
    }
    return newPDP;
};

const PDPReducer = (state = NULL_PRODUCT, action) => {
    Object.freeze(state);
    switch (action.type) {
        case RECEIVE_PDP_INFO:
            if (!action.pdpInfo.upsell && state.upsell) {
                return checkUpsell(action.pdpInfo, state);
            } else {
                return action.pdpInfo;
            }
        case RECEIVE_UPSELL:
            if (state.sku === action.sku) {
                return Object.assign({}, state, { upsell: action.upsell });
            } else {
                return state;
            }
        case RECEIVE_PDP_ERROR:
            return { ...state, error: 'pdp not found' };
        case CLEAR_PDP_INFO:
            return NULL_PRODUCT;
        default:
            return state;
    }
};

export default PDPReducer;
