import React from 'react';
import Script from 'react-load-script';
import { connect, useSelector } from 'react-redux';
import { handleScriptLoad, handleScriptError, handleScriptCreate } from './script-loader-actions.js';

/**
 * Script loader loaders scripts and store in redux.
 * This is to prevent the script resource being fetched several times on a SPA
 */
class ScriptLoader extends React.Component {
    constructor(props) {
        super(props);
    }

    onCreate = () => {
        let { name, url, onCreate } = this.props;
        let reduxKey = name || url;
        this.props.handleScriptCreate(reduxKey);
        onCreate && onCreate(reduxKey);
    };

    onError = () => {
        let { name, url, onError } = this.props;
        let reduxKey = name || url;
        this.props.handleScriptError(reduxKey);
        onError && onError(reduxKey);
    };

    onLoad = () => {
        let { name, url, onLoad } = this.props;
        let reduxKey = name || url;
        this.props.handleScriptLoad(reduxKey);
        onLoad && onLoad(reduxKey);
    };

    render() {
        const { url, attributes } = this.props;

        return (
            !this.props.scriptLoaded && (
                <Script
                    url={url}
                    onCreate={this.onCreate}
                    onError={this.onError}
                    onLoad={this.onLoad}
                    attributes={attributes}
                />
            )
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { scriptLoader } = state;
    let { name, url } = ownProps;
    let reduxKey = name || url;
    return { scriptLoaded: scriptLoader[reduxKey] };
};

const mapDispatchToProps = dispatch => {
    return {
        handleScriptLoad: name => dispatch(handleScriptLoad(name)),
        handleScriptCreate: name => dispatch(handleScriptCreate(name)),
        handleScriptError: name => dispatch(handleScriptError(name)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScriptLoader);

export const useScriptLoaded = url => {
    const reduxScriptLoaded = useSelector(state => state.scriptLoader[url]);
    let isScriptLoaded;
    try {
        isScriptLoaded = document.querySelector(`script[src="${url}"]`);
    } catch (e) {}
    return !!reduxScriptLoaded || !!isScriptLoaded;
};
