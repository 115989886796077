import merge from 'lodash/merge';

import { RECEIVE_SITE_CONFIG } from './page-actions';

//mainly needs for local dev CRS. Since site config will come from server intial store
const DEFAULT_SITE_CONFIG = {
	basename: process.env.BASENAME,
	isLocal: true
};

const SlugInfoReducer = (state = DEFAULT_SITE_CONFIG, action) => {
	Object.freeze(state);
	switch(action.type) {
		case RECEIVE_SITE_CONFIG:
			return merge({},state,action.siteConfig, { isLocal: false });
		default:
			return state;
	}
};

export default SlugInfoReducer;
