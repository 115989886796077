import React from 'react';
import { MetricsElement } from "react-metrics";

import { ROOT_FILTER_KEYS } from '../constants';
import SearchClearFilter from '../../search-filter/components/search-clear-filter';
import SearchFilterCrumbs from '../../search-filter/components/search-filter-crumbs';
import { SelectedCategory } from '../../filters/components/select-categories';

export const CustomFilterCrumb = ({ filterName, field, options, onClick=()=>{}, useAlias}) => {
    if(options){
        return (<React.Fragment>
                {
                    options.map((value, i) => {
                        if (value && value.selected) {
                            let { displayName, selected, count, _id, alias } = value;
                            //TODO: figure out a better way to differentiat facets that are nest or root
                            let fieldName = field in ROOT_FILTER_KEYS ? field : `facets.${field}`;
                            let queryValue = {[fieldName]: useAlias ? alias : _id};
                            
                            return (
                                <MetricsElement
                                     element="li"
                                     className={`facet-crumb ${count == 0 ? ' disabled' : ''}`}
                                     data-metrics-event-name="searchFilter"
                                     data-metrics-event="e_facet"
                                     data-metrics-filter-checked={!selected}
                                     data-metrics-filter-categories={filterName}
                                     data-metrics-filter-value={displayName}
                                     onClick={() => onClick(selected ? 'remove' : 'add', queryValue)}
                                     key={_id}
                                    >
                                    <span className='facet-value-name'>{displayName}</span>
                                </MetricsElement>
                            )
                        } else {
                            return null;
                        }
                     })
                }
            </React.Fragment>)
    }else{
        return null
    }

    
}

class VWAFilterCrumb extends React.PureComponent {

    render(){
        const { hideCategory, useAlias } = this.props;
        return (<div className='filter-crumb-bar'>
            <SearchFilterCrumbs>
            {
                 ({ query, customAggregations={}, updateQuery, disableSearch  }) => {
                     {
                        if(disableSearch){
                            return null
                        }
                        const { facets=[] } = customAggregations;
                        
                        return (<React.Fragment>
                                    {!hideCategory && <SelectedCategory useAlias={useAlias}>
                                    {
                                        ({ selectedCategory }) => {
                                            return <h2 className={'category-crumb'}>{selectedCategory && selectedCategory.displayName}</h2>
                                        }
                                    }
                                    </SelectedCategory>}
                                    {
                                        facets.map((agg, i) => {
                                            const { displayName, facetValues, key, alias } = agg;
                                            return  <CustomFilterCrumb 
                                                        options={facetValues} 
                                                        filterName={displayName} 
                                                        field={useAlias ?  alias : key}
                                                        onClick={updateQuery}
                                                        key={`${useAlias ? alias : key}-${i}`}
                                                        useAlias={useAlias}
                                                     />
                                        })
                                    }
                                    <SearchClearFilter 
                                        autoHide={true}
                                        watchFields={/facets.*|price|rating/}
                                    />
                                </React.Fragment>);
                    }
                 }
            }
            </SearchFilterCrumbs>
        </div>)
    }
}

export default VWAFilterCrumb;
