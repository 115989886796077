import React from 'react';
import loadable from '@loadable/component';

import withPage, { loadGraphQLData } from '../page';
import { Helpers } from '../core/src/helpers';

const SubBrand = loadable(() => Helpers.retryFunc(() => import('./sub-brand-container')));

export default {
    component: SubBrand,
    loadData: loadGraphQLData,
};
