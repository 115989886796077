import React from 'react';
import loadable from '@loadable/component';

import AboveTheFoldOnlyServerRender from '../../shared/components/above-the-fold-only-server-render';
import { Helpers } from '../../core/src/helpers';

const Disclaimer = loadable(() => Helpers.retryFunc(() => import('./disclaimer-v2')));

const DEFAULT_LAZYLOAD_SETTINGS = {
    robots: true,
    skip: true,
    //default to showTimeout rather than offset so that anchor scroll on PDP works well with disclaimer text
    showTimeout: 300,
    placeholderClassName: 'shoppinglinks_support v2',
};

export default props => {
    const { lazyLoadSettings = DEFAULT_LAZYLOAD_SETTINGS, ...restProps } = props;
    return (
        <AboveTheFoldOnlyServerRender {...lazyLoadSettings}>
            <Disclaimer {...restProps} />
        </AboveTheFoldOnlyServerRender>
    );
};
