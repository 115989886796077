import { StoreAppAPI } from '../../../core/src/storeapp-api-client';

const storeAppAPI = new StoreAppAPI();

export const RECEIVE_HEADER_FOOTER = 'RECEIVE_HEADER_FOOTER';
export const UPDATE_HEADER = 'UPDATE_HEADER';
export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const SHOW_POPOVER = 'SHOW_POPOVER';
export const SHOW_MOBILE_SEARCH_BAR = 'SHOW_MOBILE_SEARCH_BAR';

export const receiveHeaderFooter = headerFooter => ({
    type: RECEIVE_HEADER_FOOTER,
    headerFooter,
});

export const receiveUpdateHeader = header => ({ type: UPDATE_HEADER, header });

export const updateHeader = header => dispatch => {
    return dispatch(receiveUpdateHeader(header));
};

export const fetchHeaderFooterComponent = keys => dispatch =>
    storeAppAPI.component
        .get(keys)
        .then(
            resp => {
                if (resp.status * 1 === 200) {
                    const { data } = resp;
                    const headerFooter = data.reduce((acc, c) => ({ ...acc, [c.key]: c.value }), {});

                    return headerFooter;
                }
                return null;
            },
            err => {
                // TODO: probaby need to dispatch a HEADER_FOOTER error here and set the header footer to some default value
            }
        )
        .then(headerFooterData => {
            if (!headerFooterData || !headerFooterData.footer) return headerFooterData;
            const countryList = Object.entries(headerFooterData.footer.countries)
                .reduce((acc, [regionTitle, countries]) => acc.concat(countries.map(country => country)), [])
                .sort((a, b) => a.countryTitle.localeCompare(b.countryTitle));

            return {
                ...headerFooterData,
                footer: {
                    ...headerFooterData.footer,
                    countryList,
                },
            };
        })
        .then(headerFooter => dispatch(receiveHeaderFooter(headerFooter)));

export const toggleDrawer = () => ({
    type: TOGGLE_DRAWER,
});

export const showPopover = (show, overlayShade) => ({
    type: SHOW_POPOVER,
    show,
    overlayShade,
});

export const showMobileSearchBar = show => ({
    type: SHOW_MOBILE_SEARCH_BAR,
    show
})

export const toggleHeaderFooterDrawer = (open, freeze, push) => dispatch => {
    /* eslint no-undef: "error" */
    /* eslint-env browser */
    if (!document || !document.body) return null;

    if (open) {
        if (freeze) {
            document.body.style.top = `-${window.pageYOffset}px`;
            document.body.classList.add('drawer-freeze');
        }

        if (push) {
            document.body.classList.add('drawer-push');
        }
    } else {
        const scrollPos = document.body.style.top;
        document.body.style.top = 'unset';
        document.body.classList.remove('drawer-freeze');
        document.body.classList.remove('drawer-push');
        // window.scrollTo(0, parseInt(scrollPos, 10) * -1);
    }

    return dispatch(toggleDrawer());
};
