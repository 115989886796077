export * from './src/string-polyfill';

if (typeof window !== 'undefined') {
    window.matchMedia =
        window.matchMedia ||
        function () {
            return {
                matches: false,
                addListener: function () {},
                removeListener: function () {},
            };
        };

    //wrap decodeURIComponent in try catch to protect from
    //malformed urls that the email team periodically creates
    const ogDecodeURIComponent = window.decodeURIComponent;

    window.decodeURIComponent = function () {
        try {
            return ogDecodeURIComponent.apply(this, arguments);
        } catch (e) {
            return '';
        }
    };
}
