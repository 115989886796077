import React from 'react';
import { connect } from 'react-redux';
import { updateUserInputType } from '../ui-action';

class UserInputUpdater extends React.PureComponent { 
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        const { device } = this.props;
        document.body.addEventListener('click', function () {
            // on mousedown add gating class to disable focus outline
            document.body.classList.remove('using-keyboard');
            document.body.classList.remove('using-touchscreen');  
            document.body.classList.remove('using-keyboard-focus');
            document.body.classList.add('using-mouse');
            //updateInputType('mouse');   
        });
    
        let hasKeyboardFocus;
        document.body.addEventListener('keydown', function (event) {
                // remove gating class when keyboard used
            document.body.classList.remove('using-mouse');
            document.body.classList.remove('using-touchscreen');
            document.body.classList.add('using-keyboard');
            
            // keyboard focus styling is maintained when either clicking tab, or pressing left/right arrows after clicking tab
            let keyCode = event && event.keyCode;
            hasKeyboardFocus = keyCode === 9 || (hasKeyboardFocus && [37,39].includes(keyCode));
            if(hasKeyboardFocus) document.body.classList.add('using-keyboard-focus');
            else document.body.classList.remove('using-keyboard-focus');

            //updateInputType('keyboard');
        });
    
        document.body.addEventListener('touchstart', function () {
            document.body.classList.remove('using-mouse');
            document.body.classList.remove('using-keyboard');
            document.body.classList.remove('using-keyboard-focus');
            document.body.classList.add('using-touchscreen');
            //updateInputType('touch');
        });
        //default class based on device
        document.body.classList.add(`using-${device ==='desktop'?'mouse':'touchscreen'}`)
    }

    render() {
        return null;
    }
}

const mapStateToProps = (state) => ({
    device: state.ui.device
});

const mapDispatchToProps = (dispatch) => ({
    updateInputType: (type) => {
        dispatch(updateUserInputType(type))
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserInputUpdater);
