import React from 'react';
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce';

import { withError } from '../../../shared/components/error-boundary';

/**
 * Modified from https://github.com/SupremeTechnopriest/react-idle-timer
 */

const DEFAULT_EVENTS = [
  'mousemove',
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mouseWheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove'
];

const IS_BROWSER = (typeof window === 'undefined' ? 'undefined' : typeof (window)) === 'object'
const DEFAULT_ELEMENT = IS_BROWSER ? document : {}


class IdleUser extends React.Component {


  static propTypes = {
    timeout: PropTypes.number,
    events: PropTypes.arrayOf(PropTypes.string),
    element: PropTypes.oneOfType([PropTypes.object, PropTypes.element])
  }

  static defaultProps = {
    timeout: 1000 * 60 * 5,
    element: DEFAULT_ELEMENT,
    events: DEFAULT_EVENTS
  }

  tId = null;

  componentDidMount() {
    if (!IS_BROWSER) return
    const { element, events } = this.props
    events.forEach(e => {
      element.addEventListener(e, this.handleEvent, {
        passive: true
      })
    });
    this.reset();
  }

  componentWillUnmount() {
    clearTimeout(this.tId)

    if (!IS_BROWSER) return

    this.removeListener();
  }

  removeListener = () => {
    const { element, events } = this.props
    events.forEach(e => {
      element.removeEventListener(e, this.handleEvent, {
        passive: true
      })
    });
  }

  setIdle = () => {
    //update redux, user is idle
    this.props.setUserIdle(true);
  }

  reset = () => {
    // Set new timeout
    const { timeout } = this.props

    // Clear any existing timeout
    this.tId && clearTimeout(this.tId)

    //update redux, user is no longer idle
    this.props.setUserIdle(false);

    this.tId = setTimeout(this.setIdle, timeout)
  }

  handleEvent = debounce((e) => {
    this.reset();
  }, 800);

  render() {
    const { children } = this.props
    return children || null
  }

}

export default withError(IdleUser);
