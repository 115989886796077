import { SCREEN_RESIZE, UPDATE_USERINPUT_TYPE, getCustomBreakpoints } from './ui-action';

const initialState = {
    width: typeof window === 'object' ? window.innerWidth : null,
    height: typeof window === 'object' ? window.innerHeight : null,
};

const deviceKind = initialState.width <= 800 ? 'mobile' : initialState.width <= 1024 ? 'tablet' : 'desktop';
initialState.device = deviceKind;
initialState.customBreakpoints = getCustomBreakpoints(initialState.width, initialState.height);

const UIReducer = (state = initialState, action) => {
    switch (action.type) {
        case SCREEN_RESIZE: {
            let { width, height, device, customBreakpoints, innerHeight, innerWidth } = action;
            return {
                ...state,
                width,
                height,
                innerHeight,
                innerWidth,
                device,
                customBreakpoints: customBreakpoints || state.customBreakpoints,
                isServerCalc: false,
            };
        }
        case UPDATE_USERINPUT_TYPE: {
            let { inputType } = action;
            return { ...state, inputType };
        }
        default:
            return state;
    }
};

export default UIReducer;
