class Page {
    constructor(apiConfig, http) {
        const { siteInfo } = apiConfig;
        const { storeId } = siteInfo;
        this.apiConfig = apiConfig;
        this.http = http;
        this.path = `/wcs/resources/store/${storeId}/page`;
    }

    /**
     * Fetch page data
     * @param {string} template - template key of the page
     * @param {string} vanityUrl - the relative path of the page
     * @returns 
     */
    get(template, vanityUrl, config = {}) {
        const params = {
            path: vanityUrl
        };
        return this.http.get(`${this.path}/${template}`, { params, ...config })
            .then(resp => {
                const { data, status } = resp;
                //attempt to parse response
                if (status === 200 && data && data !== '') {
                    return data;
                } else {
                    return Promise.reject({})
                }
            });
    }
}

export default Page;


