import React from 'react';
/**
 * Example usage:
 * <ConditionalWrapper
        condition={link}
        wrapper={children => <Link newTab={newTab} to={link}>{children}</Link>} >
        <LazyLoadImage className={imageAlignment} src={src} alt={alt} title={title} customStyle={{width: width+"%"}}/>
    </ConditionalWrapper>
 */
export default ({ condition, wrapper, children }) => condition && wrapper ? wrapper(children) : children;
