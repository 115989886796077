import axios from 'axios';

import { Helpers } from '../helpers';

const HEADERS = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Expose-Headers': '*',
    'Access-Control-Allow-Credentials': true,
    'Content-type': 'application/json',
};

export default (siteConfig, userData) => {
    const { hpSSOClientKey } = siteConfig || {};
    const { profileData } = userData || {};
    const { logonUrl, logoutUrl, personData } = profileData || {};
    const { isLoggedIn } = personData || {};
    if (!hpSSOClientKey || process.env.NODE_ENV === 'development' || isLoggedIn) return Promise.resolve();

    return axios
        .get(hpSSOClientKey, { withCredentials: true, headers: HEADERS })
        .then(({ data }) => {
            const { sessionIsValid } = data || {};
            if (sessionIsValid && Helpers.getCookie('SSOLOGIN') == null) {
                Helpers.setCookie('SSOLOGIN', 'true');
                Helpers.setCookie('SSOLANDINGURL', window.location.href);
                logonUrl && (window.location.href = Helpers.decodeHtml(logonUrl));
            } else if (Helpers.getCookie('SSOLOGIN') == 'true' && !sessionIsValid) {
                Helpers.deleteCookie('SSOLOGIN');
                Helpers.deleteCookie('SSOLANDINGURL');
                logoutUrl && (window.location.href = Helpers.decodeHtml(logoutUrl));
            }
        })
        .catch(e => console.error('Error during sso check', e));
};
