
/**
 * @module Ensighten
 * @class
 * @internal
 */
class Ensighten {
    constructor(options = {}) {
        this.options = options;
        this.name = "Ensighten";
        this.optOut = false;
    }

    /**
     *
     * @method pageView
     * @param {String} eventName
     * @param {Object} params
     * @returns {Promise}
     * @internal
     */
     
    pageView(...args) {
        // Create the event.
        return this.track(...args);
    }

    /**
     *
     * @method track
     * @param {String} eventName
     * @param {Object} params
     * @returns {Promise}
     * @internal
     */

    track(eventName, params) {
        try{
            var event = new Event(eventName);
            // Dispatch the event.
            document.dispatchEvent(event);
        }catch(e){

        }
    }

}

export default Ensighten;