import {
    SUPPLIES_SEARCH,
    SUPPLIES_SEARCH_LOADING,
    SUPPLIES_SEARCH_KEYWORD,
    SUPPLIES_RESET,
} from './suppliesSearchActions.js';

const initialState = {
    searchResults: {},
    loading: false,
    latestSearch: '',
};

const SuppliesSearchReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUPPLIES_SEARCH: {
            let { results, keyword } = action;

            // TODO: temporary fix for late request, ideally should be cancelled
            if (keyword !== state.latestSearch) {
                return { ...state };
            }

            return { ...state, results };
        }
        case SUPPLIES_SEARCH_LOADING: {
            let { loading } = action;
            return { ...state, loading };
        }
        case SUPPLIES_SEARCH_KEYWORD: {
            let { latestSearch } = action;
            return { ...state, latestSearch };
        }
        case SUPPLIES_RESET: {
            return { ...state, results: {}, keyword: '' };
        }
        default:
            return state;
    }
};

export default SuppliesSearchReducer;
