import { MetricsObserver } from "../event-observer";
import { parseMetricParams } from '../track-params';

class StoreAppEvents {
    constructor(options = {}) {
        this.options = options;
        this.name = "StoreApp Events";
    }

    /**
     *
     * @method pageView
     * @param {String} eventName
     * @param {Object} params
     * @returns {Promise}
     * @internal
     */
     
    pageView(...args) {
        return this.track(...args);
    }

    /**
     *
     * @method track
     * @param {String} eventName
     * @param {Object} params
     * @returns {Promise}
     * @internal
     */

    track(eventName, params) {
        const {event = "", eventCallback} = params;
        let _params = parseMetricParams(eventName, params);
        MetricsObserver.publish( event, _params );
        try{
            if( typeof eventCallback === 'function' && window.location.hostname.indexOf('localhost') > -1){
                eventCallback();
            }
        }catch(e){}
    }

}

export default StoreAppEvents;