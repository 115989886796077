import { LOCATION_CHANGE } from 'connected-react-router';

import { CONFIRM_ADD_CART, REMOVE_CONFIRM } from './add-to-cart-confirm-actions';

const NULL_STATE = {};

const AddToCartConfirm = (state = NULL_STATE, action) => {
    Object.freeze(state);
    switch (action.type) {
        case CONFIRM_ADD_CART:
            return Object.assign({ type: action.confirmType, params: action.params });
        case REMOVE_CONFIRM:
            return NULL_STATE;
        case LOCATION_CHANGE:
            return NULL_STATE;
        default:
            return state;
    }
};

export default AddToCartConfirm;
