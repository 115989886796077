import React from 'react';
import loadable from '@loadable/component';

import { Helpers } from '../../../core/src/helpers';
import { withError } from '../error-boundary';

const ETRFingerPrint = loadable(() => Helpers.retryFunc(() => import('./etr-fingerprint')));

export default withError(({ enabled }) => {
    if (!enabled) {
        return null;
    }

    return <ETRFingerPrint />;
});
