export const HANDLE_SCRIPT_CREATE = 'HANDLE_SCRIPT_CREATE';
export const HANDLE_SCRIPT_ERROR = 'HANDLE_SCRIPT_ERROR';
export const HANDLE_SCRIPT_LOAD = 'HANDLE_SCRIPT_LOAD';

export const handleScriptCreate = (url) => ({type: HANDLE_SCRIPT_CREATE, url});

export const handleScriptError = (url) => ({type: HANDLE_SCRIPT_ERROR, url});

export const handleScriptLoad = (url) => ({type: HANDLE_SCRIPT_LOAD, url});

