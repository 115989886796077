import React from 'react';
import { useSelector } from 'react-redux';
import { Helpers } from '../core/src/helpers';

const EMPTY_OBJ = {};

export const getAbTestTypeFromPreferences = preferenceObj => {
    try{
        return  String(preferenceObj[2]) === '1' && String(preferenceObj[4]) === '1' && String(preferenceObj[8]) !== '0'
                ? String(preferenceObj[3]) === '1'
                ? 'personalization'
                : 'abtest'
                : null
    }catch(e){}
}

export const transformPreferenceStringToObj = Helpers.memoize(preferenceString => (
    typeof preferenceString === 'string' ? (
        preferenceString.split(',').reduce((allPreferences, preference) => {
            try{
                let [group, opt] = preference.split(':');
                allPreferences[group] = opt;
            }catch(e){}

            return allPreferences;
        }, {})
    ) : {}
));
const DEFAULT_TEST_GROUPS = '2:1,4:1';
const evaluatePreferenceStrings = (ssrTestFlag, preferences) => {
    try{
        if(ssrTestFlag.isFeature){
            return true;
        }
        
        let ssrTestFlagPreferences = ssrTestFlag && transformPreferenceStringToObj(ssrTestFlag.preferences || DEFAULT_TEST_GROUPS);
        let userPreferences = transformPreferenceStringToObj(preferences);

        return Object.keys(ssrTestFlagPreferences).every(pref => (
            ssrTestFlagPreferences[pref] === userPreferences[pref]
        ))
    }catch(e){}
}

const pickTestFlags = Helpers.memoize(
    (keys, testFlags = {}, preferences) => {
        return keys.reduce((r, key) => {
            if(evaluatePreferenceStrings(testFlags[key], preferences)){
                r[key] = testFlags[key];
            }
            return r;
        }, {});
    },
    (keys, testFlags, preferences) => `${keys && keys.join(',')}-${preferences}`
);

const get100PercentTestsOnly = ssrTest => (
    ssrTest ? Object.keys(ssrTest).reduce((enabledTests, key) => {
        if(ssrTest && ssrTest[key] && ssrTest[key].isFeature){
            enabledTests[key] = ssrTest[key];
        }
        return enabledTests
    }, {}) : {}
)

// abTestType unset if 8:0
const preferencesMap = {
    'personalization': '2:1,3:1,4:1',
    'abtest': '2:1,4:1'
}

/**
 * Returns server side AB test flags
 * @name useTestFlags
 * @returns {}
 */
export default function useTestFlags(keys) {
    const { ssrTest, abTestType } = useSelector(state => state.testFlags);
    const preferences = preferencesMap[abTestType];
    
    if(!preferences || preferences.length === 0){
        return get100PercentTestsOnly(ssrTest);
    }

    if (keys && keys.length > 0) {
        return pickTestFlags(keys, ssrTest, preferences);
    } else {
        return typeof ssrTest === 'object' && ssrTest ? pickTestFlags(Object.keys(ssrTest), ssrTest, preferences) : {}
    }
}

export function useTestFlagPreferences(){
    const testFlags = useSelector(state => state.testFlags);
    const { abTestType } = testFlags || {};
    return preferencesMap[abTestType]
}

export function useTestFlagAbTestType(){
    const testFlags = useSelector(state => state.testFlags);
    const { abTestType } = testFlags || {};
    return abTestType;
}

//Higher Order Component withTestFlags
export const withTestFlags = (Component, keys) => {
    return props => {
        const testFlags = useTestFlags(keys);
        return <Component {...props} testFlags={testFlags} />;
    };
};
