import { Helpers } from '../helpers';

export const CallCenterAPI = function(apiConfig, http){
  let self = this;

  self.csrDiscApporve = () => {
    return http.post("AjaxCSRDiscApproveCmd",{});
  }
  self.csrDiscount = () => {
    return http.post("AjaxCSRDiscountCmd",{});
  }
  self.emailQuote = () => {
    return http.post("emailQuote",{});
  }
  self.manageLVOFlag = () => {
    return http.post("ccAjaxManageLVOFlag",{});
  }
  self.manageOrderLock = (action) => {
     var params = {
        ccAction: action,
        storeId: apiConfig.siteInfo.storeId
      };

    return http.post("ccAjaxManageOrderLock",Helpers.parseFormData(params));
  }
  self.orderLockHelper = () => {
    return http.post("ccAjaxOrderLockHelper",{});
  }
};

