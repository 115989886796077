import { formatMetricValue, getValidProductBrandMetricValue, getBusinessUnit, derivePageNameValuesFromProductList } from '../metrics-helpers';
import { parseDlpProducts } from '../../dlp/util';

export default {
    pageView: ({ slugInfo, searchFilter, productData }) => {
        try{
            let { analyticsData, components } = slugInfo || {};
            let { productInfo } = productData || {};
            let { productPrices } = productInfo || {};
            let { dlpKeyword } = components || {};
            let { keyword } = dlpKeyword || {};
            let { searchResults } = (searchFilter && searchFilter[keyword]) || {};
            let products = searchResults && searchResults.hits && searchResults.hits.hits;

            let parsedProducts = parseDlpProducts(products, productPrices)
            let { family='shared', product_type='shared', bu='shared', segment='segment neutral' } = derivePageNameValuesFromProductList(parsedProducts) || {};
            return {
                family,
                product_type,
                bu,
                segment
            };
        }catch(e){}

        return {};
    }
}