import React, { Component } from 'react';
import PropTypes from 'prop-types';

import HeaderCart from './header-cart';
import HeaderSearch from './header-search';
import PopOver from './popover';
import ButtonIcon from './button-icon';
import SubMenu from './sub-menu';

import './header-desktop.less';

const POPOVER_KEY = 'headerTabs';

export class HeaderDesktop extends Component {
    state = {
        target: null,
        focus: -1,
        inMenu: false,
        inTabs: false,
    };

    onHover = (event, focus) => {
        const { showDropdown } = this.props;
        this.setState({
            focus,
            target: event.target,
        });
        showDropdown(POPOVER_KEY);
    };

    onMenuEnter = event => {
        this.setState({ inMenu: true });
    };

    onTabsEnter = () => {
        this.setState({ inTabs: true });
    };

    onMenuLeave = event => {
        this.setState({ inMenu: false });
        this.onDropDownClose();
    };

    onDropDownClose = () => {
        setTimeout(() => {
            const { inMenu, inTabs } = this.state;
            if (!inMenu && !inTabs) {
                this.onMenuClose();
            }
        }, 50);
        this.setState({ inTabs: false });
    };

    onMenuClose = () => {
        const { showDropdown } = this.props;
        this.setState({ focus: -1 });
        showDropdown(null);
    };

    onOpen = (event, i) => {
        if (event.which === 13 || event.which === 32) {
            this.onHover(event, i);
        }
        if (event.which !== 9) {
            event.preventDefault();
        }
    };

    render() {
        const {
            nav,
            popover,
            setSearchKey,
            setInputFocused,
            inputFocused,
            searchUrl,
            searchQueryStringKey,
            variation,
            openSearchModal,
            onInputFocusOut,
            searchKey,
            width,
        } = this.props;
        const { focus, target } = this.state;
        const offsetLeft = width < 1024 ? -43 : target && (target.offsetLeft || 0);

        return (
            <div className="menu-desktop">
                <div>
                    {!openSearchModal && (
                        <ul
                            className="tabs"
                            onMouseLeave={this.onDropDownClose}
                            onMouseEnter={this.onTabsEnter}
                            role="menu"
                        >
                            {nav.map((n, i) => (
                                <li
                                    onMouseEnter={event => this.onHover(event, i)}
                                    className={`${focus === i ? 'focus' : ''} tab`}
                                    key={i}
                                    aria-haspopup="true"
                                    role="menuitem"
                                    tabIndex={0}
                                    onKeyDown={e => this.onOpen(e, i)}
                                >
                                    {n.name}
                                </li>
                            ))}
                        </ul>
                    )}
                    {popover && (
                        <PopOver
                            show={popover}
                            onClose={this.onDropDownClose}
                            leftOffset={offsetLeft - 40}
                            onMouseEnter={this.onMenuEnter}
                            onMouseLeave={this.onMenuLeave}
                            overlay
                        >
                            <div className="submenu-container">
                                {nav[focus] &&
                                    nav[focus].categories &&
                                    nav[focus].categories.map((category, ck) => (
                                        <SubMenu
                                            title={category.name}
                                            items={category.items}
                                            key={ck}
                                            closeMenu={this.onMenuClose}
                                        />
                                    ))}
                                <div className="close-container">
                                    <ButtonIcon
                                        icon="icon-close"
                                        onClick={this.onMenuClose}
                                        ariaLabel="Close Sub Menu"
                                    />
                                </div>
                            </div>
                        </PopOver>
                    )}
                </div>
                <div className={`tools${inputFocused ? ' focused' : ''}`}>
                    <HeaderSearch
                        searchUrl={searchUrl}
                        searchQueryStringKey={searchQueryStringKey}
                        variation={variation}
                        setSearchKey={setSearchKey}
                        setInputFocused={setInputFocused}
                        inputFocused={inputFocused}
                        searchKey={searchKey}
                        onInputFocusOut={onInputFocusOut}
                    />
                    {!openSearchModal && <HeaderCart />}
                </div>
            </div>
        );
    }
}

HeaderDesktop.propTypes = {
    /** navigaton data object */
    nav: PropTypes.array.isRequired,
    /** popover open or closed state */
    popover: PropTypes.bool.isRequired,
    /** action sets which nav dropdown to show */
    showDropdown: PropTypes.func.isRequired,
};

export default HeaderDesktop;
