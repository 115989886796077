import React from 'react';
import loadable from '@loadable/component';

import { loadGraphQLData } from '../page';
import { Helpers } from '../core/src/helpers';

const CLPContainer = loadable(() => Helpers.retryFunc(() => import('./category-landing-page-container')));

export default {
    component: CLPContainer,
    loadData: loadGraphQLData,
};
