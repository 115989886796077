import React from 'react';
import loadable from '@loadable/component';
import { loadGraphQLData } from '../page';
import { Helpers } from '../core/src/helpers';

const MLP = loadable(() => Helpers.retryFunc(() => import('./mlp-container')));

export default {
    component: MLP,
    loadData: loadGraphQLData,
};
