import { SET_VIEW_OFFERS } from './compare-actions';

const DEFAULT_STATE = {};
const CompareResultsErrorReducer = (state = DEFAULT_STATE, action) => {
    Object.freeze(state);
    switch (action.type) {
        case SET_VIEW_OFFERS:
            const { sku } = action;
            return sku ? { [sku]: true } : DEFAULT_STATE;
        default:
            return state;
    }
};

export default CompareResultsErrorReducer;
