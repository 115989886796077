export const BreadcrumbAPI = function(apiConfig, http){
    let self = this;
    let path = "/HPBreadCrumbView";

    self.get = (productId, modelId, options) => {
        let q = {
            langId: -1,
            storeId: 10151,
            catalogId: 10051,
            urlLangId: -1
        };
        Object.assign(q, { productId, modelId }, options);

        return http.get(path,{
            params: q
        });
    };

};

