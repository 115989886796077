import React from 'react';
import { useHeroBannerProps } from '@hpstellar/core/visId/HeroBanner';
import { useInnovationBannerProps } from '@hpstellar/core/visId/InnovationBanner';
import { Helpers } from '../core/src/helpers';

export const html = prop => prop && <span dangerouslySetInnerHTML={Helpers.createMarkup(prop)} />;

export const getHeroBannerContent = banners =>
    banners.map((banner, idx) => {
        //override lazyload image settings on initial banner, since it's will most likely be the LCP element
        if (idx === 0) {
            banner.image = {
                ...banner.image,
                loading: 'eager',
            };
        }
        return {
            ...banner,
            title: html(banner.title),
            titleMobile: html(banner.titleMobile),
            subtitle: html(banner.subtitle),
            description: html(banner.description),
            descriptionMobile: html(banner.descriptionMobile),
        };
    });

export const getVisIdBannerContent = banner => {
    if (!banner) return {};

    return {
        ...banner,
        titleDesktop: html(banner?.titleDesktop),
        titleMobile: html(banner?.titleMobile),
        descriptionDesktop: html(banner?.descriptionDesktop),
        descriptionMobile: html(banner?.descriptionMobile),
        subtitleDesktop: html(banner?.subtitleDesktop),
        subtitleMobile: html(banner?.subtitleMobile),
        CalloutProps: {
            ...banner?.CalloutProps,
            label: html(banner?.CalloutProps?.label),
        },
    };
};

export const getBannerContent = (banner, options = {}) => {
    if (!banner) return {};
    const { image, titleTypographyProps, descriptionTypographyProps } = options;
    const { loading } = image || {};
    return {
        ...banner,
        title: html(banner?.title),
        titleMobile: html(banner?.titleMobile),
        titleTypographyProps,
        description: html(banner?.description),
        descriptionMobile: html(banner?.descriptionMobile),
        descriptionTypographyProps,
        disclaimer: {
            ...banner?.disclaimer,
            text: html(banner?.disclaimer?.text),
            terms: html(banner?.disclaimer?.terms),
        },
        ...(loading ? { image: { loading, ...banner.image } } : {}),
    };
};

export const getBannerContentArray = (banners, options) => {
    const content = banners.map(banner => getBannerContent(banner, options));
    return content;
};

export const getContentCardContent = ({ title, content }) => ({
    title: html(title),
    content: html(content),
});

export const getCountdownProps = format => {
    const longNamesMap = format.split(':').reduce((r, key) => {
        ['d', 'h', 'm', 's'].forEach(e => {
            if (key.indexOf(`${e}${e}`) > -1) {
                r[e] = true;
            }
        });
        return r;
    }, {});
    if (Object.keys(longNamesMap).length > 0) {
        return {
            format,
            translations: {
                getDayText: units => (longNamesMap['d'] ? `DAY${units > 1 ? 'S' : ''}` : 'd'),
                getHourText: units => (longNamesMap['h'] ? `HR${units > 1 ? 'S' : ''}` : 'h'),
                getMinuteText: units => (longNamesMap['m'] ? `MIN${units > 1 ? 'S' : ''}` : 'm'),
                getSecondText: units => (longNamesMap['s'] ? `SEC${units > 1 ? 'S' : ''}` : 's'),
            },
        };
    } else {
        //if not using long names then use default translations
        return {
            format,
        };
    }
};

export const getHighlightBannerContent = banner => {
    return {
        ...banner,
        title: html(banner?.title),
        titleMobile: html(banner?.titleMobile),
        description: html(banner?.description),
        descriptionMobile: html(banner?.descriptionMobile),
    };
};

export const getImageBannerContent = banner => {
    return {
        ...banner,
        title: html(banner?.title),
        titleMobile: html(banner?.titleMobile),
        description: html(banner?.description),
        descriptionMobile: html(banner?.descriptionMobile),
        subtitle: html(banner?.subtitle),
        subtitleMobile: html(banner?.subtitleMobile),
    };
};

export const getPartnerLogoContent = logos => {
    if (!logos || logos?.length < 1) return [];

    return logos?.map(logo => ({
        ...logo,
        description: html(logo?.description),
        descriptionMobile: html(logo?.descriptionMobile),
    }));
};

export const getTileContent = isMobile => tile => ({
    ...tile,
    title: html(tile?.title),
    titleMobile: html(tile?.titleMobile),
    description: html(tile?.description),
    descriptionMobile: html(tile?.descriptionMobile),
    image: isMobile ? tile?.imageMobile || tile?.image : tile?.image,
    cta: isMobile ? tile?.ctaMobile || tile?.cta : tile?.cta,
});

export const getTilesContentArray = (tiles, isMobile) => {
    const content = tiles.map(tile => getTileContent(isMobile)(tile));
    return content;
};

const getInnovationBannerWithHtml = innovationBanner => {
    return {
        ...innovationBanner,
        title: html(innovationBanner.title),
        description: html(innovationBanner.description),
        disclaimer: html(innovationBanner.disclaimer),
    };
};

export const extractCommonProperties = banners => {
    let onlyBanners = banners;
    let commonProperties = {};
    if(banners[0].commonProperties) {
        commonProperties = banners[0].commonProperties;
        onlyBanners = banners.slice(1);
    }

    let bannerProps = {};
    bannerProps['slides'] = onlyBanners;
    const heroBannerProps = useHeroBannerProps(bannerProps);
    let { slides } = heroBannerProps;
    slides = slides.map(slide => {
        const { titleTypographyProps, subtitleTypographyProps } = slide;
        //TODO: default tags for title and subtitle when unset. See if GFE team can fix this in the hook
        if (titleTypographyProps && !titleTypographyProps.tag) {
            titleTypographyProps.tag = 'h2';
        }
        if (subtitleTypographyProps && !subtitleTypographyProps.tag) {
            subtitleTypographyProps.tag = 'h2';
        }
        // check if there is innovation banner
        if ('InnovationBannerProps' in slide) {
            const slideWithInnovationProps = useInnovationBannerProps(slide['InnovationBannerProps']);
            const slideInnovationPropsWithHtml = getInnovationBannerWithHtml(slideWithInnovationProps);

            return {
                ...slide,
                title: html(slide.title),
                subtitle: html(slide.subtitle),
                description: html(slide.description),
                disclaimer: html(slide.disclaimer),
                InnovationBannerProps: slideInnovationPropsWithHtml,
            };
        }

        return {
            ...slide,
            title: html(slide.title),
            subtitle: html(slide.subtitle),
            description: html(slide.description),
            disclaimer: html(slide.disclaimer),
        };
    });

    return {
        slides: slides,
        commonProperties: commonProperties
    }
};
