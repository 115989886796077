import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';


const TestFlagWrapper = (Component) => (props) => {
    return(
        <Component {...props}/>
    )
}

const EMPTY_OBJECT = {};

const mapStateToProps = (state, ownProps) => {
    //add user data in cases need for test
    let { testFlags, userData=EMPTY_OBJECT,routes,siteConfig=EMPTY_OBJECT } = state;
    let { profileData=EMPTY_OBJECT } = userData;
    let { personData=EMPTY_OBJECT } = profileData;
    return {
        testFlags,
        personData,
        routes,
        basename: siteConfig.basename
	}; 
};


export const withTestFlags = compose(connect(mapStateToProps, null),TestFlagWrapper);


export const TestFlags = compose(connect(mapStateToProps, null),class extends React.Component{
    render(){
        let { testFlags } = this.props;
        return this.props.children(testFlags);
    }
});
