import EtrAPIClient from '../core/src/etr-wcs-api-client';

export const SUBSCRIPTION_PROMO_ERROR = 'SUBSCRIPTION_PROMO_ERROR';
export const SUBSCRIPTION_PROMO_FETCH = 'SUBSCRIPTION_PROMO_FETCH';
export const SUBSCRIPTION_PROMO_RECEIVE = 'SUBSCRIPTION_PROMO_RECEIVE';
export const PROMO_ERROR = 'PROMO_ERROR';
export const PROMO_FETCH = 'PROMO_FETCH';
export const PROMO_RECEIVE = 'PROMO_RECEIVE';

export const fetchSubscriptionsPromo = paths => dispatch => {
    dispatch({ type: 'SUBSCRIPTION_PROMO_FETCH', paths });
    const eSpotName = 'Subscriptions-ListingPages-Logo';

    //TODO: API doesn't work with multiple anymore make separate calls for now.
    //Catch errors for each fetch to prevent one failure from messing up the rest
    return Promise.all(
        paths.map(p =>
            EtrAPIClient.component
                .get('espot', eSpotName, {
                    path: `pdp/${p}`,
                })
                .catch(e => ({ [eSpotName]: [{ title: '', content: [] }] }))
        )
    )
        .then(values => {
            const promos = values.reduce((r, data, idx) => {
                const { content } = (data || {})[eSpotName]?.[0] || {};
                const slug = paths[idx];
                if (content && content.length > 0) {
                    r[slug] = { content: content[0] };
                }
                return r;
            }, {});
            if (promos && Object.keys(promos).length > 0) {
                dispatch({ type: 'SUBSCRIPTION_PROMO_RECEIVE', promos });
            }
        })
        .catch(e => {
            dispatch({ type: 'SUBSCRIPTION_PROMO_ERROR', error: e });
        });
    /* return EtrAPIClient.component
        .get('espot', eSpotName, {
            path: paths.map(p => `pdp/${p}`).join(','),
        })
        .then(data => {
            const keys = (data || {})[eSpotName];
            const promos = (keys || []).reduce((r, promo) => {
                const [slug] = Object.keys(promo);
                const { content } = promo[slug]?.[0] || {};
                if (content && content.length > 0) {
                    r[slug] = { content: content[0] };
                }
                return r;
            }, {});
            if (promos && Object.keys(promos).length > 0) {
                dispatch({ type: 'SUBSCRIPTION_PROMO_RECEIVE', promos });
            }
        })
        .catch(e => {
            dispatch({ type: 'SUBSCRIPTION_PROMO_ERROR', error: e });
        });*/
};

export const fetchOfferPromos = (productIds, batchSize) => dispatch => {
    //sort productIds then break into batches, then fetch each batch and dispatch
    dispatch({ type: PROMO_FETCH, paths: productIds });
    const sortedIds = productIds.sort();
    const batches = [];
    while (sortedIds.length > 0) {
        batches.push(sortedIds.splice(0, batchSize));
    }
    return Promise.all(batches.map(batch => EtrAPIClient.component.getOffers(batch).catch(e => [])))
        .then(values => {
            const promos = values.reduce((r, data) => {
                const { specialOffers } = data || {};
                const keys = Object.keys(specialOffers || {});
                const batchPromos = (keys || []).reduce((r, promo) => {
                    const offers = specialOffers[promo];
                    if (offers && offers.length > 0) {
                        r[promo] = offers;
                    }
                    return r;
                }, {});
                return { ...r, ...batchPromos };
            }, {});
            if (promos && Object.keys(promos).length > 0) {
                dispatch({ type: PROMO_RECEIVE, promos });
            }
        })
        .catch(e => {
            dispatch({ type: PROMO_ERROR, error: e });
        });
};
