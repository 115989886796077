export const mapAccessories = accessories => {
    const { products, ...rest } = accessories;
    let categories = [];
    let productMap;
    //if products is an array, it's an ordered list which we can use to create the category list
    // which is what is actually used to determin order in the UI.
    if (Array.isArray(products)) {
        productMap = products.reduce((acc, pObj) => {
            const [title] = Object.keys(pObj);
            const products = pObj[title];
            if (products?.length < 1) {
                return acc;
            }
            const iconKey = title.toLowerCase();
            categories.push({
                title,
                id: title,
                iconName: getIconName(iconKey),
            });
            return {
                ...acc,
                [title]: products,
            };
        }, {});
    } else {
        productMap = products;
        categories = Object.keys(products).reduce((acc, title) => {
            if (products[title]?.length < 1) {
                return acc;
            }

            const iconKey = title.toLowerCase();
            return [
                ...acc,
                {
                    title,
                    id: title,
                    iconName: getIconName(iconKey),
                },
            ];
        }, []);
    }

    const response = {
        categories,
        products: productMap,
        ...rest,
    };
    return response;
};

const getIconName = iconKey => {
    const key = iconKey.toLowerCase();
    if (key.indexOf('multimedia') > -1 || key.indexOf('headset') > -1) {
        return 'multimedia';
    }
    if (key.indexOf('bags') > -1 || key.indexOf('cases') > -1) {
        return 'bags_cases';
    }
    if (key.indexOf('batteries') > -1) {
        return 'batteries';
    }
    if (key.indexOf('business') > -1) {
        return 'business';
    }
    if (key.indexOf('cables') > -1) {
        return 'cables';
    }
    if (key.indexOf('calculators') > -1) {
        return 'calculators';
    }
    if (key.indexOf('care packs') > -1) {
        return 'care_packs';
    }
    if (key.indexOf('connectivity') > -1) {
        return 'connectivity';
    }
    if (key.indexOf('docking') > -1) {
        return 'docking';
    }
    if (key.indexOf('gaming') > -1) {
        return 'gaming';
    }
    if (key.indexOf('ink') > -1) {
        return 'ink_cartridges';
    }
    if (key.indexOf('mice') > -1 && key.indexOf('keyboards') > -1) {
        return 'mice_keyboards';
    }
    if (key.indexOf('monitors') > -1) {
        return 'monitors';
    }
    if (key.indexOf('paper') > -1) {
        return 'paper';
    }
    if (key.indexOf('printer') > -1 && key.indexOf('accessories') > -1) {
        return 'printing_accessories';
    }
    if (key.indexOf('smart home') > -1) {
        return 'smarthome';
    }
    if (key.indexOf('software') > -1) {
        return 'software';
    }
    if (key.indexOf('stem') > -1) {
        return 'stemandkids';
    }
    if (key.indexOf('storage') > -1) {
        return 'storage';
    }
    if (key.indexOf('toner') > -1) {
        return 'toner';
    }
    if (key.indexOf('workspace') > -1) {
        return 'workspace';
    }
};
