import { formatMetricValue, getDerivedProductPageNameLevels } from '../metrics-helpers';

export default {
    baseConfig: ({ component }) => {
        const {recommendedConfigs, specCategories, sku} = component || {};
        if(!component) return;
        const { ctaCompareLink, ctaCompareText, ...configs } = recommendedConfigs || {};
        Object.values(configs).forEach(config => {
            const {configName} = config;
            config.gtmActions = new Map([
                    [
                        'linkClick',
                        {
                            gtmId: 'recommended-config',
                            gtmCategory: 'linkClick',
                            gtmValue: formatMetricValue(configName)
                        }
                    ]
                ]);
        })

        component.sendConfigurationGtmAttributes = {
            'data-gtm-id': 'send-configuration',
            'data-gtm-category': 'linkClick',
            'data-gtm-value': 'product'
        };

        if(specCategories){
            component.specCategories = specCategories.map(specCat => {
                let {optionitems, title, type} = specCat || {}
                try{
                    specCat.optionitems = optionitems.map(opt => {
                        let {name} = opt || {};
                        
                        if(opt){
                            opt.gtmActions = new Map([
                                [
                                    type === 'radio' ? 'radioClick' : 'checkboxClick',
                                    {
                                        gtmCategory: 'configuratorChange',
                                        gtmId: sku, //TODO: using baseSku, check if we need to use sku associated with option
                                        gtmValue: 'cto',
                                        gtmComponentSelection: formatMetricValue(name),
                                        gtmComponentCategory: formatMetricValue(title)
                                    }
                                ]
                            ])
                        }
                        return opt;
                    });
                }catch(e){}

                return specCat;
            })
        }

        return component;
    },
    compareConfig: ({ component }) => {
        if(!component) return component;
        component.gtmAttributes = {
            'data-gtm-category': 'linkClick',
            'data-gtm-id': 'recommended-config',
            'data-gtm-value': 'compare-options'
        }
        
        return component;
    },
    ctoPrice: ({ component }) => {
        if(!component) return component;

        try{
            let { salePrice, regularPrice } = component || {};
            if(window.pagePropertiesData.mainSKU){
                window.pagePropertiesData.mainSKU.price = salePrice || regularPrice;
            }
        }catch(e){}

        return component;
    },
    pageView: ({ slugInfo }) => {
        try{
            let {vanityUrl, components} = slugInfo;
            let {ctoProductInitial, baseConfig} = components;
            let {title} = baseConfig || {};
            let derivedProductAnalytics = getDerivedProductPageNameLevels(ctoProductInitial, null, true)
            let isCtoStep2 = typeof vanityUrl === 'string' && /(configureattach|AccessoryAttachView)/i.test(vanityUrl);
            
            return {
                page_level: 'cto',
                simple_title: isCtoStep2 ? 'cto-accessory-attach' : formatMetricValue(title),
                ...isCtoStep2 ? { bu : 'ps' } : (derivedProductAnalytics || {})
            }
        }catch(e){}

        return {};
    }
}