import { StoreAppAPI, isCancelledRequest } from '../core/src/storeapp-api-client';
import { Helpers } from '../core/src/helpers';
import { receiveProducts } from '../product/product-actions';
import { receiveHeaderFooter } from './components/header-footer/header-footer-actions';

export const TRACK_PAGE_VIEW = 'TRACK_PAGE_VIEW';
export const FETCH_SLUG_INFO = 'FETCH_SLUG_INFO';
export const RECEIVE_SLUG_INFO = 'RECEIVE_SLUG_INFO';
export const UPDATE_SLUG_INFO = 'UPDATE_SLUG_INFO';
export const RECEIVE_ASYNC_COMPONENTS = 'RECEIVE_ASYNC_COMPONENTS';
export const UPDATE_COMPONENTS = 'UPDATE_COMPONENTS';
export const RECEIVE_SLUG_ERROR = 'RECEIVE_SLUG_ERROR';
export const CLEAR_SLUG_ERROR = 'CLEAR_SLUG_ERROR';
export const ADD_REDIRECT = 'ADD_REDIRECT';
export const CLEAR_REDIRECTS = 'CLEAR_REDIRECTS';
export const RECEIVE_SITE_CONFIG = 'RECEIVE_SITE_CONFIG';
export const UPDATE_SEO = 'UPDATE_SEO';

const storeAppAPI = new StoreAppAPI();

const waitFor = timeout => {
    return new Promise((resolve, reject) => {
        setTimeout(resolve, timeout);
    });
};

export const fetchingSlugInfo = () => ({
    type: FETCH_SLUG_INFO,
});

export const receiveSlugInfo = slugInfo => ({
    type: RECEIVE_SLUG_INFO,
    slugInfo,
});

export const updateSlugInfo = slugInfo => ({
    type: UPDATE_SLUG_INFO,
    slugInfo,
});

export const receiveSiteConfig = siteConfig => ({
    type: RECEIVE_SITE_CONFIG,
    siteConfig,
});

export const updateComponent = (components, mergeFunction) => ({
    type: UPDATE_COMPONENTS,
    components,
    mergeFunction,
});

export const updateSEO = (seo, mergeFunction) => ({
    type: UPDATE_SEO,
    seo,
    mergeFunction,
});

/**
 * Tracking dispatch for metrics-middleware, this is to tigger page view metrics on hard load
 * Since the page data is stored in the inital redux store
 * @param {*} slugInfo
 */
export const trackPageView = slugInfo => ({
    type: TRACK_PAGE_VIEW,
    slugInfo,
});

export const receiveSlugError = () => ({
    type: RECEIVE_SLUG_ERROR,
});

export const receiveAsyncComponents = (components, nextQuery, errors) => ({
    type: RECEIVE_ASYNC_COMPONENTS,
    components,
    nextQuery,
    errors,
});

export const clearSlugError = () => ({
    type: CLEAR_SLUG_ERROR,
});

export const addRedirect = (destination, status, isPGRedirect) => {
    return (dispatch, getState) => {
        const store = getState();
        const { routes } = store;
        dispatch({
            type: ADD_REDIRECT,
            destination,
            routes,
            status,
            isPGRedirect, // not stored in redux but used in the metrics-middleware reducer
        });
    };
};

export const clearRedirects = () => ({
    type: CLEAR_REDIRECTS,
});

const UXABTEST_MAP = {
    'sale-v3': 'sale-v4',
    filters: 'filters-v2',
    dlp: 'dlp-v2',
    pdp: 'pdp-v2',
    'pdp-puf': 'pdp-v2',
    blog: 'blog-v2',
};
export const processSlugInfo = (slugInfo, testFlags) => {
    let ids = [];
    let productObj = {};
    let defaultPg;
    let { templateKey, template_key, components = [] } = slugInfo;
    //TEMP AB test code
    const { ssrTest = {} } = testFlags;
    const { uxRedesign } = ssrTest;

    //TODO: remove this backwards compatability changes
    slugInfo.templateKey = templateKey || template_key;
    slugInfo.components = components.reduce((map, comp) => {
        let { key } = comp;
        map[key] = comp;
        return map;
    }, {});

    if (uxRedesign && uxRedesign.variation && uxRedesign.variation.toLowerCase() === 'variation') {
        let templateKey = UXABTEST_MAP[slugInfo.templateKey] || slugInfo.templateKey;
        //limit sale-v4 to weekly-deals and weekly-smb-deals
        if (
            templateKey !== 'sale-v4' ||
            slugInfo.key in { weekly_deals: 1, weekly_smb_deals: 1, 'presidents-day-sale': 1 }
        ) {
            slugInfo.templateKey = templateKey;
        }
    }
    let { productGroups = [] } = slugInfo.components.productTab || {};

    productGroups.forEach(pg => {
        const { productsList, metaData, desc, name, key, disableIntelIcon } = pg;
        if (productsList) {
            let pgObj = {
                key,
                products: productsList,
                sortValue: 'rank.recommended',
                metaData: metaData,
                description: desc,
                name: name,
                disableIntelIcon,
            };
            const { sortBy } = metaData || {};
            if (!defaultPg) defaultPg = key;
            //If override sortBy provided, change the default sort order
            if (sortBy && sortBy !== 'manual') {
                pgObj.products = Helpers.overrideDefaultSortOrder(pgObj.products, 'recommended', sortBy);
            }
            productObj[key] = pgObj;
            productsList.forEach(product => {
                if (product.prdClass !== 'USER_DEFINED') {
                    ids.push(product.itemId);
                }
            });
        }
    });
    slugInfo.defaultPg = defaultPg;

    return [ids, productObj, slugInfo];
};

const dispatchSlugInfo = (resp, dispatch, testFlags, router = {}, hasRedirect) => {
    const [ids, productObj, slugInfo] = processSlugInfo(resp.data, testFlags);
    dispatch(receiveProducts(productObj));
    if (!hasRedirect) {
        dispatch(clearRedirects());
    }
    const { location } = router;
    if (location) {
        const { search } = location;
        if (search) {
            slugInfo.urlParams = Helpers.getSearch(search);
        }
    }

    return dispatch(receiveSlugInfo(slugInfo));
};

export const dispatchGraphQLResponse = (response, dispatch, clearSlugInfo) => {
    const { data, errors, extensions } = typeof response === 'string' ? JSON.parse(response) : response;
    const { page, seo } = data || {};
    const {
        pageComponents,
        globalComponents,
        status: statusResponse,
        redirectTo,
        gqlAsyncClientLoad = false,
        ...rest
    } = page || {};
    const { privateStoreHeader } = pageComponents || {};

    const { pStoreID, customHTML } = privateStoreHeader || {};

    const status = statusResponse * 1;
    const { redirect: extRedirect, gqlClientLoad, ...extensionsRest } = extensions || {};
    const { status: extStatus, redirectTo: extRedirectTo } = extRedirect || {};
    if (status === 404) {
        dispatch(addRedirect(redirectTo, status));
        return dispatch(receiveSlugError());
    } else if (status >= 300 && status < 400) {
        return dispatch(addRedirect(redirectTo, status));
    } else if (extStatus >= 300 && extStatus < 400 && extRedirectTo) {
        return dispatch(addRedirect(extRedirectTo, extStatus));
    }

    // private store header from ETR API
    if (customHTML && pStoreID) {
        dispatch(
            receiveHeaderFooter({
                customHTML,
                pStoreID,
                etrHeader: true,
            })
        );
    }
    //clear redirects on 2xx response
    if (status >= 200 && status < 300) {
        dispatch(clearRedirects());
    }
    //For client side GQL prevent full page refresh when clearSlugInfo is false
    const dispatchAction = !clearSlugInfo && gqlClientLoad ? updateSlugInfo : receiveSlugInfo;
    return dispatch(
        dispatchAction({
            components: {
                ...pageComponents,
                ...globalComponents,
            },
            seo,
            errors,
            ...rest,
            gql: true,
            gqlClientLoad,
            gqlAsyncClientLoad,
            ...extensionsRest,
        })
    );
};

export const dispatchAsyncComponents = (response, dispatch) => {
    //TODO: figure out why when data is picked from redis for graphql on the server it's returned as a string rather than JSON
    //maybe because the injected express server lacks some middleware?
    const { data, errors } = typeof response === 'string' ? JSON.parse(response) : response;
    const { page } = data;
    const { pageComponents, nextQuery } = page || {};
    return dispatch(receiveAsyncComponents(pageComponents, nextQuery, errors));
};

/**
 * Bulk fetch prices and inventory data for products and product
 *
 * @param {string} slug - vanity url of page to be fetch
 * @param {boolean} options.useGraphQL - If true use graphQL endpoint rather than REST
 */
export const fetchSlugInfo = (slug, options) => (dispatch, getState) => {
    //TEMP AB test code
    const store = getState();
    const { router, testFlags } = store;
    const { useGraphQL, hash, shouldClearSlugInfo, ...restOptions } = options;
    const clearSlugInfo = !shouldClearSlugInfo || shouldClearSlugInfo(store, slug);

    if (clearSlugInfo) {
        dispatch(fetchingSlugInfo());
        //cancel inflight page requests if any. This is helpful during client side routing
        //if someone hits the back button before the page API responds. Prevents a weird race condition where previous page data gets set in redux
        storeAppAPI.cancelRequests();
    }
    if (useGraphQL) {
        return storeAppAPI.graphql
            .getPersistedPath(slug, hash || 'sync', { enableRequestCancel: true, ...restOptions })
            .then(({ data: response }) => {
                dispatchGraphQLResponse(response, dispatch, clearSlugInfo);
            })
            .catch(e => {
                if (isCancelledRequest(e)) {
                    return;
                }
                return dispatch(receiveSlugError());
            });
    }
    return storeAppAPI.page.get(slug, restOptions, { enableRequestCancel: true }).then(
        resp => {
            const { status } = resp.data;

            if (status === 404) {
                dispatch(addRedirect(resp.data.destination, status));
                return dispatch(receiveSlugError());
            } else if (status >= 300 && status < 400) {
                dispatch(addRedirect(resp.data.destination, status));
            } else if (resp.status * 1 === 200) {
                let hasRedirect = resp.data.redirectStatusCode && resp.data.destination;
                if (hasRedirect) dispatch(addRedirect(resp.data.destination, resp.data.redirectStatusCode, true));

                dispatchSlugInfo(resp, dispatch, testFlags, router, hasRedirect);
            }
        },
        err => {
            if (isCancelledRequest(err)) {
                return;
            }
            if (
                err.response &&
                ((err.response.status >= 300 && err.response.status < 400) ||
                    (err.response.status === 404 && err.response.data.destination))
            ) {
                return dispatch(addRedirect(err.response.data.destination, err.response.status));
            } else {
                dispatch(addRedirect());
            }
            return dispatch(receiveSlugError());
        }
    );
};

/**
 * Fetch component not needed for above the fold render of a page
 *
 * @param {string} slug - vanity url of the page to fetch async components for
 * @param {object} options
 * @returns
 */
export const fetchAsyncComponents = (slug, hash, options) => (dispatch, getState) => {
    return storeAppAPI.graphql
        .getPersistedPath(slug, hash, options)
        .then(({ data: response }) => {
            dispatchAsyncComponents(response, dispatch);
        })
        .catch(e => {
            //prevent spam of requests on error and clear out next query and push error
            dispatch(receiveAsyncComponents({}, null, [e]));
        });
};

export const updatePageComponents = (components, mergeFunction) => dispatch => {
    return new Promise((resolve, reject) => {
        try {
            dispatch(updateComponent(components, mergeFunction));
            resolve();
        } catch (e) {
            reject(e);
        }
    });
};

/**
 * For local dev only. Returns site config data
 *
 * @returns
 */
export const fetchSiteConfig = () => dispatch => {
    storeAppAPI.component.get(['siteConfig']).then(resp => {
        if (resp.status * 1 === 200) {
            const { data } = resp;
            dispatch(receiveSiteConfig(data));
        }
    });
};

/**
 * Default SSR load function
 * @name loadData
 * @param {*} urlParams
 * @param {*} store
 * @param {*} querySelector
 * @returns
 */
export const loadData = async (urlParams, store, context) => {
    const { dir, slug, filters } = urlParams;
    const fullSlug = dir && slug ? `/${dir}/${slug}${filters ? '/' + filters : ''}` : '/';
    await store.dispatch(fetchSlugInfo(fullSlug));

    const { slugInfo, redirects, basename } = store.getState();

    const needRedirect = !slugInfo || slugInfo.error || (redirects && redirects.count !== 0);

    const { destination = `${basename}/slp/weekly-deals`, status = 301 } = redirects;
    if (needRedirect) return Promise.resolve({ status, destination });

    return Promise.resolve(slugInfo);
};

/**
 * Load chain of queries, used for BOT traffic primarily
 * @param {*} store
 * @param {*} fullSlug
 * @param {*} hash
 */
const loadAsyncData = async (fullSlug, store, hash, options) => {
    await store.dispatch(fetchAsyncComponents(fullSlug, hash, options));
    //TODO: temp fix might be better to chain the fetches then marge and dispatch at the end
    //throw into the event loop to hope that the redux state is properly updated
    await waitFor(100);
    const { slugInfo } = store.getState();
    const { nextQuery } = slugInfo || {};
    if (nextQuery && nextQuery.hash) {
        return loadAsyncData(fullSlug, store, nextQuery.hash, options);
    }
};

/**
 * Handle bot traffic by synchronously loading all API data to
 * ensure that bots get consistently rendered content
 * @param {*} store
 * @param {*} fullSlug
 * @param {*} context
 */
export const dynamicLoadData = async (fullSlug, store, context, options) => {
    const { hash } = options || {};
    const { headers, query, deviceInfo } = context;
    const { isBot } = deviceInfo || {};
    const httpOptions = { params: query, headers };
    await store.dispatch(fetchSlugInfo(fullSlug, { useGraphQL: true, hash, ...httpOptions }));
    //TODO: temp fix might be better to chain the fetches then marge and dispatch at the end
    //throw into the event loop to hope that the redux state is properly updated
    await waitFor(100);
    const { slugInfo: syncPageData } = store.getState();
    const { nextQuery } = syncPageData || {};
    if (nextQuery && nextQuery.hash && isBot) {
        return loadAsyncData(fullSlug, store, nextQuery.hash, httpOptions);
    }
};

/**
 * Default SSR load function with graphql endpoint
 * @name loadGraphQLData
 * @param {*} urlParams
 * @param {*} store
 * @param {*} querySelector
 * @returns
 */
export const loadGraphQLData = async (urlParams, store, context = {}) => {
    const { dir, slug, slugTwo, filters } = urlParams;
    const fullSlug = dir && slug ? `${dir}/${slug}${slugTwo ? `/${slugTwo}` : ''}${filters ? '/' + filters : ''}` : '/';
    await dynamicLoadData(fullSlug, store, context);
    const { slugInfo, redirects, basename } = store.getState();

    const needRedirect = !slugInfo || slugInfo.error || (redirects && redirects.count !== 0);

    const { destination = `${basename}/slp/weekly-deals`, status = 301 } = redirects;
    if (needRedirect) return Promise.resolve({ status, destination });

    return Promise.resolve(slugInfo);
};
