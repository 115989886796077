import { SET_COMPARE_ERROR, SET_COMPARE_MAXED } from './compare-actions';

const CompareResultsErrorReducer = (state = { error: false, maxed: false }, action) => {
    Object.freeze(state);
    switch (action.type) {
        case SET_COMPARE_MAXED:
            return { ...state, maxed: action.maxed };
        case SET_COMPARE_ERROR:
            return { ...state, error: action.error };
        default:
            return state;
    }
};

export default CompareResultsErrorReducer;
