import { LOCATION_CHANGE } from 'connected-react-router';

import { RECEIVE_ADD_ONS } from './addon-actions';

const NULL_STATE = {};

const AddOnReducer = (state = NULL_STATE, action) => {
	Object.freeze(state);
	switch(action.type) {
		case LOCATION_CHANGE:
			return NULL_STATE;
		case RECEIVE_ADD_ONS:
			return Object.assign({}, state, { [action.sku]: action.addOns });
		default:
			return state;
	}
};

export default AddOnReducer;
