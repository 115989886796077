import { productTypeMap, addGtmAttributesToModelTiles, formatMetricValue, getValidProductBrandMetricValue, getValidProductCategoryMetricValue, getBusinessUnit, getMarketSegment } from '../metrics-helpers';

export default {
    mlpModelList: ({ component }) => {
        let { models } = component || {};
        if(!component || !Array.isArray(models)){
            return component;
        }
        component.models = addGtmAttributesToModelTiles(models)
        return component;
    },
    pageView: ({ slugInfo }) => {
        try{
            const { components, analyticsData, vanityUrl } = slugInfo || {};
            const { page_level, product_type, family, bu, simple_title, segment } = analyticsData || {};
            const { mlpModelList } = components || {};
            const { sectionTitle } = mlpModelList || {};
            const mlpTitle = vanityUrl && vanityUrl.split('/').pop();
            const productType = (
                productTypeMap[product_type] || 
                getValidProductCategoryMetricValue(product_type === 'business' ? simple_title : product_type, true)
            );
            const businessUnit = getBusinessUnit(productType);
            return {
                ...analyticsData || {},
                segment: typeof vanityUrl === 'string' && /business\-solutions/i.test(vanityUrl)
                         ? 'commercial.smb'
                         : getMarketSegment(family),
                //typeof simple_title === 'string' && /business/i.test(simple_title) ? 'commercial.smb' : segment,
                product_type: productType,
                bu: businessUnit,
                family: getValidProductBrandMetricValue(family, null, family, true),
                // Analytics API was updated to have the following format. However, some MLPs are still incorrect.
                simple_title: `${formatMetricValue(sectionTitle || mlpTitle || '')}-${page_level}`
            }
        }catch(e){}
        
        return slugInfo && slugInfo.analyticsData;
    }
}