import { Helpers } from '../helpers';

class CTOAPI {
    constructor(apiConfig, http) {
        this.apiConfig = apiConfig;
        this.http = http;
    }

    getLeadTime(parentSku, skus) {
        const params = { parentSku, skus: skus.join(',') };
        return this.http.get('HPCTOLeadTimeService', { params }).then(({ data }) => {
            return data;
        });
    }
}

export default CTOAPI;
