import React from 'react';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { Redirect } from 'react-router-dom';

import { clearRedirects } from '../../page/page-actions';

const APP_REDIRECT_URL = '/slp/weekly-deals';
const APP_REDIRECT = true;
const EMPTY_OBJECT = {};

class ErrorRedirect extends React.Component {
    constructor(props) {
        super(props);
        this.REDIRECT_404 = props.basename ? props.basename : '/us-en/shop';
    }

    componentDidMount() {
        const { basename, redirects, to } = this.props;
        if (typeof window === 'undefined') return;
        if (APP_REDIRECT && redirects.count < 3) {
            const dest = (to && `${basename}${to}`) || redirects.destination || `${basename}${APP_REDIRECT_URL}`;
            const search = !process.env.ISNODE ? this.props.router.location.search : '?ssr=1';

            if (/^\//.test(dest)) {
                // if is in-app redirect
                if (!process.env.ISNODE) {
                    this.props.replaceHistory(dest, search);
                    this.props.clearRedirects();
                } else {
                    this.redirectUrl = `${basename}${dest}${search}`;
                }
            } else {
                // in case person specified no protocol - e.g. store.hp.com
                const prefix = /^https?/.test(dest) ? '' : 'https://';
                window.location = `${prefix}${dest}${search}`;
            }
        } else {
            // attempt to redirect to a non 404 page failed; failing over to store home
            window.location = this.REDIRECT_404;
        }
    }

    render() {
        const redirectVal = (this.redirectUrl || this.REDIRECT_404).replace(/\/$/, '');
        return !this.props.redirectOnMount && <Redirect to={redirectVal} />;
    }
}

const mapStateToProps = state => {
    const { siteConfig = EMPTY_OBJECT } = state;
    return {
        //prevent re-render of component when clearing redirects
        redirects: state.redirects.count > 0 ? state.redirects : EMPTY_OBJECT,
        router: state.router,
        basename: siteConfig.basename,
    };
};

const mapDispatchToProps = dispatch => ({
    replaceHistory: (pathname, queryString) => {
        dispatch(
            replace({
                pathname: pathname,
                search: queryString,
            })
        );
    },
    clearRedirects: (...args) => dispatch(clearRedirects(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorRedirect);
