import React from 'react';
import PropTypes from 'prop-types';

import { withError } from './error-boundary';
import { Helpers } from '../../core/src/helpers';

const timeParts = {
    d: 'days',
    h: 'hours',
    m: 'minutes',
    s: 'seconds',
};
const regexFormat = /(d{2}\?*|d{1}\?*|h{2}\?*|h{1}\?*|m{2}\?*|m{1}\?*|s{2}\?*|s{1}\?*)/g;
const delimRegex = /[^d|s|m|h|?]/g;

class Countdown extends React.Component {
    static propTypes = {
        endtime: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string, PropTypes.number]),
        format: PropTypes.string,
        expiredText: PropTypes.string,
    };

    static defaultProps = {
        format: 'd h m s',
        expiredText: ' (This offer has expired) ',
        startTime: undefined,
    };

    state = {
        delta: { total: 0 },
    };

    interval = undefined;

    constructor(props) {
        super(props);
        let startTime = props.startTime ? new Date(props.startTime) : new Date();
        const delta = this.getTimeRemaining(props.endtime, startTime);
        if (!process.env.ISNODE) {
            const query = Helpers.getSearch(window.location.search);
            if (query._preview) {
                startTime = new Date(query._preview.date);
            }
        }

        this.state = { delta, startTime };
    }

    componentDidMount() {
        const { format } = this.props;
        const factor =
            (format.indexOf('s') > -1 && 1) ||
            (format.indexOf('m') > -1 && 60) ||
            (format.indexOf('h') > -1 && 3600) ||
            (format.indexOf('d') > -1 && 86400);

        this.interval = setInterval(this.tick, this.props.intervalDelay || 1000 * factor);
    }

    componentWillUnmount() {
        this.endCountdown();
    }

    endCountdown = () => {
        clearInterval(this.interval);
    };

    getTimeRemaining = (endtime, startTime) => {
        const parsedTime = typeof endtime === 'number' ? endtime : Date.parse(endtime);
        const { format } = this.props;
        const [maxDisplayUnit] = (format.match(regexFormat) || [])[0];

        const t = parsedTime - Date.parse(startTime);
        let seconds = Math.floor((t / 1000) % 60);
        let minutes = Math.floor((t / 1000 / 60) % 60);
        let hours = Math.floor((t / (1000 * 60 * 60)) % 24);
        let days = Math.floor(t / (1000 * 60 * 60 * 24));

        //if days is not the max, add days to hours and 0 out days
        if (maxDisplayUnit !== 'd') {
            hours += days * 24;
            days = 0;
        }
        //if hours is not max add hours to minutes
        if (['m', 's'].includes(maxDisplayUnit)) {
            minutes += hours * 60;
            hours = 0;
        }
        //if seconds is max add minutes to seconds
        if (maxDisplayUnit === 's') {
            seconds += minutes * 60;
            minutes = 0;
        }

        return {
            total: t,
            days,
            hours,
            minutes,
            seconds,
        };
    };

    tick = () => {
        const delta = this.getTimeRemaining(this.props.endtime, new Date());
        //if passed time, clear interval
        if (delta.total <= 0) {
            this.endCountdown();
        }

        this.setState({
            delta,
        });
    };

    pad = (unit, key) => {
        return unit < 10 && key !== 'days' ? '0' + unit : unit;
    };

    renderTimeRemaining = () => {
        const remain = this.state.delta;
        const { format, hideTimeUnitName = false } = this.props;
        let delim = format.match(delimRegex) || [];
        let matches = {};

        return (format.match(regexFormat) || []).reduce((time, value, index) => {
            let i = value.slice(0, 1);
            let key = timeParts[i];
            let longName = remain[key] === 1 ? key.substring(0, key.length - 1) : key;
            let delimiter = delim.pop();
            if (!(i in matches) && key) {
                //only show one of each type
                matches[i] = 1;

                if (remain[key] > 0 || value.indexOf('?') === -1) {
                    time.push(
                        <React.Fragment key={key}>
                            <span className={'time-part ' + key}>
                                <span className="time-unit">{this.pad(Math.max(0, remain[key]), key)}</span>
                                {!hideTimeUnitName && (
                                    <span className="time-key">
                                        {value.replace('?', '').length > 1 ? ' ' + longName : key.slice(0, 1)}
                                    </span>
                                )}
                            </span>
                            {delimiter ? <span className="delimiter">{delimiter}</span> : null}
                        </React.Fragment>
                    );
                } else {
                    <span key={key} className="delimiter">
                        {delimiter}
                    </span>;
                }
            }
            return time;
        }, []);
    };

    render() {
        const { expiredText, endtime } = this.props;
        if (this.state.delta.total <= 0) {
            return <span className={'countdown-timer expired'}>{expiredText}</span>;
        }
        return endtime ? (
            <span className={'countdown-timer'} onClick={this.endCountdown}>
                {this.renderTimeRemaining()}
            </span>
        ) : null;
    }
}

export default withError(Countdown);
