import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ButtonIcon from './button-icon';
import { Typography } from '@hpstellar/core';

import './css/action-textbox.less';

export default class ActionTextBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.initialSearchKey || '',
            focus: false,
        };
    }

    onChange = event => {
        const { value } = event.target;
        const { onChangeCallback } = this.props;
        this.setState((state, props) => {
            props.onChange(value);
            return { value };
        });

        typeof onChangeCallback === 'function' && onChangeCallback();

        if (!this.state.inputFocused) this.onClick();
    };

    onClear = () => {
        const { clearOnClick } = this.props;

        this.setState({ value: '' });
        clearOnClick && clearOnClick();
    };

    handleKeyDown = event => {
        let { variation, setInputFocused } = this.props;

        setInputFocused && setInputFocused(true);

        // if it's hawksearch, pressing Enter is handled in Suggestions component
        if (event.key === 'Enter' && variation !== 'hawksearch') {
            const { actionOnClick } = this.props;
            actionOnClick();
        }
    };

    onClick = () => {
        let { setSearchKey, setInputFocused, openModal, setAutocompleteStateOnClick } = this.props;
        let { value } = this.state;

        typeof setAutocompleteStateOnClick === 'function' && setAutocompleteStateOnClick();

        if (value && value.length > 0) {
            setSearchKey(value);
            setInputFocused && setInputFocused(true);
            openModal && openModal();
        }
    };

    onFocusOut = () => {
        let { onInputFocusOut } = this.props;
        this.setState({ inputFocused: false });
        onInputFocusOut && onInputFocusOut();
    };

    render() {
        const {
            actionIcon,
            placeholder,
            clearIcon,
            actionOnClick = () => {},
            className,
            focus,
            hideClearIconOnEmptyText,
            closeButtonComponent,
            clearButtonComponent,
            ClearButtonComponent,
            searchIconComponent,
            inputProps,
        } = this.props;
        const { value, inputFocused } = this.state;
        const hasText = value && value.length > 0;

        // TODO TASK: not sure where this one is set, data or cms? causing warnings in lowercase
        delete inputProps?.autocomplete;
        inputProps.autoComplete = 'off';

        return (
            <div className="action-textbox-container">
                <Typography variant="bodyL" tag="div" className={`action-textbox ${className}`}>
                    <div className='search-bar-input-container'>
                    <input
                        ref={input => {
                            focus && input && input.focus && input.focus();
                        }}
                        placeholder={placeholder}
                        onChange={this.onChange}
                        value={value}
                        onKeyDown={this.handleKeyDown}
                        aria-label={placeholder}
                        onClick={this.onClick}
                        onFocusOut={this.onFocusOut}
                        className={inputFocused ? 'search-input-focused' : null}
                        {...(inputProps || {})}
                    />
                    </div>
                    {!hideClearIconOnEmptyText ? (
                        ClearButtonComponent ? (
                            hasText && <ClearButtonComponent onClear={this.onClear} />
                        ) : !!clearButtonComponent ? (
                            <span onClick={this.onClear}>{clearButtonComponent}</span>
                        ) : clearIcon ? (
                            <ButtonIcon
                                buttonClass="clearIcon"
                                icon={clearIcon}
                                onClick={this.onClear}
                                ariaLabel="Clear Text"
                            />
                        ) : null
                    ) : null}
                    { searchIconComponent }
                    {actionIcon && !searchIconComponent && (
                        <ButtonIcon
                            buttonClass={`actionIcon ${clearIcon ? 'actionActive' : ''}`}
                            icon={actionIcon}
                            onClick={actionOnClick}
                            ariaLabel="Submit"
                        />
                    )}
                </Typography>
                {closeButtonComponent}
            </div>
        );
    }
}

ActionTextBox.defaultProps = {
    clearIcon: null,
    placeholder: '',
    className: '',
    clearOnClick: () => {},
    focus: false,
};

ActionTextBox.propTypes = {
    /** icon string, must be a valid icon form icon.less */
    actionIcon: PropTypes.string.isRequired,
    /** clear icon, used the clear input */
    clearIcon: PropTypes.string,
    /** placeholder text */
    placeholder: PropTypes.string,
    /** input onchange */
    onChange: PropTypes.func.isRequired,
    /** clear icon click action, clears input text */
    clearOnClick: PropTypes.func,
    /** icon onclick */
    actionOnClick: PropTypes.func,
    /** optional additional style classname */
    className: PropTypes.string,
    /** option if input should be in focus on first mount */
    focus: PropTypes.bool,
};
