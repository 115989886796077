import { removeKebabCase } from 'hpstore-helpers';

import { StoreAppAPI } from '../core/src/storeapp-api-client';
import { receiveSlugInfo, trackPageView } from '../page/page-actions';

export const RECEIVE_FEATURED_ARTICLE = 'RECEIVE_FEATURED_ARTICLE';
export const RECEIVE_BLOG_LIST_DATA = 'RECEIVE_BLOG_LIST_DATA';
export const RECEIVE_TOP_BLOGS_DATA = 'RECEIVE_TOP_BLOGS_DATA';
export const RECEIVE_RELATED_BLOGS_DATA = 'RECEIVE_RELATED_BLOGS_DATA';
export const RECEIVE_TAGS_DATA = 'RECEIVE_TAGS_DATA';
export const RECEIVE_ARTICLE_ARCHIVES_DATA = 'RECEIVE_ARTICLE_ARCHIVES_DATA';
export const RESET_BLOG_LIST_DATA = 'RESET_BLOG_LIST_DATA';
export const RECEIVE_WORDCLOUD_DATA = 'RECEIVE_WORDCLOUD_DATA';

const storeAppAPI = new StoreAppAPI();

export const receiveFeaturedArticle = featuredArticle => ({
    type: RECEIVE_FEATURED_ARTICLE,
    featuredArticle,
});

export const resetBlogListData = () => ({
    type: RESET_BLOG_LIST_DATA,
});

export const receiveBlogListData = blogListData => ({
    type: RECEIVE_BLOG_LIST_DATA,
    blogListData,
});

export const receiveTopBlogsData = topBlogsData => ({
    type: RECEIVE_TOP_BLOGS_DATA,
    topBlogsData,
});

export const receiveRelatedBlogsData = relatedBlogsData => ({
    type: RECEIVE_RELATED_BLOGS_DATA,
    relatedBlogsData,
});

export const receiveTagsData = tagsData => ({
    type: RECEIVE_TAGS_DATA,
    tagsData,
});

export const receiveArticleArchivesData = articleArchivesData => ({
    type: RECEIVE_ARTICLE_ARCHIVES_DATA,
    articleArchivesData,
});

export const receiveWorldCloudData = brightEdgeLinks => ({
    type: RECEIVE_WORDCLOUD_DATA,
    brightEdgeLinks,
});

export const searchBlogs = (
    filters,
    post_filters = null,
    aggregates = null,
    aggregateFilters,
    sort = { blogDate: 'desc' },
    size = 10,
    from = 0,
    bucketSize = 10,
    searchId
) => {
    return storeAppAPI.page
        .elasticSearch(filters, post_filters, aggregates, aggregateFilters, sort, size, from, bucketSize)
        .then(resp => {
            if (resp && resp.data && typeof resp.data === 'object') {
                resp.data.post_filters = post_filters;
                resp.data.searchId = searchId;
            }
            return resp;
        });
};

export const fetchWordCloudData = () => dispatch => {
    const error = { error: 'error fetching bright edge links' };
    return storeAppAPI.component
        .get(['brightEdge/tech-takes'])
        .then(resp => {
            if (resp.status == '200' && resp.data) {
                const data = typeof resp.data === 'string' ? JSON.parse(resp.data) : resp.data;
                return dispatch(receiveWorldCloudData(data));
            }
            return error;
        })
        .catch(e => {
            return error;
        });
};

export const fetchBlogPages =
    (
        filters,
        post_filters = {},
        aggregates = {},
        aggregateFilters,
        sort = { blogDate: 'desc' },
        size = 10,
        from = 0,
        query = {},
        bucketSize = 10
    ) =>
    dispatch => {
        return searchBlogs(filters, post_filters, aggregates, aggregateFilters, sort, size, from, bucketSize)
            .then(resp => {
                if (resp.status == '200' && resp.data) {
                    resp.data.from = from;
                    resp.data.postFilters = post_filters;
                    resp.data.query = query;

                    return dispatch(receiveBlogListData(resp.data));
                }
            })
            .catch(error => {
                console.log('Error', error);
            });
    };

export const fetchRelatedBlogs =
    (
        filters,
        post_filters = null,
        aggregates = null,
        aggregateFilters,
        sort = { blogDate: 'desc' },
        size = 10,
        from = 0,
        bucketSize = 10
    ) =>
    dispatch => {
        return searchBlogs(filters, post_filters, aggregates, aggregateFilters, sort, size, from, bucketSize)
            .then(resp => {
                if (resp.status == '200' && resp.data) {
                    resp.data.postFilters = post_filters;
                    return dispatch(receiveRelatedBlogsData(resp.data));
                }
            })
            .catch(error => {
                console.log('Error', error);
            });
    };

export const fetchTags =
    (
        filters,
        post_filters = null,
        aggregates = null,
        aggregateFilters,
        sort = null,
        size = 0,
        from = 0,
        bucketSize = 10
    ) =>
    dispatch => {
        return searchBlogs(filters, post_filters, aggregates, aggregateFilters, sort, size, from, bucketSize)
            .then(resp => {
                if (resp.status == '200' && resp.data) {
                    return dispatch(receiveTagsData(resp.data));
                }
            })
            .catch(error => {
                console.log('Error', error);
            });
    };

export const fetchArticleArchives =
    (
        filters,
        post_filters = null,
        aggregates = null,
        aggregateFilters,
        sort = { blogDate: 'desc' },
        size = 10,
        from = 0,
        bucketSize = 10
    ) =>
    dispatch => {
        return searchBlogs(filters, post_filters, aggregates, aggregateFilters, sort, size, from, bucketSize)
            .then(resp => {
                if (resp.status == '200' && resp.data) {
                    return dispatch(receiveArticleArchivesData(resp.data));
                }
            })
            .catch(error => {
                console.log('Error', error);
            });
    };

export const fetchFeaturedArticle =
    (query, slugInfo = null) =>
    dispatch => {
        let storeAppAPI = new StoreAppAPI();
        return storeAppAPI.page
            .search(query)
            .then(response => {
                if (response) {
                    const { result } = response.data;

                    const processedPages = result.map(page => {
                        let slugInfo = processSlugInfo(page);
                        return slugInfo;
                    });

                    const blogs = processBlogListInfo(processedPages);
                    const [featuredArticle = null] = blogs || [];

                    if (slugInfo) {
                        return Promise.all([
                            dispatch(receiveFeaturedArticle(featuredArticle)),
                            dispatch(receiveSlugInfo(slugInfo)),
                            dispatch(trackPageView(slugInfo)),
                        ]);
                    } else {
                        return dispatch(receiveFeaturedArticle(featuredArticle));
                    }
                }
            })
            .catch(error => {
                console.log('Error', error);
            });
    };

export const createMetaText = filters => {
    let { tags = [], categories = [], month = [], year = [] } = filters || {};
    let titleMetaText = '';
    let description = 'Explore all HP® Tech Takes articles ';
    let descriptionMetaText = '';

    if (categories.length && !tags.length && !month.length && !year.length) {
        const categoriesMetaText = formatMetaText(categories, 2);
        titleMetaText += categoriesMetaText + ' | ';
        description +=
            'in our ' +
            categoriesMetaText +
            ` ${
                categories.length > 1 ? 'categories' : 'category'
            } to learn more about consumer and business technology.`;
    } else if (!categories.length && !tags.length && month.length && !year.length) {
        const cap = 2;
        if (month.length > 2) {
            month = [month[0], month[month.length - 1]];
        }
        const monthsMetaText = formatMetaText(month, cap, {}, 'through');
        titleMetaText += formatMetaText(month, cap, {}, 'to') + ' Articles | ';
        description +=
            `published ${month.length > 1 ? 'from ' : 'in '}` +
            monthsMetaText +
            ` to learn more about consumer and business technology.`;
    } else if (!categories.length && !tags.length && !month.length && year.length) {
        const cap = Object.keys(filters).length === 1 ? 2 : 1;
        if (year.length > cap) {
            year = [year[0], year[year.length - 1]];
        }
        const yearsMetaText = formatMetaText(year, cap, {}, 'through');
        titleMetaText += formatMetaText(year, cap, {}, 'to') + ' Articles | ';
        description +=
            `published ${year.length > 1 ? 'from ' : 'in '}` +
            yearsMetaText +
            ` to learn more about consumer and business technology.`;
    } else {
        description = 'Explore the latest HP® Tech Takes articles to learn more about ';
        if (tags.length) {
            const tagsMetaText = formatMetaText(tags, 3);
            titleMetaText += tagsMetaText + ' | ';
            descriptionMetaText += tagsMetaText + (categories.length ? ' in ' : month.length || year.length ? ' ' : '');
        }
        if (categories.length) {
            const categoriesMetaText = formatMetaText(categories, 2);
            titleMetaText += categoriesMetaText + ' | ';
            descriptionMetaText += 'our articles on ' + categoriesMetaText + (month.length || year.length ? ' ' : '');
        }
        if (month.length) {
            //If more than cap, we need to have it {First Month} to {Last Month}
            const cap = 2;
            if (month.length > 2) {
                month = [month[0], month[month.length - 1]];
            }
            const monthsMetaText = formatMetaText(month, cap, {}, 'to');
            titleMetaText +=
                monthsMetaText + (Object.keys(filters).length === 1 ? ' Articles | ' : year.length ? ', ' : ' | ');
            descriptionMetaText +=
                (tags.length || categories.length ? 'from ' : 'our articles from ') +
                monthsMetaText +
                (year.length ? ', ' : '');
        }
        if (year.length) {
            //If more than cap, we need to have it {First Year} to {Last Year}
            const cap = Object.keys(filters).length === 1 ? 2 : 1;
            if (year.length > cap) {
                year = [year[0], year[year.length - 1]];
            }
            const yearsMetaText = formatMetaText(year, cap, {}, 'to');
            titleMetaText += yearsMetaText + (Object.keys(filters).length === 1 ? ' Articles | ' : ' | ');
            descriptionMetaText +=
                (month.length ? '' : tags.length || categories.length ? 'from ' : 'our articles from ') + yearsMetaText;
        }

        description += descriptionMetaText + '.';
    }

    return { titleMetaText, description };
};

const formatMetaText = (arr = [], cap = 3, map, connector = 'and') => {
    const delimeter = arr.length > 2 ? ',' : '';
    const metaText = arr.slice(0, cap).reduce((text, curr, index, srcArray) => {
        let el = typeof curr === 'string' ? removeKebabCase({ str: curr, map }) : curr;
        if (srcArray.length === 1) {
            return el;
        } else if (index === srcArray.length - 1) {
            text += `${connector} ${el}`;
        } else {
            text += srcArray.length > 2 ? `${el}${delimeter} ` : `${el} `;
        }
        return text;
    }, '');

    return metaText;
};

const processSlugInfo = slugInfo => {
    let { templateKey, template_key, components } = slugInfo;

    slugInfo.templateKey = templateKey || template_key;
    slugInfo.components = components.reduce((map, comp) => {
        let { key } = comp;
        map[key] = comp;
        return map;
    }, {});

    return slugInfo;
};

const processBlogListInfo = pages => {
    if (!pages.length) {
        return;
    }

    const blogs = pages.map(blog => {
        const { author = {}, blogPost = {} } = Array.isArray(blog.components) ? blog.components[0] : blog.components;
        const { authorName, avatar } = author;
        const { key, blogDate, blogThumbnail, blogTitle, categories, tags } = blogPost;
        const { vanityUrl, canonical } = blog;

        return {
            authorName,
            avatar,
            key,
            blogDate,
            blogThumbnail,
            blogTitle,
            categories,
            tags,
            vanityUrl,
            canonical,
        };
    });

    return blogs;
};

export const articleListPageType = 'tech-takes-list-view';
export const articleViewPageType = 'tech-takes-article-view';
