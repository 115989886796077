import { SET_COMPARE_EXPANDED } from './compare-actions';

const CompareExpandedReducer = (state = true, action) => {
    Object.freeze(state);
    switch (action.type) {
        case SET_COMPARE_EXPANDED:
            return action.expanded;
        default:
            return state;
    }
};

export default CompareExpandedReducer;
