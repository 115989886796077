import React, { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ButtonIcon from '@hpstellar/core/visId/ButtonIcon';
import { Search } from '@hpstellar/vis-icons';

import ButtonIconOld from './button-icon';
import Icon from './icon';
import Drawer from '../drawer';
import SubMenu from './sub-menu';
import SearchAutocompleteContext from '../../../search/components/search-autocomplete-context';
import { showMobileSearchBar } from './header-footer-actions';

import './header-mobile.less';
import HeaderSearch from './header-search';
import HeaderCart from './header-cart';

function MenuScreen({ menuItems = [], onSelect, onClose }) {
    const onEnter = () => {
        // TODO: implement keyboard click
    };

    return (
        <ul className="menu-screen">
            <li className="menu-screen-header">
                <div className="close-container-menu">
                    <ButtonIconOld icon="icon-close" onClick={onClose} ariaLabel="Close Drawer" />
                </div>
            </li>
            {menuItems.map((item, itemId) => (
                <li onClick={() => onSelect(itemId)} onKeyDown={onEnter} key={itemId}>
                    {/* TODO: change <li> to an interactive element */}
                    {item.name}
                    <Icon icon="icon-forward" />
                </li>
            ))}
        </ul>
    );
}

MenuScreen.propTypes = {
    menuItems: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired,
};

function SubMenuScreen({ name, categories = [], onBack, onClose }) {
    return (
        <div className="submenu-screen">
            <div className="menu-screen-header">
                <ButtonIconOld icon="icon-back" onClick={onBack} ariaLabel="Go Back" />
                <div className="title">{name}</div>
                <div className="close-container-menu">
                    <ButtonIconOld
                        icon="icon-close"
                        buttonClass="button-close"
                        onClick={onClose}
                        ariaLabel="Close Drawer"
                    />
                </div>
            </div>
            {categories.map((category, ck) => (
                <SubMenu title={category.name} items={category.items} key={ck} closeMenu={onClose} />
            ))}
        </div>
    );
}

SubMenuScreen.propTypes = {
    name: PropTypes.string.isRequired,
    categories: PropTypes.array.isRequired,
    onBack: PropTypes.func.isRequired,
};

const HeaderMobile = props => {
    const { autocompleteModalOpen, autocompleteWithBreadcrumb } = useContext(SearchAutocompleteContext);
    const dispatch = useDispatch();

    const { setMobileDrawerOpen } = props || {};
    const [openDrawer, setOpenDrawer] = useState(false);
    const [showSubMenu, setShowSubMenu] = useState(false);
    const [subMenuIndex, setSubMenuIndex] = useState(0);
    const [openSearch, setOpenSearch] = useState(autocompleteWithBreadcrumb);
    const onGoBack = useCallback(() => {
        setShowSubMenu(false);
    }, []);
    const onClose = useCallback(() => {
        try {
            setOpenDrawer(false);
            setShowSubMenu(false);
            setSubMenuIndex(0);
            setMobileDrawerOpen(false);
        } catch (e) {}
    }, [setMobileDrawerOpen]);

    const onOpen = useCallback(() => {
        setOpenDrawer(!openDrawer);
        typeof setMobileDrawerOpen === 'function' && setMobileDrawerOpen(!openDrawer);
    }, [openDrawer, setMobileDrawerOpen]);

    const onMenuItemClick = useCallback(menuId => {
        setSubMenuIndex(menuId);
        setShowSubMenu(true);
    }, []);

    const onCloseSearch = useCallback(() => {
        setOpenSearch(false);
        dispatch(showMobileSearchBar(false));
    }, []);

    const onOpenSearch = useCallback(() => {
        setOpenSearch(true);
        dispatch(showMobileSearchBar(true));
    }, []);

    useEffect(() => {
        if (autocompleteWithBreadcrumb) {
            dispatch(showMobileSearchBar(true));
        }

        return () => {
            dispatch(showMobileSearchBar(false));
        };
    }, []);

    const {
        nav,
        setInputFocused,
        inputFocused,
        setSearchKey,
        searchUrl,
        searchQueryStringKey,
        variation,
        onInputFocusOut,
        searchKey,
    } = props || {};

    useEffect(() => {
        if (!autocompleteModalOpen) {
            onCloseSearch();
        }
    }, [autocompleteModalOpen]);

    const buttonIcon = useMemo(
        () => (
            <ButtonIcon
                icon={<Search size="large" />}
                variant="secondary"
                theme="dark"
                size="large"
                aria-label="Menu"
                onClick={onOpenSearch}
                hasBorder={false}
            />
        ),
        [onOpenSearch]
    );

    const isSrp = useSelector(state => {
        try {
            return state.slugInfo.vanityUrl === 'sitesearch';
        } catch (e) {}
    });

    return (
        <>
            <div className="menu-mobile">
                {buttonIcon}
                <HeaderCart />
                <ButtonIconOld onClick={onOpen} icon="icon-menu" ariaLabel="Menu" />
            </div>
            <Drawer open={openDrawer} onClose={onClose} position="right" overlay>
                <div className="drawer-container">
                    {showSubMenu ? (
                        <SubMenuScreen
                            name={nav[subMenuIndex].name}
                            categories={nav[subMenuIndex].categories}
                            onBack={onGoBack}
                            onClose={onClose}
                        />
                    ) : (
                        <MenuScreen menuItems={nav} onClose={onClose} onSelect={onMenuItemClick} />
                    )}
                </div>
            </Drawer>
            {(openSearch || autocompleteModalOpen) && (
                <div className={`search-header-container-mobile${autocompleteWithBreadcrumb ? ' with-bc' : ''}`}>
                    <HeaderSearch
                        searchUrl={searchUrl}
                        isMobile
                        closeSearch={onCloseSearch}
                        searchQueryStringKey={searchQueryStringKey}
                        variation={variation}
                        setSearchKey={setSearchKey}
                        setInputFocused={setInputFocused}
                        inputFocused={inputFocused}
                        onInputFocusOut={onInputFocusOut}
                        searchKey={searchKey}
                    />
                </div>
            )}
        </>
    );
};

HeaderMobile.propTypes = {
    /** navigation data */
    nav: PropTypes.array.isRequired,
};

export default HeaderMobile;
