import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { togglePreference } from '../test-flag-actions';
import { useTestFlagPreferences, transformPreferenceStringToObj } from '../useTestFlags';

let listenersTriggered = false;
const GROUP_PREFERENCES = [
    { group: 1, value: 1 },
    { group: 2, value: 1 },
    { group: 3, value: 1 },
    { group: 4, value: 1 },
    { group: 8, value: '0-', togglePreferenceValue: true }
]; // 8 must not exist or isnt equal to 0
const preferencesEvaluated = {};

export default () => {
    const dispatch = useDispatch();
    const dispatchReady = typeof dispatch === 'function';
    const preferences = useTestFlagPreferences();

    useEffect(() => {
        try{
            if(dispatchReady && !listenersTriggered){
                listenersTriggered = true;
                const enabledPreferences = transformPreferenceStringToObj(preferences);
                GROUP_PREFERENCES.forEach( groupData => {
                    const { group, value, togglePreferenceValue } = groupData || {};
                    if(!enabledPreferences[group] && !preferencesEvaluated[group]){
                        preferencesEvaluated[group] = 1;
                        dataLayer.push({
                            event:'pb.onOptInGroups',
                            groups: `${group}:${value}`,
                            callback: function(){
                                dispatch(togglePreference(group, typeof togglePreferenceValue === 'boolean' ? togglePreferenceValue : (value ? true :  false)));
                            }
                        })
                    }
                })
            }          
        }catch(e){}
    }, [dispatchReady])
}