let expressServer = {
    handle: () => {
        throw 'ETR API accessed on server with dependency injection';
    },
};

/**
 * Mocks the express request API, returns a response similar to the
 *  structure of an axios http request
 */
const getResponse = resolve => {
    let statusCode = 200;
    let sendData = data => resolve({ data, status: statusCode });
    let respObj = {
        json: data => {
            try {
                sendData(data);
            } catch (e) {
                console.log('SERVER RESPONSE API HANDLER ERROR ON JSON', e);
            }
        },
        send: data => {
            try {
                sendData(data);
            } catch (e) {
                console.log('SERVER RESPONSE API HANDLER ERROR ON SEND', e);
            }
        },
        setHeader: () => {},
        //Add attributes created by the graphql-http lib
        outputData: [],
        _onPendingData: function () {},
        writeHead: function () {
            return {
                end: function (data) {
                    try {
                        sendData(data);
                    } catch (e) {
                        console.log('SERVER WRITE HEAD API HANDLER ERROR ON SEND', e);
                    }
                },
            };
        },
    };

    respObj.status = code => {
        //temp remove status code support, always return 200
        //statusCode = code;
        return respObj;
    };

    return respObj;
};

/**
 * Server API works in place of axios on server side code
 * Rather than making an HTTP request on the server the expess app is
 * passed in, and the express internal api 'handle' is used to execute the
 *  request against the regestered routes on the app
 */
class ServerAPI {
    constructor(server) {
        //when the server starts it will pass express in here,
        //overwriting the ref here so that subsequent API calls will be
        // handled by th express server
        expressServer = server || expressServer;
    }

    get(path, options = {}) {
        const { headers = {}, params } = options;
        const req = {
            url: path,
            method: 'GET',
            query: params,
            headers: { 'Transfer-Encoding': 'chunked', ...headers },
        };
        return new Promise((resolve, reject) => {
            try {
                expressServer.handle(req, getResponse(resolve));
            } catch (e) {
                reject(e);
            }
        });
    }

    post(path, body, options = {}) {
        const { headers = {}, params } = options;
        const req = {
            url: path,
            method: 'POST',
            body: body,
            query: params,
            headers: { 'Transfer-Encoding': 'chunked', ...headers },
        };

        return new Promise((resolve, reject) => {
            try {
                expressServer.handle(req, getResponse(resolve));
            } catch (e) {
                reject(e);
            }
        });
    }
}

/**
 * Version of the Server class that can be used as a singlton in the GQL context
 */
export class ServerSinglton {
    constructor(server) {
        //when the server starts it will pass express in here,
        //overwriting the ref here so that subsequent API calls will be
        // handled by th express server
        this.expressServer = server || {
            handle: () => {
                throw 'ETR API accessed on server with dependency injection';
            },
        };
    }

    get(path, options = {}) {
        const { headers = {}, params } = options;
        const req = {
            url: path,
            method: 'GET',
            query: params,
            headers: { 'Transfer-Encoding': 'chunked', ...headers },
        };
        return new Promise((resolve, reject) => {
            try {
                this.expressServer.handle(req, getResponse(resolve));
            } catch (e) {
                reject(e);
            }
        });
    }

    post(path, body, options = {}) {
        const { headers = {}, params } = options;
        const req = {
            url: path,
            method: 'POST',
            body: body,
            query: params,
            headers: { 'Transfer-Encoding': 'chunked', ...headers },
        };

        return new Promise((resolve, reject) => {
            try {
                this.expressServer.handle(req, getResponse(resolve));
            } catch (e) {
                reject(e);
            }
        });
    }
}

export default ServerAPI;
