import { StoreAppAPI } from "../core/src/storeapp-api-client";
import { doSearch } from '../search-filter/search-filter-actions';

// get 21 for now, remove the last or if the page was found, remove that.
export const DEFAULT_ES_PARAMS = {
  sort: { "viewCount": "desc" },
  size: 20,
  aggregates: {},
  aggregateFilters: [],
  bucketSize: 10,
  postFilters: {},
  from: 0
}
const storeAppAPI = new StoreAppAPI();

export const searchRelatedPages = ( filters,          post_filters = null,          aggregates = null,
                                    aggregateFilters, sort = {"viewCount": "desc"}, size = 21,
                                    from = 0,         bucketSize=10,                _source )  => {
  return storeAppAPI.page.elasticSearch(filters, post_filters, aggregates, aggregateFilters, sort, size, from, bucketSize, _source);
}

export const ssrRelatedPages = (keyword, slugInfo, filter) => {
  return doSearch(keyword, () => fetchRelatedPages(slugInfo, filter));
}

export const fetchRelatedPages = ({categories=[], templateKey, key}, filter, _source) => {

  // passing an object as value allows boolType and type to be specified
  // default filter excludes current page with:  {"must_not": { "match_phrase: { "key" :value } }
  const filters = filter || ({
    categories: categories[0],
    templateKey: templateKey,
    hosted: "true",
    key: { value: key, boolType: "must_not", type: "match_phrase" }
  });

  const { sort, size, aggregates, aggregateFilters, post_filters, bucketSize, from } = DEFAULT_ES_PARAMS;

  return searchRelatedPages( filters, post_filters, aggregates, aggregateFilters, sort, size, from, bucketSize, _source );
};
