import React from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';

import {
    fetchBlogPages,
    fetchFeaturedArticle,
    searchBlogs,
    fetchArticleArchives,
    fetchTags,
    createMetaText,
    articleListPageType,
    fetchWordCloudData,
} from '../blog-list-actions';
import { doSearch } from '../../search-filter/search-filter-actions';
import { Helpers } from '../../core/src/helpers';
import BlogAdSenseScript from './ads/adsense/adsense-script';
import { isTechAtWork, TECH_AT_WORK_TAG } from './utils';
import withPage from '../../page';

const BlogList = loadable(() => Helpers.retryFunc(() => import('./blog-list')));
const BlogListV2 = loadable(() => Helpers.retryFunc(() => import('../components-v2/blog-list')));

const getDefaultSlugInfo = Helpers.memoize((title, description, canonical, brightEdgeLinks) => {
    return {
        vanityUrl: 'tech-takes',
        templateKey: 'blog-list',
        key: 'blog-listing-page',
        hosted: true,
        categories: [],
        tags: [],
        components: [],
        title,
        canonical,
        description,
        seo: { brightEdgeLinks },
        customFields: { bannerHtml: '', css: '', isComingSoon: false },
        pgKeys: [],
        analyticsData: {},
    };
});

const mapDispatchToProps = dispatch => {
    return {
        fetchFeaturedArticle: (query, slugInfo) => dispatch(fetchFeaturedArticle(query, slugInfo)),
        fetchBlogPages: (filters, post_filters, aggregates, aggregateFilters, sort, size, from, query, bucketSize) =>
            dispatch(
                fetchBlogPages(filters, post_filters, aggregates, aggregateFilters, sort, size, from, query, bucketSize)
            ),
        fetchWordCloudData: () => dispatch(fetchWordCloudData()),
    };
};

const mapStateToProps = (state, ownProps) => {
    const { filters } = ownProps.match.params;
    const { siteConfig = {}, userData, storeEnvironment, router, blogListInfo } = state;
    const { brightEdgeLinks, featuredArticle, blogHits } = blogListInfo || {};
    const { blog, enableNewRoutes } = siteConfig || {};
    const { domain } = blog || {};
    const { originCountryCode } = userData || {};
    let title = 'Consumer and Business Technology Trends | HP® Tech Takes';
    let description =
        "Find out about the latest in Consumer and Business Technology Trends on HP® Tech Takes. Exploring today's technology for tomorrow's possibilities.";
    let canonicalDomain = domain ? `https://${domain}` : 'https://www.hp.com';
    let canonical = `${canonicalDomain}${siteConfig.basename}/tech-takes`;
    const { location } = router || {};
    const { search } = location || {};

    if (filters) {
        const filtersObj = Helpers.parseFilters(filters);
        const { titleMetaText, description: generatedDescription } = createMetaText(filtersObj.query);
        const { router = { location: { pathname: '' } } } = state;
        title = titleMetaText + 'HP® Tech Takes';
        description = generatedDescription;
        canonical = canonicalDomain + state.router.location.pathname;
    }

    //add sluginfo to the state so we can use it in the page component
    const slugInfo = getDefaultSlugInfo(title, canonical, description, brightEdgeLinks);

    return {
        title,
        description,
        canonical,
        featuredArticle,
        blogHits,
        testFlags: state.testFlags,
        //TODO: site config shouldn't have template specific settings. It should only have universal page settings
        //we may want to considering storing this as part of the template and bringing it in along with the page components
        blogConfig: siteConfig.blog,
        originCountryCode,
        isV2: enableNewRoutes,
        storeEnvironment,
        search,
        slugInfo,
        brightEdgeLinks,
    };
};

const BlogListContainer = props => {
    let { isV2 } = props;
    return (
        <React.Fragment>
            <BlogAdSenseScript />
            {isV2 ? <BlogListV2 {...props} /> : <BlogList {...props} />}
        </React.Fragment>
    );
};

const loadData = async (urlParams, store, query) => {
    const featuredArticleQuery = {
        templateKey: 'blog',
        featured: 'true',
        limit: '1',
        hosted: 'true',
        sort: '-updatedAt',
    };

    let slug = 'tech-takes';

    let filters = { templateKey: 'blog', hosted: 'true', blogArchive: 'false' };
    let post_filters = {};
    let sort = { blogDate: 'desc' };
    let size = 14;

    const filterParams = urlParams.filters;
    let tags, categories;

    if (filterParams) {
        const additionalFilters = Helpers.parseFilters(filterParams);

        const query = additionalFilters.query || {};
        tags = query && query.tags;
        categories = query && query.categories;
        if (query.size) {
            size = parseInt(query.size[0]);
            delete query.size;
        }
        if (query.sort) {
            sort = query.sort[0];
            delete query.sort;
        }

        post_filters = { ...query };
    }

    const filtersArticleArchives = { templateKey: 'blog', hosted: 'true', blogArchive: 'true' };
    const aggregates = {
        all_years: 'year',
        all_months: 'month',
        all_categories: 'categories',
    };

    const bucketSize = 30;

    const sortTopBlogs = { visitCount: 'desc' };
    const sizeTopBlogs = 10;

    const filtersTopTags = { templateKey: 'blog', hosted: 'true', blogArchive: 'false' };
    const aggregatesTopTags = { tags: 'tags' };
    const aggregateFiltersTopTags = Object.keys(aggregatesTopTags);
    const bucketSizeTopTags = 10;

    const aggregateFilters = Object.keys(aggregates);
    const from = 0;

    const promise = await store.dispatch(
        fetchBlogPages(filters, post_filters, aggregates, aggregateFilters, sort, size, from, {}, bucketSize)
    );

    const storeData = store.getState();
    const { blogListInfo = {} } = storeData;
    const { blogHits } = blogListInfo;
    const needRedirect = blogHits && blogHits.hits.total === 0 && filterParams;
    const destination = storeData.redirects.destination || '/tech-takes';
    if (needRedirect) return Promise.resolve({ status: 301, destination });

    const isTaw = isTechAtWork(tags, categories);
    const topBlogFilters = isTaw
        ? {
              tags: [TECH_AT_WORK_TAG],
              categories: [TECH_AT_WORK_TAG],
          }
        : post_filters;

    return Promise.all([
        store.dispatch(fetchFeaturedArticle(featuredArticleQuery, { vanityUrl: slug })),
        store.dispatch(
            fetchBlogPages(filters, post_filters, aggregates, aggregateFilters, sort, size, from, {}, bucketSize)
        ),
        store.dispatch(
            doSearch('topBlogs', () =>
                searchBlogs(
                    filters,
                    topBlogFilters,
                    aggregates,
                    aggregateFilters,
                    sortTopBlogs,
                    sizeTopBlogs,
                    from,
                    bucketSize,
                    articleListPageType
                )
            )
        ),
        store.dispatch(
            fetchArticleArchives(filtersArticleArchives, {}, aggregates, aggregateFilters, sort, size, from, bucketSize)
        ),
        store.dispatch(
            fetchTags(filtersTopTags, {}, aggregatesTopTags, aggregateFiltersTopTags, null, 0, from, bucketSizeTopTags)
        ),
        store.dispatch(fetchWordCloudData()),
    ]);
};

export default {
    component: connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        withPage(BlogListContainer, {
            customMetaData: true,
            customFetchSlugInfo: props => {
                const { slugInfo } = props;
                return slugInfo;
            },
            derivedPath: match => {
                const {
                    params: { dir },
                } = match;
                return `${dir}`;
            },
        })
    ),
    loadData,
};
