import React from 'react';
import loadable from '@loadable/component';

import { doSearch } from '../search-filter/search-filter-actions';
import withPage from '../page';
import { StoreAppAPI } from '../core/src/storeapp-api-client';
import { fetchSlugInfo } from '../page/page-actions';
import { ssrRelatedPages } from '../related-pages/related-pages-actions';
import { DEFAULT_SEARCH_RESULTS } from '../search-filter/search-filter-reducer';
import { getInitialSearchObject, transformProducts } from '../search/util';
import { getClientData } from '../search/useHawksearchClientData';
import { Helpers } from '../core/src/helpers';
import useTestFlags from '../abtest/useTestFlags';
import useSearchEndpoints, { getSearchAPIClientFromState } from '../search/useSearchEndpoint';

const storeAppAPI = new StoreAppAPI();

const DLP = loadable(() => Helpers.retryFunc(() => import('./components/dlp')));
const DLPV2 = loadable(() => Helpers.retryFunc(() => import('./components-v2/dlp')));

export const DLP_DEFAULT_SEARCH_OPTIONS = {
    pageSize: 8,
    resultsLimit: 8,
    filters: { instock: 'true' },
};

const getDLPKeyword = slugInfo => {
    const { components = {} } = slugInfo || {};
    const { dlpKeyword: { keyword } = {} } = components;
    return keyword || slugInfo.key;
};

const getDLPFilters = (slugInfo, query) => {
    const { pStoreID, aoid, jumpid } = query || {};
    let searchFilter = {
        inventory_status: 'In Stock',
        clientData: getClientData({ JumpID: jumpid, AOID: aoid, pStoreID }),
    };

    //TODO: categories not currently support in HawkSearch DLP

    return searchFilter;
};

/**
 * Transforms weird Swiftype response into a much more usable format.
 *    Example response:
 *       { price: { raw: 500.00 }, name: { raw: "Product Name" } }
 *    Would become:
 *       { price: 500.00, name: "Product Name" }
 *
 * Formats the response object in appropriate way.
 */
const getProducts = (
    hawksearchApi,
    searchParams,
    aggregations,
    sort,
    startPage,
    pageSize,
    keyword,
    filters,
    fetchAll,
    basename,
) => {
    const { clientData, ...query } = searchParams;
    const queryString = Helpers.getFilterString({ query }, true);
    const queryObj = getInitialSearchObject(queryString);
    return hawksearchApi
        .search({
            ...queryObj,
            MaxPerPage: DLP_DEFAULT_SEARCH_OPTIONS.pageSize,
            PaginationSetCode: 'dlp',
            ClientData: clientData,
        })
        .then(resp => {
            const { data } = resp || {};
            const { Results, Pagination } = data || {};
            const { NofResults } = Pagination;
            const hits = transformProducts(Results, basename, { pickImage: true });
            const hasReviews = hits.reduce((r, p) => (p.numReviews > 0 ? r.concat([p.sku]) : r), []).sort();
            if (hasReviews.length > 0) {
                //fetch and merge reviews
                return storeAppAPI.product.details(hasReviews, 'reviews').then(r => {
                    return {
                        data: {
                            hits: {
                                hits: hits.map(p => {
                                    const { value } = (r || []).find(({ sku }) => sku === p.sku) || {};
                                    const { reviews } = value || {};
                                    if (reviews) {
                                        p.reviews = reviews;
                                    }
                                    return p;
                                }),
                                total: NofResults,
                            },
                        },
                    };
                });
            }
            return {
                data: {
                    hits: {
                        hits,
                        total: NofResults,
                    },
                },
            };
        });
};

const loadData = async (urlParams, store, context) => {
    const { query: queryObj } = context;
    const { slug } = urlParams;
    const fullSlug = `/dlp/${slug}`;

    await store.dispatch(fetchSlugInfo(fullSlug, queryObj));
    const state = store.getState();
    const { slugInfo, redirects, siteConfig } = state;
    const engineId = 'search';
    const hawksearchApi = getSearchAPIClientFromState(engineId, state);
    const { basename } = siteConfig || {};

    const searchKeyword = getDLPKeyword(slugInfo);

    const needRedirect = !searchKeyword || !slugInfo || slugInfo.error || (redirects && redirects.count !== 0);

    const { destination = `${basename}/slp/weekly-deals`, status = 301 } = redirects;
    if (needRedirect) return Promise.resolve({ status, destination });

    const { aggregation, sort } = DEFAULT_SEARCH_RESULTS;
    const { pageSize } = DLP_DEFAULT_SEARCH_OPTIONS;
    const startPage = 0;

    // query should look like this eventually:
    // { query: <DLPKeyword>, filters: { all: [<filters as objects>, {categories: <Product category linked to dlp category>}], ... } }
    const query = {
        keyword: searchKeyword,
        ...getDLPFilters(slugInfo, queryObj),
    };

    return Promise.all([
        store.dispatch(ssrRelatedPages(`${slug}:relatedPages`, slugInfo)),
        store.dispatch(
            doSearch(searchKeyword, () =>
                getProducts(
                    hawksearchApi,
                    query,
                    aggregation,
                    sort,
                    startPage,
                    pageSize,
                    searchKeyword,
                    null,
                    true,
                    basename,
                ),
            ),
        ),
    ]);
};

const dlpContainer = props => {
    const { slugInfo, search, siteConfig } = props;
    const { aggregation, sort } = DEFAULT_SEARCH_RESULTS;
    const { pageSize } = DLP_DEFAULT_SEARCH_OPTIONS;
    const startPage = 0;
    const searchKeyword = getDLPKeyword(slugInfo) || 'csr-fetching';
    const [hawksearchApi] = useSearchEndpoints();

    const searchParams = {
        keyword: searchKeyword,
        ...getDLPFilters(slugInfo, search),
    };

    const compProps = {
        hawksearchApi,
        searchKey: searchKeyword,
        searchFunc: () =>
            getProducts(
                hawksearchApi,
                searchParams,
                aggregation,
                sort,
                startPage,
                pageSize,
                searchKeyword,
                null,
                true,
                siteConfig.basename,
            ),
        ...props,
    };

    const { dlp_abtest } = useTestFlags(['dlp_abtest']);
    const { enabled: enableRedesign } = dlp_abtest || {};

    return enableRedesign ? <DLPV2 {...compProps} /> : <DLP {...compProps} />;
};

export default {
    component: withPage(dlpContainer, {
        wrapperClassName: 'dlp-root',
        disableMobileBreadcrumb: true,
        useV2: true,
    }),
    loadData: loadData,
};
