if (!String.prototype.toFloat) {
	String.prototype.toFloat = function(){
		  let n = 0;
		  try{
			  n = parseFloat(this.replace(/[^0-9\.-]+/g,""));
		  }catch(e){
			  //pass
		  }
		  return n;
   }
  }