import merge from 'lodash/merge';

import {
    RECEIVE_SLUG_INFO,
    UPDATE_SLUG_INFO,
    RECEIVE_SLUG_ERROR,
    CLEAR_SLUG_ERROR,
    FETCH_SLUG_INFO,
    RECEIVE_ASYNC_COMPONENTS,
    UPDATE_COMPONENTS,
    UPDATE_SEO,
} from './page-actions';
/**
 * Store universal page defaults here
 * @type {Object}
 */
const DEFAULT_SLUG_INFO = {
    components: {
        productTab: {},
    },
    customFields: {},
    seo: {
        title: '',
        canonical: '',
        description: '',
        keywords: '',
        robots: false,
        hideCopyBlock: false,
        copyBlock: {},
        categories: [],
        tags: [],
        queryStringWhitelist: [],
    },
    asyncComponentsReceived: false,
    addOns: {},
    errors: [],
};

export const getDefaultStaticSlugInfo = props => {
    const { location } = props || {};
    const { pathname } = location || {};

    return {
        ...DEFAULT_SLUG_INFO,
        vanityUrl: pathname,
        fetching: false,
    };
};
const EMPTY_OBJ = {};
const getRevisionNumber = () => {
    return (+new Date()).toString(36);
};

const SlugInfoReducer = (state = DEFAULT_SLUG_INFO, action) => {
    Object.freeze(state);
    const { components, mergeFunction } = action;
    switch (action.type) {
        case RECEIVE_SLUG_INFO:
            //merge with defaults
            return merge({ fetching: false, revisionNumber: getRevisionNumber() }, DEFAULT_SLUG_INFO, action.slugInfo);
        case UPDATE_SEO:
            const { seo } = action;
            return mergeFunction && seo ? Object.assign({}, state, mergeFunction(state, seo)) : state;
        case RECEIVE_SLUG_ERROR:
            return merge({ fetching: false }, state, { error: 'slug not found' });
        case CLEAR_SLUG_ERROR:
            return merge({}, state, { error: null });
        case FETCH_SLUG_INFO:
            return merge({ fetching: true }, DEFAULT_SLUG_INFO);
        case UPDATE_SLUG_INFO:
            //include revision number here so that when slugInfo is actually received, memoized functions will update
            return merge({ revisionNumber: getRevisionNumber() }, state, action.slugInfo);
        case RECEIVE_ASYNC_COMPONENTS:
            let { errors, nextQuery } = action;
            let { errors: existingErrors } = state;
            return merge({}, state, {
                components: components || EMPTY_OBJ,
                nextQuery: nextQuery || null,
                errors: (existingErrors || []).concat(errors).filter(e => !!e),
            });
        case UPDATE_COMPONENTS:
            const revisions = Object.keys(components || EMPTY_OBJ);
            const nextState = mergeFunction
                ? Object.assign({}, state, mergeFunction(state, components))
                : merge({}, state, { components });

            //track revisions on component keys with a time based hash. This can be usefully for functions that memoize on component keys
            revisions.forEach(key => {
                try {
                    nextState.components[key].revisionNumber = getRevisionNumber();
                } catch (e) {}
            });
            return nextState;
        default:
            return state;
    }
};

export default SlugInfoReducer;
