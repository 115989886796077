import React from 'react';
import loadable from '@loadable/component';

import { Helpers } from '../core/src/helpers';
import { derivedPath } from '../configure/routing';
import { dynamicLoadData } from '../page';

const ConfigureAttachPage = loadable(() => Helpers.retryFunc(() => import('./configure-attach-container')));

const ConfigureAttachContainer = props => {
    return <ConfigureAttachPage {...props} />;
};

export const getStaticSlugInfo = props => {
    const { location, match } = props || {};
    const { search } = location || {};
    const { params } = match || {};
    const { dir } = params || {};

    return {
        vanityUrl: `${dir}${search}`,
        analyticsData: {
            derivedAnalyticsData: {
                analyticsMapping: 'configureview',
                batchedImpressions: {
                    accessoriesAttach: 1,
                },
                asyncImpressionComponents: ['accessoriesAttach'],
                family: 'shared',
                product_type: 'shared',
                bu: 'ps',
            },
        },
    };
};

export const loadData = async (urlParams, store, context = {}) => {
    const { query, headers } = context;
    const fullSlug = derivedPath({ params: urlParams }, query);
    await dynamicLoadData(fullSlug, store, context);

    const { slugInfo, redirects, basename } = store.getState();

    //const needRedirect = !slugInfo || slugInfo.error || (redirects && redirects.count !== 0);
    //attach page should always resolve. It should never redirect
    //const { destination = `${basename}/slp/weekly-deals`, status = 301 } = redirects;
    //if (needRedirect) return Promise.resolve({ status, destination });

    return Promise.resolve(slugInfo);
};

export default {
    component: ConfigureAttachContainer,
    loadData,
};
