import React from 'react';

const AnswerWrapper = ({children})=><div itemscope="true" itemprop="acceptedAnswer" itemtype="https://schema.org/Answer"><div itemprop="text">{children}</div></div>;
const QuestionWrapper = ({children})=><div itemscope="true" itemprop="mainEntity" itemtype="https://schema.org/Question" style={{marginBottom: '20px'}}>{children}</div>;
const QuestionNameWrapper = ({children}) => <div itemprop="name">{children}</div>;

export {
    AnswerWrapper,
    QuestionWrapper,
    QuestionNameWrapper
}