/**
 * Performs mpulse tracking on SPA route changes 
 * @module BoomerangAPI
 * @class
 * @internal
 */
class BoomerangAPI {
    constructor(options = {}) {
        this.name = "BOOMR tag";
        this.optOut = false;

        // set-up global configs
        window.BOOMR_config = window.BOOMR_config || {};
					BOOMR_config.autorun = false;
					BOOMR_config.History = {
					    enabled: true,
					    auto: false
				};
    }

    /**
     *
     * @method pageView
     * @param {String} eventName
     * @param {Object} params
     * @returns {Promise}
     * @internal
     */
     
    pageView(...args) {
        return this.track(...args);
    }

    /**
     *
     * @method track
     * @param {String} eventName
     * @param {Object} params
     * @returns {Promise}
     * @internal
     */
    track(eventName, params = {}) {
    	if(params.event === 'e_pageView'){
            const BOOMR = window.BOOMR;
            BOOMR && BOOMR.plugins && BOOMR.plugins.SPA && typeof BOOMR.plugins.SPA.route_change === "function" && BOOMR.plugins.SPA.route_change()
        }
    }

}

export default BoomerangAPI;