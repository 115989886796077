
import { Helpers } from '../core/src/helpers';
import { StoreAppAPI } from '../core/src/storeapp-api-client';
import { ROOT_FILTER_KEYS } from '../plp/constants';

const storeAppAPI = new StoreAppAPI();

/**
 * Helpers functions for sale apge specific filters
 */
export const  urlParser = match => {
    const { filters } = match.params;
    let queryObj = Helpers.parseFilters(filters);
    //treat primary group key as category filters
    queryObj.query = queryObj.query || {};
    //loop through query keys and add facets scope for non root filters
    Object.keys(queryObj.query).reduce((r, k) => {
        if (!(k in ROOT_FILTER_KEYS)) {
            let alias = `facets.${k}`;
            r[alias] = r[k];
            delete r[k];
        }
        return r;
    }, queryObj.query);
    return queryObj.query;
};

/**
 * Format search request specific to slp pages
 * @param {*} query 
 * @param {*} pgKey 
 * @param {*} activePG 
 * @param {*} disableAutoHide 
 * @param {*} autoHideType 
 */
export const slpSearch = (query, pgKey, activePG, disableAutoHide, autoHideType, _preview) => {
    let [, , tabName] = pgKey.split(':');
    const { categoryFacets = [] } = activePG.metaData || {};
    let q = {
        aggregates: { price: 1, rating: 1 },
        filters: Object.keys(query)
            .sort()
            .reduce(
                (result, key) => {
                    let value = query[key];
                    if (key !== 'price' && Array.isArray(value)) {
                        result[key] = value.sort().join(',');
                    } else {
                        result[key] = value;
                    }
                    return result;
                },
                {
                    cat: categoryFacets[0] && (categoryFacets[0].key || categoryFacets[0]._id),
                }
            ),
        from: 0,
        _source: ['sku'],
    };

    //modify query for auto hide functionality
    if (!disableAutoHide) {
        if (['hideNoIR', 'hideNoIROOS'].includes(autoHideType)) {
            q.filters['facets.sale'] = 'sale';
        }
        if (['hideOOS', 'hideNoIROOS'].includes(autoHideType)) {
            q.filters.instock = true;
        }
    }

    // temp to fix previews for scheduled versions
    if(_preview){
        q._preview = _preview;
    }
    return storeAppAPI.product.es.productGroup(pgKey, q).then(resp => {
        //format response to support custom aggregates
        let { data } = resp;
        //add product group key to each facet for tracking
        let facets = (data.facets || []).map(f => {
            f.metricsId = `tab.${tabName}`;
            return f;
        });
        delete data.facets;
        let { category } = data;

        return { data, customAggregations: { facets, category } };
    }).catch((e) => {
        //catch this exception to try to avoid SSR issues
        return { data: {}, customAggregations: { facets: [], category: {}}};
    })
};