import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { screenResize } from '../ui-action';

const WindowResizeWrapper = Component => {
    return class extends React.PureComponent {
        componentDidMount() {
            const { resize, width, height } = this.props;
            if (!width || !height) {
                resize();
            }
        }

        render() {
            return <Component {...this.props} />;
        }
    };
};

const mapStateToProps = state => ({
    device: state.ui.device,
    width: state.ui.width,
    height: state.ui.height,
    customBreakpoints: state.ui.customBreakpoints,
});
const mapDispatchToProps = dispatch => ({
    resize: () => dispatch(screenResize()),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), WindowResizeWrapper);
