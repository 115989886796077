import { connect } from 'react-redux';

import IdleUser from './idle-user'
import { setUserIdle } from '../../utility-actions';

const mapStateToProps = (state, ownProps ) => {
	const { userIdle } = state.userData;

	return {
		userIdle
	};
}

const mapDispatchToProps = dispatch => {
	return {
		setUserIdle: (userIdle) => dispatch(setUserIdle(userIdle))

}};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(IdleUser);