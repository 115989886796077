import React from 'react';
import loadable from '@loadable/component';
import AboveTheFoldOnlyServerRender from '../../../shared/components/above-the-fold-only-server-render';

import { Helpers } from '../../../core/src/helpers';
const CompareDrawer = loadable(() => Helpers.retryFunc(() => import('./compare-drawer')));

export default props => {
    return (
        <AboveTheFoldOnlyServerRender skip={true}>
            <CompareDrawer {...props} />
        </AboveTheFoldOnlyServerRender>
    );
};
