import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StoreAppAPI } from '../core/src/storeapp-api-client';
import { receiveProductSettings } from '../product/product-settings-reducer';
const storeAppAPI = new StoreAppAPI();

/**
 * Fetches and dispatches async global data
 * @name useAsyncGlobalSettings
 */
export default function useAsyncGlobalSettings() {
    const dispatch = useDispatch();
    const productSettings = useSelector(state => state.productData.productSettings);
    useEffect(() => {
        if (!productSettings) {
            //if product setting haven't been fetched, get them
            storeAppAPI.component.get(['productSettings']).then(({ data }) => {
                const [productSettings] = data || [];
                const { value } = productSettings || {};
                const { oosProducts = [] } = value || {};
                dispatch(receiveProductSettings({ oosProducts }));
            });
        }
    }, [productSettings]);
}
