import merge from 'lodash/merge';

import { SET_LEFT_MENU } from './left-menu-actions';

const NULL_SLUG_INFO = {
    active: false,
    activeID: {},
};
const SlugInfoReducer = (state = NULL_SLUG_INFO, { type, active, menuID }) => {
    Object.freeze(state);
    switch (type) {
        case SET_LEFT_MENU:
            return menuID ? merge({}, state, { activeID: { [menuID]: active } }) : merge({}, state, { active });
        default:
            return state;
    }
};

export default SlugInfoReducer;
