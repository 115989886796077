import React from 'react';
import { Loader } from '@hpstellar/core';

import './page-placeholder.less';

//page placeholder to be use during client side routing
export default ({ ssr = false }) => {
    //never render the loader on the server
    if (!ssr && process.env.ISNODE) {
        return null;
    }
    return (
        <div id="pageloader" className="page-placeholder">
            <Loader
                id="pageloader"
                translations={{
                    label: 'Loading...',
                }}
                style={{ height: '500px' }}
            />
        </div>
    );
};
