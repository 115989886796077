import debounce from 'lodash/debounce';

import { EtrAPI } from '../../../core/src/etr-api-client';

export const RECEIVE_MODEL_PRICES = 'RECEIVE_MODEL_PRICES';
export const RECEIVE_MODEL_PRICE_ERROR = 'RECEIVE_MODEL_PRICE_ERROR';
export const RECEIVE_MODEL_PRICE_FETCH = 'RECEIV_MODEL_PRICE_FETCH';

export const receivePrices = prices => ({
    type: RECEIVE_MODEL_PRICES,
    prices,
});

export const receivePriceError = ids => ({
    type: RECEIVE_MODEL_PRICE_ERROR,
    failedModelIds: ids,
});

/**
 * Use this action dispatch to minimize extra price calls by setting values for inflight price calls
 * @param {*} ids
 * @returns
 */
export const receivePriceFetch = ids => dispatch => {
    const action = {
        type: RECEIVE_MODEL_PRICE_FETCH,
        modelIds: ids,
    };
    return dispatch(action);
};

// TODO: temp hotfix to prevent spam renrender if price api fails,
// since we batch price calls, this gets hit hard when the batch queue purges and rejects promises
const handlePriceError = debounce((dispatch, error, ids) => {
    const { catentryId = [] } = error;
    const failedIds = !catentryId || catentryId.length === 0 ? ids : catentryId;
    return dispatch(receivePriceError(failedIds));
});

export const fetchModelPrices = ids => (dispatch, getState) => {
    return EtrAPI.services
        .fetchModelPriceAndInventory(ids)
        .then(resp => {
            /** .fetchPriceAndInventory() throws on non 200 */
            const { serviceData } = resp.data;
            const { productData } = getState();
            const { productInfo = {} } = productData;
            const missingIds = ids.filter(id => !(id in serviceData) && !(id in productInfo.modelPrices));
            const newProductInfo = {};
            Object.keys(serviceData).reduce((r, modelId) => {
                let priceObj = serviceData[modelId];
                let existPriceObj = productInfo.modelPrices[modelId];
                if (priceObj.productType === 'category' && (!existPriceObj || existPriceObj.priceFetchFailed)) {
                    r[modelId] = priceObj;
                }
                return r;
            }, newProductInfo);
            missingIds.length > 0 && handlePriceError(dispatch, 'no price available', missingIds);
            if (Object.keys(newProductInfo).length > 0) {
                return dispatch(receivePrices(newProductInfo));
            }
        })
        .catch(err => {
            return handlePriceError(dispatch, err, ids);
        });
};
