import { useDispatch, useSelector } from 'react-redux';

import { updateHeader } from '../page/components/header-footer/header-footer-actions';
import { Helpers } from '../core/src/helpers';

/**
 * @typedef {Object} PageFunctions
 * @property {function(): promise} updateHeader - Update a page component in redux
 */

/**
 * Returns redux connected page functions
 * @name useHeaderFooter
 * @returns {HeaderFooterFunctions}
 */
export default function useHeaderFooter() {
    const dispatch = useDispatch();

    return {
        updateHeader: header => dispatch(updateHeader(header)),
    };
}

const pickHeaderFooterSettings = Helpers.memoize((autoCompleteProductResultCount, disableCart) => {
    return { autoCompleteProductResultCount, disableCart };
});
// A custom hook that builds on useLocation to parse
// the query string for you.
export const useHeaderSettings = () => {
    const { autoCompleteProductResultCount, disableCart } = useSelector(
        state => state && state.headerFooter && state.headerFooter.header
    );
    return { autoCompleteProductResultCount, disableCart };
};
