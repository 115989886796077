export const FETCH_SEARCH_RESULTS = 'FETCH_SEARCH_RESULTS';
export const RECEIVE_SEARCH_RESULTS = 'RECEIVE_SEARCH_RESULTS';
export const RECEIVE_SEARCH_ERROR = 'RECEIVE_SEARCH_ERROR';
export const TRACK_SORT = 'TRACK_SORT';

export const trackSort = (sortValue) => ({
	type: TRACK_SORT,
	sortValue
});

export const receiveSearchResults = (searchKey, searchResults, pageNumber, sort, query, customAggregations) => ({
  type: RECEIVE_SEARCH_RESULTS,
  searchKey,
  searchResults,
  pageNumber,
  sort,
  query,
  customAggregations
});

// combines subsequent results together with old results rather than replacing it. searchResults is expected to be an object
export const receiveMergedSearchResults = (searchKey, searchResults) => ({
  type: RECEIVE_SEARCH_RESULTS,
  searchKey,
  searchResults,
  isMerging: true
})

export const doSearch = (searchKey, searchFunc, query, aggregations, sort, currentPage, pageSize) => (dispatch) => {
  const startPage = (pageSize * (currentPage - 1));
	return searchFunc(query, aggregations, sort, startPage, pageSize, searchKey).then(({ data, customAggregations }) => {
		return dispatch(receiveSearchResults(searchKey, data, currentPage, sort, query, customAggregations));
	}).catch((e) => {
		//TODO: error?
		console.log(e);
	});
}