import { StoreAppAPI } from '../core/src/storeapp-api-client';
import { fetchingSlugInfo, addRedirect } from '../page/page-actions';

export const TRACK_PDP_PAGE_VIEW = 'TRACK_PDP_PAGE_VIEW';
export const RECEIVE_PDP_INFO = 'RECEIVE_PDP_INFO';
export const RECEIVE_PDP_ERROR = 'RECEIVE_PDP_ERROR';
export const CLEAR_PDP_INFO = 'CLEAR_PDP_INFO';
export const RECEIVE_VARIANT_INFO = 'RECEIVE_VARIANT_INFO';
export const RECEIVE_UPSELL = 'RECEIVE_UPSELL';

export const receivePDPInfo = pdpInfo => ({
    type: RECEIVE_PDP_INFO,
    pdpInfo,
});

/**
 * Tracks page view for pdp on hard load. Page component already does this
 * So when we migrate this final tremplate too use that it will be handled
 */
export const trackPDPPageView = pdpInfo => ({
    type: TRACK_PDP_PAGE_VIEW,
    pdpInfo,
});

export const receivePDPError = () => ({
    type: RECEIVE_PDP_ERROR,
});

export const clearPDPInfo = () => ({
    type: CLEAR_PDP_INFO,
});

export const receiveVariantInfo = variantInfo => ({
    type: RECEIVE_VARIANT_INFO,
    variantInfo,
});

export const fetchPDP = (slug, filters, options) => dispatch => {
    let storeAppAPI = new StoreAppAPI();
    //append variant sku filter
    if (filters) {
        slug = `${slug}/${filters}`;
    }
    dispatch(fetchingSlugInfo());
    return storeAppAPI.page
        .get(`pdp/${slug}`, options)
        .then(resp => {
            const dataStatusCode = resp.data.status;
            const statusCode = resp.status;

            if (statusCode == '200' && dataStatusCode !== 404) {
                const product = resp.data;
                return dispatch(receivePDPInfo(product));
            } else {
                if (resp.data.destination) {
                    return dispatch(addRedirect(resp.data.destination));
                }
                return dispatch(receivePDPError());
            }
        })
        .catch(err => {
            if (err.response.data.destination) {
                dispatch(addRedirect(err.response.data.destination));
            } else if (err.response.status === 404) {
                dispatch(addRedirect('/slp/weekly-deals'));
            }
            return dispatch(receivePDPError());
        });
};
