class CompatData {
	constructor(apiConfig, http) {
		this.apiConfig = apiConfig;
		this.http = http;
	}

	get(sku) {
        const { siteInfo } = this.apiConfig;
        const { storeId } = siteInfo;
		return this.http.get(`${storeId}/compatdata/product/${encodeURIComponent(sku)}`)
				.then(({ data, status }) => {
					//attemp to parse response
					if(status === 200 && data && data !== ''){
					    return data;
					}else{
						return Promise.reject({})
					}
				});
	}

}

export default CompatData;


