import React from 'react';
import { Link } from 'react-router-dom';
import { matchRoutes } from 'react-router-config';
import { url as urlHelper } from 'hpstore-helpers';

import { withTestFlags } from './serverside-test-flags';
import { Helpers } from '../../core/src/helpers';

const { memoize } = Helpers;

const EMPTY_OBJ = {};
const EMPTY_ARRAY = [];

//TODO: deprecate this. This should now be handling in the routes. With a catch all at the end that redirects to external link
const matchAppRoutes = memoize(
    (routes, url, isAbsolute, basename) => {
        //if not a relative link or doesnt not include /app/
        if (isAbsolute || (url.indexOf('/app/') < 0 && url.indexOf(basename) < 0)) {
            return false;
        }

        const matches = matchRoutes(routes, url) || [];
        const [firstMatch] = matches;
        return !!(
            matches.length > 1 ||
            (firstMatch && Object.keys(firstMatch.route).length > 0 && firstMatch.route.path)
        );
    },
    (routes, url) => {
        return url;
    }
);

class Hyperlink extends React.PureComponent {
    static defaultProps = {
        to: '',
        testFlags: EMPTY_OBJ,
        personData: EMPTY_OBJ,
        routes: EMPTY_ARRAY,
    };

    handleClick = e => {
        e.preventDefault();
        const { onClick } = this.props;
        onClick && onClick();
    };

    render() {
        let {
            to,
            newTab,
            linkType,
            testFlags,
            personData,
            routes,
            basename,
            dispatch,
            onClick,
            makeBasenameRelative = false,
            buttonProps,
            ...linkProps
        } = this.props;
        if (onClick) {
            return (
                <a className="link" onClick={this.handleClick} {...buttonProps || {}}>
                    {this.props.children}
                </a>
            );
        }

        let { pStoreID } = personData;
        to = to || '/';

        let isAbsolute = linkType === 'absolute' || /^(https?:)?\/\//i.test(to);
        //if relative link is missing leading slash, add it
        if (!isAbsolute && to[0] !== '/') {
            to = `/${to}`;
        }
        let isAppRoute = matchAppRoutes(routes, to, isAbsolute, basename);
        //if is not an absolute route and does not contain basename,
        //test if url with basename does match an app route
        //this will catch instances where a relative route assumes the basename
        //this could however incorrectly correct a url if a directory happens to match an app directory
        //for example if /slp/page where an ETR page it would get sent to /basename/slp/page instead
        if (!isAbsolute && !isAppRoute) {
            let baseRelative = (typeof to === 'string' && to.startsWith(basename) ? to : `${basename}${to}`).replace(/\/+/gi, '/');
            if (matchAppRoutes(routes, baseRelative, isAbsolute, basename)) {
                isAppRoute = true;
                to = baseRelative;
            } else if (makeBasenameRelative) {
                to = baseRelative;
            }
        }

        // if it's an app route, even when absolute, get the relative path and use that.
        if (isAppRoute) {
            //i dont think this code will ever be reachedm with the new matchAppRoutes
            // it's not going to match when the domain is included
            to = urlHelper.getRelativePath(to);
        }

        // setup security for new tab urls
        if (newTab || linkProps.target === '_blank') {
            linkProps.target = '_blank';
            linkProps.rel = 'noopener noreferrer';
        }

        // if private store add pStoreId query parameter to link
        if (pStoreID) {
            to = Helpers.addUrlParam(to, { pStoreID }, true);
        }

        return !isAppRoute ? (
            <a {...linkProps} href={to} data-type="external">
                {this.props.children}
            </a>
        ) : (
            <Link {...linkProps} to={to}>
                {this.props.children}
            </Link>
        );
    }
}

export default withTestFlags(Hyperlink);

export { matchAppRoutes, Hyperlink };
