export const CACHE_ADD = 'CACHE_ADD';
export const CACHE_BULK_ADD = 'CACHE_BULK_ADD';
export const CACHE_DELETE = 'CACHE_DELETE';
export const CACHE_BULK_DELETE = 'CACHE_BULK_DELETE';


export const cacheBulkAdd = (key, data) => ({
	type: CACHE_BULK_ADD,
	data
});


export const cacheDelete = (key) => ({
	type: CACHE_DELETE,
	key,
});


export const cacheBulkDelete = (keys) => ({
	type: CACHE_BULK_DELETE,
	keys,
});


export const cacheAdd = (key, data) => (dispatch, getState) =>  {
	let { itemCache } = getState();
	if(!itemCache[key]){
			dispatch({
				type: CACHE_ADD,
				key,
				data
			});
	}
}
