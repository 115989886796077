import { 
    addGtmPropsToCategoryTiles,
    addGtmPropsToBanners,
    addGtmAttributes,
    setPromotion,
    addGtmAttributesToModelTiles,
    addGtmAttributesToContentCardBanners,
    addGtmAttributesToClpModelTiles,
    getClpModelLinkId
} from '../metrics-helpers';

import { categoryTileLinkMap } from '../../category-landing-page/util';

export default {
    highlightedGrid: ({ component, device }) => {
        let { tileLayouts } = component || {};
        let { tiles } = tileLayouts || {};
        return {
            ...component || {},
            tileLayouts: {
                ...tileLayouts || {},
                tiles: addGtmPropsToCategoryTiles(tiles, device)
            }
        };
    },
    highlightedTwoThreeGrid: ({ component, device }) => {
        let {tileLayouts} = component || {};
        let {tiles} = tileLayouts || {};
        if(!Array.isArray(tiles)) return component;

        component.tileLayouts.tiles =  addGtmPropsToCategoryTiles(tiles, device);
        return component;
    },
    categoryHeroBanner: ({ component, device, analyticsData }) => addGtmPropsToBanners(component, device, analyticsData, 'category-hero-banner',  {promotionClickType: 'cta'}),
    modelByBrand: ({ component, slugInfo }) => {
        let { modelList } = component || {};
        let { components } = slugInfo || {};
        let { clpType } = components || {};
        if(modelList){
            const linkIdParams = { clpType, carouselName: 'brand' };
            const gtmId = 'carousel';
            component.modelList = addGtmAttributesToClpModelTiles(modelList, linkIdParams)
            component.carouselGtmActions = new Map([
                [
                    'prevArrowAction',
                    {
                        gtmCategory: 'linkClick',
                        gtmValue: getClpModelLinkId({
                            ...linkIdParams,
                            tileName: 'left-arrow'
                        }),
                        gtmId
                    }
                ],
                [
                    'nextArrowAction',
                    {
                        gtmCategory: 'linkClick',
                        gtmValue: getClpModelLinkId({
                            ...linkIdParams,
                            tileName: 'right-arrow'
                        }),
                        gtmId
                    }
                ]
            ]);
        }


        return component;
    },
    modelByUse: ({ component, slugInfo }) => {
        if(!component) return component;
        let { modelList } = component;
        let { components } = slugInfo || {};
        let { clpType } = components || {};

        if(modelList){
            component.modelList = addGtmAttributesToClpModelTiles(modelList, { clpType, carouselName: 'lifestyle', withTabs: true});
        }

        component.tabGtmActions = new Map([
            [
                'tabClick',
                {
                    gtmCategory: 'linkClick',
                    gtmId: 'carousel'
                }
            ]
        ]);

        return component;
    },
    latestPromos: ({ component }) => {
        let {contentCardBanners} = component || {};
        let {banners} = contentCardBanners || {};
        return {
            ...component || {},
            contentCardBanners: {
                ...contentCardBanners || {},
                banners: addGtmAttributesToContentCardBanners(banners, { gtmId: 'other' })
            }
        }
    },
    innovationBanner: ({ component, device, analyticsData }) => {
        const { contentAlignment, image, type, ...bannerContentProps } = component;

        const getGtmAttributes = bannerContentProp => {
            return setPromotion(bannerContentProp, { device, id: 'innovationBanner', type: 'promotionClick' }, analyticsData );
        };
    
        return {
            ...component || {},
            ...addGtmAttributes(bannerContentProps, getGtmAttributes)
        }
    },
    inkTonerExclusives: ({ component, device, analyticsData }) => {
        if (!Array.isArray(component)) return component;
        return addGtmPropsToBanners(component, device, analyticsData, 'inkTonerExclusives',  { device, id: 'innovationBanner', type: 'promotionClick' })
    },
    accessoriesContent: ({ component, device, analyticsData }) => {
        const { contentCardBanners } = component || {};
        const { banners } = contentCardBanners || {};
        if(!Array.isArray(banners)) return component;

        component.contentCardBanners.banners = addGtmPropsToBanners(banners, device, analyticsData, 'accessoriesContent',  {promotionClickType: 'cta'});
        return component;
    },
    shopByCategory: ({ component, device }) => {
        const { tileLayouts } = component || {};
        const { tiles } = tileLayouts || {};
        if(!Array.isArray(tiles)) return component;

        component.tileLayouts.tiles = addGtmPropsToCategoryTiles(tiles, device)

        return component;
    },
    carepackFinder: ({ component }) => {
        if(!component) return component;
        component.gtmAttributes = {
            'data-gtm-category': 'linkClick',
            'data-gtm-id': 'carepack-finder',
            'data-gtm-value': 'tool-clicked'
        }
        return component;
    },
    categoryTitleSection: ({ component, slugInfo }) => {
        if(!component) return component;
        const { vanityUrl } = slugInfo || {};
        const { gtmValue } = categoryTileLinkMap[vanityUrl] || {};
        component.categoryTilesLinkGtmAttributes = (
            gtmValue ? 
                {
                    'data-gtm-id': 'tiles',
                    'data-gtm-value': gtmValue,
                    'data-gtm-category': 'linkClick'    
                }
            : null
        );
        return component;
    }
};