import debounce from 'lodash/debounce';

import { Helpers } from '../core/src/helpers';
import { StoreAppAPI } from '../core/src/storeapp-api-client';
import { EtrAPI } from '../core/src/etr-api-client';

export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';
export const RECEIVE_PRICES = 'RECEIVE_PRICES';
export const PRICE_VIEW = 'PRICE_VIEW';
export const RECEIVE_PRICE_ERROR = 'RECEIVE_PRICE_ERROR';
export const RECEIVE_UTILITY = 'RECEIVE_UTILITY';
export const RECEIVE_TECH_SPECS = 'RECEIVE_TECH_SPECS';
export const CLEAR_TECH_SPECS = 'CLEAR_TECH_SPECS';
export const SORT_PRODUCTS = 'SORT_PRODUCTS';
export const RECEIVE_PRICE_FETCH = 'RECEIVE_PRICE_FETCH';

const storeAppAPI = new StoreAppAPI();

//Map of product class that do not have price or inventory data
export const NO_PRICE = {
    PRT: 1,
    EXT: 1,
    USER_DEFINED: 1,
};

export const receiveProducts = productObj => ({
    type: RECEIVE_PRODUCTS,
    productObj,
});

export const receivePrices = prices => ({
    type: RECEIVE_PRICES,
    prices,
});

//indicate that a prices has been requestsed.
//Used by the server to hint to the client of prices that may need to be pre-fetched
//
/**
 * indicate that a prices has been requestsed.
 * Used by the server to hint to the client of prices that may need to be pre-fetched
 * In cased the client is delaying hydration
 */
export const receivePriceView = priceId => ({
    type: PRICE_VIEW,
    priceId,
});

export const receivePriceError = failedCatentryIds => (dispatch, getState) => {
    const action = {
        type: RECEIVE_PRICE_ERROR,
    };

    if (Array.isArray(failedCatentryIds) && failedCatentryIds.length) {
        // get products from all tabs
        const products = Object.values(getState().productData.productInfo.productList).reduce((products, tab) => {
            products = [...products, ...tab.products];
            return products;
        }, []);

        action.products = products;
        action.failedCatentryIds = failedCatentryIds;
    } else {
        action.failedCatentryIds = [];
    }

    return dispatch(action);
};

/**
 * Use this action dispatch to minimize extra price calls by setting values for inflight price calls
 * @param {*} catEntryIds
 * @returns
 */
export const receivePriceFetch = catEntryIds => dispatch => {
    const action = {
        type: RECEIVE_PRICE_FETCH,
        catEntryIds: catEntryIds,
    };
    return dispatch(action);
};

export const receiveUtility = utilityInfo => ({
    type: RECEIVE_UTILITY,
    utilityInfo,
});

export const sortProducts = (sortValue, pg, analyticsLabel) => ({
    type: SORT_PRODUCTS,
    sortValue,
    pg,
    analyticsLabel,
});

// TODO: temp hotfix to prevent spam renrender if price api fails,
// since we batch price calls, this gets hit hard when the batch queue purges and rejects promises
const handlePriceError = debounce((dispatch, error) => {
    const failedIds = error.catentryId;
    return dispatch(receivePriceError(failedIds));
});

export const viewPrice = sku => dispatch => {
    return dispatch(receivePriceView(sku));
};

export const fetchProducts = pgKey => (dispatch, getState) => {
    return storeAppAPI.productGroup.get(pgKey, { merged: 1 }).then(resp => {
        if (resp && resp.status == 200) {
            let { productsList = [], metaData, desc, name, key } = resp.data[0] || {};
            let pgObj = {
                products: productsList,
                sortValue: 'rank.recommended',
                metaData,
                description: desc,
                name: name,
                key,
                prefetch: false, //when receive mark prefetch false
            };
            const { sortBy } = metaData || {};

            if (sortBy && sortBy !== 'manual') {
                pgObj.products = Helpers.overrideDefaultSortOrder(pgObj.products, 'recommended', sortBy);
            }

            const productObj = { [key]: pgObj };

            let ids = productsList.reduce((r, product) => {
                if (product.prdClass !== 'USER_DEFINED') {
                    r.push(product.itemId);
                }
                return r;
            }, []);

            return dispatch(receiveProducts(productObj));
        }
    });
};

export const prefetchProducts = vanityUrl => (dispatch, getState) => {
    const store = getState();
    const { productData = {}, slugInfo = {} } = store;
    const { productList } = productData.productInfo || {};
    const { components = {} } = slugInfo;
    const { productTab = {} } = components;
    const { productGroups = [] } = productTab;
    //only allow prefetch for active page
    if (vanityUrl !== slugInfo.vanityUrl) {
        return Promise.reject('You can currently only prefetch component data for the active page');
    }
    //exclude product groups for the page that are already in redux
    const prefetch = {};
    const exclude = productGroups.reduce((r, pg) => {
        const key = typeof pg === 'string' ? pg : pg.key;
        if (key in productList) {
            r.push(key);
        } else {
            prefetch[key] = { prefetch: true };
        }
        return r;
    }, []);
    //all groups have already been fetched do nothing
    if (exclude.length === productGroups.length) {
        return Promise.resolve();
    }
    //add prefetch place holders to redux to prevent multiple calls
    dispatch(receiveProducts(prefetch));

    return storeAppAPI.page.getPageComponent(vanityUrl, 'productTab', { exclude }).then(resp => {
        if (resp && resp.status == 200) {
            const { productGroups = [] } = resp.data[0] || {};
            const productObj = productGroups.reduce((listData, pg) => {
                let { productsList, metaData, desc, name, key } = pg;
                let pgObj = {
                    products: productsList,
                    sortValue: 'rank.recommended',
                    metaData,
                    description: desc,
                    name: name,
                    key,
                    prefetch: false, //when receive mark prefetch false
                };
                listData[key] = pgObj;
                return listData;
            }, {});
            if (Object.keys(productObj).length > 0) {
                return dispatch(receiveProducts(productObj));
            }
            return Promise.resolve();
        }
    });
};

/**
 *
 * @param {*} ids - itemIds of products
 * @param {*} expectAll - if true dispatch price error for any price not return in the results
 * @returns
 */
export const fetchPrices = (ids, expectAll) => (dispatch, getState) => {
    const { siteConfig } = getState();
    const { priceSettings } = siteConfig || {};
    //set price settings from store, for pre-fetch
    EtrAPI.services.setPriceSettings(priceSettings);
    return EtrAPI.services
        .fetchPriceAndInventory(ids)
        .then(resp => {
            /** .fetchPriceAndInventory() throws on non 200 */
            const { serviceData } = resp.data;
            const { productData } = getState();
            const { productInfo = {} } = productData;
            let newProductInfo = false;
            let missingIds = [];
            try {
                missingIds = Object.keys(serviceData).reduce((array, sku) => {
                    const priceObj = serviceData[sku];
                    const index = array.indexOf(priceObj.itemId);
                    if (index > -1) {
                        array.splice(index, 1);
                    }
                    return array;
                }, ids.slice(0));
            } catch (e) {}

            for (let prd in serviceData) {
                newProductInfo = !(prd in productInfo.productPrices);
                if (newProductInfo) {
                    break;
                }
            }
            missingIds.length > 0 && handlePriceError(dispatch, { catentryId: missingIds });
            if (newProductInfo) {
                return dispatch(receivePrices(serviceData));
            }
        })
        .catch(err => {
            return handlePriceError(dispatch, err);
        });
};
