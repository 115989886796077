import React from 'react';
import omitBy from 'lodash/omitBy';

import { Helpers } from '../../core/src/helpers';
import DealViolator from '../components/deals/deal-violator';
import SustainabilityLogo from '../components/sustainability-logo';
import { getStockIndicator, getEOLCTALink } from './cart';
import { addGtmPropsToProductTile } from '../../metrics/metrics-helpers';

//TODO: replace with URLS to logos from UX. These are temporary ones grabbed from google images
export const ENVIRONMENTAL_BADGES = {
    epeat: {
        imageUrl: 'https://www.hp.com/us-en/shop/app/static/images/icon-epeat-green.png',
        imageAlt: 'EPEAT',
    },
    epeatBronze: {
        imageUrl: 'https://www.hp.com/us-en/shop/app/static/images/icon_epeat-bronze.png',
        imageAlt: 'EPEAT Bronze',
    },
    epeatSilver: {
        imageUrl: 'https://www.hp.com/us-en/shop/app/static/images/icon-epeat-silver.png',
        imageAlt: 'EPEAT Silver',
    },
    epeatGold: {
        imageUrl: 'https://www.hp.com/us-en/shop/app/static/images/icon-epeat-gold.png',
        imageAlt: 'EPEAT',
    },
    epeatClimatePlus: {
        imageUrl: 'https://www.hp.com/us-en/shop/app/assets/images/uploads/prod/EPEAT-Climate-Logo-EPEAT-Green1728400989191411.png',
        imageAlt: 'EPEAT Climate+',
    },
    epeatBronzeClimatePlus: {
        imageUrl: 'https://www.hp.com/us-en/shop/app/assets/images/uploads/prod/bronze1728400988896515.png',
        imageAlt: 'EPEAT Bronze Climate+',
    },
    epeatSilverClimatePlus: {
        imageUrl: 'https://www.hp.com/us-en/shop/app/assets/images/uploads/prod/silver1728400989320713.png',
        imageAlt: 'EPEAT Silver Climate+',
    },
    epeatGoldClimatePlus: {
        imageUrl: 'https://www.hp.com/us-en/shop/app/assets/images/uploads/prod/gold1728400989464138.png',
        imageAlt: 'EPEAT Gold Climate+',
    },
    energyStar: {
        imageUrl: 'https://www.hp.com/us-en/shop/app/static/images/icon-energy-star.png',
        imageAlt: 'Energy Star',
    },
};

const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getTileCtaLink = (ctaLink, urlParams) =>
    Helpers.addUrlParam(
        ctaLink && ctaLink.indexOf('/us-en/shop') > -1 ? Helpers.getRelativePath(ctaLink) : ctaLink,
        urlParams
    );

// TODO: further optimization we can specify image width for components that need smaller images
export const getImageLink = (url, resize, policy = Helpers.DEFAULT_IMAGE_POLICY) => {
    //conditionally add query params
    const params = policy ? { impolicy: policy } : {};
    if (resize) {
        params['imwidth'] = 270;
        params['imdensity'] = 1;
    }
    return Helpers.addUrlParam(url, params);
};

export const mapBundleProducts = (bundleProducts, priceObj, isUnique, onlyImages) => {
    const distinctSkus = {};
    if (bundleProducts && bundleProducts.length > 0) {
        return bundleProducts.reduce((prds, p) => {
            const { name: bundleProductTitle, imageUrl, _id, sku } = p;
            const uniqueId = sku || _id;
            const hasUniqueId = isUnique && uniqueId;
            if (hasUniqueId && distinctSkus[uniqueId]) {
                return prds;
            }

            if (hasUniqueId) {
                distinctSkus[uniqueId] = 1;
            }

            const price = ((priceObj && priceObj.items) || {})[Helpers.getCatEntryId(p)];
            const { salePrice } = price || {};
            if (onlyImages) {
                prds.push({
                    src: imageUrl,
                    alt: bundleProductTitle,
                });
                return prds;
            }
            prds.push({
                bundlePricePrefix: 'Worth',
                bundleProductImage: {
                    src: imageUrl,
                    alt: bundleProductTitle,
                },
                bundleProductTitle,
                bundleProductPrice: salePrice,
            });

            return prds;
        }, []);
    }
    return undefined;
};

/**
 *
 * @param {*} product - the product object
 * @param {*} price - the price object
 * @param {*} compare - compare hook
 * @returns
 */
export const getCompare = (product, compare) => {
    const { isSelected, toggleCompareProduct } = compare || {};
    const { sku, pm_producttype, eol } = product || {};
    if (!product || !toggleCompareProduct || Helpers.isGiftCard(product) || pm_producttype === 'Services' || eol) {
        return {};
    }
    return {
        compareBtnProps: {
            onCompare: () => toggleCompareProduct({ ...product }, true),
            compareChecked: isSelected(sku),
        },
    };
};

export const hasFreeShipping = offers => {
    try {
        return (offers || []).reduce((r, offer) => {
            const offerMsg = (getOfferText(offer) || '').toLowerCase();
            const hasFreeShipping = offerMsg.indexOf('free') > -1 && offerMsg.indexOf('shipping') > -1;
            return r || hasFreeShipping;
        }, false);
    } catch (e) {
        return false;
    }
};

export const resolveOfferMsg = (msg, price, props) => {
    const { noViolator, delimiter = ' ' } = props || {};
    const { deal, violatorMessage } = price || {};
    const { activeDeal } = deal || {};
    const offerMessage = Array.isArray(msg) ? msg.join(delimiter) : msg;
    if (activeDeal) {
        return <DealViolator price={price} noViolator={noViolator} />;
    }
    //if no offer message but one is present from HPServices use it
    return offerMessage || violatorMessage || '';
};

export const resolveViolatorMessage = msg => {
    return msg ? <span dangerouslySetInnerHTML={Helpers.createMarkup(msg)} /> : '';
};

export const getTileBadges = (product, price, combineBadges = true) => {
    const { subPromoBanner } = price || {};
    const { content } = subPromoBanner || {};
    const { sustainability_logo_attribute, attributes } = product || {};
    //check SLP product data for sustainability as well
    const sustainability = sustainability_logo_attribute || (attributes || {}).sustainability_logo_attribute;
    if (!content && !sustainability) {
        return undefined;
    }
    if (!combineBadges) {
        return {
            customContent: content ? (
                <span className="sub-promo" dangerouslySetInnerHTML={Helpers.createMarkup(content)} />
            ) : null,
            sustainability: <SustainabilityLogo sustainabilityAttribute={sustainability} size="small" />,
        };
    }
    //if custom content, prepend sustainability logo
    return content ? (
        <>
            <SustainabilityLogo sustainabilityAttribute={sustainability} size="small" />
            <span
                className="sub-promo"
                style={{ marginTop: '0.25rem' }}
                dangerouslySetInnerHTML={Helpers.createMarkup(content)}
            />
        </>
    ) : (
        <SustainabilityLogo sustainabilityAttribute={sustainability} size="small" />
    );
};

/**
 *
 * @param {*} product - object containing loyalty_multiplier (used on SRP) or rewardsBadge (used on VWAs)
 * @param {*} pStoreID - the private store id. If provided, return null if using loyalty_multiplier to determine the rewards badge
 * @returns Object passed to ProductTile component's rewardBadge prop
 */
export const getRewardsBadge = (product, pStoreID) => {
    let { loyalty_multiplier, rewardsBadge, attributes } = product || {};
    const { loyalty_multiplier: attrLM } = attributes || {};
    const loyaltyMultiplier = (loyalty_multiplier || attrLM) * 1;
    //percentage should always be the loyalty multiplier times 3
    const loyaltyPercentage = loyaltyMultiplier * 3;
    if (typeof rewardsBadge === 'object') {
        return {
            message: rewardsBadge.label,
            tooltipContent: {
                title: rewardsBadge.tooltipTitle,
                description: rewardsBadge.tooltipBody,
            },
        };
    }

    return !pStoreID && loyaltyMultiplier > 0
        ? {
              message: `${loyaltyPercentage}% back in HP Rewards`,
              tooltipContent: {
                  title: `${loyaltyPercentage}% BACK IN HP REWARDS`,
                  description:
                      'HP Rewards is a loyalty program where users earn HP Rewards points by buying eligible HP Products.',
              },
          }
        : null;
};

const isEmptyObject = obj => {
    return Object.values(obj).every(value => {
        try {
            return (
                value === null ||
                value === undefined ||
                (Array.isArray(value) && value.length < 1) ||
                (typeof value === 'object' && isEmptyObject(obj))
            );
        } catch (e) {}

        return false;
    });
};

export const getHeaderProps = (product, priceObj, options) => {
    const { compact, combineBadges, noViolator } = options || {};
    const usageLabel = [...(product.usageLabel || [])];
    if (product.isNew) usageLabel.unshift('NEW');
    if (product.energystar) usageLabel.unshift('ENERGY STAR');
    const headerProps = {
        usageLabel,
        offerMessage: resolveOfferMsg(product.violatorMessage || product.violatorMsg, priceObj, {
            noViolator,
        }),
        customElement: getTileBadges(product, priceObj, combineBadges),
        ...getStockIndicator(priceObj, options),
    };

    return {
        headerProps: compact
            ? omitBy(headerProps, value => {
                  return (
                      value === null ||
                      value === undefined ||
                      (Array.isArray(value) && value.length === 0) ||
                      (value && typeof value === 'object' && isEmptyObject(value))
                  );
              })
            : headerProps,
    };
};

export const getProductLinkProps = (product, priceObj, analyticsData, btnProps = {}) => {
    const { ctaViewDetails = 'View Details', ctaViewDetailsLink, productUrl, linkUrl, eol, sku } = product;
    const ctaLink = getTileCtaLink(ctaViewDetailsLink || productUrl || linkUrl);
    const eolUrl = getEOLCTALink(product);
    const isEol = eol && eolUrl;

    // see HPEDT-16947
    if (isEol) {
        return {
            productLinkProps: {
                to: ctaLink,
                preventDefault: false,
            },
        };
    }

    let gtmActions;
    if (analyticsData) {
        let { list, gtmId, position, prices, hsResID } = analyticsData;
        let { gtmUniqueId } = product || {};
        gtmActions = addGtmPropsToProductTile(
            {
                ...product,
                customGtmId: `${list}-${sku}`,
                sku,
                price: prices || priceObj,
                position,
                hsResID,
            },
            { ...analyticsData, gtmUniqueId },
            { [sku]: priceObj }
        );
    }

    let gtmAction = gtmActions && typeof gtmActions.get === 'function' && gtmActions.get('productClick');

    return {
        productLinkProps: {
            btnProps: {
                children: ctaViewDetails,
                ...btnProps,
            },
            to: ctaLink,
            gtmAction,
            preventDefault: false,
        },
    };
};

export const cleanText = text => {
    if (!text) return text;
    return text.replace(/\u00a0/g, ' ');
};

export const getTitle = (product, titleTag) => {
    const { name, title } = product || {};
    const productTitle = title || name;
    return typeof titleTag === 'string' ? { content: productTitle, tag: titleTag } : productTitle;
};

export const getEnvironmentalCertificationBadges = product => {
    const badges = [];
    const isClimatePlus = /climate plus/gi.test(energy_efficiency_icon_attribute);
    if (!product) {
        return badges;
    }
    const { energy_efficiency_icon_attribute, sustainability_logo_attribute, energystar } = product;
    //start energy_efficiency_icon_attribute
    if (/energy star/gi.test(energy_efficiency_icon_attribute) || energystar === true) {
        //check for energy star
        badges.push(ENVIRONMENTAL_BADGES.energyStar);
    }

    if (/gold/gi.test(energy_efficiency_icon_attribute)) {
        //check for gold
        const badgeUrl= isClimatePlus ? ENVIRONMENTAL_BADGES.epeatGoldClimatePlus  : ENVIRONMENTAL_BADGES.epeatGold;
        badges.push(badgeUrl);
    } else if (/silver/gi.test(energy_efficiency_icon_attribute)) {
        //check for silver
        const badgeUrl= isClimatePlus ? ENVIRONMENTAL_BADGES.epeatSilverClimatePlus : ENVIRONMENTAL_BADGES.epeatSilver;
        badges.push(badgeUrl);
    } else if (/bronze/gi.test(energy_efficiency_icon_attribute)) {
        //check for bronze
        const badgeUrl= isClimatePlus ? ENVIRONMENTAL_BADGES.epeatBronzeClimatePlus : ENVIRONMENTAL_BADGES.epeatBronze;
        badges.push(badgeUrl);
    } else if (/epeat/gi.test(energy_efficiency_icon_attribute)) {
        //check for epeat
        const badgeUrl= isClimatePlus ? ENVIRONMENTAL_BADGES.epeatClimatePlus : ENVIRONMENTAL_BADGES.epeat;
        badges.push(badgeUrl);
    }
    //end energy_efficiency_icon_attribute
    return badges;
};

/**
 * Attempt to normalize the color option name to make i
 */
export const getColorId = colorName => {
    try {
        return colorName.trim().toLowerCase().replace(/\s/gi, '');
    } catch (e) {
        return colorName;
    }
};

export const getConfigurations = (variants, selectedColor, pdpImages) => {
    const { thumbnailImages = [] } = pdpImages || {};
    return (variants || []).map((v, i) => {
        const { default: isDefault, name, colorHex } = v;
        //attempt to find an image match the color
        const { url: imageUrl } = thumbnailImages.find(i => i.color === name) || {};
        return {
            label: name,
            checked: i === 0,
            value: name,
            colorHex,
            checked: selectedColor ? name === selectedColor : isDefault === 'yes',
            imageUrl,
        };
    });
};

//picks image url from image object based on all the alternative formats
export const getImageUrl = image => {
    return image?.src || image?.image || image?.lrg;
};

export const getSelectedVariant = (variants, value) => {
    return (
        variants.find(c => {
            const { value: cValue } = c;
            return cValue === value;
        }) || variants[0]
    );
};

export const getColors = (variants, selectedIndex, hexColorMap) => {
    let validSelectedIndex = selectedIndex > -1;
    return (variants || []).map((v, i) => {
        const { default: isDefault, name, colorHex, linkProps } = v;
        const notSelected = (!validSelectedIndex && isDefault !== 'yes') || (validSelectedIndex && selectedIndex !== i);
        return {
            tooltipProps: {
                description: name ? capitalizeFirstLetter(name.toLowerCase()) : null,
            },
            sku: name,
            color: colorHex ? colorHex : Helpers.getHexColor(name, hexColorMap),
            ...(notSelected && linkProps ? { linkProps } : {}),
        };
    });
};

const getVisIDRating = rating => {
    let roundedRating = typeof rating === 'number' ? rating.toFixed(1) : Number(rating).toFixed(1);
    if (isNaN(roundedRating)) return null;

    return Number(roundedRating.replace(/\.0$/, ''));
};

export const getRatings = (product, options) => {
    const { visId } = options || {};
    const { ctaViewDetails = 'View Details', ctaViewDetailsLink, productUrl, linkUrl, eol, sku } = product;
    const ctaLink = getTileCtaLink(ctaViewDetailsLink || productUrl || linkUrl);
    const score = visId ? getVisIDRating(product.rating) : product.rating;
    const ratings =
        typeof product.rating === 'number'
            ? {
                  score,
                  reviewsCount: product.numReviews,
              }
            : score;

    const link = ctaLink ? `${ctaLink}#reviews` : undefined;
    return {
        ...ratings,
        link,
        /*TODO:HPEDT-18151 Remove inline rating attrinbutes till we can fix issue with render  */
        /*'data-bv-show': 'inline_rating',
        'data-bv-product-id': Helpers.BVTransformSKU(sku),
        'data-bv-redirect-url': link,*/
    };
};

const getOfferText = offer => {
    const { title = '', text = '', value = '' } = typeof offer === 'string' ? { title: offer } : offer;
    const description = text && Helpers.getInnerText(text) !== Helpers.getInnerText(title) ? ` ${text}` : '';
    return `${title}${description}${value}`.trim();
};

/**
 *
 * @param {*} product Product object
 * @param {*} compare Compare hook
 * @param {*} tierDiscount Optionally provide a string to display a tier discount message
 * @returns
 */
export const getOffersModals = (product, compare, priceObj, offersFilter, specsDelimeter = ' • ') => {
    try {
        const { name, specialOffers, offers, keyPoints, ksps, imageUrl, images, specFirstGlance, sku } = product;
        const { offers: dynamicOffers } = priceObj || {};
        const [firstImage] = images || [];
        let list = dynamicOffers || specialOffers || offers || [];
        const description = keyPoints || ksps || specFirstGlance || [];
        const { setViewOffers, isViewOffersOpen } = compare || {};
        //if no offers or compare hook is not provided, return empty object
        if (list?.length < 1 || !setViewOffers) {
            return {};
        }
        list = offersFilter ? offersFilter(list, sku) : list;
        return {
            modalProps: {
                isOpen: typeof isViewOffersOpen === 'function' ? isViewOffersOpen(sku) : isViewOffersOpen,
                onToggle: isOpen => {
                    isOpen ? setViewOffers(sku) : setViewOffers(null);
                },
                contentProps: {
                    miniProductTileProps: {
                        image: { url: imageUrl || getImageUrl(firstImage), alt: name },
                        name,
                        description: description?.length > 0 ? description.join(specsDelimeter) : undefined,
                    },
                    title: 'All Offers',
                    list: list.map(offer => {
                        return (
                            <span
                                className="cust-html"
                                dangerouslySetInnerHTML={Helpers.createMarkup(getOfferText(offer))}
                            />
                        );
                    }),
                },
            },
        };
    } catch (e) {
        console.error('Error in getOffersModals', e);
        return {};
    }
};
