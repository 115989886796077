import { connect } from 'react-redux';
import loadable from '@loadable/component';

import { fetchReorderProducts, clearReorderInfo, receiveReorderInfo } from '../reorder-actions';
import { Helpers } from '../../core/src/helpers';

const Reorder = loadable(() => Helpers.retryFunc(() => import('./reorder')));

const loadData = async (urlParams, store, context) => {
    const { query } = context;
    const skus = (query.sku || '').split(',');
    const promise = await store.dispatch(fetchReorderProducts(skus, query.printerID, query.source, query.orderDate));
    return promise;
};

const mapStateToProps = state => {
    const { reorder, cartInfo, router } = state;
    const { location } = router;
    const { related = {}, primary } = reorder;
    return { reorder: related, primarySku: primary, cartInfo, location };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchReorderProducts: (skus, primarySku, source, orderDate) =>
            dispatch(fetchReorderProducts(skus, primarySku, source, orderDate)),
        clearReorderInfo: () => dispatch(clearReorderInfo()),
        receiveReorderInfo: (skus, primarySku, source, orderDate) =>
            dispatch(receiveReorderInfo({ related: skus, primary: primarySku }, source, orderDate)),
    };
};

export default {
    component: connect(mapStateToProps, mapDispatchToProps)(Reorder),
    loadData,
};
