export const ProductGroupAPI = function(apiConfig, http, helpers){
  let self = this;
  let path = `${process.env.BASENAME}/app/api/web/product-group`;

  self.get = (pgKey, options) => {
    pgKey = encodeURIComponent(pgKey)

    return http.get(`${path}/${pgKey}`,{
      params: options
    });
  };

  self.getComponent = (pgKeys, options) => {
  	return http.get(`${path}/component/${encodeURIComponent(pgKeys.join('&'))}`,{
      params: options
    });
  }

}

