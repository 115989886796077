import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import { Helpers } from '../../core/src/helpers';

const jsonLdDefaults = {
    blog: {
        '@context': 'http://schema.org',
        '@type': 'Article',
        headline: 'HP Tech Takes',
        image: 'https://www.hp.com/us-en/shop/app/assets/images/uploads/stage/teck-takes-logo1539732419616.png',
        author: {
            '@type': 'Person',
            name: 'Staff Writer',
        },
        genre: 'technology trends',
        publisher: {
            '@type': 'Organization',
            name: 'HP',
            logo: {
                url: 'https://ssl.www8.hp.com/us/en/images/i/hpi/header-footer/caas-hf-v3.2/hpi-hp-logo-pr.gif',
                '@type': 'ImageObject',
            },
        },
        url: 'https://store.hp.com',
        datePublished: '2018-05-19T07:00:00.000Z',
        dateModified: '2018-05-19T07:00:00.000Z',
        description: '',
        articleBody: '',
    },
    breadcrumb: {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
            {
                '@type': 'ListItem',
                position: 1,
                name: 'HP Store',
                item: {
                    '@id': Helpers.getAbsoluteRedirectPath('/'),
                    name: 'Store Home',
                },
            },
        ],
    },
    product: {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        aggregateRating: {},
        sku: '',
        description: '',
        image: [],
        name: '',
        mpn: '',
        brand: {},
        offers: {},
        gtin: '',
        breadcrumb: {},
        itemCondition: '',
    },
    'how-tos': {
        '@context': 'https://schema.org',
        '@type': 'HowTo',
        name: '',
        step: [],
        description: '',
        image: '',
    },
    'faq-page': {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: [],
    },
};

class JsonLd extends Component {
    static propTypes = {
        data: PropTypes.oneOfType([
            PropTypes.shape({
                '@context': PropTypes.string,
                '@type': PropTypes.string,
                headline: PropTypes.string,
                image: PropTypes.string,
                author: PropTypes.object,
                genre: PropTypes.string,
                publisher: PropTypes.object,
                url: PropTypes.string,
                datePublished: PropTypes.string,
                dateModified: PropTypes.string,
                description: PropTypes.string,
                articleBody: PropTypes.string,
            }),
            PropTypes.shape({
                '@context': PropTypes.string,
                '@type': PropTypes.string,
                itemListElement: PropTypes.shape({
                    '@type': PropTypes.string,
                    position: PropTypes.number,
                    name: PropTypes.string,
                    item: PropTypes.string,
                }),
            }),
            PropTypes.arrayOf(
                PropTypes.shape({
                    type: PropTypes.string,
                    data: PropTypes.object,
                })
            ),
        ]),
        renderInHead: PropTypes.bool,
        shouldNotUseDefaults: PropTypes.bool,
        schemaMarkup: PropTypes.object,
    };

    getMarkup = (data, type) => {
        const { shouldNotUseDefaults } = this.props;
        const jsonLdDefault = jsonLdDefaults[type] || jsonLdDefaults['blog'];

        const d = Object.keys(jsonLdDefault).reduce((result = {}, key) => {
            if (
                data &&
                data[key] &&
                (typeof data[key] === 'string' || (typeof data[key] === 'object' && Object.keys(data[key]).length))
            ) {
                result[key] = data[key];
                return result;
            } else if (!shouldNotUseDefaults) {
                result[key] = jsonLdDefault[key];
            }
            return result;
        }, {});

        return d;
    };

    getData = () => {
        const { data, schemaMarkup, type } = this.props;
        if (typeof schemaMarkup === 'object') {
            return {
                '@context': 'https://schema.org',
                ...schemaMarkup,
            };
        }

        return Array.isArray(data)
            ? data.map(({ type, data }) => this.getMarkup(data, type))
            : this.getMarkup(data, type);
    };

    render() {
        const { renderInHead } = this.props;
        const jsonString = JSON.stringify(this.getData());

        return renderInHead ? (
            <Helmet>
                <script type="application/ld+json">{jsonString}</script>
            </Helmet>
        ) : (
            <script type="application/ld+json" dangerouslySetInnerHTML={Helpers.createMarkup(jsonString)} />
        );
    }
}

export default JsonLd;
