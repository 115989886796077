import React from 'react';

import { SearchContext } from './search-context';

/** 
 * Passes customContext prop to child elements
 */
export default class SearchCustomContext extends React.Component {

    static defaultProps = {
        label: 'results'
    }

    render() {
        return (
            <SearchContext.Consumer>
             {
                ({ customContext, customAggregations, query, setQuery, updateQuery, updateFilterUrl, seralizedQuery }) => {
                    if (this.props.children) {
                        return this.props.children({ customContext, customAggregations, query, setQuery, updateQuery, updateFilterUrl, seralizedQuery })
                    }else{
                        return null
                    }
                }
              }
            </SearchContext.Consumer>
        );
    }

}

