import { connect } from 'react-redux';

import { fetchProfileAndCart, manageOrderLock } from '../utility-actions';
import UtilityNav from './utility-nav';

const mapStateToProps = (state, ownProps) => {
    const { profileData, callCenter, updatedFromService } = state.userData;
    return {
        profileData: profileData,
        callCenter: callCenter,
        updatedFromService,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchUtility: utilityData => dispatch(fetchProfileAndCart(utilityData)),
        manageOrderLock: ccAction => dispatch(manageOrderLock(ccAction)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UtilityNav);
