import React from 'react';
import {connect} from 'react-redux';
import {handleScriptLoad} from '../../../../page/components/script-loader/script-loader-actions';

class BlogAdSenseScript extends React.Component{

    static ADSENSE_ID = "ca-pub-9379236959924950";
    
    componentDidMount(){
        this.loadScript();
    }

    loadScript(){
        try{

            // load once only
            let currScript = document.querySelector(`head [data-ad-client=${BlogAdSenseScript.ADSENSE_ID}]`);
            if(currScript){
                return;
            }
            let script = document.createElement('script');
            script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
            script.setAttribute('data-ad-client', BlogAdSenseScript.ADSENSE_ID);
            script.async = true;

            script.onload = () => {
                try{
                    this.props.handleScriptLoad('blog-adsense');
                }catch(e){}
            }
            
            document.querySelector('head').appendChild(script);
        }catch(e){}
    }

    render(){
        return null;
    }
}

const mapDispatchToProps = dispatch => ({
    handleScriptLoad: reduxKey => dispatch(handleScriptLoad(reduxKey))
})

export default connect(null, mapDispatchToProps)(BlogAdSenseScript);