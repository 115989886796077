import React from 'react';

export default React.createContext({
    Categories: [], 
    CategoryHeading: '',
    Content: [],
    ContentHeading: '',
    ContentCount: 0,
    Count: 0,
    KeywordField: '',
    Popular: [],
    PopularHeading: '',
    Products: [],
    ProductHeading: '',
    SearchWebsiteUrl: '',
    ViewAllButtonLabel: '',
    noMatchingSearchKey: null,
    prices: {},
    onLinkHover: () => {}
});