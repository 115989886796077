import throttle from 'lodash/throttle';

import { LocalCache } from '../core/src/local-storage-cache';
import {
    UPDATE_COMPARE_PRODUCTS,
    CLEAR_COMPARE_PRODUCTS,
    SET_COMPARE_EXPANDED,
    updateCompareProducts,
    setCompareExpanded,
    getCleanCompareState,
} from '../compare/compare-actions';

/**
 * For now keep everything in the same namespace with a 1 day expiration
 */
const localCache = new LocalCache('presistedReduxState', 1);

const saveState = throttle((key, value) => {
    localCache.set(key, value);
}, 1000);

export const getPersistedState = () => {
    return localCache.data();
};

/**
 * Middleware for tracking state changes and presisting parts of the redux store to local storage
 */
export default function presistStoreMiddleware({ getState }) {
    return next => action => {
        const returnValue = next(action);
        switch (action.type) {
            case CLEAR_COMPARE_PRODUCTS:
            case UPDATE_COMPARE_PRODUCTS:
            case SET_COMPARE_EXPANDED: {
                saveState('compare', getCleanCompareState(getState().compare));
            }
        }

        return returnValue;
    };
}

/***
 *  Add event listener to update redux store based on changes to local storage
 */
export const crossTabSync = store => {
    try {
        window.addEventListener('storage', handleStorageEvent, false);
        function handleStorageEvent(e) {
            try {
                if (e.key && e.key.indexOf('presistedReduxState') === 0) {
                    if (e.oldValue === e.newValue) {
                        return;
                    }
                    const stateChange = JSON.parse(e.newValue);
                    const oldState = JSON.parse(e.oldValue);
                    /**
                     * TODO: for now we are just using this for the compare state, but if more keys are added later
                     * we probably need to identify which to presist across tabs and which ones actually changed
                     */
                    const { compare } = stateChange;
                    const { compare: oldCompareState } = oldState;
                    if (
                        compare.products &&
                        compare.products.length !== ((oldCompareState.products && oldCompareState.products.length) || 0)
                    ) {
                        store.dispatch(updateCompareProducts(compare.products));
                    }
                }
            } catch (e) {}
        }
    } catch (e) {
        //e
    }
};

/**
 * Update redux from persisted state. This is used rather than loading into the initial store
 * because that will cause conflicts between the store used for SSR and client side causing some weirdness
 * @param {*} store
 */
export const dispatchPersistedStore = store => {
    const persistedStore = getPersistedState();
    try {
        const { compare } = persistedStore || {};
        if (compare && compare.products && compare.products.length > 0) {
            //toss it into the event loop and hope that hydration has completed
            setTimeout(() => {
                store.dispatch(updateCompareProducts(compare.products));
                store.dispatch(setCompareExpanded(compare.expanded));
            }, 1000);
        }
    } catch (e) {}
};
