import React from 'react';
import { Plus, ArrowRight, Cross, ShippingChecked } from '@hpstellar/icons';

import { addGtmPropsToProductTile } from '../../metrics/metrics-helpers';
import { Helpers } from '../../core/src/helpers';
import { hasFreeShipping } from './util';

const getProductType = (product, priceObj, toLowerCase) => {
    const { product_type, prdClass } = product || {};
    const productType = product_type || prdClass || (priceObj && priceObj.productType);
    return toLowerCase ? productType.toLowerCase() : productType;
};

export const getButtonIcon = (product, priceObj, attached) => {
    const { isOOS, isComingSoonSku } = priceObj || {};
    const btnAction = getButtonAction(product, priceObj);
    //no action for coming soon skus that are OOS
    if (isComingSoonSku && isOOS) {
        return null;
    }
    return btnAction === 'go' ? <ArrowRight size="s" /> : attached ? <Cross size="xs" /> : <Plus size="s" />;
};

export const getButtonAction = (product, priceObj) => {
    const { eol } = product || {};
    const { customizeText } = priceObj || {};
    const eolUrl = getEOLCTALink(product);
    const productType = getProductType(product, priceObj);
    const btnAction =
        ['cto', 'vgc', 'pgc'].includes(typeof productType === 'string' && productType.toLowerCase()) ||
        customizeText ||
        (eol && eolUrl)
            ? 'go'
            : 'add';
    return btnAction;
};

export const handleUpdateCart = (product, quantity, addToCartHook, callback) => () => {
    const { updateCartBySKU } = addToCartHook;
    updateCartBySKU && updateCartBySKU(product.sku, quantity);
    callback && callback(quantity === 0 ? 'remove' : 'update', product);
};

export const getStockIndicator = (priceObj, options) => {
    const { hideStockIndicator } = options || {};
    const { stockMessage, stockStatus, preOrder, isComingSoonSku, isOOS } = priceObj || {};
    //currently we do not show stock indicator info for preOrder products
    if (preOrder || (isComingSoonSku && isOOS) || hideStockIndicator) {
        return {};
    }
    return {
        stockIndicator: {
            stockMessage,
            color: stockStatus,
        },
    };
};

export const getShippingMessage = (product, priceObj, promos) => {
    const { deliveryDate, translations, backOrderFlag, invAvaDate } = priceObj || {};
    const { specialOffers, offers, shipDate } = product || {};
    const { freeShippingMessage = 'Free Shipping' } = translations || {};
    if (!deliveryDate) {
        return {};
    }
    let offersList = specialOffers || offers || promos || [];
    const isFree = !backOrderFlag && hasFreeShipping(offersList);
    const icon = <ShippingChecked fill={isFree ? '#007959' : undefined} />;
    const deliveryDateText = invAvaDate ? shipDate || `Ships on ${invAvaDate}` : deliveryDate;
    return {
        shippingMessage: {
            title: isFree ? { icon, label: freeShippingMessage } : null,
            subTitle: isFree ? { label: deliveryDateText } : { label: deliveryDateText, icon },
            isFree,
        },
    };
};

export const getCustomPrefix = (priceObj, product, hasPriceSeoText) => {
    if (!hasPriceSeoText) {
        return undefined;
    }
    const { bundle, productType, priceFetchFailed } = priceObj || {};
    const prdClass = product && (product.prdClass || product.product_class);
    const validType =
        (typeof productType === 'string' && !/cto/i.test(productType.trim())) ||
        (priceFetchFailed &&
            typeof prdClass === 'string' &&
            prdClass.length > 0 &&
            !/(cto|bundle)/i.test(prdClass.trim()));

    if (validType && !bundle) {
        return 'Price';
    }
};

export const getPriceBlockDefaultTranslations = hasPriceSeoText =>
    hasPriceSeoText
        ? {
              startingAt: 'Price starting at',
              regularPrice: 'Price',
          }
        : {
              startingAt: 'Starting at',
          };

export const getPriceProps = (product, priceObj, extraProps) => {
    const { size = 'medium', hasPriceSeoText } = extraProps || {};
    const { eol } = product || {};
    const {
        regularPrice,
        salePrice,
        tierMsg,
        locale,
        currency,
        priceDifference,
        ctoLink,
        cpf,
        salePriceSuffix,
        translations,
        bundle,
        customSaleText,
    } = priceObj || {};
    const isGiftCard = product && Helpers.isGiftCard(product);
    const productType = getProductType(product, priceObj);

    return salePrice && !eol
        ? {
              regularPrice,
              salePrice,
              locale,
              currency,
              tierMsg,
              priceDifference,
              ctoLink,
              productType: isGiftCard ? 'cto' : productType && productType.toLowerCase(),
              showInCartText: cpf,
              size,
              translations: {
                  salePriceSuffix,
                  ...getPriceBlockDefaultTranslations(hasPriceSeoText),
                  ...(translations || {}),
              },
              bundle,
              customSaleText,
              customPrefix: getCustomPrefix(priceObj, product, hasPriceSeoText),
          }
        : undefined;
};

export const getPunchOutProps = priceObj => {
    const { gmPoints, stock, isccf, preOrder } = priceObj || {};
    if (!isccf) {
        return null;
    }
    return {
        punchOutProps: {
            grossMargin: gmPoints,
            availableInStock: typeof stock === 'number' ? stock.toString() : stock,
            maxQtyLimitPerPreOrder: preOrder ? priceObj.preordermaxqty : null,
        },
    };
};

export const getPurchaseButtonProps = (product, priceObj, hooks, analyticsData, trackCustomMetric, options) => {
    const { sku, eol } = product;
    const { isOOS, isccf, priceFetchFailed, hideAddToCart, isComingSoonSku, salePrice, regularPrice } = priceObj || {};
    const { attached, quantity, toUpperCase, oldGtmId } = options || {};
    const { addToCart } = hooks || {};
    const { addedToCart } = addToCart || {};
    const productType = getProductType(product, priceObj);
    const isAddToQuote = shouldUseAddToQuote(isccf, isOOS, productType, product && product.eol);
    const eolUrl = getEOLCTALink(product);
    const isEol = eol && eolUrl;
    const isCto = /cto/i.test(productType || '');

    let gtmActions;
    if (analyticsData) {
        let { list, gtmId, position, prices, hsResID } = analyticsData;
        gtmActions = addGtmPropsToProductTile(
            {
                ...product,
                customGtmId: `${list}-${sku}`,
                sku,
                price: prices || priceObj,
                position,
                hsResID,
                quantity,
                ...(oldGtmId && { gtmUniqueId: oldGtmId, oldGtmId }),
            },
            analyticsData,
            { [sku]: priceObj },
        );
    }

    let gtmAction = gtmActions && typeof gtmActions.get === 'function' && gtmActions.get('addToCart');
    //if you want to hide the CTA till price call returns add another check here
    if (priceFetchFailed || hideAddToCart || (!salePrice && !regularPrice && !isEol && !isCto)) {
        return {};
    }
    const addToCartFun = handleAddToCart(
        product,
        addToCart,
        priceObj,
        null,
        isAddToQuote,
        { ...(analyticsData || {}), baseProductSku: sku },
        trackCustomMetric,
    );
    const onClick = e => {
        addToCartFun(e, quantity);
    };
    const disabled = isOOS && !isAddToQuote && !isEol && !isComingSoonSku;

    return {
        purchaseBtnProps: {
            onClick: addToCart && !hideAddToCart && !priceFetchFailed && onClick,
            ...(disabled && { disabled }),
            endIcon: getButtonIcon(product, priceObj, attached),
            gtmAction,
            children: getCTAText(product, priceObj, toUpperCase),
            success: addedToCart && addedToCart[product.sku],
        },
    };
};

export const getCTAText = (product, priceObj, toUpperCase) => {
    const { ctaAddToCart, eol, suppliesLinkUrl, ctaEolUrl } = product || {};
    const { ctaText, customizeText, isccf, isOOS, priceFetchFailed, hideAddToCart } = priceObj || {};
    const eolUrl = suppliesLinkUrl || ctaEolUrl;
    const productType = getProductType(product, priceObj);
    if (priceFetchFailed || hideAddToCart) {
        return null;
    }
    if (eol && eolUrl) return 'View supplies';

    if (shouldUseAddToQuote(isccf, isOOS, productType, eol)) return 'Add to quote';

    const liveCtaText = (priceObj || !(priceObj || {}).priceFetchFailed) && (customizeText ? customizeText : ctaText);
    const ctaTextValue = liveCtaText || ctaAddToCart;
    return toUpperCase ? ctaTextValue?.toUpperCase() : ctaTextValue;
};

export const handleAddToCart =
    (product, addToCartHook, price, callback, isAddToQuote, analyticsData, trackCustomMetric) =>
    (event, qty = 1) => {
        const { productUrl, eol, eligibleSKU, removeExistingAssociation, Devdependency } = product;
        const { isOOS, isComingSoonSku, deviceDependency } = price;
        const hasDeviceDependency = (deviceDependency || Devdependency) !== 'NO';
        const { addToCart, goToCTOPage, launchCartFlyout, confirmAddToCart, goToDestination } = addToCartHook;
        const { list, baseProductSku, xsellType } = analyticsData || {};
        const productType = getProductType(product, price);
        const eolUrl = getEOLCTALink(product);
        const isCto = /cto/i.test(productType || '');
        //normal CTA with no action is expected for a coming soon sku
        if (isComingSoonSku && isOOS) {
            return;
        }
        if (price.preOrder && confirmAddToCart) {
            confirmAddToCart('preorder', [
                product,
                qty,
                undefined,
                undefined,
                undefined,
                eligibleSKU,
                xsellType,
                { list },
            ]);
        } else if (Helpers.isECarepack(product) && confirmAddToCart && hasDeviceDependency) {
            confirmAddToCart('ecarepack', [
                product,
                qty,
                undefined,
                undefined,
                undefined,
                eligibleSKU,
                xsellType,
                { list },
            ]);
        } else if (eol && eolUrl) {
            goToDestination(eolUrl);
        } else if (isCto) {
            let { list, ctoGtmId } = analyticsData || {};
            let linkPlacement = list || ctoGtmId;
            typeof trackCustomMetric === 'function' &&
                linkPlacement &&
                trackCustomMetric('linkClick', {
                    event: 'e_linkClick',
                    linkPlacement,
                    linkId: 'cto',
                });
            goToCTOPage(product, 1);
        } else if (['VGC', 'PGC'].includes(productType)) {
            goToDestination(Helpers.getRelativePath(productUrl));
        } else if (!isOOS || isAddToQuote) {
            addToCart(product, qty, undefined, undefined, { removeExistingAssociation }, eligibleSKU, xsellType, {
                list,
            }).then(() => {
                launchCartFlyout &&
                    launchCartFlyout({
                        analyticsData: {
                            baseProductSku,
                            xsellType,
                            list,
                        },
                    });
            });
        }
        callback && callback('add', product);
    };

export const getEOLCTALink = product => {
    const { eol, suppliesLinkUrl, ctaEolUrl } = product;
    if (!eol) {
        return undefined;
    }
    return suppliesLinkUrl || ctaEolUrl;
};

export const shouldUseAddToQuote = (isccf, isOOS, productType, eol) => isccf && isOOS && productType !== 'CTO' && !eol;
