import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import productData from './product/product-reducer';
import xSell from './xsell/xsell-reducer';
import compare from './compare/compare-reducer';
import userData, { StoreDataReducer } from './utility/utility-reducer';
import CartReducer from './utility/cart-reducer';
import AddToCartConfirmReducer from './utility/components/add-to-cart-confirm/add-to-cart-confirm-reducer';
import leftMenu from './left-menu/left-menu-reducer';
import slugInfoReducer from './page/slug-info-reducer';
import redirectsReducer from './page/redirect-reducer';
import siteConfigReducer from './page/site-config-reducer';
import scriptLoaderReducer from './page/components/script-loader/script-loader-reducer';
import headerFooterReducer from './page/components/header-footer/header-footer-reducer';
import CacheReducer from './caching/cache-reducer';
import pdp from './pdp/pdp-reducer';
import { DealReducer } from './product/components/deals';
import LoaderReducer from './page/components/full-page-loader/full-page-loader-reducer';
import BlogListReducer from './blog/blog-list-reducer';
import SearchFilterReducer from './search-filter/search-filter-reducer';
import ReorderReducer from './reorder/reorder-reducer';
import UIReducer from './ui/ui-reducer';
import LoadLazyComponentReducer from './shared/components/above-the-fold-only-server-render/lazy-load-reducer.js';
import { disclaimerReducer } from './page/components/disclaimer-tabs/disclaimer-store';
import MetricsReducer from './metrics/metrics-reducer';
import SuppliesSearchReducer from './supplies-finder/suppliesSearchReducer';
import TestFlagReducer from './abtest/test-flag-reducer';

export default history =>
    combineReducers({
        userData,
        leftMenu,
        compare,
        cartInfo: CartReducer,
        productData,
        xSell,
        addToCartConfirmation: AddToCartConfirmReducer,
        slugInfo: slugInfoReducer,
        redirects: redirectsReducer,
        pdp,
        blogListInfo: BlogListReducer,
        router: connectRouter(history),
        scriptLoader: scriptLoaderReducer,
        itemCache: CacheReducer,
        deals: DealReducer,
        pageLoader: LoaderReducer,
        searchFilter: SearchFilterReducer,
        reorder: ReorderReducer,
        ui: UIReducer,
        lazyLoadComponent: LoadLazyComponentReducer,
        headerFooter: headerFooterReducer,
        /** pass through reducers from server */
        testFlags: TestFlagReducer,
        routes: (state = [], action) => state,
        storeEnvironment: (state = process.env.NODE_ENV, action) => state,
        etrStoreData: StoreDataReducer,
        proxyHost: (state = '', action) => state,
        storeDomain: (state = '', action) => state,
        thirdPartyTags: (state = {}, action) => state,
        searchEndPoints: (state = {}, action) => state,
        siteConfig: siteConfigReducer,
        disclaimer: disclaimerReducer,
        metrics: MetricsReducer, // must always run after routes reducer
        suppliesSearch: SuppliesSearchReducer,
    });
