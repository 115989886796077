import React from 'react';

import withPageComponents from '../with-page-components';
import Disclaimer from '../disclaimer-container';

export default withPageComponents(
    ({ seo, blogListInfo, vanityUrl, templateKey, useSeoV2 = true }) => {
        const isHomepage = templateKey === 'home' || vanityUrl === '/';
        const { wordCloud, brightEdgeLinks, hideCopyBlock } = seo || {};
        //tech takes listing page still doesnt use page components. So we need to pass the wordCloud and brightEdgeLinks to the disclaimer component
        const { brightEdgeLinks: blogBrightEdgeLinks } = blogListInfo || {};
        return (
            <div className={`footer-links-container${isHomepage ? ' with-chat' : ''}`}>
                <Disclaimer
                    hideSeoParagraph={hideCopyBlock || false}
                    wordCloud={wordCloud}
                    brightEdgeLinks={blogBrightEdgeLinks?.pageHash ? blogBrightEdgeLinks : brightEdgeLinks}
                    isHomepage={isHomepage}
                    useSeoV2={useSeoV2}
                />
            </div>
        );
    },
    {
        seo: true,
        stateComponents: ['blogListInfo'],
    },
);
