import merge from 'lodash/merge';

import { RECEIVE_PRODUCT_INITIALS } from './etr-product-actions';
	
const ProductInitialReducer = (state = {}, action) => {
	Object.freeze(state);
	switch(action.type) {
		case RECEIVE_PRODUCT_INITIALS:
			return merge({}, state, action.productInitials);
		default:
			return state;
	}
};

export default ProductInitialReducer;