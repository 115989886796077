import { TOGGLE_COMPARE_MODAL } from './compare-actions';

const CompareModalReducer = (state = false, action) => {
	Object.freeze(state);
	switch(action.type) {
		case TOGGLE_COMPARE_MODAL:
			return action.toggle;
		default:
			return state;
	}
};

export default CompareModalReducer;