import React from 'react';

import SearchCustomContext from '../../search-filter/components/search-custom-context';
import { Helpers } from '../../core/src/helpers';

const { memoize } = Helpers;

export const findCat = memoize((catId, categories=[], customAggregations) => {
    const { category: aggCategory } = customAggregations || {};
    catId = catId || (aggCategory && aggCategory._id);
    if(!catId || !aggCategory) return categories[0];
    let category = categories.find(category => catId == category._id || catId === category.key) 
                    || categories[0];
    if(aggCategory){
        category = Object.assign(category, aggCategory);
    }
    return category;
},(catId, categories=[],customAggregations) => {
    return `${catId}-${categories.length}${customAggregations && customAggregations.category && customAggregations.category._id}`;
})

export class SelectedCategory extends React.PureComponent {
    render() {
        const { useAlias, children } = this.props;
        return (
            <SearchCustomContext>
            {
                ({ customContext, query, updateFilterUrl, customAggregations, seralizedQuery }) => {
                    const { categories=[] } = customContext;
                    const catId = query.cat;
                    const selectedCategory  = findCat(!useAlias && catId, categories, customAggregations);
                    return children({ selectedCategory, updateFilterUrl, seralizedQuery });
                }
            }
            </SearchCustomContext>
        );
    }
}