import React from 'react';

import './css/icon.less';
import PropTypes from 'prop-types';

function Icon({ icon }) {
    const iconClass = icon || 'icon-search';
    return <i className={`icon ${iconClass}`} />;
}

Icon.propTypes = {
    /** icon string, must match icon.less */
    icon: PropTypes.string.isRequired,
};

export default Icon;
