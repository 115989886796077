import React, { useState } from 'react';
import Button from '@hpstellar/core/Button';
import Typography from '@hpstellar/core/visId/Typography';
import './soft-sign-in.less';
import HandleClickOutside from './clickEventHandler';

/**
 * @param {Object} props
 * @param {Object} props.username    username
 * @param {Object} props.message    soft sign in message
 * @param {Object} props.ctaProps    CTA object
 * @param {Object} props.ctaProps.label   CTA Label
 * @param {Object} props.ctaProps.link    CTA link
 *
 */

const DEFAULT_MESSAGE = 'Please sign in to access your account or to sign in with a different email';
const DEFAULT_CTA = { label: 'Sign in' };

export default function SoftSignIn({ signInData, onClose }) {
    if (!signInData) {
        return null;
    }

    let { useremail, message = DEFAULT_MESSAGE, ctaProps } = signInData;
    let { label = DEFAULT_CTA.label, link } = ctaProps;

    return (
        <>
            <HandleClickOutside callback={onClose}>
                <div className="soft-sign-in">
                    <div className="ssi-content-wrapper">
                        <div className="soft-sign-in-content">
                            <Typography responsive tag="div" variant="subtitle" weight={400} className="dialog-email">
                                {useremail}
                            </Typography>
                            <Typography responsive tag="div" variant="body1" weight={400} className="message">
                                {message}
                            </Typography>
                            <Button className="ssi-cta" to={link} isFullWidth>
                                {label}
                            </Button>
                        </div>
                    </div>
                </div>
            </HandleClickOutside>
            <div className="soft-sign-in-backdrop"></div>
        </>
    );
}
