import * as SwiftypeAppSearch from './by-swiftype';

export const SwiftypeAPI = function() {
    let self = this;

    const client = SwiftypeAppSearch.createClient({
        hostIdentifier: 'host-ez1ofx',/*process.env.REACT_APP_HOST_IDENTIFIER,*/
        apiKey: 'private-ru4nuohexx7svhdce6dd1xkr',/*process.env.REACT_APP_SEARCH_KEY*/
        engineName: 'hp-store-products',
    });

    self.getProducts = (queryString, options) => client.search(queryString, options).catch(e => console.log('err:', e))
}
