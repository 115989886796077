import React from 'react';
import loadable from '@loadable/component';

import { Helpers } from '../core/src/helpers';
import { loadGraphQLData } from '../page';

const VWAContainer = loadable(() => Helpers.retryFunc(() => import('./vwa-container')));

const VWA = props => {
    return <VWAContainer {...props} />;
};

export default {
    component: VWA,
    loadData: loadGraphQLData,
};
