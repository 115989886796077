import React from 'react';
import loadable from '@loadable/component';

import { withError } from '../../shared/components/error-boundary';
import { Helpers } from '../../core/src/helpers';

const SalePage = loadable(() => Helpers.retryFunc(() => import('../../sale-page')));
const SalePageTemplate = loadable(() =>
    Helpers.retryFunc(() => import('../../sale-page/components/sale-page-template')),
);

const Main = React.memo(props => (
    <SalePageTemplate {...props}>
        {salePageTemplateProps => {
            return <SalePage {...salePageTemplateProps} />;
        }}
    </SalePageTemplate>
));

Main.whyDidYouRender = true;

export default withError(Main);
