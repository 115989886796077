import React, { useState, useCallback } from 'react';
import loadable from '@loadable/component';

import { Helpers } from '../../../core/src/helpers';
import withPageComponents from '../../../page/components/with-page-components';
import useUserData, { getPStoreID, isCallCenter } from '../../../hooks/useUserData';
import { useThirdPartyTags } from '../../../hooks/useSiteConfig';

const SubscribeForm = loadable(() => Helpers.retryFunc(() => import('../../../blog/components/blog-email-form')));

const COOKIE_NAME = 'newsletter';

export default withPageComponents(({ templateKey }) => {
    const [showForm, setShowForm] = useState(true);
    const { userData } = useUserData();
    const { updatedFromService } = userData;
    const { newsletter: newsletterSettings } = useThirdPartyTags(['newsletter']);
    const { templateTarget = ['home'] } = newsletterSettings || {};
    const onClose = useCallback(() => {
        Helpers.setCookie(COOKIE_NAME, 'true', 30);
        setShowForm(false);
    }, []);

    if (
        process.env.ISNODE ||
        !updatedFromService ||
        !showForm ||
        Helpers.getCookie(COOKIE_NAME) ||
        //disable for pStore and call center
        getPStoreID(userData) ||
        isCallCenter(userData) ||
        !templateTarget.includes(templateKey)
    )
        return null;

    return <SubscribeForm key="newsletter" isOpen={showForm} handleClose={onClose} withBackdrop={true} />;
});
