import React from 'react';
import {Modal,SkeletonLoader} from '@hpstellar/core';
import './add-to-cart-confirm-skeleton.less';

export default ({ isOpen }) => {
    return (
        <Modal
            isOpen={isOpen}
            modalClassName="add-to-cart-confirm-skeleton"
            animationType={'slide-in-top'}
            withBackdrop={true}
            horizontalAlignment="center"
            verticalAlignment="center"
        >
            <div className={`modal-content-skeleton`}>
                <SkeletonLoader style={{ width: '50%', margin: '4px 0' }} />
                <SkeletonLoader />
                <SkeletonLoader variant="rect" height={300} style={{ margin: '4px 0' }} />
            </div>
        </Modal>
    );
};
