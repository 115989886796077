import merge from 'lodash/merge';

import { RECEIVE_PRODUCTS } from './product-actions';
	
const ProductListReducer = (state = {}, action) => {
	Object.freeze(state);
	switch(action.type) {
		case RECEIVE_PRODUCTS:
			return merge({}, state, action.productObj);
		default:
			return state;
	}
};

export default ProductListReducer;