import StoreAppEvents from './vendors/storeapp';
import BOOMR from './vendors/boomerang';
import Ensighten from './vendors/ensighten';
import MoxieConcierge from './vendors/moxie-concierge';
import UDL_API from './vendors/udl';
import DynamicYield from './vendors/dynamic-yield';

const config = {
    vendors: [
        {
            name: 'UDL',
            api: new UDL_API(),
        },
        {
            name: 'Ensighten',
            api: new Ensighten(),
        },
        {
            name: 'Boomerange',
            api: new BOOMR(),
        },
        {
            name: 'MoxieConcierge',
            api: new MoxieConcierge(),
        },
        {
            name: 'StoreAppEvents',
            api: new StoreAppEvents(),
        },
        {
            name: 'DynamicYield',
            api: new DynamicYield(),
        },
    ],
    pageViewEvent: 'pageLoad',
    debug: false,
};

const devConfig = {
    vendors: [
        {
            name: 'StoreAppEvents',
            api: new StoreAppEvents(),
        },
    ],
    debug: true,
};

let isSelenium, isLocal, isAppOrigin;
try {
    isSelenium = window.location.search.indexOf('selenium=true') > -1;
    // TODO: we can probably ask Michael's team to ignore tracking from localhost,
    // this way we can still QA tracking on local without worrying about feeding to Adobe/GA
    isLocal =
        window.location.hostname.indexOf('localhost') > -1 && window.location.search.indexOf('metricsEnabled=true') < 0;
    //Do not fire analytics call from app origin.
    isAppOrigin = window.location.hostname.indexOf('hpstoreapp') > -1;
} catch (e) {
    isSelenium = false;
    isLocal = false;
}
const disableMetrics = isSelenium || isLocal || isAppOrigin;

export default disableMetrics ? devConfig : config;
