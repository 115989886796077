import { LOAD_LAZY_COMPONENT } from './lazy-load-actions';
import { LOCATION_CHANGE } from 'connected-react-router';

const NULL_STATE = {};
const DealReducer = (state = NULL_STATE, action) => {
    Object.freeze(state);
    switch (action.type) {
        case LOAD_LAZY_COMPONENT:
            if (state[action.componentKey]) {
                return state;
            }
            return Object.assign({}, { [action.componentKey]: true });
        case LOCATION_CHANGE:
            return NULL_STATE;
        default:
            return state;
    }
};

export default DealReducer;
