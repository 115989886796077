import { RECEIVE_REORDER_INFO, RECEIVE_REORDER_ERROR, CLEAR_REORDER_INFO } from './reorder-actions';

const NULL_STATE = { related: {}, primary: undefined };
const ReorderReducer = (state = NULL_STATE, action) => {
    Object.freeze(state);
	switch(action.type) {
		case RECEIVE_REORDER_INFO:
            return action.reorderInfo || NULL_STATE;
		case RECEIVE_REORDER_ERROR:
			return { ...state, error: "reorder products are not found" };
        case CLEAR_REORDER_INFO:
            return {};
		default:
			return state;
	}
}

export default ReorderReducer;
