import React from 'react';
import { Typography, Tooltip } from '@hpstellar/core';

import './sustainability-logo.less';

//TODO: should just create a proxy on local to handle relative images
const getUrl = size => {
    const domain =
        process.env.NODE_ENV === 'local' ||
        (typeof document !== 'undefined' && document?.location?.host.indexOf('localhost') > -1)
            ? 'https://www.hp.com'
            : '';
    const path =
        size === 'large'
            ? '/us-en/shop/app/static/images/sustainability-lrg.png'
            : '/us-en/shop/app/static/images/sustainability.png';
    return `${domain}${path}`;
};

export default ({
    sustainabilityAttribute,
    size = 'large',
    text = 'Engineered for Sustainability',
    style,
    enableTooltip = false,
    toolTipProps = {},
}) => {
    if (!sustainabilityAttribute || sustainabilityAttribute.toLowerCase() === 'none') {
        return null;
    }
    const imageUrl = getUrl(size);
    return (
        <div className={`sustainability-badge ${size}`} style={style}>
            <img src={imageUrl} />
            <Typography variant={size === 'large' ? 'bodyS' : 'bodyXS'} tag="span" responsive>
                {text}
            </Typography>
            {enableTooltip && (
                <Tooltip
                    className="sustainability-tooltip-button"
                    tooltipClassName="sustainability-tooltip"
                    description={sustainabilityAttribute}
                    position="bottom-start"
                    title={text}
                    {...toolTipProps}
                />
            )}
        </div>
    );
};
