export default {
    productTab: ({ component }) => {
        if(!component) return component;
        component.tabGtmActions = new Map([
            [
                'tabClick',
                {
                    gtmCategory: 'linkClick',
                    gtmId: 'product-type-bar'
                }
            ]
        ]);

        return component;
    }
}