import { Helpers } from '../helpers';

export const getCTOLink = (itemId, quantity = 1, additionalParams) => {
    //pick valid additional parameters
    const { color, configCatentryId } = additionalParams || {};
    let basename = process.env.BASENAME || '/us/en';
    let params = {
        langId: -1,
        storeId: 10151,
        catalogId: 10051,
        catEntryId: itemId,
        urlLangId: '',
        quantity,
        color,
        configCatentryId,
    };

    let paramsString = Helpers.paramsToString(params, false);
    const ctoLink = `${basename}/ConfigureView?${paramsString}`;
    return ctoLink;
};

class CTOAPI {
    constructor(apiConfig, http) {
        this.apiConfig = apiConfig;
        this.http = http;
    }

    addPreConfig(catEntryId) {
        return this.http
            .post('HPCTORestAddToCartCmd', Helpers.parseFormData({ catentryId: catEntryId }), {
                includePrivateStoreID: true,
            })
            .then(({ data }) => {
                //attemp to parse response
                if (data && data !== '') {
                    return JSON.parse(data.replace(/(\/\*|\*\/)/gi, ''));
                } else {
                    return Promise.reject('');
                }
            });
    }

    geLeadTimeOverride() {
        return this.http.post('').then(({ data }) => {});
    }
}

export default CTOAPI;
