import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';

import { trackPageView } from '../page/page-actions';
import { receivePageViewUrl } from '../metrics/track-params';
import { templateAnalytics, resetImpressions } from '../metrics/metrics-helpers';
import useSiteConfig from '../hooks/useSiteConfig';
import { useUserReceived } from './useUserData'

const impressionsAreReady = (
    pageViewDependentImpressions,
    asyncImpressionComponents,
    metrics,
    errors,
    enableProductImpressions,
    enablePromotionImpressions,
) => {
    try {
        let impressionsToEvaluate = pageViewDependentImpressions || asyncImpressionComponents;

        /**
         * if it's required, always wait for it even if impression is turned off
         * if it's not required and impression is turned off, don't wait for it.
         */
        return impressionsToEvaluate.every(impressionData => {
            let { name, type, required } = impressionData || {};

            if (
                !required &&
                ((type === 'promotion' && !enablePromotionImpressions) ||
                    (type === 'product' && !enableProductImpressions))
            ) {
                return true;
            }
            return (
                !!metrics[name] ||
                (errors && errors.length > 0 && errors.find(err => err && err.componentKey === impressionKey))
            );
        });
    } catch (e) {}
    return false;
};

// TODO: Find a better way to do this.
const getTemplateSpecificStateDependenciesForAnalytics = (state, templateKey, vanityUrl) => {
    if (templateKey === 'dlp' && state) {
        return {
            searchFilter: state.searchFilter,
            productData: state.productData,
        };
    }
    if (vanityUrl === 'sitesearch' && state) {
        return {
            searchResults: state.slugInfo && state.slugInfo.components && state.slugInfo.components.search,
        };
    }
};

const hasMissingPageViewParameters = (vanityUrl, customMetrics) => {
    try{
        return /^sitesearch/i.test(vanityUrl) && (!customMetrics || !customMetrics.searchResults);
    }catch(e){}

    return false;
};

export const usePageViewTriggered = () => {
    const userReceived = useUserReceived();
    const pageViewTriggered = useSelector(state => {
        try{
            return !!state.slugInfo.vanityUrl && state.slugInfo.vanityUrl === state.metrics.pageView.vanityUrl;
        }catch(e){}
        return false;
    });
    return userReceived && pageViewTriggered;
}

const isValidVanityUrl = vanityUrl => typeof vanityUrl === 'string' && !/^configure\//i.test(vanityUrl)

let trackedVanityUrl;
const setTrackedVanityUrl = vanityUrl => {
    if(typeof vanityUrl === 'string'){
        trackedVanityUrl = vanityUrl;
    }
}
const vanityUrlIsNotTracked = vanityUrl => typeof vanityUrl === 'string' && vanityUrl !== trackedVanityUrl

export default ({ slugInfo, hasBatchedImpressions }) => {
    const { analyticsData, vanityUrl, errors, templateKey, gqlClientLoad } = slugInfo || {};
    let dispatch = useDispatch();
    let { asyncImpressionComponents, pageViewDependentImpressions, immediatePageView } =
        (analyticsData && analyticsData.derivedAnalyticsData) || {};
    let customMetrics = useSelector(state => state && state.metrics && state.metrics.customMetrics);
    let impressionMetrics = useSelector(
        state => state && state.metrics && state.metrics.impressions && state.metrics.impressions[vanityUrl],
    );
    let pageViewUrl = useSelector(
        state => state && state.metrics && state.metrics.pageView && state.metrics.pageView.vanityUrl,
    );
    const siteConfig = useSiteConfig();
    const { pageViewTimeLimit = 9, enableProductImpressions, enablePromotionImpressions } = siteConfig || {};
    let templateAnalyticsDependencies = useSelector(state =>
        getTemplateSpecificStateDependenciesForAnalytics(state, templateKey, vanityUrl),
    );
    let impressionsAvailable = impressionsAreReady(
        pageViewDependentImpressions,
        asyncImpressionComponents,
        impressionMetrics,
        errors,
        enableProductImpressions,
        enablePromotionImpressions,
    );

    let derivedAnalyticsData = {};
    let { analyticsMapping = templateKey } = (analyticsData && analyticsData.derivedAnalyticsData) || {}; // use templateKey for storeapp sourced pages
    if (analyticsMapping && templateAnalytics[analyticsMapping] && templateAnalytics[analyticsMapping].pageView) {
        derivedAnalyticsData = templateAnalytics[analyticsMapping].pageView({
            slugInfo,
            ...(templateAnalyticsDependencies || {}),
        });
    }

    const derivedSlugInfo = {
        ...(slugInfo || {}),
        analyticsData: {
            ...((slugInfo && slugInfo.analyticsData) || {}),
            derivedAnalyticsData: {
                ...((slugInfo && slugInfo.analyticsData && slugInfo.analyticsData.derivedAnalyticsData) || {}),
                ...(derivedAnalyticsData || {}),
            },
        },
    };

    const triggerPageView = useCallback((slugInfo, vanityUrl) => {
        dispatch(trackPageView(slugInfo));
        dispatch(receivePageViewUrl(vanityUrl));
    }, []);

    const debouncedPageView = debounce(triggerPageView, 800);
    const vanityUrlIsValid = isValidVanityUrl(vanityUrl);

    // fallback if the async components take too long, just trigger the page view
    useEffect(() => {
        let timedVanityUrl = vanityUrl;
        let timedPageViewFallback = setTimeout(() => {
            // if components take too long, just trigger page view and trigger the rest as separate impressions
            if (
                pageViewTimeLimit > 0 &&
                timedVanityUrl &&
                timedVanityUrl === vanityUrl &&
                (!pageViewUrl || pageViewUrl !== timedVanityUrl) && 
                isValidVanityUrl(timedVanityUrl) &&
                vanityUrlIsNotTracked(vanityUrl)
            ) {
                setTrackedVanityUrl(vanityUrl);
                triggerPageView(derivedSlugInfo, vanityUrl);
            }
        }, pageViewTimeLimit * 1000);

        // if vanityUrl changes, remove the timer
        return () => {
            try {
                clearTimeout(timedPageViewFallback);
            } catch (e) {}
        };
    }, [vanityUrl, pageViewTimeLimit]);

    // reset impressions when there is a page change
    useEffect(() => {
        resetImpressions();
    }, [vanityUrl])

    // debounced product impressions and page view if lazy loading impressions
    useEffect(() => {
        let hasNotTriggeredPageView = !pageViewUrl || pageViewUrl !== vanityUrl;
        if (hasBatchedImpressions && hasNotTriggeredPageView && vanityUrlIsValid && impressionMetrics && impressionMetrics.length > 0 && vanityUrl) {
            setTrackedVanityUrl(vanityUrl);
            debouncedPageView(derivedSlugInfo, vanityUrl);
        }
    }, [hasBatchedImpressions, impressionMetrics && impressionMetrics.length, pageViewUrl, vanityUrl]);

    let missingPageViewParameters = hasMissingPageViewParameters(vanityUrl, customMetrics);
    useEffect(() => {
        if (
            !gqlClientLoad &&
            !hasBatchedImpressions &&
            vanityUrlIsValid &&
            vanityUrl &&
            ((!missingPageViewParameters && impressionsAvailable) || !asyncImpressionComponents || immediatePageView) &&
            vanityUrlIsNotTracked(vanityUrl) && 
            isValidVanityUrl(vanityUrl)
        ) {
            setTrackedVanityUrl(vanityUrl);
            triggerPageView(derivedSlugInfo, vanityUrl);
        }
    }, [vanityUrl, impressionsAvailable, hasBatchedImpressions, missingPageViewParameters, gqlClientLoad]);

    return derivedSlugInfo;
};
