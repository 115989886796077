const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';

export const setActiveTab = tab => ({
    type: SET_ACTIVE_TAB,
    tab,
});

export const disclaimerReducer = (state = null, { type, tab }) => {
    switch (type) {
        case SET_ACTIVE_TAB:
            return tab;
        default:
            return state;
    }
};
