import React from 'react';
import { Breadcrumbs } from '@hpstellar/core';

import useDeviceInfo from '../../hooks/useDeviceInfo';
import useUserData, { getPStoreID } from '../../hooks/useUserData';

/***
 * GUX Breacrumbs component
 */
export default ({ breadcrumbs, disableMobileBreadcrumb, ...rest }) => {
    const { device } = useDeviceInfo(['device']);
    const { userData } = useUserData();
    const pStoreID = getPStoreID(userData);
    const gtmActions = new Map([
        [
            'linkClick',
            {
                gtmId: 'breadcrumb',
                gtmCategory: 'linkClick',
            },
        ],
    ]);
    if ((device === 'mobile' && disableMobileBreadcrumb) || !breadcrumbs || breadcrumbs.length < 1) {
        return null;
    }
    //Always show home link if breadcrumb depth is greater than 2
    const alwaysShowFirstItem = breadcrumbs.length > 2;

    const parsedBc = pStoreID
        ? breadcrumbs.map(bc => {
              const { href } = bc || {};
              let newHref = href;
              if (typeof href === 'string' && !/pstoreid=.+/i.test(href)) {
                  let [beforeHash, afterHash] = href.split('#');
                  let [beforeQueryStr, afterQueryStr] = beforeHash.split('?');
                  newHref = `${beforeQueryStr}?${
                      afterQueryStr && afterQueryStr.length
                          ? `${afterQueryStr}&pStoreID=${pStoreID}`
                          : `pStoreID=${pStoreID}`
                  }${afterHash ? '#' + afterHash : ''}`;
              }

              return {
                  ...(bc || {}),
                  href: newHref,
              };
          })
        : breadcrumbs;

    return (
        <Breadcrumbs
            alwaysShowFirstItem={alwaysShowFirstItem}
            gtmActions={gtmActions}
            breadcrumbs={parsedBc}
            {...rest}
        />
    );
};
