import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { showPopover } from './header-footer-actions';

/** Shadow overlay on page, used in combination with popover */
function Overlay({ show, closeOverlay, overlayShade, offset }) {
    const display = show ? 'block' : 'none';
    const top = offset ? `${offset}px` : '0';
    return (
        <div
            role={show ? 'presentation' : undefined}
            className={`overlay${overlayShade ? ' overlay-shade' : ''}`}
            onClick={closeOverlay}
            style={{ display, top }}
        />
    );
}

Overlay.defaultProps = {
    overlayShade: false,
};

Overlay.propTypes = {
    /** flag to show activate overlay */
    show: PropTypes.bool.isRequired,
    /** onclick event to toggle show prop on outside state */
    closeOverlay: PropTypes.func.isRequired,
    /** adds overlay-shade class, modify shade intensity in css */
    overlayShade: PropTypes.bool,
};

const mapProps = state => {
    const { popover, overlayShade } = state.headerFooter;

    const skip = [
        'cartPreviewClassic',
        'cartPreviewNew',
        // 'headerSearch',
        null,
        undefined,
        'undefined',
    ];
    const showCart = !skip.includes(popover);

    // TODO: clean up by setting popover default on server store state
    return {
        show: showCart,
        overlayShade,
    };
};

const mapActions = dispatch => ({
    closeOverlay: () => dispatch(showPopover(null)),
});

export default connect(mapProps, mapActions)(Overlay);
