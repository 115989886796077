import { combineReducers } from 'redux';

import productList from './product-list-reducer';
import productPrices from './price-reducer';
import modelPrices from './components/product-price/model-reducer';
import sortReducer from './sort-reducer';
import productInitials from './product-initial-reducer';
import productSettings from './product-settings-reducer';
import productSubscriptionsPromo, { productPromo } from './promo-reducer';

const preSortData = combineReducers({
    productList,
    productPrices,
    modelPrices,
    productInitials,
    productSettings,
    productSubscriptionsPromo,
    productPromo,
});

export const NULL_STATE = {
    productList: {},
    productPrices: {},
    modelPrices: {},
    productInitials: {},
    productSettings: null,
    productSubscriptionsPromo: {},
    productPromo: {},
};

const ProductInfoReducer = (state = NULL_STATE, action) => {
    const intermediateState = preSortData(state, action);
    const finalState = sortReducer(intermediateState, action);
    return finalState;
};

export default ProductInfoReducer;
