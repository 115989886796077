import { STORE_TRACK_PREFIX } from '../track-params';
import { trackAllProductImpressions, trackAllPromotionImpressions, trackImpressionKeys } from '../metrics-helpers';
import { Helpers } from '../../core';
import {
    _productClick,
    _productImpression,
    _promotionImpression,
    _promotionClick,
    _compare,
    _configuratorChange,
    _configuratorStart,
    _configuratorComplete,
    _addToCart,
    _readReview,
    triggerPostPageViewActions,
    _searchAutoClick,
    _searchResults,
    _productReview,
    _chat,
    _videoLoad,
    _miniCartQuantityChange,
} from '../track-params';

/**
 * Performs the tracking calls to UDL (Universal Data Layer).
 * @module UDLAPI
 * @class
 * @internal
 */
class UDLAPI {
    constructor(options = {}) {
        this.options = options;
        this.name = 'UDL tag';
        this.optOut = false;
        this.initialTrack = true;
        try{
            window.pagePropertiesData = {};
        }catch(e){}
    }

    /**
     *
     * @method pageView
     * @param {String} eventName
     * @param {Object} params
     * @returns {Promise}
     * @internal
     */

    pageView(...args) {
        return this.track(...args);
    }

    /**
     *
     * @method track
     * @param {String} eventName
     * @param {Object} params
     * @param {Object} state
     * @returns {Promise}
     * @internal
     */

    track(eventName, params) {
        window.dataLayer = window.dataLayer || [];
        window.beaconCustomTrack = window.beaconCustomTrack || {};

        const isPageview = params && params.event === 'e_pageView';
        const { postPageViewActionParameters, eventCallback, eventTimeout, useCustomMetrics, event } = params || {};
        const hasPostPageViewActionParams = postPageViewActionParameters && isPageview;
        if (hasPostPageViewActionParams) delete params.postPageViewActionParameters;
        // useGtmMetrics is used for newer metrics that rely on GTM container logic rather than manual dataLayer calls made through this reducer.
        // if specified, the manual dataLayer calls aren't made.
        if (event === 'e_addToCart' && !useCustomMetrics) return;

        let offerID;
        try {
            offerID = isPageview && Helpers.getCookie('Offer_id');
        } catch (e) {}

        if (offerID) {
            params = params || {};
            params.offerID = offerID;
        }

        /* data-metrics prefix by default lower-case params object keys' name, will revisit if analytic code is case-sensitve. */
        let _params = {};
        let isProductImpression;
        let isPromotionImpression;
        // switch statement to transform data into the accepeted format.
        switch (eventName) {
            case 'linkClick': {
                _params = {
                    event: params.event,
                    linkPlacement: params.linkPlacement || STORE_TRACK_PREFIX,
                    linkID: params.linkId,
                };
                break;
            }
            case 'compareModels': {
                _params = _compare(params);
                break;
            }
            case 'searchSortBy': {
                _params = { event: params.event, searchSortType: params.searchsorttype };
                break;
            }
            case 'productClick': {
                _params = _productClick(params);
                break;
            }
            case 'productImpression': {
                _params = _productImpression(params);
                isProductImpression = true;
                break;
            }
            case 'promotionImpression': {
                _params = _promotionImpression(params);
                isPromotionImpression = true;
                break;
            }
            case 'promotionClick': {
                _params = _promotionClick(params);
                break;
            }
            case 'compareModels': {
                _params = _compare(params);
                break;
            }
            case 'configuratorChange': {
                _params = _configuratorChange(params);
                break;
            }
            case 'configuratorStart': {
                _params = _configuratorStart(params);
                break;
            }
            case 'configuratorComplete': {
                _params = _configuratorComplete(params);
                break;
            }
            case 'addToCart': {
                let { products, currency, orderId, ...config } = params || {};
                _params = _addToCart(products, currency, orderId, config);
                break;
            }
            case 'readReview': {
                _params = _readReview(params);
                break;
            }
            case 'searchAutoClick': {
                _params = _searchAutoClick(params);
                break;
            }
            case 'searchResults': {
                _params = _searchResults(params);
                break;
            }
            case 'productReview': {
                _params = _productReview(params);
                break;
            }
            case 'chat': {
                _params = _chat(params);
                break;
            }
            case 'videoLoad': {
                _params = _videoLoad(params);
                break;
            }
            default: {
                _params = params;
                break;
            }
        }

        if (eventCallback) {
            _params.eventCallback = eventCallback;
        }

        if (eventTimeout) {
            _params.eventTimeout = eventTimeout;
        }
        const { productsChanged, promoChanged } = params || {};
        const isCartProductsReady = eventName === 'cartProductsReady';
        
        if(isPageview){
            try{
                window.dataLayer.push({ event: 'standards.pageReady' });
                if(window.productMap && Object.keys(window.productMap).length > 0){
                    trackAllProductImpressions();
                    window.dataLayer.push({ event: 'standards.productsReady' });
                }

                if(window.promotionsMap && Object.keys(window.promotionsMap).length > 0){
                    trackAllPromotionImpressions();
                    window.dataLayer.push({ event: 'standards.promoReady' });
                }
            }catch(e){}
        }
        else if(isProductImpression && Array.isArray(productsChanged) && productsChanged.length > 0){
            try{
                window.dataLayer.push({ event: 'standards.productsChange', productsChanged });
                trackImpressionKeys(productsChanged, 'products')
            }catch(e){}
        }
        else if(isPromotionImpression && Array.isArray(promoChanged) && promoChanged.length > 0 ){
            try{
                window.dataLayer.push({ event: 'standards.promoChange', promoChanged });
                trackImpressionKeys(promoChanged, 'promotions');
            }catch(e){}
        }
        else if(isCartProductsReady){
            try{
                window.dataLayer.push({event: 'standards.cartProductsReady'}); 
            }catch(e){}
        }
        else if(eventName === 'standards.miniCartQuantityChange'){
            _params = _miniCartQuantityChange(params)
            _params && window.dataLayer.push(_params);
        }
        else {
            window.dataLayer.push(_params);
        }

        // TODO: Refactor later
        // if (hasPostPageViewActionParams) {
        //     setTimeout(() => {
        //         triggerPostPageViewActions(postPageViewActionParameters);
        //     }, 3000);
        // }

        // send optimizely events after dataLayer push (GA/Adobe)
        if (isPageview) {
            // capture adobe timestamp asap to get closer mesure to adobe firing time
            const perfstamp =
                performance && performance.now && typeof performance.now === 'function' ? performance.now() : null;

            window.beaconCustomTrack.adobeFired = true;
            window.beaconCustomTrack.sendLogglyBeacon && window.beaconCustomTrack.sendLogglyBeacon({ perfstamp });

            if (this.initialTrack) {
                window['optimizely'] = window['optimizely'] || [];
                window['optimizely'].push({
                    type: 'sendEvents',
                });

                this.initialTrack = false;
            }
        }
    }
}

export default UDLAPI;
