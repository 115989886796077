import { useSmoothScroll } from '@hpstellar/core/hooks';
function getVerticalPosition(el) {
    var rect = el.getBoundingClientRect(),
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return rect.top + scrollTop;
}
const MAX_ATTEMPTS = 5;

export const lazyScrollPosition = (position, options, attempts = 0) => {
    const { offset = 0, patchDelay = 900, maxAttempts = MAX_ATTEMPTS, threshold = 100, backoff = true } = options || {};
    window.scrollTo({
        top: position - offset,
        left: 0,
        behavior: 'smooth',
    });
    setTimeout(function () {
        const distance = Math.abs(position - document.documentElement.scrollTop);
        if (distance > threshold && attempts < maxAttempts) {
            //backoff the patch delay
            lazyScrollPosition(position, { ...options, patchDelay: backoff ? patchDelay * 2 : patchDelay }, ++attempts);
        }
    }, patchDelay);
};

/**
 * Scrolls and attempts to patch the position as lazy loaded elements on the page render
 * @param {*} target
 */
const lazyScroll =
    (scrollToTarget, offset = 0, patchDelay = 900, attempts = 0) =>
    target => {
        if (!target) {
            return;
        }
        try {
            scrollToTarget(target);
            setTimeout(function () {
                const distance = Math.abs(getVerticalPosition(target) - window.scrollY);
                if (distance > 100 && attempts < MAX_ATTEMPTS) {
                    lazyScroll(scrollToTarget, offset, patchDelay / 2, ++attempts)(target);
                }
            }, patchDelay);
            target.focus();
        } catch (e) {}
    };

export default (options = {}) => {
    const { offset, duration = 800 } = options;
    const { scrollToTarget } = useSmoothScroll({ offset, duration });
    return [lazyScroll(scrollToTarget, offset)];
};
