import axios from 'axios';

import hawksearchConfig from '../../../search/config/hawksearch';
import { isNode } from '../util';

const searchAPI = function (config) {
    //fall back to env settings for hawksearch in case no config is passed
    const apiSettings = config
        ? config
        : {
              uri: hawksearchConfig.apiUrl,
              clientGuid: hawksearchConfig.clientGuid,
              indexName: hawksearchConfig.indexName,
          };

    const http = axios.create({
        baseURL: apiSettings.uri,
        //set a longer timeout for browser side call
        timeout: isNode ? 2000 : 60000 * 2,
    });

    const settings = {
        ClientGuid: apiSettings.clientGuid,
        ...(apiSettings.indexName && { IndexName: apiSettings.indexName }),
    };
    this._engineInfo = {
        ...apiSettings,
    };
    this.autocomplete = (value, clientData, searchParams) => {
        let { ProductCount, Type } = searchParams || {};
        let params = {
            ...settings,
            ...(clientData ? { ClientData: clientData } : {}),
            DisplayFullResponse: true,
            Keyword: value,
        };

        if (typeof ProductCount === 'number') {
            params.ProductCount = ProductCount;
        }

        if (typeof Type === 'string') {
            params.Type = Type;
        }

        return http.post('/api/v2/autocomplete', params);
    };

    this.search = data => {
        const {
            query,
            ClientData,
            FacetSelections,
            SortBy,
            PageNo,
            Keyword,
            MaxPerPage,
            FieldOverride,
            PaginationSetCode,
        } = data || {};
        let params = {
            ...(query && { query }),
            ...(ClientData && { ClientData }),
            ...(FacetSelections && { FacetSelections }),
            ...(SortBy && { SortBy }),
            ...(PageNo && { PageNo }),
            ...(Keyword && { Keyword }),
            ...(MaxPerPage && { MaxPerPage }),
            ...(FieldOverride && { FieldOverride }),
            ...(PaginationSetCode && { PaginationSetCode }),
            ...settings,
        };
        return http.post('/api/v2/search', params);
    };
};

export const HawksearchAPI = config => new searchAPI(config);
