import React from 'react';
import { Loader } from '@hpstellar/core';

import usePageLoader from '../../../hooks/usePageLoader';

export default ({}) => {
    const [loader] = usePageLoader();
    if (!loader || !loader.loading) {
        return null;
    }
    return <Loader fullPage zIndex={9999999} />;
};
