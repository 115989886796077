import { LOCATION_CHANGE } from 'connected-react-router';

import { RECEIVE_CTO_CONFIG } from './cto-actions';

const NULL_STATE = {};

/**
 * Attempt to only reset state if path changes
 * @param {*} action
 */
const handleLocationChange = (state, action) => {
    if (typeof document !== undefined) {
        try {
            return document.location.pathname !== action.payload.location.pathname ? NULL_STATE : state;
        } catch (e) {}
    }
    return NULL_STATE;
};

const AddOnReducer = (state = NULL_STATE, action) => {
    Object.freeze(state);
    switch (action.type) {
        case LOCATION_CHANGE:
            return handleLocationChange(state, action);
        case RECEIVE_CTO_CONFIG:
            let { error = {}, ...currentRest } = state[action.sku] || {};
            const { error: newErrorState = {}, ...configRest } = action.config;
            //update error state by path
            Object.keys(newErrorState).forEach(k => {
                const value = newErrorState[k];
                if (value == null) {
                    delete error[k];
                } else {
                    error[k] = value;
                }
            });
            if (Object.keys(error).length === 0) {
                error = undefined;
            }
            let newState = { ...currentRest, ...configRest, error };
            return Object.assign({}, state, { [action.sku]: newState });
        default:
            return state;
    }
};

export default AddOnReducer;
