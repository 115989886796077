import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { showPopover } from './header-footer-actions';

import './css/popover.less';

export function PopOver({
    show,
    leftOffset,
    children,
    arrowTop,
    arrowBottom,
    margin,
    animated,
    onMouseEnter,
    onMouseLeave,
}) {
    const display = show ? 'flex' : 'none';
    // TODO: clean this up
    const style = {};
    if (margin !== 0) style.margin = margin + 90;
    if (leftOffset !== 0) style.left = leftOffset;
    if (!animated) style.display = display;

    // const onEsc = () => {
    //     // TODO: implement escape key press
    // };

    return (
        <Fragment>
            <div
                className="popover-container"
                style={style}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <div className={`popover ${show ? 'show' : ''}`}>
                    <div className="popover-content">
                        {arrowTop && (
                            <div className="popover-arrow-top">
                                <div className="background-arrow" />
                                <div className="foreground-arrow" />
                            </div>
                        )}
                        {children}
                    </div>
                    {arrowBottom && (
                        <div className="popover-arrow-bottom">
                            <div className="background-arrow" />
                            <div className="foreground-arrow" />
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    );
}

PopOver.defaultProps = {
    arrowTop: false,
    arrowBottom: false,
    margin: 0,
    leftOffset: 0,
    animated: false,
};

PopOver.propTypes = {
    /** option show/hide popover */
    show: PropTypes.bool.isRequired,
    /** offset from left in pixels */
    leftOffset: PropTypes.number,
    /** children componet to display in popover */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    /** display top arrow */
    arrowTop: PropTypes.bool,
    /** display bottom arrow */
    arrowBottom: PropTypes.bool,
    /** margin in pixels */
    margin: PropTypes.number,
    /** flag, if display is to be animated in css */
    animated: PropTypes.bool,
};

const mapActions = (dispatch) => ({
    onOverlay: (show) => dispatch(showPopover(show)),
});

export default connect(null, mapActions)(PopOver);
