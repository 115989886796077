class ObligationService {
    constructor(apiConfig, http) {
        const { siteInfo } = apiConfig;
        const { storeId } = siteInfo;
        this.apiConfig = apiConfig;
        this.http = http;
        this.path = `/wcs/resources/store/${storeId}/HPObligationService`;
    }

    /**
     * Fetch page data
     * @param {string} template - template key of the page
     * @param {string} vanityUrl - the relative path of the page
     * @returns
     */
    get(config = {}) {
        return this.http.get(`${this.path}`, config).then(resp => {
            const { data, status } = resp;
            //attempt to parse response
            if (status === 200 && data && data !== '') {
                return data;
            } else {
                return Promise.reject({});
            }
        });
    }

    /**
     *
     * @param {string} eCarePackSN - the serial number imputed by the customer
     * @param {boolean} isCCFlow - is call center user
     * @param {*} cpOrderItemId - cart item id if of current carepack
     * @returns
     */
    eCarePackSerialNumberValidation(
        eCarePackSN,
        eCarePackCatEntryId,
        isCCFlow,
        cpOrderItemId,
        ObligationServiceEnable = true
    ) {
        return this.get({
            params: {
                eCarePackSN,
                eCarePackCatEntryId,
                isCCFlow,
                cpOrderItemId,
                ObligationServiceEnable,
            },
        });
    }
}

export default ObligationService;
