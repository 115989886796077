import { useSelector } from 'react-redux';
import { Helpers } from '../core/src/helpers';

import useDeviceInfo from './useDeviceInfo';

const BV_URL = 'https://apps.bazaarvoice.com/deployments/hp/main_site/production/en_US/bv.js';
const BV_PRP_URL = 'https://apps.bazaarvoice.com/deployments/hp/seo_prp/production/en_US/bv.js';

/**
 * Returns the siteConfig redux state
 * @name useSiteConfig
 * @returns {siteConfig}
 */
export default function useSiteConfig() {
    return useSelector(state => state.siteConfig);
}

const DEFAULT_LAZYLOAD_SETTINGS = {
    robots: true,
    skip: true,
    offset: 500,
};

/**
 * find the lazy load settings based on the templateKey and vanityUrl
 */
const matchLazyLoadSettings = Helpers.memoize((lazyLoadSettings = [], templateKey, vanityUrl, device) => {
    try {
        const templateSetting =
            lazyLoadSettings.find(settings => {
                const { templates, regex } = settings;
                try {
                    if (regex) {
                        return new RegExp(regex).text(vanityUrl);
                    }
                    if (templates && templates.length > 0) {
                        return templates.includes(templateKey);
                    }
                } catch (e) {}
                return false;
            }) || DEFAULT_LAZYLOAD_SETTINGS;
        const { mobileOffset, offset, regex, templates, ...rest } = templateSetting;
        return { offset: device !== 'desktop' && mobileOffset ? mobileOffset : offset, ...rest };
    } catch (e) {
        return DEFAULT_LAZYLOAD_SETTINGS;
    }
});

export const useLazyLoadSetttings = (templateKey, vanityUrl) => {
    const { lazyLoadSettings } = useSiteConfig();
    const { device } = useDeviceInfo(['device']);
    return matchLazyLoadSettings(lazyLoadSettings, templateKey, vanityUrl, device);
};

export const useETRStoreData = () => {
    return useSelector(state => state.etrStoreData);
};

const pickDependencies = Helpers.memoize((obj, dependencies) => {
    if (dependencies && dependencies.length > 0) {
        dependencies.reduce((r, key) => {
            if (key in obj) {
                r[key] = obj[key];
            }
            return r;
        }, {});
    }
    return obj;
});

export const useThirdPartyTags = dependencies => {
    return pickDependencies(
        useSelector(state => state.thirdPartyTags),
        dependencies
    );
};

const pickBVSettings = Helpers.memoize((sku, thirdPartyTags) => {
    const { bvRemoveLocalization, bvScript = BV_URL, bvPRPScript = BV_PRP_URL } = thirdPartyTags;
    return { transformedSKU: Helpers.BVTransformSKU(sku, bvRemoveLocalization), bvScript, bvPRPScript };
});

export const useBVSettings = sku => {
    return pickBVSettings(
        sku,
        useSelector(state => state.thirdPartyTags)
    );
};
