export const ComponentAPI = function (apiConfig, http, helpers) {
    let self = this;
    let path = `${process.env.BASENAME}/app/api/web/component`;

    /**
     * Get components match the provided component keys
     */
    self.get = (keys, options = {}, valueOnly) => {
        return http
            .get(`${path}/${keys.join(';')}`, {
                params: options,
            })
            .then(resp => {
                if (valueOnly) {
                    try {
                        const { data } = resp;
                        return data.map(component => {
                            return component.value;
                        });
                    } catch (e) {}
                }
                return resp;
            });
    };

    /**
     * Helper function for fetching and mapping the correct facet overrides for the VWA
     */
    self.getVWAOverride = (vanityUrl, filters) => {
        const [dir, category] = vanityUrl.split('/');
        const keys = ['vwaFacetOverride:default', `vwaFacetOverride:${category}`];
        return self.get(keys).then(({ data }) => {
            const [defaultKey, pageSpecific] = keys;
            const defaultFacets = data.find(({ key }) => key === defaultKey);
            const pageFacets = data.find(({ key }) => key === pageSpecific);
            return pageFacets || defaultFacets;
        });
    };

    /**
     * Helper function for fetch and mapping color hex settings
     */
    self.getHexColorMap = (logger = () => {}) => {
        const defaultKey = 'ctoColorOptions';
        return self.get([defaultKey], {}).then(({ data }) => {
            const isStringResponse = typeof data === 'string';
            isStringResponse && logger('error', 'CTO COLOR OPTION RETURNED AS STRING');
            const [ctoColorComponent] = isStringResponse ? JSON.parse(data) : data;
            const { value } = ctoColorComponent;
            const { colorMap } = value;
            return (colorMap || []).reduce((r, color) => {
                const { key, colorHex } = color;
                r[key] = colorHex;
                return r;
            }, {});
        });
    };
};
