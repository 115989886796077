import merge from 'lodash/merge';
import { RECEIVE_PRICE_ERROR, RECEIVE_PRICES, PRICE_VIEW, RECEIVE_PRICE_FETCH } from './product-actions';

export const parsePrices = prices => {
    return Object.keys(prices).reduce((acc, key) => {
        acc[key] = { ...prices[key], priceFetchFailed: false };
        return acc;
    }, {});
};

const PriceReducer = (state = {}, action) => {
    Object.freeze(state);
    switch (action.type) {
        case RECEIVE_PRICES:
            return merge({}, state, parsePrices(action.prices));
        case RECEIVE_PRICE_ERROR:
            // create sku to catentryid map
            const skuIdMap = action.failedCatentryIds.reduce((acc, id) => {
                const product = action.products.find(product => product.itemId === id);
                if (product) {
                    acc[id] = product.sku;
                }
                return acc;
            }, {});

            // generate state update object
            const update = action.failedCatentryIds.reduce((acc, id) => {
                const sku = skuIdMap[id];
                // priceFetchError is currently only used for analytics
                if (sku) {
                    acc[sku] = { priceFetchFailed: true, priceFetchError: true };
                } else {
                    acc[id] = { priceFetchFailed: true, priceFetchError: true };
                }
                return acc;
            }, {});

            return merge({}, state, update);
        case RECEIVE_PRICE_FETCH:
            /*Try to minimize extra price calls by set default values for inflight price calls */
            const fetching = action.catEntryIds.reduce((acc, id) => {
                acc[id] = { priceFetchFailed: true };
                return acc;
            }, {});
            return merge({}, state, fetching);
        case PRICE_VIEW:
            let prefetchPrice = state.prefetchPrice || [];
            if (Array.isArray(action.priceId)) {
                prefetchPrice = prefetchPrice.concat(action.priceId);
            } else {
                prefetchPrice.push(action.priceId);
            }
            return merge({}, state, { prefetchPrice });
        default:
            return state;
    }
};

export default PriceReducer;
