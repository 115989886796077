import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ScripLoader from '../page/components/script-loader';

import { withError } from '../shared/components/error-boundary';
import { useThirdPartyTags } from '../hooks/useSiteConfig';
import usePagComponents from '../hooks/usePageComponents';
import AboveTheFoldOnlyServerRender from '../shared/components/above-the-fold-only-server-render';

/**
 * Helper function to get the resource hints for the scripts to use on the server
 * @param {*} scripts
 * @param {*} vanityUrl
 * @returns
 */
export const getResourceHint = (scripts, vanityUrl) => {
    try {
        return mapScriptSettings(scripts, vanityUrl).reduce((r, script) => {
            if (script.resourceHint) {
                return r.concat({ href: script.src, rel: script.resourceHint, as: script.as || 'script' });
            }
            return r;
        }, []);
    } catch (e) {
        return [];
    }
};

export const mapScriptSettings = (scripts, vanityUrl) => {
    return (scripts || []).reduce((r, script) => {
        const { settings, src } = script;
        const currentSettings = settings.find(({ path }) => {
            return !path || new RegExp(path).test(vanityUrl);
        });
        if (!currentSettings || src?.length < 1) return r;
        const scriptSettings = src.map(url => ({ src: url, ...currentSettings }));
        if (scriptSettings.length > 0) {
            return r.concat(scriptSettings);
        }
        return r;
    }, []);
};

const useScriptLoader = () => {
    const { vanityUrl } = usePagComponents([]);
    const { advancedScriptLoader: scripts } = useThirdPartyTags(['advancedScriptLoader']);
    //loop through the scripts and get the script settings for each type
    return mapScriptSettings(scripts, vanityUrl);
};

/**
 * track the loaded scripts to avoid loading them again
 */
const scriptIsLoaded = {};

const ScriptTag = React.memo(({ src, priority, renderOn, execute, once = true, resourceHint }) => {
    const [isMounted, setIsMounted] = useState(false);
    const scriptTag =
        execute === 'async' ? (
            <script src={src} async />
        ) : execute === 'defer' ? (
            <script src={src} defer />
        ) : (
            <script src={src} />
        );
    useEffect(() => {
        setIsMounted(true);
    }, []);

    if (!src || (scriptIsLoaded[src] && once)) return;
    if (isMounted) {
        //only track the script is loaded on the client side
        scriptIsLoaded[src] = true;
    }
    if (priority === 'high') {
        return <Helmet>{scriptTag}</Helmet>;
    } else {
        return (
            <AboveTheFoldOnlyServerRender skip={true} renderOn={renderOn}>
                <ScripLoader url={src} once={once} />
            </AboveTheFoldOnlyServerRender>
        );
    }
});

export default withError(() => {
    const scripts = useScriptLoader();
    if (scripts?.length < 1) return null;
    return (
        <>
            {scripts.map((script, i) => (
                <div key={i}>
                    <ScriptTag {...script} />
                </div>
            ))}
        </>
    );
});
