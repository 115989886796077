import { 
    generateUniqueProductMapKey, 
    formatMetricValue, 
    getValidProductBrandMetricValue, 
    derivePageNameValuesFromProductList
} from '../metrics-helpers';

export default {
    pageView: ({ slugInfo }) => {
        try{
            const { components, analyticsData } = slugInfo || {};
            const { mdpProductList } = components || {};
            const { sectionTitle, products } = mdpProductList || {};
            const { family } = analyticsData || {};
            const { product_type, bu, segment, family: derivedFamily } = derivePageNameValuesFromProductList(products) || {};

            return {
                ...analyticsData || {},
                product_type,
                bu,
                family: derivedFamily || getValidProductBrandMetricValue(family, null, family, true),
                simple_title: formatMetricValue(sectionTitle || ''),
                segment
            }
        }catch(e){}
        
        return slugInfo && slugInfo.analyticsData;
    },
    // mdpProductList: ({component, slugInfo }) => {
    //     try{
    //         let { products } = component;
    //         const { vanityUrl } = slugInfo || {};
    //         Array.isArray(products) && products.forEach((prd, idx) => {
    //             try{
    //                 let { sku } = prd || {};
    //                 if(prd && !prd.gtmUniqueId) {
    //                     prd.gtmUniqueId = generateUniqueProductMapKey(
    //                         { sku }, 
    //                         { 
    //                             vanityUrl,
    //                             componentName: 'mdpProductList',
    //                             listPosition: idx+1 
    //                         }
    //                     )
    //                 }
    //             }catch(e){}
    //         });
    //     }catch(e){}

    //     return component;
    // },
}