import { HANDLE_SCRIPT_CREATE, HANDLE_SCRIPT_ERROR, HANDLE_SCRIPT_LOAD } from './script-loader-actions';

const NULL_STATE = {};

 const ScriptReducer = (state = NULL_STATE, { type , url }) => {
    let newState = {};
	switch (type) {
		case HANDLE_SCRIPT_CREATE:
			newState[url] = 'create'
			return Object.assign(state, newState);
        case HANDLE_SCRIPT_ERROR:
			newState[url] = null
			return Object.assign(state, newState);
        case HANDLE_SCRIPT_LOAD:
			newState[url] = 'load'
			return Object.assign(state, newState);
		default:
			return state;
	}
};

export default ScriptReducer;