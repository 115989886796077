import React from 'react';
import PropTypes from 'prop-types';

import { withError } from '../../../shared/components/error-boundary';
import useProductPrice from '../../../hooks/useProductPrice';
import DealCallout from './deal-callout';

import './intra-day-banner.less';

export const IntraDayBanner = ({ product, deals = {}, message, className }) => {
    const { prices = {} } = useProductPrice(product ? [product] : []);
    const { activeDeal = {} } = deals;
    const productPrice = prices[product.sku];

    return (
        <div className={`deals-banner${className ? ' ' + className : ''}`}>
            <DealCallout message={message} activeDeal={activeDeal} price={productPrice} />
        </div>
    );
};

class IntraDayDeals extends React.PureComponent {
    static propTypes = {
        itemId: PropTypes.string,
    };

    static defaultProps = {
        itemId: null,
    };

    componentDidMount() {
        const { itemId } = this.props;
        if (itemId) {
            this.props.fetchDeals(itemId);
        }
    }

    componentDidUpdate(prevProps) {
        let { userIdle, itemId, pStoreID } = this.props;
        //fetch new deals if itemId changed, or if user came back from being idle
        const pStoreIdChange = prevProps.pStoreID !== pStoreID;
        if (pStoreIdChange) {
            //if private store changed throw into the event loop and request deals again
            //this is the cover cases where a user lands on the page without a pStoreID in the url
            setTimeout(() => {
                this.props.fetchDeals(itemId, pStoreIdChange);
            }, 800);
        } else if (itemId !== prevProps.itemId || (!userIdle && prevProps.userIdle)) {
            this.props.fetchDeals(itemId, prevProps.userIdle);
        }
    }

    render() {
        const { productDeals = {}, isDealActive } = this.props;
        const isActive = isDealActive(productDeals);

        if (this.props.children) {
            return this.props.children({
                isActive,
                productDeals,
                IntraDayBanner,
            });
        } else {
            return null;
        }
    }
}

export default withError(IntraDayDeals);
