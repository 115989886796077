import React from 'react';
import loadable from '@loadable/component';

import AboveTheFoldOnlyServerRender from '../../../shared/components/above-the-fold-only-server-render';
import Header from './header';
import FooterLinks from './footer-links';
import { Helpers } from '../../../core/src/helpers';

import './new-header-style.less';

const Footer = loadable(() => Helpers.retryFunc(() => import('./footer')), {
    ssr: false,
});

const withHeaderFooter = Component => {
    return class extends React.PureComponent {
        render() {
            return (
                <React.Fragment>
                    <header>
                        <Header variation="hawksearch" />
                    </header>
                    <main>
                        <Component {...this.state} {...this.props} />
                    </main>
                    <FooterLinks />
                    <div id="footer" role="navigation" aria-label="Footer">
                        <AboveTheFoldOnlyServerRender skip={true} offset={800}>
                            <Footer />
                        </AboveTheFoldOnlyServerRender>
                    </div>
                </React.Fragment>
            );
        }
    };
};

export { Header, withHeaderFooter };
