import cloneDeep from 'lodash/cloneDeep';

import {
    formatMetricValue,
    getValidProductBrandMetricValue,
    getBusinessUnit,
    addGtmPropsToHighlightBannerComponent,
    generateUniqueProductMapKey,
    getUniqueVwaProductMapKey,
    addGtmUniqueIdToVwaProducts
} from '../metrics-helpers';
import { getVwaBannerGtmWithDifferentStructures } from '../../vwa/util';

export default {
    quickFilters: ({ component }) => {
        const { quickFilters } = component || {};
        if (!Array.isArray(quickFilters)) return component;

        const newComponent = cloneDeep(component);
        newComponent.quickFilters = quickFilters.map(quickFilter => ({
            ...(quickFilter || {}),
            gtmActions: new Map([
                [
                    'linkClick',
                    {
                        gtmValue: quickFilter && formatMetricValue(quickFilter.label),
                        gtmCategory: 'facet',
                        gtmId: 'quick-filters',
                    },
                ],
            ]),
        }));
        return newComponent;
    },
    vwaResultsFooterBanner: ({ component, analyticsData }) =>
        getVwaBannerGtmWithDifferentStructures({
            componentKey: 'vwaResultsFooterBanner',
            component,
            analyticsData,
        }),
    vwaResultsInlineBanner: ({ component, analyticsData }) =>
        getVwaBannerGtmWithDifferentStructures({
            componentKey: 'vwaResultsInlineBanner',
            component,
            analyticsData,
        }),
    vwaResultsFooterBanner: ({ component, analyticsData }) =>
        getVwaBannerGtmWithDifferentStructures({
            componentKey: 'vwaResultsFooterBanner',
            component,
            analyticsData,
        }),
    vwaTopBanner: ({ component, analyticsData }) =>
        getVwaBannerGtmWithDifferentStructures({
            componentKey: 'vwaTopBanner',
            component,
            analyticsData,
        }),
    productListingHeroBanner: ({ component, analyticsData }) =>
        getVwaBannerGtmWithDifferentStructures({
            componentKey: 'productListingHeroBanner',
            component,
            analyticsData,
        }),
    finderResults: ({ component, slugInfo }) => {
        const { vwaDetails, searchKey } = component || {};
        const { vanityUrl } = slugInfo || {};
        const { sortValues, facets, pageSize } = vwaDetails;
        if (!vwaDetails) return component;

        component.loadMoreGtmAttributes = {
            'data-gtm-category': 'linkClick',
            'data-gtm-id': 'product',
            'data-gtm-value': 'load-more',
        };

        vwaDetails.sortGtmActions = new Map([
            [
                'dropdownSelect',
                {
                    gtmCategory: 'facet',
                    gtmId: 'sort',
                },
            ],
        ]);

        vwaDetails.sortValues = Array.isArray(sortValues)
            ? sortValues.map(sort => {
                  if (!sort) return sort;

                  let formattedTitle = formatMetricValue(sort.title);
                  sort.gtmOption = formattedTitle;
                  sort.gtmSort = formattedTitle;
                  sort.gtmCategory = 'facet';
                  sort.label = sort.title;
                  sort.value = sort.key;

                  return sort;
              })
            : sortValues;

        vwaDetails.facets = Array.isArray(facets)
            ? facets.map(facet => {
                  const { facetValue } = facet || {};
                  if (facetValue) {
                      facet.facetValue = facetValue.map(fv => {
                          const { displayName, checked } = fv || {};
                          if (fv) {
                              fv.gtmActions = new Map([
                                  [
                                      'checkboxClick',
                                      {
                                          // TODO: GTM team mentioned gtmId should be quick-filters, but if this is always the case, how can we track the facet key?
                                          gtmId: facet && formatMetricValue(facet.displayName),
                                          gtmCategory: 'facet',
                                          gtmValue: formatMetricValue(displayName),
                                          gtmChecked: checked !== 'true',
                                      },
                                  ],
                              ]);
                          }

                          return fv;
                      });
                  }
                  return facet;
              })
            : facets;

        if(Array.isArray(vwaDetails.products)) {
            vwaDetails.products = vwaDetails.products.map(prdSet => {
                const { page, results } = prdSet || {};
                prdSet.results = addGtmUniqueIdToVwaProducts(results, { page })

                return prdSet;
            });
        }
        return component;
    },
    pageView: ({ slugInfo }) => {
        try {
            let { analyticsData, components } = slugInfo || {};
            let { derivedAnalyticsData, product_type } = analyticsData || {};
            let { product_type: derivedProductType } = derivedAnalyticsData || {};
            let { finderResults } = components || {};
            let { vwaDetails } = finderResults || {};
            let { facets } = vwaDetails || {};
            let brandFacet =
                Array.isArray(facets) &&
                facets.find(
                    facet => facet && typeof facet.displayName === 'string' && /^brand/i.test(facet.displayName)
                );
            let { facetValue } = brandFacet || {};
            let productType = derivedProductType || product_type;
            let selectedBrands = Array.isArray(facetValue) && facetValue.filter(fv => fv && fv.checked === 'true');
            let { displayName } = (selectedBrands && selectedBrands.length === 1 && selectedBrands[0]) || {};
            let parsedFamily = displayName
                ? getValidProductBrandMetricValue(displayName, '', productType || '', true)
                : 'shared';

            let bu = getBusinessUnit(productType);
            return {
                ...(analyticsData || {}),
                ...(derivedAnalyticsData || {}),
                family: parsedFamily,
                bu,
            };
        } catch (e) {}

        return {};
    },
};
