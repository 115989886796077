import { getValidProductBrandMetricValue, getValidProductCategoryMetricValue, addGtmPropsToBanners, generateUniqueProductMapKey } from '../metrics-helpers';
import { Helpers } from '../../core';

const getValidQueryStringValue = val => {
    let queryObj = Helpers.getSearch(location.search);
    let key = Object.keys(queryObj).find(key => new RegExp(val, 'i').test(key));
    let array = queryObj[key].split(',');
    return array.length === 1 ? array[0] : null;
}

const getSrpBrand = () => {
    try{
        let facetSubbrand = getValidQueryStringValue('facet_subbrand');
        let family = facetSubbrand && getValidProductBrandMetricValue(
            facetSubbrand, 
            '', 
            '',
            true
        );
        return family;
    }catch(e){}
}

const getSrpCategory = () => {
    try{
        let category = getValidQueryStringValue('dte_facet_category');
        let productCategory = category && getValidProductCategoryMetricValue(category);
        
        return productCategory;
    }catch(e){}
}

export default {
    hawksearchResults: ({component, device, analyticsData }) => {
        try{
            let { products, query } = component;
            let { Merchandising: { Items }, Results } = products || {};

            // TODO: Review for future releases - improvement for product impressions.
            // Array.isArray(Results) && Results.forEach((doc, idx) => {
            //     try{
            //         let { Document } = doc || {};
            //         let { sku: [prdSku] } = Document || {};
            //         if(Document && !Document.gtmUniqueId) {
            //             Document.gtmUniqueId = generateUniqueProductMapKey(
            //                 { sku: prdSku }, 
            //                 {
            //                     componentName: 'hawksearchResults', 
            //                     listPosition: idx+1 
            //                 }
            //             )
            //         }
            //     }catch(e){}
            // });
            let itemsWithAnalytics = Items.map( item => {
                let { Title, title } = item || {};
                return {
                    ...item || {},
                    title: Title || title // name in the id
                }
            })

            itemsWithAnalytics = addGtmPropsToBanners(itemsWithAnalytics, device, analyticsData, 'hawksearch',  {promotionClickType: 'cta'})
            component.products.Merchandising.Items = itemsWithAnalytics;
        }catch(e){}

        return component;
    },
    pageView: ({ slugInfo }) => {
        try{
            let {analyticsData} = slugInfo || {};
            let {derivedAnalyticsData} = analyticsData || {};
            let family = getSrpBrand();
            let product_type = getSrpCategory();

            return {
                ...analyticsData || {},
                ...derivedAnalyticsData || {},
                ...family ? { family } : {},
                ...product_type ? { product_type } : {}
            }
        }catch(e){}

        return {};
    }
}