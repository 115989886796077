import { 
    addGtmPropsToHighlightBannerComponent
} from '../metrics-helpers';

export default {
    valuePropContentSpot: ({ component, device, analyticsData }) => (
        addGtmPropsToHighlightBannerComponent({
            componentKey: 'valuePropContentSpot',
            component,
            device,
            analyticsData
        })
    )
}