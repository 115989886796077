import { StoreAppAPI } from '../core/src/storeapp-api-client';
import ETRWCSAPI from '../core/src/etr-wcs-api-client';

export const BLOCK_XSELL_MODAL = 'BLOCK_XSELL_MODAL';
export const UNBLOCK_XSELL_MODAL = 'UNBLOCK_XSELL_MODAL';
export const XSELL_OPEN = 'XSELL_OPEN';

const storeAppAPI = new StoreAppAPI();

const catOrder = [
    'Most Popular',
    'Displays',
    'Monitors',
    'Mice & Keyboards',
    'Workspace Accessories',
    'Bags & Cases',
    'Audio, Video & Multimedia',
    'Business Solutions',
    'Batteries, Chargers & Adapters',
    'Gaming',
    'Cables & Components',
];

const gamingCatOrder = [
    'Most Popular',
    'Displays',
    'Monitors',
    'Mice & Keyboards',
    'Gaming',
    'Workspace Accessories',
    'Bags & Cases',
    'Audio, Video & Multimedia',
    'Business Solutions',
    'Batteries, Chargers & Adapters',
    'Cables & Components',
];

const smbCatOrder = [
    'Most Popular',
    'Docking stations',
    'Docking Stations',
    'Displays',
    'Monitors',
    'Mice & Keyboards',
    'Workspace Accessories',
    'Bags & Cases',
    'Audio, Video & Multimedia',
    'Business Solutions',
    'Batteries, Chargers & Adapters',
    'Gaming',
    'Cables & Components',
];

export const blockXSellModal = () => ({
    type: BLOCK_XSELL_MODAL,
});

export const unblockXSellModal = () => ({
    type: UNBLOCK_XSELL_MODAL,
});

export const addToXsellOpened = sku => {
    return {
        type: XSELL_OPEN,
        sku: sku,
    };
};

export const hasXsell = (productsList, isV2) => {
    if (isV2) {
        const { products } = productsList;
        return products && Object.keys(products).length > 0;
    }
    if (Array.isArray(productsList)) {
        return productsList.length > 0;
    } else if (typeof productsList === 'object') {
        let { xsell = [], productGroup = { productsList: [] } } = productsList;
        if (productGroup.active) return productGroup.productsList.length > 0;
        return xsell.length > 0;
    }
    return false;
};

export const getHeaderMsg = (isSmb, baseProdName, originalText) => {
    const isPrinter =
        baseProdName.toLowerCase().indexOf('laserjet') > -1 ||
        baseProdName.toLowerCase().indexOf('tango') > -1 ||
        baseProdName.toLowerCase().indexOf('printer') > -1;
    if (isPrinter) {
        return originalText;
    } else if (isSmb === 'No' || isSmb === 'Yes,No') {
        return 'Get <b>15%</b> off select accessories and Care Packs with purchase of home PCs.';
    }
    return originalText;
};

export const sortCategories = (categories, prdName = '', isSmb) => {
    let newCat = {};
    let order;
    const gamingRegex = /gaming|omen/i;
    order = prdName.match(gamingRegex) ? gamingCatOrder : catOrder;
    if (prdName.match(gamingRegex)) {
        order = gamingCatOrder;
    } else if (isSmb === 'Yes') {
        order = smbCatOrder;
    } else {
        order = catOrder;
    }
    order.forEach(cat => {
        if (cat in categories) {
            newCat[cat] = categories[cat];
        }
    });
    return Object.assign(newCat, categories);
};

export const filterCategories = categories => {
    const keys = Object.keys(categories).map(cat => cat.toLowerCase());
    if (keys.indexOf('mice & keyboards') > -1 && keys.indexOf('mice') > -1) {
        delete categories['Mice'];
    }
    if (keys.indexOf('mice & keyboards') > -1 && keys.indexOf('keyboards') > -1) {
        delete categories['Keyboards'];
    }
    if (keys.indexOf('headphones') > -1 && keys.indexOf('audio, video & multimedia') > -1) {
        delete categories['Headphones'];
    }
    if (keys.indexOf('display') > -1 && keys.indexOf('displays') > -1) {
        delete categories['Display'];
    }
    if (keys.indexOf('monitors') > -1 && keys.indexOf('displays') > -1) {
        delete categories['Displays'];
    }
    if (keys.indexOf('monitors') > -1 && keys.indexOf('display') > -1) {
        delete categories['Display'];
    }
    if (keys.indexOf('business solutions') > -1 && keys.indexOf('accessories') > -1) {
        delete categories['Accessories'];
    }
};

export const getTabsModalCategories = (products, product, removeMostPopular) => {
    const { name, isSmb } = product;
    let categories = {};
    products.map(product => {
        for (let i = 0; i < product.categories.length; i++) {
            const categoryName = changeCategoryName(product.categories[i].displayName, removeMostPopular);
            const location = product.categories[i].location;
            if (
                categoryName.toLowerCase().indexOf('dlp') < 0 &&
                categoryName.match(/care pack/gi) === null &&
                (product.isSmb === 'Yes' || categoryName.match(/service/gi) === null) &&
                (location.indexOf('<<') > -1 ||
                    categoryName.toLowerCase() === 'most popular' ||
                    categoryName.toLowerCase() === 'business solutions')
            ) {
                if (categoryName in categories) {
                    if (categories[categoryName].products.filter(prd => prd.sku === product.sku).length === 0) {
                        categories[categoryName].products.push(product);
                    }
                } else {
                    categories[categoryName] = {
                        products: [product],
                        displayName: categoryName,
                        id: product.categories[i]._id,
                    };
                }
            }
        }
    });
    filterCategories(categories);
    return sortCategories(categories, name, isSmb);
};

const changeCategoryName = (name, removeMostPopular) => {
    if (name === 'All' && !removeMostPopular) {
        return 'Most Popular';
    }
    if (name === 'Ink & Toner') {
        return 'Ink, Toner & Paper';
    }
    return name;
};

export const getProductXsell = (sku, limit, skipCache = false, source) => {
    return storeAppAPI.product.xsell(sku, limit, skipCache, source).then(resp => {
        let productsList = [];
        if (resp && resp.status == '200' && resp.data) {
            productsList = resp.data;
        }
        return productsList;
    });
};

export const fetchXSellProducts =
    (product, limit, source = 'pg', isV2) =>
    (dispatch, getState) => {
        const skipCache = false;
        //TODO: we need to refactor xsell all to together
        if (isV2) {
            //peak into redux state to see if we are on a pdp with accessories information already loaded
            const { slugInfo } = getState();
            const { components } = slugInfo || {};
            const { pdpAccessories, productInitial = {} } = components || {};
            if (pdpAccessories && pdpAccessories.products && productInitial.sku === product.sku) {
                dispatch(addToXsellOpened(product.sku));
                return Promise.resolve(pdpAccessories);
            }
        }
        return getProductXsell(product.sku, limit, skipCache, source)
            .then(productsList => {
                if (hasXsell(productsList)) {
                    dispatch(addToXsellOpened(product.sku));
                }
                return productsList;
            })
            .catch(e => {
                return {};
            });
    };
