import React, { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { trackCustomMetric } from '../metrics/track-params';

export default () => {
    const dispatch = useDispatch();
    return useCallback((event, params) => {
        try {
            dispatch(trackCustomMetric(event, params));
        } catch (e) {}
    }, []);
};
