import { Helpers } from '../core/src/helpers';
const CONFIG_PARAMS = {
    langId: 1,
    catalogId: 1,
    storeId: 1,
    quantity: 1,
    catEntryId: 1,
    color: 1,
    configCatentryId: 1,
    orderItemId: 1,
    fromURL: 1,
    contractId: 1,
    urlLangId: 1,
    ctoCacheInvalidationCnt: 1,
    selectedRecommConfig: 1,
    configId: 1,
};

export const derivedSlug = (match, search) => {
    const {
        params: { dir, slug, slugTwo, filters },
    } = match;
    const queryString = Helpers.paramsToString(search, true, CONFIG_PARAMS);
    return `${slug ? slug : ''}${slugTwo ? `/${slugTwo}` : ''}${queryString ? `?${queryString}` : ''}`;
};

export const derivedPath = (match, search) => {
    const {
        params: { dir, slug, slugTwo, filters },
    } = match;
    const queryString = Helpers.paramsToString(search, true, CONFIG_PARAMS);
    return `${dir}${slug ? `/${slug}` : ''}${slugTwo ? `/${slugTwo}` : ''}${queryString ? `?${queryString}` : ''}`;
};
