import React from 'react';

import ErrorRedirect from '../main/components/error-redirect';
import { dispatchMenuState } from '../left-menu/left-menu-actions';
import VWAFilterCrumb from './components/vwa-filter-crumbs';
import { doSearch } from '../search-filter/search-filter-actions';
import { Helpers } from '../core/src/helpers';
import { StoreAppAPI } from '../core/src/storeapp-api-client';
import withPage, { fetchSlugInfo } from '../page';
import { ROOT_FILTER_KEYS } from './constants';

const storeAppAPI = new StoreAppAPI();
//all fitlers tagged by analyzers are nested under facets.[filtername] so for any searches on root document fields need to be indicated

export const VWA_DEFAULT_SEARCH_OPTIONS = {
    pageSize: 21,
    sort: { 'rank.default': 'asc' },
};

export const urlParser = match => {
    const { filters } = match.params;
    let queryObj = Helpers.parseFilters(filters);

    //treat primary group key as category filters
    queryObj.query = queryObj.query || {};
    //loop through query keys and add facets scope for non root filters
    Object.keys(queryObj.query).reduce((r, k) => {
        if (!(k in ROOT_FILTER_KEYS)) {
            let alias = `facets.${k}`;
            r[alias] = r[k];
            delete r[k];
        }
        return r;
    }, queryObj.query);

    return setCategoryFilter(queryObj).query;
};

export const urlFormatter = (match, queryObj, customAggregations) => {
    const { basename, dir, slug } = match.params;
    const { category } = customAggregations;
    const { serializeCategory } = category || {};

    //assume filters should always be after slug or dir
    let url = `/${basename}/${dir}${slug ? '/' + slug : ''}`;
    //remove page element from serealization
    let urlQuery = queryObj && Object.keys(queryObj).length > 0 ? JSON.parse(JSON.stringify(queryObj)) : { query: {} };

    //move category to filter primary group for serialization
    if (serializeCategory) {
        urlQuery.pg = category && category.key ? category.key : urlQuery.query.cat;
    }
    delete urlQuery.page;
    delete urlQuery.query.cat;

    //strip out facets nested path for url serialization
    Object.keys(urlQuery.query).reduce((r, k) => {
        if (/^pstoreid/i.test(k)) {
            delete r[k];
        } else if (k.indexOf('facets.') > -1) {
            let [, alias] = k.split('.');
            r[alias] = r[k];
            delete r[k];
        }
        return r;
    }, urlQuery.query);

    let filterString = Helpers.getFilterString(urlQuery, true);
    return `${url}${filterString ? '/' : ''}${filterString}`;
};

const setCategoryFilter = queryObj => {
    if (queryObj.pg) {
        queryObj.query.cat = queryObj.pg;
        delete queryObj.pg;
    }
    return queryObj;
};

/**
 *
 */
export const filterProducts = (query, aggregations, sort, startPage, pageSize, searchKey) => {
    let q = {
        aggregates: { price: 1, rating: 1 },
        filters: Object.keys(query).reduce((result, key) => {
            let value = query[key];
            if (key !== 'price' && Array.isArray(value)) {
                result[key] = value.join(',');
            } else {
                result[key] = value;
            }
            return result;
        }, {}),
        from: startPage,
        size: pageSize,
        page: searchKey,
        sort: sort && sort.field ? { [sort.field]: sort.order } : VWA_DEFAULT_SEARCH_OPTIONS.sort,
    };

    return storeAppAPI.product.es.search_v3(q).then(resp => {
        //format response to support custom aggregates
        let { data } = resp;
        let { facets, category, canonical } = data;
        let customAggregations = { facets, category, canonical };
        delete data.facets;

        return { data, customAggregations };
    });
};

const loadData = async (urlParams, store, context) => {
    const { query: querySelector } = context;
    const { dir, slug, filters } = urlParams;
    const fullSlug = `/${dir}/${slug}${filters ? '/' + filters : ''}`;
    await store.dispatch(fetchSlugInfo(fullSlug, querySelector));

    const { slugInfo, redirects, basename } = store.getState();

    const needRedirect = !slugInfo || slugInfo.error || (redirects && redirects.count !== 0);

    const { destination = `${basename}/slp/weekly-deals`, status = 301 } = redirects;
    if (needRedirect) return Promise.resolve({ status, destination });

    let query = urlParser({ params: urlParams });
    query = setCategoryFilter(query);

    if (querySelector && querySelector.pStoreID) {
        query.pStoreID = querySelector.pStoreID;
    }
    return Promise.all([
        store.dispatch(dispatchMenuState(true)),
        store.dispatch(
            doSearch(
                slugInfo.vanityUrl,
                filterProducts,
                query,
                undefined,
                VWA_DEFAULT_SEARCH_OPTIONS.sort,
                1,
                VWA_DEFAULT_SEARCH_OPTIONS.pageSize
            )
        ),
    ]);
};

class VWAContainer extends React.PureComponent {
    render() {
        const { slugInfo } = this.props;
        const { hosted, vanityUrl = '' } = slugInfo || {};
        //if the template is somehow reached redirect to home page
        return hosted && vanityUrl.indexOf('plp/') > -1 ? <ErrorRedirect /> : null;
    }
}

export default {
    component: withPage(VWAContainer, { customMetaData: true, wrapperClassName: 'plp-root', showWordCloud: true }),
    loadData: loadData,
};

export { VWAFilterCrumb };
