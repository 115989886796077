import { RECEIVE_HEADER_FOOTER, SHOW_POPOVER, UPDATE_HEADER, SHOW_MOBILE_SEARCH_BAR } from './header-footer-actions';
import { LOCATION_CHANGE } from 'connected-react-router';

/**
 * Store universal page defaults here
 * @type {Object}
 */
const DEFAULT_HEADER_FOOTER = {
    header: {
        nav: [],
        disableCart: false,
    },
    footer: {
        barLinks: [],
        groups: [],
        social: { name: '', items: [] },
        countries: [],
    },
    disclaimer: {
        sections: [],
    },
    popover: null,
    overlayShade: false,
};

const HeaderFooterReducer = (state = DEFAULT_HEADER_FOOTER, action) => {
    Object.freeze(state);
    const { header } = action;
    const { header: currentHeader } = state;
    switch (action.type) {
        case RECEIVE_HEADER_FOOTER:
            const { headerFooter } = action || {};
            const { pStoreID, etrHeader, customHTML, value } = headerFooter || {};
            if (etrHeader) {
                return {
                    ...state,
                    etrHeader: {
                        ...(state.etrHeader || {}),
                        [pStoreID]: {
                            ...(customHTML && { customHTML }),
                            ...(value ? value : {}),
                        },
                    },
                };
            }
            return { ...state, ...action.headerFooter };
        case SHOW_POPOVER:
            return { ...state, popover: action.show, overlayShade: action.overlayShade };
        case SHOW_MOBILE_SEARCH_BAR:
            return { ...state, mobileSearchBarOpen: action.show };
        case UPDATE_HEADER:
            return { ...state, header: { ...currentHeader, ...header } };
        case LOCATION_CHANGE:
            const { disableCart } = currentHeader;
            //reset disabledCart as on route change
            if (disableCart) {
                return { ...state, header: { ...currentHeader, disableCart: false } };
            }
            return state;
        default:
            return state;
    }
};

export default HeaderFooterReducer;
