import React from 'react';
import { connect } from 'react-redux';

import { fetchSiteConfig } from './page-actions';


/***
 * This component exists purely for local CSR dev. Since the site config comes from the server
 * In the live environemnt this would be loaded in the initial redux store already
 */
class SiteConfig extends React.PureComponent {
    static whyDidYouRender = true;
    componentDidMount() {
        const { siteConfig, fetchSiteConfig } = this.props;
        if (!siteConfig || Object.keys(siteConfig).length === 0 || siteConfig.isLocal) {
            fetchSiteConfig();
        } 
    }

    render(){
        return null;
    }
}

const mapStateToProps = (state, ownProps) => {
    const {  siteConfig } = state;
    return {
        siteConfig
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchSiteConfig: () => dispatch(fetchSiteConfig()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteConfig);
