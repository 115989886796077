import React from 'react';
import loadable from '@loadable/component';

import { withError } from '../../../shared/components/error-boundary';
import { Helpers } from '../../../core/src/helpers';

const GraphQLClient =
    typeof window !== 'undefined' && window
        ? loadable(() => Helpers.retryFunc(() => import('./graphql-client')))
        : null;

export default withError(({ slugInfo, placeholder }) => {
    if (!GraphQLClient) {
        return null;
    }
    return <GraphQLClient slugInfo={slugInfo} placeholder={placeholder} />;
});
