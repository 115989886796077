import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import RedirectWithStatus from '../../shared/components/redirect-with-status'


/**
A component for server and client side redirect as part of an AB test
@examples
```js
//sample redirect testFlags prop experiment data
{
     redirects: [
     {
            targeting: {
                //sprocket
                "/<basename>/pdp/hp-sprocket-200-printer-p-1as85a-b1h-1": "/<basename>/pdp/hp-sprocket-printer-1/color=luna%20pearl&type=sprocket",
                "/<basename>/pdp/hp-sprocket-200-printer-p-1as86a-b1h-1": "/<basename>/pdp/hp-sprocket-printer-1/color=noir&type=sprocket",
                "/<basename>/pdp/hp-sprocket-200-printer-p-1as91a-b1h-1":"/<basename>/pdp/hp-sprocket-printer-1/color=lilac&type=sprocket",
                "/<basename>/pdp/hp-sprocket-200-printer":"/<basename>/pdp/hp-sprocket-printer-1/color=blush&type=sprocket",
                "/<basename>/pdp/hp-sprocket-select-photo-printer":"/<basename>/pdp/hp-sprocket-printer-1/color=eclipse&type=sprocket%20select",
                },
            "action": { redirect: true , tracking: { "evar79": "printer-multi-sku:var1"} }
        }
     ]
   }
```
@component ABTestRouter
@import ABTestRouter
@returns {RedirectWithStatus} The rendered Redirect component for any active AB tests
*/
class ABTestRouter extends React.Component {

    static propTypes = {
        /**
         * Object containing an array of redirects
         */
        testFlags: PropTypes.object
    }

    render() {
        let { testFlags } = this.props;
        let { redirects = [] } = testFlags;
        let abTestRedirects = [];

        try {
            //set up redirects for all active campaigns
            abTestRedirects = redirects.reduce((result, abtest) => {
                let { action = {}, targeting = {} } = abtest || {};
                if (action.redirect) {
                    return Object.keys(targeting).reduce((nResult, fromUrl) => {
                        let toUrl = abtest.targeting[fromUrl]
                        if (fromUrl && toUrl) {
                            result.push({
                                path: fromUrl,
                                exact: true,
                                component: () => (
                                    <RedirectWithStatus
                                        to={toUrl}
                                        status={307} />)
                            });
                        }
                        return nResult;
                    }, result)
                }
                return result;
            }, []);
        } catch (e) {

        }


        return this.props.children({ abTestRedirects })
    }
}

const mapStateToProps = (state) => ({
    testFlags: state.testFlags,
    router: state.router,
    basename: state.basename
});


export default connect(mapStateToProps, null)(ABTestRouter);
