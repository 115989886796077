import axios from 'axios';
import QueryCache from "./query_cache";

const cache = new QueryCache();
const name = "swiftype-app-search-javascript";
const version = "2.3.1";

export function request(
  searchKey,
  apiEndpoint,
  path,
  params,
  cacheResponses,
  { additionalHeaders } = {}
) {
  const method = "POST";
  const key = cache.getKey(method, apiEndpoint + path, params);
  if (cacheResponses) {
    const cachedResult = cache.retrieve(key);
    if (cachedResult) {
      return Promise.resolve(cachedResult);
    }
  }

  return _request(method, searchKey, apiEndpoint, path, params, {
    additionalHeaders
  }).then(response => {
    if (cacheResponses) cache.store(key, response.data);
    return response.data;
  });
}

function _request(
  method,
  searchKey,
  apiEndpoint,
  path,
  params,
  { additionalHeaders } = {}
) {
  const headers = {
    Authorization: `Bearer ${searchKey}`,
    "Content-Type": "application/json",
    "X-Swiftype-Client": name,
    "X-Swiftype-Client-Version": version,
    ...additionalHeaders
  };

  return axios({
    url: `${apiEndpoint}${path}`,
    data: params,
    method,
    headers,
    credentials: "include"
  });
}
