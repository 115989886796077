import { RECEIVE_COMPARE_RESULTS_V2 } from './compare-actions';

const CompareResultsReducer = (state = null, action) => {
    Object.freeze(state);
    switch (action.type) {
        case RECEIVE_COMPARE_RESULTS_V2:
            return action.compareResults;
        default:
            return state;
    }
};

export default CompareResultsReducer;             