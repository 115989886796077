import { LOCATION_CHANGE } from 'connected-react-router'

/* 
    refactor after page component is live 
    ideally all templates will fire one RECEIVE_SLUG_INFO 
    when they load that will be used for pageView tracking. 
*/
import { RECEIVE_REORDER_INFO } from '../reorder/reorder-actions';
import { RECEIVE_PDP_INFO, RECEIVE_VARIANT_INFO, TRACK_PDP_PAGE_VIEW } from '../pdp/pdp-actions';
import { TOGGLE_COMPARE_MODAL } from '../compare/compare-actions';
import { FETCH_SLUG_INFO, RECEIVE_SLUG_INFO, TRACK_PAGE_VIEW } from '../page/page-actions';
import { ADD_TO_CART, UPDATE_CART, REMOVE_FROM_CART } from '../utility/utility-actions';
import { RECEIVE_UTILITY, SORT_PRODUCTS } from '../product/product-actions';
import { TRACK_SORT } from '../search-filter/search-filter-actions';

const TRACK_CUSTOM_METRIC = 'TRACK_CUSTOM_METRIC';

export default {
    ADD_TO_CART,
    REMOVE_FROM_CART,
    UPDATE_CART,
    RECEIVE_UTILITY,
    SORT_PRODUCTS,
    FETCH_SLUG_INFO,
    TRACK_PAGE_VIEW,
    TRACK_PDP_PAGE_VIEW,
    RECEIVE_PDP_INFO,
    RECEIVE_VARIANT_INFO,
    RECEIVE_SLUG_INFO,
    LOCATION_CHANGE,
    TOGGLE_COMPARE_MODAL,
    RECEIVE_REORDER_INFO,
    TRACK_SORT,
    TRACK_CUSTOM_METRIC
};
