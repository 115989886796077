export class EventObserver{
	constructor(){
		this.subscribers = {};
		this.pastEvents = {}
	}

	subscribe(event, callback){
		this.subscribers[event] = this.subscribers[event] || [];
		this.subscribers[event].push(callback);

		//check for event already fire prior to subscription and call back immeditately 
		//This will handle race condition on page load and subscribers
		if(this.pastEvents[event]){
			callback(event, this.pastEvents[event]);
		}
	}

	unsubscribe(event, callback){
		this.subscribers[event] = this.subscribers[event] || [];
		this.subscribers[event] = this.subscribers[event].filter(subscriber => subscriber !== callback);
	}

	publish(event, data){
		const subscribers = this.subscribers[event] || [];

        //Since we now defer optimizely
        //store events hashmap based on event type
        //because in most cases optimizely will subscribe to the events observer 
        //after the events have already been fired on the initial page load
		this.pastEvents[event] = data;
		subscribers.forEach( sub => {
			sub.apply(this, arguments);
		});
	}
}

export const MetricsObserver = new EventObserver();