import { UPDATE_COMPARE_PRODUCTS, CLEAR_COMPARE_PRODUCTS } from './compare-actions';
const EMPTY_ARRAY = []
const CompareProductsReducer = (state = EMPTY_ARRAY, action) => {
	Object.freeze(state);
	switch (action.type) {
		case UPDATE_COMPARE_PRODUCTS:
			return action.products;
		case CLEAR_COMPARE_PRODUCTS:
			return EMPTY_ARRAY;
		default:
			return state;
	}
};

export default CompareProductsReducer;