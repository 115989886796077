import axios from 'axios';

import supportConfig from '../../../search/config/support';

export const SupportAPI = function(config) {
    const { uri, clientGuid } = config || {};
    const http = axios.create({
        baseURL: uri,
        timeout: 3000
    });

    const reqConfig = {
        headers: {
            'x-api-key': clientGuid
        }
    };

    this.typeahead = (keyword, resultLimit, page) => {
        if(!uri || !clientGuid){
            return Promise.resolve({});
        }
        return http.get('knowledge/v2/typeahead/search', {
            params: {
                ...keyword && { q: keyword },
                ...resultLimit && { resultLimit },
                store: 'tmsstore',
                languageCode: 'en',
                printFields: 'productid,title,seofriendlyname,class', // "class is a debug parameter" from email
                filters: 'class:(pm_series_value OR pm_name_value OR pm_number_value)' // needed to remove Category results as they mentioned in email, because the URL structure won't work for categories
                 // printFields: 'productid,title,seofriendlyname',
                // filters,
                // printFields: 'productid,title,seofriendlyname'
                //'tmspmseriesvalue,tmspmnamevalue,tmspmnumbervalue,class,productid,title,tmsnodedepth,seofriendlyname,navigationpath,shortestnavigationpath,childnodes,activewebsupportflag,historicalwebsupportflag,body,btoflag,description'
            },
            ...reqConfig
        })
    }

    this.search =  ({ keyword, resultLimit, resultStart, sortBy, sortOrder }) => {
        if(!uri || !clientGuid){
            return Promise.resolve({});
        }
        return http.get('knowledge/v2/typeahead/search', {
            params: {
                ...keyword && { q: keyword },
                ...resultLimit && { resultLimit },
                ...resultStart && { resultStart },
                ...sortBy && { sortBy },
                ...sortOrder && { sortOrder },
                store: 'tmsstore',
                languageCode: 'en',
                printFields: 'productid,title,seofriendlyname'
                // filters,
                // printFields: 'productid,title,seofriendlyname'
                //'tmspmseriesvalue,tmspmnamevalue,tmspmnumbervalue,class,productid,title,tmsnodedepth,seofriendlyname,navigationpath,shortestnavigationpath,childnodes,activewebsupportflag,historicalwebsupportflag,body,btoflag,description'
            },
            ...config
        })
    }
}