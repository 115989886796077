import React from 'react';
import PropTypes from 'prop-types';

import Icon from './icon';
import './css/button-icon.less';

export default function ButtonIcon({ icon, onClick, onMouseEnter, buttonClass, ariaLabel, children }) {
    return (
        <button
            type="button"
            className={`button-icon ${buttonClass}`}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            aria-label={ariaLabel}
        >
            {children}
            <Icon icon={icon} />
        </button>
    );
}

ButtonIcon.defaultProps = {
    buttonClass: '',
    onMouseEnter: null,
};

ButtonIcon.propTypes = {
    /** icon string, must be valid icon from icon.less */
    icon: PropTypes.string.isRequired,
    /** onclick event */
    onClick: PropTypes.func.isRequired,
    /** additional style class name for styling */
    buttonClass: PropTypes.string,
    /** accessibility label */
    ariaLabel: PropTypes.string.isRequired,
    /** hover event */
    onMouseEnter: PropTypes.func,
};
