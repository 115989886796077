import { StoreAppAPI } from "../core/src/storeapp-api-client";
import { addRedirect } from '../page/page-actions';

export const RECEIVE_REORDER_INFO = 'RECEIVE_REORDER_INFO';
export const RECEIVE_REORDER_ERROR = 'RECEIVE_REORDER_ERROR';
export const CLEAR_REORDER_INFO = 'CLEAR_REORDER_INFO';

const storeAppAPI = new StoreAppAPI();

export const receiveReorderInfo = (reorderInfo, source, orderDate) => ({
    type: RECEIVE_REORDER_INFO,
	reorderInfo,
	source, 
	orderDate
});

export const receiveReorderError = () => ({
	type: RECEIVE_REORDER_ERROR
});

export const clearReorderInfo = () => ({
	type: CLEAR_REORDER_INFO
});

export const fetchReorderProducts = (skus, primarySKU, source, orderDate, attrPath='attributes.pm_series') => dispatch => {
    return storeAppAPI.product.related(skus, attrPath, primarySKU).then(resp => {
        let reorderInfo;
        if (resp && resp.status == "200" && resp.data) {
            reorderInfo= resp.data;
        }
        return dispatch(receiveReorderInfo(reorderInfo, source, orderDate));
    }).catch((err)=>{
        //might need this destination check in future or not?
        if(err.response.data.destination){
            dispatch(addRedirect(err.response.data.destination));
        }
        return dispatch(receiveReorderError());
    });
};
