import React from 'react';
import { MetricsElement } from 'react-metrics';

export const getNextInteractiveElement = (query, elem) => {
	let focusElements = (elem || document).querySelectorAll(query)
	let focusElement;
	focusElements && focusElements.forEach(el => {

		let isHidden;
		try{
			let {display, visibility} = window.getComputedStyle(el, null);
			isHidden = display === 'none' || visibility === 'hidden';
		}catch(e){}
		if(!focusElement && !isHidden && el && /^[0-9]/.test(`${el.getAttribute('tabindex')}`)){
			focusElement = el;
		}
	})

	return focusElement;
}

const moveFocusToNextInterativeElement = target => {
	// polls for the element to come in
	let attempts = 0;
	if(!target || !target.href || target.href[0] !== '#') return;

	let interval = setInterval(() => {
		attempts++;
		if(attempts > 60){
			clearInterval(interval);
		}
		let id = target.href[0] === '#' && window.location.hash === target.href && target.href;
		if(!id) return;
	
		let focusElement = getNextInteractiveElement(`${id} [tabindex], ${id} a`);
		if(focusElement) {
			clearInterval(interval);
			return focusElement.focus();
		}
	
		focusElement = getNextInteractiveElement(`${id} + [tabindex], ${id} + a`);
		if(focusElement) focusElement.focus();
		clearInterval(interval);
	}, 100)
}

export const onEnterClick = (onClick, clickTarget) => e => {
	let charCode = e.keyCode || e.which;
	if (charCode === 13) {
		if (onClick) {
			onClick(e)
		} else {
			//trigger element click if no click handler provided
			try {
				clickTarget && e.target.click();
				!clickTarget && e.currentTarget.click();
				moveFocusToNextInterativeElement(e.target)
			} catch (e) { }
		}
	}
}

export default React.memo(React.forwardRef((
	{
		element,
		onClick,
		tabIndex = 0,
		className = "",
		children,
		metricsData = null,
		ariaLabel,
		style,
		onKeyUp,
		clickTarget,
		...otherProps
	}, ref) => {

	let onKeyUpFn = onKeyUp || onEnterClick(onClick, clickTarget);
	let props = {
		onKeyUp: onKeyUpFn,
		onClick,
		tabIndex,
		className,
		style,
		ref,
		'aria-label': ariaLabel,
		...otherProps
	}

	if (metricsData) {
		return (
			<MetricsElement element={element} {...props} {...metricsData}>
				{children ? children : null}
			</MetricsElement>
		)
	} else {
		return (
			React.createElement(element, props, children)
		)
	}
}));