import axios, { CancelToken } from 'axios';

import { Helpers } from '../helpers';
import { ProductAPI } from './product';
import { ProductGroupAPI } from './product-group';
import { PageAPI } from './page';
import { GraphQLAPI } from './graphql';
import { ComponentAPI } from './component';
import { LogAPI } from './logger';
import ServerAPI from './server-api';
import { contextParameterInterceptor, isNode, REQUEST_CANCELLED_MESSAGE, isCancelledRequest } from '../util';
export { isCancelledRequest };

const apiGatewayConfig = {
    maxPageSize: 25,
    domain: process.env.API_GATEWAY_SERVER,
};
export const StoreAppAPI = function (expressServer, customAPIInstance, customHost) {
    const hostMap = {
        'glbecom-edit-web.austin.hp.com': '//www.hpstoreapp.com',
        'store.hp.com': '//store.hp.com',
        'uat.store.hp.com': '//uat.store.hp.com',
        'uat2.store.hp.com': '//uat2.store.hp.com',
        localhost: 'http://localhost:5001',
        root: '/',
        stage: '//stage.hpstoreapp.com',
        production: '//store.hp.com',
    };
    let self = this;
    let ajaxRequest;

    let currHost;
    if (process.env.ISNODE) {
        currHost = process.env.ENV;
    } else if (typeof document !== 'undefined') {
        currHost = document.location.hostname;
    }

    let config = {};
    let apiInstance;
    if (customAPIInstance && !customHost) {
        apiInstance = customAPIInstance;
    } else if (process.env.ISNODE && !customHost) {
        apiInstance = new ServerAPI(expressServer);
    } else {
        apiInstance = axios.create({
            baseURL: customHost || hostMap[currHost || 'root'],
            //set a longer timeout for browser side call
            timeout: isNode ? 3000 : 60000 * 2,
        });

        //pass along pStoreID to all api calls
        apiInstance.interceptors.request.use(httpConfig => {
            try {
                httpConfig = contextParameterInterceptor(httpConfig);
                const { _preview, _gql } = Helpers.getSearch(document.location.search);
                if (httpConfig.enableRequestCancel) {
                    delete httpConfig.enableRequestCancel;
                    ajaxRequest = CancelToken.source();
                    httpConfig.cancelToken = ajaxRequest.token;
                }
                if (_preview) {
                    httpConfig.params._preview = _preview;
                }
                //debug paramter for client side gql
                if (_gql) {
                    httpConfig.params._gql = _gql;
                }
            } catch (e) {}
            return httpConfig;
        });
    }
    const apiGatewayInstance = axios.create({
        baseURL: `https://${apiGatewayConfig.domain}`,
    });
    self.component = new ComponentAPI(config, apiInstance, Helpers);
    self.product = new ProductAPI(config, apiInstance, Helpers);
    self.productGroup = new ProductGroupAPI(config, apiInstance, Helpers);
    self.page = new PageAPI(config, apiInstance, Helpers);
    self.graphql = new GraphQLAPI(config, apiInstance, Helpers);
    self.logger = new LogAPI(apiGatewayConfig, apiGatewayInstance, Helpers);

    self.cancelRequests = () => {
        // cancel previous request if exists
        if (ajaxRequest) {
            ajaxRequest.cancel(REQUEST_CANCELLED_MESSAGE);
        }
    };
};
