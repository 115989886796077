import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../shared/components/modal';
import ConditionalWrapper from '../../shared/components/conditional-wrapper';
import DynamicModalControl from './dynamic-modal-control';

const ModalContext = React.createContext();

class ModalContent extends React.Component {
    state = {
        content: null,
        contentProps: {},
    };

    setContent = (content, contentProps) => {
        this.setState({
            content,
            contentProps,
        });
    };

    render() {
        const { content: Content, contentProps } = this.state;
        return Content ? <Content {...contentProps} closeModal={this.props.closeModal} /> : null;
    }
}

export class ModalControl extends React.PureComponent {
    static propTypes = {
        content: PropTypes.elementType.isRequired, //content to be rendered inside the modal
        contentProps: PropTypes.object, //props to be based inside the content
        modalProps: PropTypes.object, //props to be passed to the modal itself
        dynamicContent: PropTypes.bool, //whether or not the content has changing props from parent (rare use case when modal stays open while props from parents change)
        atomic: PropTypes.bool, // if true Modal control creates it's own instance of a modal seperate from the context
    };

    static defaultProps = {
        atomic: false,
    };

    state = {
        atomicOpen: false,
    };

    openAtomicModal = contextClose => {
        return () => {
            contextClose();
            this.setState({
                atomicOpen: true,
            });
        };
    };

    closeAtomicModal = () => {
        this.setState({
            atomicOpen: false,
        });
    };

    render() {
        let { atomic, modalProps, content } = this.props;
        let { closeOnBGClick, disableCloseButton } = modalProps || {};
        let { atomicOpen } = this.state;

        return (
            <ModalContext.Consumer>
                {props => {
                    if (atomic) {
                        let atomicModalProps = {
                            openModal: this.openAtomicModal(props.closeModal),
                            closeModal: this.closeAtomicModal,
                            isOpen: atomicOpen,
                            closeOnBGClick,
                            disableCloseButton,
                        };
                        return (
                            <React.Fragment>
                                {this.props.children(atomicModalProps)}
                                <Modal isOpen={atomicOpen} closeModal={this.closeAtomicModal} {...atomicModalProps}>
                                    {content && content(atomicModalProps)}
                                </Modal>
                            </React.Fragment>
                        );
                    } else {
                        return (
                            <DynamicModalControl {...props} {...this.props}>
                                {this.props.children}
                            </DynamicModalControl>
                        );
                    }
                }}
            </ModalContext.Consumer>
        );
    }
}

export class ModalProvider extends React.PureComponent {
    state = {
        isOpen: false,
        modalProps: {},
        //add modalKey to ensure that only the currently opened modal controller can set content
        modalKey: null,
    };

    contentRef = React.createRef();

    openModal = (content, contentProps, modalProps, modalKey) => {
        this.setState({ isOpen: true, modalProps, modalKey }, () =>
            this.updateProps(content, contentProps, modalKey, modalProps)
        );
    };

    closeModal = () => {
        this.setState({
            isOpen: false,
            modalProps: {},
        });
    };

    updateProps = (content, contentProps, modalKey, modalProps) => {
        if (this.state.isOpen && this.contentRef && this.state.modalKey === modalKey) {
            this.contentRef.current.setContent(content, contentProps);
            this.setState({ modalProps: modalProps });
        }
    };

    render() {
        const { isOpen, modalProps } = this.state;
        const { className, id, name } = this.props;

        return (
            <ConditionalWrapper
                condition={!!className}
                wrapper={children => {
                    return <div className={className} id={id} name={name}>{children}</div>;
                }}
            >
                <ModalContext.Provider
                    value={{
                        isOpen: this.state.isOpen,
                        openModal: this.openModal,
                        closeModal: this.closeModal,
                        updateProps: this.updateProps,
                        modalProps: this.state.modalProps,
                    }}
                >
                    <Modal isOpen={isOpen} closeModal={this.closeModal} {...modalProps}>
                        {isOpen && <ModalContent closeModal={this.closeModal} ref={this.contentRef} />}
                    </Modal>
                    {this.props.children}
                </ModalContext.Provider>
            </ConditionalWrapper>
        );
    }
}
