// TODO: This is only used because current implementation of PDP add-to-cart (on image gallery and sticky header) triggers a manual click on another add-to-cart element
//       which causes 2 addToCart events instead of 1. This should no longer be needed after that xsell refactoring is completed.
export const removeGtmAttributesFromPdpAddToCart = element => {
    try{
        let gtmCategory = element.getAttribute('data-gtm-category');
        let gtmValue = element.getAttribute('data-gtm-value');
        let gtmId = element.getAttribute('data-gtm-id')
        element.removeAttribute('data-gtm-category');
        element.removeAttribute('data-gtm-value');
        element.removeAttribute('data-gtm-id')

        return {gtmCategory, gtmId, gtmValue};
    }catch(e){}

    return {};
}

// TODO: This is only used because current implementation of PDP add-to-cart (on image gallery and sticky header) triggers a manual click on another add-to-cart element
//       which causes 2 addToCart events instead of 1. The GTM attributes are removed before the second manual click, and this is used to add the GTM attributes back if
//       the component doesn't re-render
export const addGtmAttributesToPdpAddToCart = (element, gtmCategory, gtmId, gtmValue) => {
    try{
        if(element.getAttribute('data-gtm-category') !== gtmCategory)
            element.setAttribute('data-gtm-category', gtmCategory);
        if(element.getAttribute('data-gtm-id') !== gtmId)
            element.setAttribute('data-gtm-id', gtmId);
        if(element.getAttribute('data-gtm-value') !== gtmValue)
            element.setAttribute('data-gtm-value', gtmValue);
    }catch(e){}
}


export const clickPdpAddToCartWithoutGtmAttributes = element => {
    // hide the gtm attributes first
    let { gtmValue, gtmCategory, gtmId } = removeGtmAttributesFromPdpAddToCart(element);
    element.click();
    // add back gtm attributes if the re-render didn't already take care of it.
    addGtmAttributesToPdpAddToCart(element, gtmCategory, gtmId, gtmValue);
}

// mutate pdp accessories and add position value.
export const addAnalyticsDataToPdpAccessoriesComponent = pdpAccessories => {
    try{
        let { categories, products } = pdpAccessories;
        let prevLength = 0;
        // add position values for productImpressions
        for(let category of categories){
            let { id, title } = category || {};
            let productBatch = products[id] || products[title];
            // mutate
            productBatch.forEach( (prd, i) => {
                prd.position = i + prevLength + 1;
            })

            prevLength += productBatch.length;
        }
    }catch(e){} 

    return pdpAccessories;
}

export const addCarepackGtm = carepack => {
    let { sku } = carepack || {};
    if(carepack){
        carepack.gtmValue = `carepack-modify-${sku}`;
    }
    return carepack;
}