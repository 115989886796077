import React, { useState, useEffect } from 'react';
import SoftSignIn from './soft-sign-in';
import { EtrAPIClient } from '../../../core/src/etr-wcs-api-client';

const etrAPIClient = new EtrAPIClient();

export default props => {
    const { children, loggedIn, onSessionStateChange = () => {}, ...rest } = props;
    const [isActiveSession, setIsActiveSession] = useState(null);
    useEffect(() => {
        if (!loggedIn) {
            return () => {};
        }
        etrAPIClient.person.isActiveSession().then(isActive => {
            if (isActive) {
                onSessionStateChange(true);
                setIsActiveSession(true);
            } else {
                onSessionStateChange(false);
                setIsActiveSession(false);
            }
        });
    }, [loggedIn]);

    if (loggedIn && isActiveSession === null) {
        return null;
    }
    if (!loggedIn || isActiveSession) {
        return children;
    }
    return <SoftSignIn {...rest} />;
};
