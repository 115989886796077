import React, { useContext } from 'react';

import loadable from '@loadable/component';

import SearchAutocompleteContext from './search-autocomplete-context';
import { Helpers } from '../../core';

const SearchBar = loadable(() => Helpers.retryFunc(() => import('./search-bar')));
const SearchBarWithTrendingKeywords = loadable(() => Helpers.retryFunc(() => import('./trending-keywords/search-bar')));

export default props => {
    const autocompleteContext = useContext(SearchAutocompleteContext);
    const {
        autocompleteTrendingKeywordsTestEnabled
    } = autocompleteContext || {};

    const SearchBarComponent = autocompleteTrendingKeywordsTestEnabled ? SearchBarWithTrendingKeywords : SearchBar;

    return <SearchBarComponent {...props} />
};
