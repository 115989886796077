import React, { useEffect, useState } from 'react';
import throttle from 'lodash/throttle';

import { ChevronUp } from '@hpstellar/icons';

import './scroll-up-button.less';

export default function ScrollUpButton() {
    const [scroll, setScroll] = useState(0);

    const onScroll = throttle(() => {
        setScroll(window.scrollY);
    }, 300);

    const scrollToY = y => {
        window.scrollTo({
            top: y,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, []);

    return (
        <button
            onClick={() => scrollToY(0)}
            className={`scrollUpButton ${scroll > 2500 ? ' show' : ''}${scroll > 1023 ? ' showMobile' : ''}`}
            type="button"
            aria-label="scroll to top"
        >
            <ChevronUp size="s" />
        </button>
    );
}
