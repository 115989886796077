import React from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';

import { receivePDPInfo } from '../../pdp-actions';
import withPage from '../../../page';
import { Helpers } from '../../../core/src/helpers';

const MMD = loadable(() => Helpers.retryFunc(() => import('./mmd')));

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
        receivePDPInfo: product => dispatch(receivePDPInfo(product)),
    };
};

const loadData = (params, store, context) => {};

/**
 * Set up redux incase we want to SSR this at some point
 */
export default {
    component: connect(
        mapStateToProps,
        mapDispatchToProps
    )(withPage(MMD, { customMetaData: true, disablePageCall: true })),
    loadData,
};
