import { combineReducers } from 'redux';

import {
    RECEIVE_FEATURED_ARTICLE,
    RECEIVE_BLOG_LIST_DATA,
    RECEIVE_TOP_BLOGS_DATA,
    RECEIVE_RELATED_BLOGS_DATA,
    RECEIVE_TAGS_DATA,
    RECEIVE_ARTICLE_ARCHIVES_DATA,
    RECEIVE_WORDCLOUD_DATA,
} from './blog-list-actions';

const FeaturedArticleReducer = (state = null, action) => {
    if (action.type === 'RECEIVE_FEATURED_ARTICLE') {
        return action.featuredArticle;
    } else {
        return state;
    }
};

const BlogListDataReducer = (state = [], action) => {
    if (action.type === 'RECEIVE_BLOG_LIST_DATA') {
        return action.blogListData;
    } else {
        return state;
    }
};

const TopBlogsDataReducer = (state = [], action) => {
    if (action.type === 'RECEIVE_TOP_BLOGS_DATA') {
        return action.topBlogsData;
    } else {
        return state;
    }
};

const RelatedBlogsDataReducer = (state = [], action) => {
    if (action.type === 'RECEIVE_RELATED_BLOGS_DATA') {
        return action.relatedBlogsData;
    } else if (action.type === 'RESET_BLOG_LIST_DATA') {
        return [];
    } else {
        return state;
    }
};

const TagsDataReducer = (state = [], action) => {
    if (action.type === 'RECEIVE_TAGS_DATA') {
        return action.tagsData;
    } else {
        return state;
    }
};

const ArticleArchivesDataReducer = (state = [], action) => {
    if (action.type === 'RECEIVE_ARTICLE_ARCHIVES_DATA') {
        return action.articleArchivesData;
    } else {
        return state;
    }
};

const WordCloudDataReducer = (state = {}, action) => {
    if (action.type === RECEIVE_WORDCLOUD_DATA) {
        return action.brightEdgeLinks;
    } else {
        return state;
    }
};

const blogListData = combineReducers({
    featuredArticle: FeaturedArticleReducer,
    blogHits: BlogListDataReducer,
    topBlogs: TopBlogsDataReducer,
    relatedBlogs: RelatedBlogsDataReducer,
    topTags: TagsDataReducer,
    articleArchives: ArticleArchivesDataReducer,
    brightEdgeLinks: WordCloudDataReducer,
});

export default blogListData;
