import { LocalCache } from '../local-storage-cache';

const getActiveSessionCache = () => {
    const localCache = new LocalCache('activeSession', 0.0007);
    return localCache.get('activeSesssionFlag');
};

const setActiveSessionCache = activeSesssionFlag => {
    const localCache = new LocalCache('activeSession', 0.0007);
    localCache.set('activeSesssionFlag', activeSesssionFlag);
};

class Person {
    constructor(apiConfig, http) {
        const { siteInfo } = apiConfig;
        const { storeId } = siteInfo;
        this.apiConfig = apiConfig;
        this.http = http;
        this.path = `/wcs/resources/store/${storeId}`;
    }

    get() {
        return this.http(`${this.path}/person/@self`).then(({ data, status }) => {
            if (data && data.userId === '-1002') {
                data.isGuest = true;
            }
            return data;
        });
    }

    setGuestIdentity() {
        return this.http.post(`${this.path}/guestidentity?updateCookies=true`).then(({ data, status }) => {
            return data;
        });
    }

    isActiveSession() {
        const currentTime = new Date().getTime();
        const localCache = new LocalCache('activeSession', 0.0007);
        const activeSesssionFlag = localCache.get('activeSesssionFlag');
        if (activeSesssionFlag) {
            return Promise.resolve(activeSesssionFlag);
        }
        return this.http(`${this.path}/HPUserSession?timeStamp=${currentTime}`)
            .then(({ data, status }) => {
                //store in session storage for a 1 minute to prevent spamming this API
                setActiveSessionCache(data?.activeSesssionFlag);
                return data?.activeSesssionFlag === 'true';
            })
            .catch(e => {
                const { response } = e || {};
                const { status } = response || {};
                if (status === 401) {
                    setActiveSessionCache(false);
                    return false;
                }
                //if API fails, set flag to true to prevent spamming this API
                setActiveSessionCache(true);
                return true;
            });
    }
}

export default Person;
