import { Helpers } from '../helpers';
const DEFAULT_INPUT_JSON = {
    country: 'US',
    currency: 'USD',
    language: 'en',
    organizationCode: 'US_Store',
};

/**
 * Add useful CTO functions
 */
export default (etrAPI, config, http) => {
    const { siteInfo } = config;
    const { storeId } = siteInfo;
    const path = `/wcs/resources/store/${storeId}`;
    return {
        /** the base config endpoint is completely wack and cannot handle encoded parameters */
        baseConfig: (vanityUrl, config) => {
            return http(`${path}/component/configure/baseconfig?path=${vanityUrl}`, {
                method: 'get',
                ...config,
            }).then(resp => {
                const { data, status } = resp;
                //attempt to parse response
                if (status === 200 && data && data !== '') {
                    return data;
                } else {
                    return Promise.reject({});
                }
            });
        },
        gameList: config => {
            return http(`${path}/HPGameList`, {
                method: 'get',
                ...config,
            }).then(resp => {
                const { data, status } = resp;
                //attempt to parse response
                if (status === 200 && data && data !== '') {
                    return data?.gameList?.map((game, index) => ({ ...game, id: index }));
                } else {
                    return Promise.reject({});
                }
            });
        },
        estimate: (data, config) => {
            return http(`${path}/component/configure/estimate`, {
                method: 'POST',
                data,
                ...config,
            }).then(resp => {
                const { data, status } = resp;
                //attempt to parse response
                if (status === 200 && data && data !== '') {
                    return data;
                } else {
                    return Promise.reject({});
                }
            });
        },
        /** Another special handler because config endpoints can't handle encoded parameters */
        froggerMessage: (vanityUrl, eSpotName = 'CtoFroggerMessages', config) => {
            return http(`${path}/component/espot/${eSpotName}?path=${vanityUrl}`, {
                method: 'get',
                ...config,
            }).then(resp => {
                const { data, status } = resp;
                //attempt to parse response
                if (status === 200 && data && data !== '') {
                    return data;
                } else {
                    return Promise.reject({});
                }
            });
        },
        compare: catentryId => {
            const params = {
                ...siteInfo,
                catEntryId: catentryId,
                responseFormat: 'json',
            };

            return etrAPI.component.get('configure', 'compareconfig', params).then(resp => {
                const { compareconfig } = resp || {};
                return compareconfig;
            });
        },
        accessories: (catentryId, configCatentryId = '', pStoreId) => {
            const params = {
                ...siteInfo,
                catEntryId: catentryId,
                configCatentryId,
                responseFormat: 'json',
                pStoreId,
            };
            return etrAPI.component.get('cto', 'accessoriesattach', {}, { params });
        },
        addToCart: (
            inputJson,
            inJSONData,
            catEntryId,
            hasAccessories,
            shipDate,
            leadDays,
            recConfig1,
            orderId,
            orderItemId,
            configCatentryId,
            crossSells,
            currentDate
        ) => {
            const params = {
                responseFormat: 'json',
                catalogId: 10051,
                langId: -1,
                quantity: 1,
            };
            const crossSellSkus = crossSells && crossSells.length > 0 ? crossSells.join(',') : undefined;
            const data = {
                inputJson: { ...DEFAULT_INPUT_JSON, ...inputJson },
                inJSONData,
                currentDate: currentDate || Helpers.date.etrDateFormat(new Date().toUTCString()),
                catEntryId,
                configCatentryId,
                orderItemId,
                hasAccessories,
                shipDate,
                leadDays,
                crossSellSkus,
            };
            return etrAPI.component.get(
                'configure',
                'addtocartconfig',
                {},
                { method: 'POST', data, params, includePrivateStoreID: true }
            );
        },
    };
};
