import React from 'react';
import loadable from '@loadable/component';

import { loadGraphQLData } from '../page';
import { Helpers } from '../core/src/helpers';

const PRPContainer = loadable(() => Helpers.retryFunc(() => import('./product-reviews-container')));

const PRP = props => {
    return <PRPContainer {...props} />;
};

export default {
    component: PRP,
    loadData: loadGraphQLData,
};
