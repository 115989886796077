import { Helpers } from './helpers';
export const isNode = process.env.ISNODE || typeof document === 'undefined';
/**
\
 * Based on the hostname determine whether api client should use a relative link
 * @param {*} hostname 
 */
export const useRelativeLink = () => {
    let currHost = isNode ? 'server' : document.location.hostname;
    return currHost !== 'server' && currHost !== 'localhost' && currHost.indexOf('hpstoreapp') < 0;
};

/***
 * Interceptor that adds context related query parameter from ETR to all api calls
 * @param {*} httpConfig - axios http config object
 */
export const contextParameterInterceptor = httpConfig => {
    //TODO: find a better way to get the pStoreID in to these queries potentially
    if (typeof document !== 'undefined') {
        const query = Helpers.getSearch(document.location.search);
        httpConfig.params = httpConfig.params || {};
        //some API like HPService call it with pstoreId instead (fml)
        if (
            query.pStoreID &&
            !httpConfig.params.pstoreId &&
            (httpConfig.method === 'get' || httpConfig.includePrivateStoreID) &&
            !httpConfig.excludePrivateStoreID
        ) {
            httpConfig.params.pStoreID = query.pStoreID;
        }
        if (query.previewToken) {
            httpConfig.params.previewToken = query.previewToken;
        }
    }
    return httpConfig;
};

/***
 * Interceptor that prevents calls from app origin. This should be used when API should be call only behind akamai revers proxy
 * @param {*} httpConfig - axios http config object
 */
export const appOriginInterceptor = httpConfig => {
    const { location } = typeof document !== 'undefined' ? document : {};
    const { origin } = location || {};
    //TODO: temp prevent any traffic in prod till API are ready
    if (origin && origin.indexOf('hpstoreapp') > -1) {
        throw new Error(`API CALLS DISABLED FROM ${origin}`);
    }
    return httpConfig;
};

export const REQUEST_CANCELLED_MESSAGE = 'Operation canceled by the user.';

export const isCancelledRequest = err => {
    const message = (err && err.message) || err;
    return message === REQUEST_CANCELLED_MESSAGE;
};
