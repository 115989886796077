import { combineReducers } from 'redux';
import productInfo from './product-info-reducer';
import addOns from './components/product-configurations/addon-reducer';
import ctoConfigurations from './components/product-configurations/cto-reducer';

export default combineReducers({
  productInfo,
  addOns,
  ctoConfigurations
});
