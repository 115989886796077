import { useDispatch, useSelector } from 'react-redux';

import { setLoader } from '../page/components/full-page-loader/full-page-loader-actions';

/**
 * Returns full page loader state
 * @name usePageLoader
 */
export default function usePageLoader() {
    const dispatch = useDispatch();
    const loader = useSelector(state => state.pageLoader);
    const setPageLoader = loader => dispatch(setLoader(loader));

    return [loader, setPageLoader];
}
