export const SET_LEFT_MENU = 'SET_LEFT_MENU';

export const setMenuState = (active, menuID) => ({
    type: SET_LEFT_MENU,
    active,
    menuID,
});

export const dispatchMenuState = (active, menuID) => (dispatch, getState) => {
    let { leftMenu } = getState();
    const { activeID } = leftMenu;
    const currentState = menuID ? activeID[menuID] : leftMenu.active;
    if (active !== currentState) {
        dispatch(setMenuState(active, menuID));
    }
};
