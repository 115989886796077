import { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import useSiteConfig from './useSiteConfig';
import { fetchSubscriptionsPromo } from '../product/promo-actions';
import { Helpers } from '../core/src/helpers';

//Check for various know attributes that contains the product url
const resolveProductUrl = product => {
    const { productUrl, ctaViewDetailsLink, linkUrl } = product || {};
    return productUrl || linkUrl || ctaViewDetailsLink || '';
};

const getProductSlug = url => {
    const regex = /\/us-en\/shop\/pdp\/(.*)/;
    return url.match(regex)?.[1];
};

const getProductPaths = products => {
    const pathMap = {};
    const paths = products.map(p => {
        const url = resolveProductUrl(p);
        const slug = getProductSlug(url);
        pathMap[slug] = p.sku;
        return slug;
    });
    return { pathMap, paths };
};

/**
 * Selects a memoizes promos. Remaps them to sku rather than path for easier reference
 */
export const selectPrices = Helpers.memoize(
    (paths, promos, pathMap = {}) => {
        return (paths || []).reduce((r, path) => {
            if (path in promos && promos[path].content) {
                r[pathMap[path] || path] = promos[path];
            }
            return r;
        }, {});
    },
    (paths, promos) => `${(paths || []).map(p => `${p}-${p in promos && promos[p].content}`)}`
);

export default function useSubscriptionsPromo(products) {
    const dispatch = useDispatch();
    const { productSubscriptionsPromo } = useSelector(state => state.productData.productInfo);
    //TODO: Add temp flag through site config in case we need to disable after launch since this will generate a lot more API calls
    const { disableSubscriptionPromo } = useSiteConfig();
    const { pathMap, paths } = getProductPaths(products);
    useEffect(() => {
        const missing = paths.filter(p => p && !productSubscriptionsPromo[p]);
        if (missing?.length > 0 && !disableSubscriptionPromo) {
            dispatch(fetchSubscriptionsPromo(missing));
        }
    }, [paths]);

    return selectPrices(paths, productSubscriptionsPromo, pathMap);
}
