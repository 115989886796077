import EtrWcsAPI from '../core/src/etr-wcs-api-client';

export const RECEIVE_PRODUCT_INITIALS = 'RECEIVE_PRODUCT_INITIALS ';

export const fetchProductInitials = catentryIds => dispatch => {
    return EtrWcsAPI.product.getProductInitials(catentryIds)
                .then(productInitialsData => {
                    let productInitials = productInitialsData && productInitialsData.productDetails;
                    return dispatch({ type: RECEIVE_PRODUCT_INITIALS, productInitials });
                })
                .catch(e => console.log('error', e));
}