import { combineReducers } from 'redux';
import { BLOCK_XSELL_MODAL, UNBLOCK_XSELL_MODAL, XSELL_OPEN } from './xsell-actions';

const EMPTY_OBJECT = {};

const blockModalReducer = (state = false, action) => {
    switch(action.type) {
        case BLOCK_XSELL_MODAL:
            return true;
        case UNBLOCK_XSELL_MODAL:
            return false;
        default:
            return state;
    }
};

//XSellReducer maintains a sku map of products that have already resulted in a xsell impression
const XSellReducer = (state=EMPTY_OBJECT, action) => {
  switch (action.type) {
      case XSELL_OPEN:
          state[action.sku] = 1;
  }
  return state;
}

export default combineReducers({
  xSellOpenedSkus: XSellReducer,
  blocked: blockModalReducer
});
