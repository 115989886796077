import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import ScriptLoader from './script-loader';

import useUserData, { useUserReceived }  from '../../hooks/useUserData';
import useProductPrice from '../../hooks/useProductPrice';

export const setUpInsideChatCartData = (cartInfo, storeDomain, prices) => {
    let invalidData, cartItems;
    try{
        if(!cartInfo){
            window._insideData && window._insideData.cart && delete window._insideData.cart;
            return;
        }

        const { items, sTot, id } = cartInfo;
        window._insideData.cart = window._insideData.cart || {};
        window._insideData.cart.total = sTot;
        if(typeof sTot === 'string'){
            window._insideData.cart.total = Number(sTot.replace(/[\^$\,]/g, ''));
        }
        window._insideData.cart.id = id;
        cartItems = Array.isArray(items) ? items.reduce((allItems, item) => {
            const { pNum, name, qty, pdpUrl, img, amt } = item || {};
            const { fg, nAmt } = amt || {};
            const isFree = fg && nAmt == 0;
            const priceData = prices && prices[pNum];
            const { salePrice, regularPrice } = priceData || {};
            let individualPrice;
            try{
                let strNum = nAmt.replace(/[\$|\,]/g, '');
                let spl = strNum.split('.');
                let dec = spl.length === 2 ? spl[1] : null;
                let comp = strNum;
                if(dec && dec.length === 1){
                    comp += '0';
                }
                if(Number(comp.replace(/\./g, '')) % qty === 0){
                    individualPrice = Number(strNum) / qty
                    individualPrice = Number(individualPrice.toFixed(2));
                }
                else {
                    invalidData = true;
                }
            }catch(e){}
            const price = isFree ? 0 : individualPrice;
            const url = typeof pdpUrl === 'string' && pdpUrl[0] === '/' ? `https://${storeDomain}${pdpUrl}` : pdpUrl;
            if(typeof price === 'number' && !isNaN(price)){
                let item = {
                    sku: pNum,
                    name,
                    img,
                    url,
                    qty, 
                    price
                }
                allItems.push(item)
            }
            else {
                invalidData = true;
            }
            return allItems;
        }, []) : [];
    }catch(e){}

    try{
        if(invalidData){
            delete window._insideData.cart;
        }
        else{
            window._insideData.cart.items = cartItems
        }
    }catch(e){}
}

const setUpInsideChatUserData = userData => {
    try{
        const { profileData } = userData || {};
        const { personData } = profileData || {};
        const { firstname, lastname, hpiddata } = personData || {};
        if(firstname && lastname && hpiddata) {
            window._insideData =  window._insideData || {};
            window._insideData.user = {
                id: hpiddata,
                firstName: firstname,
                lastName: lastname
            }
            return;
        }
    
        if(window._insideData && window._insideData.user){
            delete window._insideData.user;
        }
    }catch(e){}
}

const setUpInsideData = (userData, cartInfo, storeDomain, prices) => {
    window._insideData = window._insideData || {};
    // TODO: Uncomment in the future if needed
    // window._insideData.website = {
    //     country: 'US',
    //     currency: 'USD',
    //     language: 'EN'
    // }
    setUpInsideChatCartData(cartInfo, storeDomain, prices);
    setUpInsideChatUserData(userData);
}

const insideChatSetUp = (userData, cartInfo, storeDomain, prices) => {

    if(window.hasConfiguredInitialInsideChat){
        return;
    }

    try{
        setUpInsideData(userData, cartInfo, storeDomain, prices)
    }catch(e){}

    // Global variable.
    window._inside = window._inside || [];
    // Variable to define if Chat has finished loading.
    var chatLoaded = false;
    // Variable to define if Visitor has clicked the link before INSIDE has finished loading.
    var chatRequested = false;
    // The "chatavailable" binding is used to make changes to your chat link
    // or to any part of the website depending if Chat is available or unavailable.
    window._inside.push({
        "action": "bind",
        "name": "chatavailable",
        "callback": function (available) {
            chatLoaded = true;
            if (available) {
                // Handle chat available.
                // For example change the link text to "Chat Online".
                // Open chat pane if visitor has clicked the link when INSIDE has not finished loading
                // This is optional.
                if (chatRequested) insideFrontInterface.openChatPane();
            }
            else {
                // Handle Chat unavailable.
                // For example change the link text to "Chat Offline".
            }
            chatRequested = false;
        }
    });
    // This is the main code that will open the Chat Pane (also the Offline Chat Pane if enabled).
    window.openInsideChatButtonClick = function () {
        if (chatLoaded) // This is to check if INSIDE has finished loading.
            insideFrontInterface.openChatPane();
        else 
            chatRequested = true;
        return false;
    };

    window.hasConfiguredInitialInsideChat = true;
}

export const openInsideChatTab = () => {
    try{
        window.openInsideChatButtonClick()
    }catch(e){}
}

let loadTimeout;

export default ({ scriptUrl }) => {
    const [ hasInitialConfigurations, setHasInitialConfigurations ] = useState(false);
    const { userData } = useUserData();
    const userReceived = useUserReceived();
    const { profileData, updatedFromService } = userData || {};
    const { isccf } = profileData || {};
    const disabled = !updatedFromService || isccf; // disable if call center
    const cartInfo = useSelector(state => state && state.cartInfo);
    const { items } = cartInfo || {};
    const storeDomain = useSelector(state => state && state.storeDomain);
    let cartItemKeys = '';
    const products = Array.isArray(items) && items.reduce( (allProducts, item) => {
        const { ceId, qty, pNum } = item || {};
        if(ceId && ceId.length > 0){
            cartItemKeys += `-${pNum}-${qty}-`;
            allProducts.push({
                catentryId: ceId
            })
        }
        return allProducts
    }, [])
    const { prices } = useProductPrice(products)
    const priceSkuKeys = prices && Object.keys(prices)
    const priceSkus = priceSkuKeys.join('-');
    const hasItemsInCart =  items && items.length > 0;
    const missingCartPrices = (!priceSkuKeys || priceSkuKeys.length < 1);
    const cartDataIsReady = !hasItemsInCart || (hasItemsInCart && !missingCartPrices); // cart data is fetched
    
    const initialSetUp = useCallback(() => {
        try{
            insideChatSetUp(userData, cartInfo, storeDomain, prices);
        }catch(e){}
        setHasInitialConfigurations(true)
    }, [setHasInitialConfigurations])


    useEffect(() => {
        loadTimeout = setTimeout(() => {
            // in case HPServices doesn't return with data after 7 seconds, just load the chat
            if(!hasInitialConfigurations && !disabled){
                initialSetUp();
            }
        }, 7000) // TODO: Maybe make the time out configurable?
    }, [])

    useEffect(() => {
        // set up _insideData and load the script when the user and cart data have been fetched
        // userReceived is true when the profile and cart data have been retrieved from HPServices
        if(!hasInitialConfigurations && userReceived && cartDataIsReady && !disabled){
            try{
                clearTimeout(loadTimeout);
            }catch(e){}

            initialSetUp()
        }
    }, [userReceived, cartDataIsReady, disabled]);

    useEffect(() => {
        // update cart data in _insideData when new cart prices are fetched or when updating cart quantities
        // cartItemKeys changes when a cart quanitity is updated
        setUpInsideChatCartData(cartInfo, storeDomain, prices);
    }, [priceSkus, cartItemKeys])

    useEffect(() => {
        if(userReceived){
            setUpInsideChatUserData(userData)
        }
    }, [userReceived])

    return !disabled && hasInitialConfigurations && <ScriptLoader url={scriptUrl} attributes={{ async: true }} name="insideChat"/>
}