import merge from 'lodash/merge';
export const RECEIVE_PRODUCT_SETTINGS = 'RECEIVE_PRODUCT_SETTINGS';

const ProductSettingsReducer = (state = null, action) => {
    Object.freeze(state);
    switch (action.type) {
        case RECEIVE_PRODUCT_SETTINGS:
            return merge({}, state, action.productSettings);
        default:
            return state;
    }
};

/**
 * Use this action dispatch to minimize extra price calls by setting values for inflight price calls
 * @param {*} catEntryIds
 * @returns
 */
export const receiveProductSettings = productSettings => dispatch => {
    const action = {
        type: RECEIVE_PRODUCT_SETTINGS,
        productSettings,
    };
    return dispatch(action);
};

export default ProductSettingsReducer;
