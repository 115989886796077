import React from 'react';
import loadable from '@loadable/component';

import useAddToCartConfirm from '../../../hooks/useAddToCartConfirm';
import { withError } from '../../../shared/components/error-boundary';
import { Helpers } from '../../../core/src/helpers';
import AddToCartConfirmSkeleton from './add-to-cart-confirm-skeleton';

const PreOrderConfirm = loadable(() => Helpers.retryFunc(() => import('./pre-order-confirm')));
const ECarePackConfirm = loadable(() => Helpers.retryFunc(() => import('./e-care-pack')));

export default withError(({}) => {
    const { type, params } = useAddToCartConfirm();
    const fallBack = <AddToCartConfirmSkeleton isOpen={true} />;
    if (type === 'preorder' && params) {
        return <PreOrderConfirm params={params} fallback={fallBack} />;
    }
    if (type === 'ecarepack' && params) {
        return <ECarePackConfirm params={params} fallback={fallBack} />;
    }
    return null;
});
