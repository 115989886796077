import React from 'react';

import withPageComponents from './with-page-components';
import JsonLd from '../../shared/components/json-ld';
import { Helpers } from '../../core/src/helpers';

export const getJsonLdBreadcrumbData = breadcrumbs => {
    const itemListElement = breadcrumbs.reduce((jsonLdArray, breadcrumbItem, i) => {
        let { label, text, name, href } = breadcrumbItem || {};
        const breadcrumbLink = href;
        if (!breadcrumbLink || breadcrumbLink.length === 0) {
            return jsonLdArray;
        }
        let itemHref = breadcrumbLink[0] === '/' ? Helpers.getAbsoluteRedirectPath(breadcrumbLink) : breadcrumbLink;
        jsonLdArray.push({
            '@type': 'ListItem',
            position: i + 1,
            name: label || text || name,
            item: itemHref,
        });

        return jsonLdArray;
    }, []);

    return itemListElement && itemListElement.length > 0
        ? {
              '@context': 'https://schema.org',
              '@type': 'BreadcrumbList',
              itemListElement,
          }
        : null;
};

/**
 * Page level shema markup
 */
export default withPageComponents(
    ({ seo, CustomSchemaComponent }) => {
        const { breadcrumbs: seoBreadcrumbs } = seo || {};
        let data = [];
        const breadcrumbs = getJsonLdBreadcrumbData(seoBreadcrumbs || []);
        if (breadcrumbs) {
            data.push({ type: 'breadcrumb', data: breadcrumbs });
        }
        if (CustomSchemaComponent) {
            return <CustomSchemaComponent data={data} renderInHead={true} />;
        }
        if (data.length === 0) {
            return null;
        }
        return <JsonLd data={data} renderInHead={true} />;
    },
    { seo: true }
);
