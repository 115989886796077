export const SCREEN_RESIZE = 'SCREEN_RESIZE';
export const UPDATE_USERINPUT_TYPE = 'UPDATE_USERINPUT_TYPE';

const BREAKPOINT_RULES = {
    blog: (width, height) => (width >= 1316 ? 'desktop' : width && width < 1316 ? 'mobile' : null),
    'blog-v2-article': (width, height) =>
        width >= 1024 // based on ux grid system
            ? 'desktop'
            : width && width < 1024
              ? 'mobile'
              : null,
    'blog-v2-list': (width, height) =>
        width >= 1024 // based on ux grid system
            ? 'desktop'
            : width && width < 1024
              ? 'mobile'
              : null,
    'dlp-v2': (width, height) => (width >= 801 ? 'desktop' : width && width < 801 ? 'mobile' : null),
    'stellar-grid': (width, height) =>
        width > 1919 ? 'xlarge' : width > 1365 ? 'large' : width > 1023 ? 'medium' : width > 759 ? 'small' : 'xsmall',
    srp: width => (width && width < 1024 ? 'mobile' : 'desktop'),
    unifiedSearch: (width, height, windowInnerWidth) => ((windowInnerWidth || width) < 720 ? 'mobile' : 'desktop'),
    visId: (width, height, windowInnerWidth, serverDevice) => {
        const serverWidth = serverDevice === 'desktop' ? 1300 : null;
        const evaluatedWidth = serverWidth || width || windowInnerWidth;
        switch (true) {
            case evaluatedWidth < 361:
                return 'xsmall';
            case evaluatedWidth < 769:
                return 'small';
            case evaluatedWidth < 1025:
                return 'medium';
            case evaluatedWidth < 1367:
                return 'large';
            case evaluatedWidth < 1921:
                return 'xlarge';
            default:
                return 'xxlarge';
        }
    },
    stellar: (width, height) =>
        width >= 1024
            ? 'desktop'
            : width && width < 1024 && width > 759
              ? 'tablet'
              : width && width <= 759
                ? 'mobile'
                : null,
    default: (width, height) => {
        switch (true) {
            case width <= 768:
                return 'mobile';
            case width > 1024:
                return 'desktop';
            default:
                return 'tablet';
        }
    },
};

export const getCustomBreakpoints = (width, height, innerWidth, serverDevice) => {
    const breakpoints = {};
    Object.keys(BREAKPOINT_RULES).forEach(key => {
        breakpoints[key] = BREAKPOINT_RULES[key](width, height, innerWidth, serverDevice);
    });
    return breakpoints;
};

/**
 * Client side action to handle screen resize
 * @returns
 */
export const screenResize = () => {
    let width;
    let height;
    const { innerWidth, innerHeight, navigator } = window;
    try {
        //fallback to innerWidth and innerHeight if documentElement is not available
        const { documentElement } = document;
        width = documentElement?.clientWidth || innerWidth;
        height = documentElement?.clientHeight || innerHeight;
    } catch (e) {}

    const device = width <= 800 ? 'mobile' : width <= 1024 ? 'tablet' : 'desktop';
    const customBreakpoints = getCustomBreakpoints(width, height, innerWidth);

    return {
        type: SCREEN_RESIZE,
        width,
        height,
        innerHeight,
        innerWidth,
        device,
        customBreakpoints,
    };
};

export const updateUserInputType = inputType => {
    return (dispatch, getState) => {
        const store = getState();
        if (inputType !== store.ui.inputType) {
            dispatch({
                type: UPDATE_USERINPUT_TYPE,
                inputType,
            });
        }
    };
};
