import memoize from 'lodash/memoize';

/**
 * Memoize function that only works on the browser client, 
 * since we don't have a way of clearing cache on the server if we use memoize
 * @param  {[type]} date [description]
 * @return {[type]}      [description]
 */
const clientMemoize = function(func, resolver){

  if (process.env.ISNODE) {
    //if node, just return the function without memoize HOF
    return func
  }else{
    return memoize.apply(this, arguments)
  }
}

export default clientMemoize
