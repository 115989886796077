import React from 'react';

import { SearchContext } from './search-context';
import '../css/search-filter-crumbs.less';


/**
Context provider for search facet filter selection
@examples
```jsx
<SearchFilterCrumbs>
{
    ({ query, customAggregations={}, updateQuery, disableSearch  }) => {
        {
        return (<React.Fragment>
                    {!hideCategory && <SelectedCategory useAlias={useAlias}>
                    {
                        ({ selectedCategory }) => {
                            return <h2 className={'category-crumb'}>{selectedCategory && selectedCategory.displayName}</h2>
                        }
                    }
                    </SelectedCategory>}
                    {
                        facets.map((agg, i) => {
                            const { displayName, facetValues, key, alias } = agg;
                            return  <CustomFilterCrumb 
                                        options={facetValues} 
                                        filterName={displayName} 
                                        field={useAlias ?  alias : key}
                                        onClick={updateQuery}
                                        key={`${useAlias ? alias : key}-${i}`}
                                        useAlias={useAlias}
                                        />
                        })
                    }
                    <SearchClearFilter 
                        autoHide={true}
                        watchFields={/facets.*|price|rating/}
                    />
                </React.Fragment>);
    }
    }
}
</SearchFilterCrumbs>
```
@component SearchFilterCrumbs
@import SearchFilterCrumbs
@example ../docs/SearchFilterCrumbs.md
@returns {ReactElement} The rendered component
*/
export default class SearchFilterCrumbs extends React.Component {
    static defaultProps = {
        className: ''
    }

    render() {
        const { className } = this.props;

        return (
            <SearchContext.Consumer>
             {
                ({ query, customAggregations, updateQuery, disableSearch, filterCount }) => (
                     <div className={'search-filter-crumbs ' + className}>
                         {

                            this.props.children ? this.props.children({
                                query, 
                                customAggregations, 
                                filterCount,
                                updateQuery, disableSearch}) : <div className="filter-crumb"></div>
                         }
                     </div>
                )
              }
            </SearchContext.Consumer>
        );
    }

}

