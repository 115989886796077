export const RECEIVE_CTO_CONFIG = 'RECEIVE_CTO_CONFIG';

export const setCTOConfiguration = (sku, config) => ({
    type: RECEIVE_CTO_CONFIG,
    sku,
    config,
});

export const setColor = (sku, config) => (dispatch, getState) => {
    return dispatch(setCTOConfiguration(sku, config));
};
