/**
 * Avoid double encoding of URI values
 * @param {} value
 * @returns
 */
const safeEncodeURIComponent = value => encodeURIComponent(decodeURIComponent(value));

export const GraphQLAPI = function (apiConfig, http, helpers) {
    let self = this;
    let apiPath = `${process.env.BASENAME}/app/api/web/graphql`;
    self.get = query => {
        return http.get(apiPath, {
            query,
        });
    };

    self.getPersistedPath = (vanityUrl, hash, options) => {
        //replace '+' with 'spaces' before encoding
        const encodedVanityUrl = safeEncodeURIComponent(vanityUrl.replace(/\+/gi, ' '));
        return http.get(`${apiPath}/page/${encodedVanityUrl}/${safeEncodeURIComponent(hash)}`, options).catch(e => {
            throw e;
        });
    };

    self.getPersistedQuery = (vanityUrl, hash) => {
        let query = {
            vanityUrl: vanityUrl,
            hash: encodeURIComponent(hash),
        };
        return http.get(apiPath, {
            params: query,
        });
    };
};
