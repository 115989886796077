export const SUPPLIES_SEARCH = 'SUPPLIES_SEARCH';
export const SUPPLIES_AUTOCOMPLETE = 'SUPPLIES_AUTOCOMPLETE';
export const SUPPLIES_SEARCH_LOADING = 'SUPPLIES_SEARCH_LOADING';
export const SUPPLIES_SEARCH_KEYWORD = 'SUPPLIES_SEARCH_KEYWORD';
export const SUPPLIES_RESET = 'SUPPLIES_RESET';

export const setSearchResults = ({ results, keyword }) => {
    return {
        type: SUPPLIES_SEARCH,
        results,
        keyword,
    };
};

export const setLoading = loading => {
    return {
        type: SUPPLIES_SEARCH_LOADING,
        loading,
    };
};

export const setLatestSearch = latestSearch => {
    return {
        type: SUPPLIES_SEARCH_KEYWORD,
        latestSearch,
    };
};

export const reset = () => {
    return {
        type: SUPPLIES_RESET,
    };
};
