import React from 'react';
import PropTypes from 'prop-types';
import { StoreAppAPI } from '../../core/src/storeapp-api-client';
//const defaultPlaceholderSrc = "//store.hp.com/wcsstore/hpusstore/Treatment/category/images/pg-coming-soon-lg.png?imwidth=573&impolicy=prdimg&imdensity=1";

const storeAppAPI = new StoreAppAPI();

class ImageWithPlaceholder extends React.Component {
    static propTypes = {
        src: PropTypes.string.isRequired,
        alt: PropTypes.string,
        title: PropTypes.string,
        customStyle: PropTypes.object,
        className: PropTypes.string,
        onLoad: PropTypes.func,
        onClick: PropTypes.func,
        placeholderSrc: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            showPlaceholder: false,
        };
        this.onError = this.onError.bind(this);
    }

    onError() {
        if (this.props.placeholderSrc) {
            this.setState({ showPlaceholder: true });
        }
        storeAppAPI.logger.log({
            message: `Image failed to load`,
            info: `Image with URL: ${this.props.src} failed to load`,
            level: 'warning',
        });
        this.props.onLoad && this.props.onLoad();
    }

    render() {
        const {
            src,
            alt = '',
            title = '',
            customStyle = null,
            className,
            onLoad,
            onClick,
            placeholderSrc,
			imageClassName,
            tabindex=-1,
            loading
        } = this.props;
        let customImageClass = imageClassName ? ` ${imageClassName}` : '';
        return (
            <div
                className={`img-ph-wrapper ${className ? className : ''}`}
                style={customStyle}
                onClick={onClick}
            >
                {!this.state.showPlaceholder ? (
                    <img
						aria-label="Product Image"
						tabIndex={tabindex}
                        className={`orig-img${customImageClass}`}
                        src={src}
                        alt={alt}
                        title={title}
                        onError={this.onError}
                        onLoad={onLoad} 
                        loading={loading}
                    />
                ) : (
                    <img
						aria-label="Product Image"
						tabIndex={tabindex}
                        className={`ph-img${customImageClass}`}
                        src={placeholderSrc || src}
                        alt={alt}
                        title={title}
                        onLoad={onLoad}
                        loading={loading}
                    />
                )}
            </div>
        );
    }
}

export default ImageWithPlaceholder;
