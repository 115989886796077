import React from 'react';
import ReactDOM from 'react-dom';
import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';

class ScrollEvent extends React.Component {

    static propTypes = {
        enableSticky: PropTypes.bool,
        enableDelta: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.string
        ]),
        botBound: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ])
    }

    static defaultProps = {
        enableSticky: true,
        enableDelta: false
    }

    state = {
        isSticky: false,
        botReached: false,
        delta: 0
    }

    componentDidMount() {
        this.initialize();
    }  

    componentDidUpdate() {
        this.initialize();
    }

    componentWillUnmount() {
        if(this.scrollEvent) {
            window.removeEventListener('scroll',this.scrollEvent);
        }
    }

    initialize = () => {
        //if disabled do not attach events and unscribe if already listening
        if(this.props.disableScroll){
             if(this.scrollEvent) {
                window.removeEventListener('scroll',this.scrollEvent);
                this.scrollEvent = null;
            }
            return;
        }

        if(this.content && !this.state.isSticky && !this.state.botReached) {
            this.top = this.content.getBoundingClientRect().top + window.pageYOffset;
        }
        if(this.top && !this.scrollEvent) {
            this.scrollEvent = throttle(this.handleScroll,200);
            window.addEventListener('scroll',this.scrollEvent)
        }
        if(this.props.botBound) {
            let bot = this.props.botBound;
            if(bot.constructor === String) {
                const element = document.querySelector(bot);
                bot = element ? element.getBoundingClientRect().bottom + window.pageYOffset : null;
            }
            this.bot = bot;
        }
    }

    toggleState = (isSticky, delta, botReached) => {
        if(isSticky !== this.state.isSticky ||
            delta !== this.state.delta ||
            botReached !== this.state.botReached
        ) {
            this.setState({isSticky, delta, botReached});
        }
    }

    handleScroll = (event) => {
        const top = document.documentElement.scrollTop + document.body.scrollTop;
        const scrollDelta = top - this.top;
        const { enableSticky, enableDelta, botBound, botScrollOffset } = this.props;
        let { isSticky, delta, botReached } = this.state;
        if(enableSticky && this.top) isSticky = top >= this.top ? true : false;
        if(botBound && this.bot) {
            botReached = (top + window.innerHeight - (botScrollOffset || 0)) >= this.bot ? true : false;
        }    
        if(enableDelta === true) {
            delta = scrollDelta > 0 ? 1: (scrollDelta < 0 ? -1 : 0);
        } else if (enableDelta === 'continuous') {
            delta = scrollDelta;
        }
        this.toggleState(isSticky, delta, botReached);
    }

    attachRef = (content) => {
        if(content) {
            this.content = content.getBoundingClientRect ? content : ReactDOM.findDOMNode(content);
        }
    }

    render() {
        return this.props.children(this.attachRef, this.state);
    }



}

export default ScrollEvent;