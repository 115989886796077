import React from 'react';

class StickyCart extends React.Component {
    static getDerivedStateFromProps = props => ({
        show: props.show,
    });

    state = {
        show: false,
    };

    componentDidUpdate() {
        if (this.state.show) {
            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout(() => this.setState({ show: false }), 5000);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    render() {
        const { cartUrl, qty } = this.props;
        const { show } = this.state;

        return (
            <a
                className="sticky-mobile-cart"
                href={cartUrl}
                style={{ zIndex: show ? 998 : -1, opacity: show ? 1 : 0, visibility: show ? 'visible' : 'hidden' }}
            >
                <span className="cart-icon"></span>
                <span className="cart-quantity">{qty}</span>
            </a>
        );
    }
}

export default StickyCart;
