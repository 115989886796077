import {
    SUBSCRIPTION_PROMO_ERROR,
    SUBSCRIPTION_PROMO_FETCH,
    SUBSCRIPTION_PROMO_RECEIVE,
    PROMO_ERROR,
    PROMO_FETCH,
    PROMO_RECEIVE,
} from './promo-actions';

const subscriptionsPromoReducer = (state = {}, action) => {
    switch (action.type) {
        case SUBSCRIPTION_PROMO_ERROR:
            return { ...state, error: action.error };
        case SUBSCRIPTION_PROMO_FETCH:
            return {
                ...state,
                ...action.paths.reduce((r, k) => {
                    r[k] = {};
                    return r;
                }, {}),
            };
        case SUBSCRIPTION_PROMO_RECEIVE:
            return { ...state, ...action.promos };
        default:
            return state;
    }
};

export const productPromo = (state = {}, action) => {
    switch (action.type) {
        case PROMO_ERROR:
            return { ...state, error: action.error };
        case PROMO_FETCH:
            return {
                ...state,
                ...action.paths.reduce((r, k) => {
                    r[k] = [];
                    return r;
                }, {}),
            };
        case PROMO_RECEIVE:
            return { ...state, ...action.promos };
        default:
            return state;
    }
};

export default subscriptionsPromoReducer;
