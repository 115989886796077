import React from 'react';
import { Helmet } from 'react-helmet';

import withPageComponents from './with-page-components';
import { Helpers } from '../../core/src/helpers';
const EMPTY_OBJ = {};

/**
 * Sets robots meta tag to noindex if storeEnvironment is not production or if configured through StoreApp CMS
 */
export default withPageComponents(
    ({ seo, location, previewMode, storeEnvironment, proxyHost }) => {
        const { search } = location || EMPTY_OBJ;
        const { robots } = seo || EMPTY_OBJ;
        const query = Helpers.getSearch(search);
        const noIndex = Helpers.shouldNotIndex(storeEnvironment, query, robots, previewMode, proxyHost);
        if (!noIndex) return null;

        return (
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>
        );
    },
    { seo: true, isPreviewMode: true, storeEnvironment: true, location: true, proxyHost: true }
);
