import { useSelector } from 'react-redux';

import { Helpers } from '../core/src/helpers';

const EMPTY_OBJECT = {};

const pickComponents = (vanityUrl, components, componentKeys) => {
    return Object.keys(components).reduce(
        (r, key) => {
            if (componentKeys.includes(key)) {
                r[key] = components[key];
            }
            return r;
        },
        { vanityUrl },
    );
};

const memoizedPick = Helpers.memoize(pickComponents, (vanityUrl, components, componentKeys) => {
    return `${vanityUrl}-${componentKeys.join('-')}-${Object.keys(components || {}).length}`;
});

/**
 * @typedef {Object} PageComponents
 */

/**
 * Returns redux connected page functions
 * @name usePageComponents
 * @returns {PageComponents}
 */
export default function usePage(componentKeys, memoize = false) {
    const pageComponents = useSelector(state => {
        const { slugInfo } = state;
        const { components, vanityUrl } = slugInfo || {};
        if (Array.isArray(componentKeys)) {
            const pickFn = memoize ? memoizedPick : pickComponents;
            return pickFn(vanityUrl, components, componentKeys);
        }
        return components || EMPTY_OBJECT;
    });

    return pageComponents;
}
