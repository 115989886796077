const parseCode = `self.addEventListener('message', (e) => {
    const { payload } = e.data;
    self.postMessage(JSON.parse(payload));
  }, false);`

const getWorker = () => {
  let blob;
  try {
      blob = new Blob([parseCode], {type: 'application/javascript'});
  } catch (e) { 
      window.BlobBuilder = window.BlobBuilder || window.WebKitBlobBuilder || window.MozBlobBuilder;
      blob = new BlobBuilder();
      blob.append(parseCode);
      blob = blob.getBlob();
  }
  return new Worker(URL.createObjectURL(blob, {type: 'application/javascript'}));
}

export default getWorker;