import { useSelector } from 'react-redux';

/**
 * Returns the redux state for user data
 * @name useUserData
 * @returns {userData}
 */
export default function useUserData() {
    const userData = useSelector(state => state.userData);
    return {
        userData,
    };
}

export const useIsPrivateStore = () => {
    const { userData } = useUserData();
    return getPStoreID(userData) !== undefined;
};

/**
 * Attempts to pick the private store id from the userData in redux
 * @param {*} userData - user data from redux
 * @returns
 */
export const getPStoreID = userData => {
    const { profileData } = userData || {};
    const { pstoreId, personData } = profileData || {};
    const { pStoreID } = personData || {};
    return pstoreId || pStoreID;
};

export const isCallCenter = userData => {
    const { profileData } = userData || {};
    return profileData && profileData.isccf;
};

export const useUserReceived = () => {
    const userReceived = useSelector(
        state => state && state.userData && (state.userData.updatedFromService || state.userData.userFetchFailed),
    );
    return userReceived;
};
