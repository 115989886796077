import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

function useHandleClickOutside(ref, callback) {

    useEffect(() => {
        function clickEventHandler(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback && callback();
            } 
        }
        // Bind the event listener
        document.addEventListener("mousedown", clickEventHandler);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", clickEventHandler);
        };
    }, [ref]);
}

function HandleClickOutside({callback, children}){ 
    const wrapperRef = useRef(null);
    useHandleClickOutside(wrapperRef, callback); 
    return <div ref={wrapperRef}>{children}</div>; 
}

HandleClickOutside.propTypes = {
    children: PropTypes.element.isRequired
};

export default HandleClickOutside;