export const ENABLE_TEST_FLAGS = 'ENABLE_TEST_FLAGS';
export const DISABLE_TEST_FLAGS = 'DISABLE_TEST_FLAGS';
export const TOGGLE_PREFERENCE = 'TOGGLE_PREFERENCE';

export const toggleAbTests = dispatch => enabled => (
    dispatch({ type: enabled ? ENABLE_TEST_FLAGS : DISABLE_TEST_FLAGS })
);

export const togglePreference = (groupNumber, enabled) => ({
    type: TOGGLE_PREFERENCE,
    payload: {
        groupNumber,
        enabled
    }
})