import { SET_LOADER } from './full-page-loader-actions';
import { LOCATION_CHANGE } from 'connected-react-router';

const NULL_STATE = {
    loading: false,
};

const LoaderReducer = (state = NULL_STATE, action) => {
    Object.freeze(state);
    switch (action.type) {
        case SET_LOADER:
            return Object.assign({}, state, action.loader);
        case LOCATION_CHANGE:
            return NULL_STATE;
        default:
            return state;
    }
};

export default LoaderReducer;
