import React from 'react';
import loadable from '@loadable/component';

import CompareDrawer from '../compare/components/compare-drawer';
import withPage, { loadGraphQLData } from '../page';
import { Helpers } from '../core/src/helpers';

const HomePage = loadable(() => Helpers.retryFunc(() => import('./components')));

const HomeContainer = ({ templateKey, vanityUrl }) => {
    return (
        <>
            <HomePage templateKey={templateKey} vanityUrl={vanityUrl} />
            <CompareDrawer />
        </>
    );
};

export default {
    component: withPage(HomeContainer, {
        wrapperClassName: 'home-root',
        showWordCloud: true,
        useGraphQL: true,
        useV2: true,
        noProps: true,
    }),
    loadData: loadGraphQLData,
};
