import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Modal from '../../shared/components/modal';
import ImageWithPlaceholder from '../../shared/components/image-with-placeholder';
import { withError } from '../../shared/components/error-boundary';
import Link from '../../shared/components/hyperlink';
import { Helpers } from '../../core/src/helpers';
import AccessibleElement from '../../ui/components/accessible-element';
import CustomerCareNumber from './customer-care-number';
import SoftSignIn from './soft-sign-in';
import { getGlobalNavGtm } from '../../metrics/metrics-helpers';

import '../css/utility-nav.less';

const EMPTY_OBJ = {};
const STATIC_LABELS = {
    lblLoyaltyAdmin: 'Loyalty Admin Tool',
};

const isUserLoggedIn = personData => {
    const { userTier, firstname } = personData || {};
    return userTier && firstname;
};

const trimEmail = email => {
    if (!email) return '';
    if (email.length > 16) return email.substring(0, 16) + '...';
    return email;
};

class UtilityNav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showMenu: false,
            discountMenuOpen: false,
            modalOpen: false,
            setIframe: false,
            isActiveSession: null,
            callCenter: {
                manageLock: false,
                message: '',
                displayMsg: false,
            },
            openHelpMenu: false,
        };
        this.toggleHelpMenu = this.toggleHelpMenu.bind(this);
        this.openMenu = this.openMenu.bind(this);
        this.openDiscountMenu = this.openDiscountMenu.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.helpModalContent = this.helpModalContent.bind(this);
        this.manageLock = this.manageLock.bind(this);
        this.onSessionStateChange = this.onSessionStateChange.bind(this);
    }

    componentDidMount() {
        //only fetch if not already updated from service
        const { updatedFromService } = this.props;
        if (!updatedFromService) {
            this.props.fetchUtility();
        }
    }

    componentDidUpdate() {
        const { profileData, history } = this.props;
        const { location } = this.props;
        const p = profileData || EMPTY_OBJ;
        const pStoreID = (p.personData || EMPTY_OBJ).pStoreID;
        //update the current url with the pStoreID if it's not already present
        if (pStoreID && location.search.indexOf('pStoreID') < 0) {
            const searchObj = Helpers.getSearch(location.search);
            searchObj.pStoreID = pStoreID;
            let isSearch;
            try {
                isSearch = new RegExp(`${process.env.BASENAME}/sitesearch`, 'i').test(window.location.pathname);
            } catch (e) {}
            history.replace({
                ...location,
                search: `?${Helpers.paramsToString(searchObj, undefined, undefined, isSearch)}`,
            });
        }
    }

    static getDerivedStateFromProps(nextProps, state) {
        if (nextProps.callCenter && nextProps.callCenter.orderLock && state.callCenter.manageLock) {
            return {
                callCenter: Object.assign(state.callCenter, {
                    manageLock: false,
                    modalOpen: true,
                    message: nextProps.callCenter.orderLock.message,
                }),
            };
        }
        return null;
    }

    toggleHelpMenu() {
        this.setState({ openHelpMenu: !this.state.openHelpMenu });
    }

    openMenu(e) {
        e.preventDefault();
        const { showMenu } = this.state;
        if ((window.innerWidth || window.screen.availWidth) <= 800) {
            this.setState({ showMenu: !showMenu, modalOpen: true, discountMenuOpen: false });
        } else {
            this.setState({ showMenu: !showMenu, discountMenuOpen: false });
        }
    }

    openDiscountMenu(e) {
        e.preventDefault();
        const { discountMenuOpen } = this.state;
        this.setState({ discountMenuOpen: !discountMenuOpen, showMenu: false });
    }

    openModal(loadIframe) {
        //iframe will only be set the first time the modal open
        this.setState({ modalOpen: true, showMenu: false, setIframe: this.state.setIframe || loadIframe });
    }

    onSessionStateChange(isActiveSession) {
        this.setState({ isActiveSession });
    }

    closeModal() {
        this.setState({
            modalOpen: false,
            discountMenuOpen: false,
            showMenu: false,
            callCenter: Object.assign(this.state.callCenter, {
                modalOpen: false,
                message: '',
            }),
        });
    }

    getCustomerCareNumber(label) {
        try {
            let text = Helpers.decodeHtml(label);
            let tempDiv = document.createElement('div');
            tempDiv.innerHTML = text;
            let [, number = ''] = tempDiv.textContent.split('Sales:');
            return number.trim().match(/[\d|-]+/gi)[0];
        } catch (e) {
            return '1-866-625-3906';
        }
    }

    createInput(name, value) {
        let input = document.createElement('input');
        input.type = 'hidden';
        input.name = name;
        input.value = value;
        return input;
    }

    manageLock(action) {
        this.setState({
            callCenter: Object.assign(this.state.callCenter, {
                manageLock: true,
                modalOpen: true,
            }),
        });
        this.props.manageOrderLock(action);
    }

    dropDownItems(forMobile, sectionTitle, analyticsTitle) {
        let p = this.props.profileData;
        const isGS = p.personData.custSeg === 'CG_gs';
        const { isReferAFriendAllowed } = p.personData || {};
        let pStoreID = (p.personData || EMPTY_OBJ).pStoreID;
        let withRegisteredPrinter = p['personData']['printersData'];
        let classes =
            'lastBox topmenuchild' +
            (this.state.showMenu ? ' showMenu' : '') +
            (forMobile ? '' : ' hide-mobile') +
            (withRegisteredPrinter ? ' with-registeredPrinter' : '');
        let loggedInItems = [
            ['logoutUrl', 'lblLogout'],
            ['myAccountUrl', 'lblMyAccount'],
            ['orderHistoryUrl', 'lblMyOrders'],
            ['manageMyDeviceCCUrl', 'lblMyDevices'],
            ['myFinancingLink', 'myFinancing'],
            isGS ? ['hpRewardsUrl', 'hpRewards'] : [],
            ['mySubscriptionsFormURL', 'lblMySubscriptions'],
            withRegisteredPrinter ? ['personData', 'printersData'] : [],
            ['newsletterSubsribeUrl', 'lblNewsReg'],
            isReferAFriendAllowed ? ['myAccountUrl', 'referUtilityMsg'] : [],
        ];
        let loggedOutItems = [
            ['logonUrl', 'lblSignin'],
            ['registerUrl', 'lblRegister'],
            ['orderStatusUrl', 'lblMyOrders'],
            ['manageMyDeviceUrl', 'lblMyDevices'],
            ['myFinancingLink', 'myFinancing'],
            ['hpRewardsUrl', 'hpRewards'],
            ['mySubscriptionsFormURL', 'lblMySubscriptions'],
            ['newsletterSubsribeUrl', 'lblNewsReg'],
        ];
        let itemList = isUserLoggedIn(p.personData) ? loggedInItems : loggedOutItems;
        return (
            <ul className={classes} onMouseLeave={e => this.closeModal()}>
                {itemList.map((item, idx) => {
                    const [valKey, lblKey] = item;
                    const lnkLabel = p.labels[lblKey] || p.personData[lblKey];
                    let linkUrl = Helpers.decodeHtml(p[valKey]);
                    if (pStoreID) {
                        linkUrl = Helpers.addUrlParam(linkUrl, { pStoreID });
                    }
                    if (item[0] === 'personData' && p[item[0]][item[1]]) {
                        let printerList = p[item[0]][item[1]];
                        return (
                            <li className="savedPrinterList" key={idx}>
                                <div>Buy ink or toner for my latest printer(s)</div>
                                <ul>
                                    {printerList.map((printer, i) => (
                                        <li key={i}>
                                            <a
                                                href={Helpers.decodeHtml(printer['printerUrl'])}
                                                aria-label={printer['printerName']}
                                            >
                                                <img src={Helpers.decodeHtml(printer['printerImg'])} />
                                                <span>{printer['printerName']}</span>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        );
                    } else if (lnkLabel && linkUrl) {
                        return (
                            <li key={`${idx}-${linkUrl}`}>
                                <a aria-label={`${lnkLabel}`} href={linkUrl} {...sectionTitle || analyticsTitle ? getGlobalNavGtm(analyticsTitle || sectionTitle, lnkLabel, 2) : {}}>
                                    {lnkLabel}
                                </a>
                            </li>
                        );
                    }
                })}
            </ul>
        );
    }

    helpModalContent() {
        const p = this.props.profileData || EMPTY_OBJ;
        const pStoreID = (p.personData || EMPTY_OBJ).pStoreID;
        const pStoreQueryParam = pStoreID ? `&pStoreID=${pStoreID}` : '';
        //set iframe on first open
        return (
            <div>
                <ul className="help-menu hide-desktop">
                    <li>
                        <a
                            href={`${process.env.BASENAME}/ContentView?eSpotName=CustomerService&catalogId=10051&langId=-1&storeId=10151${pStoreQueryParam}`}
                        >
                            {p.labels.cutomerService}
                        </a>
                    </li>
                    <li className="user-phone">
                        <CustomerCareNumber profileData={p} />
                    </li>
                    <li>
                        <a
                            href={`${process.env.BASENAME}/MyAccountOrderStatusView?catalogId=10051&langId=-1&storeId=10151${pStoreQueryParam}`}
                            data-gtm-category='globalNavigation'
                        >
                            Order Status
                        </a>
                    </li>
                </ul>

                <div className="live-help-overlay hide-mobile">
                    <iframe
                        className="live-help"
                        src={
                            this.state.setIframe
                                ? `${process.env.BASENAME}/LiveHelpView?storeId=10151&langId=-1&catalogId=10051&pagelevel=Home%20Page${pStoreQueryParam}`
                                : ''
                        }
                    ></iframe>
                </div>
            </div>
        );
    }

    renderDropDown(psn) {
        let p = this.props.profileData || EMPTY_OBJ;
        const { personData } = p || {};
        const { trimmedEmail, email } = personData || {};
        const displayName = trimmedEmail || trimEmail(email);
        const { isMobile } = this.props;
        let loggedIn = isUserLoggedIn(p.personData);
        let dropDownMenuTitle = loggedIn ? displayName : p.labels.lblSigninRegister;
        let mobileMenuTitle = loggedIn ? displayName : p.labels.lblMyAccount;
        let { showMenu } = this.state;
        const signInData = loggedIn
            ? {
                  useremail: p.personData.email || p.personData.firstname,
                  ctaProps: { link: Helpers.decodeHtml(p.logonUrl), label: p.labels.lblSignin },
              }
            : null;
        return (
            <li className="lastItemInList hasdropdown mobile-menu-item">
                <AccessibleElement
                    element="a"
                    ariaLabel="Categories Menu"
                    aria-expanded={showMenu}
                    className="mainLink downDisclosure arrow-down hp-icon page-down"
                    onClick={this.openMenu}
                >
                    <span aria-label={dropDownMenuTitle} className="hide-mobile">
                        {dropDownMenuTitle}
                    </span>
                    <span className="hide-desktop">{mobileMenuTitle}</span>
                    <i></i>
                </AccessibleElement>
                {showMenu && (
                    <SoftSignIn
                        signInData={signInData}
                        loggedIn={loggedIn}
                        onClose={this.closeModal}
                        onSessionStateChange={this.onSessionStateChange}
                    >
                        {this.dropDownItems(null, isMobile ? mobileMenuTitle : dropDownMenuTitle, loggedIn ? 'customer' : null)}
                    </SoftSignIn>
                )}
            </li>
        );
    }

    renderDiscountDropDown({ title, sublink }) {
        let p = this.props.profileData || EMPTY_OBJ;
        let loggedIn = isUserLoggedIn(p.personData);
        const { discountMenuOpen } = this.state;
        let classes = 'menu topmenuchild' + (this.state.discountMenuOpen ? '' : ' hide');
        if (loggedIn) {
            return null;
        }
        return (
            <li className="hasdropdown hide-sm utilDropdown">
                <AccessibleElement
                    element="a"
                    ariaLabel="Categories Menu"
                    aria-expanded={discountMenuOpen}
                    className="mainLink downDisclosure arrow-down hp-icon page-down"
                    onClick={this.openDiscountMenu}
                >
                    <span aria-label={title} className="hide-mobile">
                        {title}
                    </span>
                    <i></i>
                </AccessibleElement>
                <div className={classes}>
                    <ul onMouseLeave={e => this.closeModal()}>
                        <span className="message">JOIN NOW FOR IMMEDIATE SAVINGS AND SPECIAL OFFERS</span>
                        {sublink?.map(({ link, label }) => {
                            let labelText; 
                            try{
                                labelText = Helpers.decodeHtml(label);
                            }catch(e){}
                            return labelText && (
                                <li key={label}>
                                    <a href={link} {...getGlobalNavGtm(title, labelText, 2)}>{labelText}</a>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </li>
        );
    }

    renderNav(renderLeft) {
        return (
            <section className="topmenu">
                {renderLeft && this.renderLeftNav()}
                {this.renderRightNav()}
            </section>
        );
    }

    renderRightNav() {
        const { modalOpen } = this.state;
        const p = this.props.profileData || EMPTY_OBJ;
        let loggedIn = isUserLoggedIn(p.personData);
        const isGS = !loggedIn || p.personData?.custSeg === 'CG_gs';
        const pStoreID = (p.personData || EMPTY_OBJ).pStoreID;
        const pStoreQueryParam = pStoreID ? `&pStoreID=${pStoreID}` : '';
        let psn = p.psn ? (
            <li className="psn hide-mobile">
                <ImageWithPlaceholder src={p.personData.eppCompanyLogoUrl} />
                <div>{p.psn}</div>
            </li>
        ) : null;
        const { memberdisUtilNav } = p;
        const callOutLabel = p.labels.hpFinancing || p.labels.hpRewards;
        const callOutLink = Helpers.decodeHtml(p.hpFinancingLink || (isGS ? p.hpRewardsUrl : ''));
        const orderStatusTitle = 'Order Status';
        return (
            <ul className="right">
                {psn}
                <li tabIndex={0} className="hide-mobile">
                    <CustomerCareNumber profileData={p} />
                </li>
                <li className={`hide-mobile hide-md`}>
                    <a
                        className={`${p.personData.custSeg === 'CG_gs' ? 'orderStatusHide' : ''}`}
                        aria-label="Order Status"
                        href={`${process.env.BASENAME}/MyAccountOrderStatusView?catalogId=10051&langId=-1&storeId=10151${pStoreQueryParam}`}
                        {...getGlobalNavGtm(orderStatusTitle, orderStatusTitle, 1)}
                    >
                        {orderStatusTitle}
                    </a>
                </li>
                {/* HPEDT-11181: removed. commenting just in case they change their mind
                <li className="hide-mobile">
                    <a
                        href={`${process.env.BASENAME}/LiveHelpView?catalogId=10051&langId=-1&storeId=10151${pStoreQueryParam}`}
                        className="mainLink js_overlay_trigger"
                        aria-label={p.labels.lblNeedHelp || 'Need help?'}
                    >
                        {p.labels.lblNeedHelp || 'Need help?'}
                    </a>
                </li>*/}
                <li className="hide-mobile">
                    <a
                        aria-label={p.labels.cutomerService}
                        href={`${process.env.BASENAME}/ContentView?eSpotName=CustomerService&catalogId=10051&langId=-1&storeId=10151${pStoreQueryParam}`}
                        {...getGlobalNavGtm(p.labels.cutomerService, p.labels.cutomerService, 1)}
                    >
                        {p.labels.cutomerService}
                    </a>
                </li>
                {memberdisUtilNav && this.renderDiscountDropDown(memberdisUtilNav)}
                {callOutLink && callOutLabel && (
                    <li className="financeLink">
                        <a aria-label={callOutLabel} href={callOutLink} {...getGlobalNavGtm(callOutLabel, callOutLabel, 1)}>
                            {callOutLabel}
                        </a>
                    </li>
                )}
                {this.renderDropDown(psn)}
                <li className="hide-desktop mobile-menu-item" onClick={this.toggleHelpMenu} aria-expanded={modalOpen}>
                    <a className="helpLink hp-icon page-down">
                        {p.labels.lblNeedHelp || 'Need help?'}
                        <i></i>
                    </a>
                </li>
            </ul>
        );
    }

    renderLeftNav() {
        const { isV2 } = this.props;
        const p = this.props.profileData || EMPTY_OBJ;
        const pStoreID = (p.personData || EMPTY_OBJ).pStoreID;
        const pStoreQueryParam = pStoreID ? `?pStoreID=${pStoreID}` : '';
        return (
            !isV2 && (
                <ul className="left-nav">
                    <li className="store-link">
                        <a aria-label="HP Store" href={`${process.env.BASENAME}${pStoreQueryParam}`}>
                            HP Store
                        </a>
                    </li>
                </ul>
            )
        );
    }

    renderPunchOut() {
        const { profileData } = this.props;
        const { labels = {}, personData = {}, hasLAdminRole, ispaccf } = profileData || {};
        const loggedInMenu = [
            ['lblCCDevicesList', 'manageMyDeviceCCUrl'],
            ['lblMySubscriptions', 'mySubscriptionsFormURL'],
            hasLAdminRole ? ['lblLoyaltyAdmin', 'hpLoyaltyAdminUrl'] : [],
            ['hpRewards', 'hpRewardsUrl'],
        ];
        let storeName = personData.storeName || personData.hpEmpStore;
        return (
            <div>
                <section className="topmenu punchout">
                    <ul className="right">
                        <li>
                            <span>
                                Welcome, &nbsp; <a>{profileData.personData.agentLogonId}</a>
                            </span>
                        </li>
                        <li>
                            <a onClick={e => this.manageLock('A')}>{labels.lblAcquireLock || 'Acquire Lock'}</a>
                        </li>
                        <li>
                            <a onClick={e => this.manageLock('R')}>{labels.lblReleaseLock || 'Release Lock'}</a>
                        </li>

                        {personData.customerLogonId || ispaccf ? null : (
                            <li>
                                <a href={Helpers.decodeHtml(profileData.ccCreateUserUrl)}>
                                    {labels.lblCreateUser || 'Create User'}
                                </a>
                            </li>
                        )}
                        {personData.customerLogonId ? (
                            <li>
                                <a href={Helpers.decodeHtml(profileData.ccEndCurrentCustomerSessionUrl)}>
                                    End Current Customer
                                </a>
                            </li>
                        ) : !ispaccf ? (
                            <li>
                                <a href={Helpers.decodeHtml(profileData.ccSearchUserUrl)}>
                                    {labels.lblSearchUser || 'Search User'}
                                </a>
                            </li>
                        ) : null}
                        {personData.customerLogonId ? (
                            loggedInMenu.map(([lblID, linkID]) => {
                                // show rewards on punchout only for non private store HPEDT-11505
                                if (lblID === 'hpRewards' && personData.custSeg !== 'CG_gs') {
                                    return null;
                                }

                                const link = profileData[linkID];
                                const label = labels[lblID] || STATIC_LABELS[lblID];
                                if (!link || !label) {
                                    return null;
                                }
                                return (
                                    <li key={link}>
                                        <a href={Helpers.decodeHtml(link)}>{label}</a>
                                    </li>
                                );
                            })
                        ) : (
                            <li>
                                <a href={Helpers.decodeHtml(profileData.smbPrivateAccountUrl)}>
                                    {labels.lblManageAccounts || 'Manage Accounts'}
                                </a>
                            </li>
                        )}
                        <li>
                            <a href={Helpers.decodeHtml(profileData.ccEndCallUrl)}>
                                {labels.lblClosePunchout || 'Close Punchout'}
                            </a>
                        </li>
                    </ul>
                </section>
                <section className="topmenu punchout">
                    <ul className="right">
                        {storeName ? (
                            <li>
                                <strong>{storeName}</strong>
                            </li>
                        ) : null}
                        <h3>
                            <li>
                                Customer: &nbsp;
                                <a>
                                    {personData.firstname}&nbsp;{personData.lastname}&nbsp; (
                                    {personData.customerLogonId})
                                </a>
                            </li>
                        </h3>
                    </ul>
                </section>
            </div>
        );
    }

    render() {
        const { isV2, location, isMobile, updatedFromService } = this.props;
        const { isActiveSession, showMenu, modalOpen, openHelpMenu } = this.state;
        let p = this.props.profileData || EMPTY_OBJ;
        const matches = location?.pathname.match(/\/us-en\/shop\/?/) || [];
        const isHome = location?.pathname && matches[0] === location?.pathname;
        const className = `utilityNav${p.isccf ? ' punchout' : ''}${isV2 ? ' v2' : ''}`;
        if (!updatedFromService) return <div className={className}></div>;

        return (
            <div className={className} role="navigation" aria-label="HP Website Menu">
                <div className="login-container">
                    {!isHome && isMobile && (
                        <Link className="m_back hp-icon page-left" to={process.env.BASENAME}>
                            HP Store
                        </Link>
                    )}
                    {p.isccf ? this.renderPunchOut() : this.renderNav(!isHome)}
                </div>
                <Modal
                    modalContent={this.dropDownItems(true)}
                    isOpen={modalOpen && (!isUserLoggedIn(p.personData) || isActiveSession)}
                    closeModal={this.closeModal}
                />
                <Modal
                    modalContent={openHelpMenu && this.helpModalContent()}
                    isOpen={openHelpMenu}
                    closeModal={this.toggleHelpMenu}
                />
                <Modal
                    modalContent={this.state.callCenter.message}
                    isOpen={this.state.callCenter.modalOpen}
                    closeModal={this.closeModal}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({ isMobile: state && state.ui && state.ui.width && state.ui.width <= 800 });

export default connect(mapStateToProps)(withError(withRouter(UtilityNav)));
