import React from 'react';
import loadable from '@loadable/component';
import { Helpers } from '../core';
import { dynamicLoadData } from '../page/page-actions';

const SRPContainer = loadable(() => Helpers.retryFunc(() => import('./srp-container')));

export const getSlugInfo = () => ({
    templateKey: 'search',
    analyticsData: {
        page_level: 'search',
        lifecycle: 'presales.selection',
        simple_title: 'search',
        family: 'shared',
        product_type: 'shared',
        derivedAnalyticsData: {
            analyticsMapping: 'sitesearch',
            asyncImpressionComponents: [
                {
                    name: 'searchProducts',
                    type: 'product',
                },
            ],
        },
    },
    vanityUrl: 'sitesearch',
    seo: {
        breadcrumbs: [
            {
                label: 'HOME',
                href: process.env.BASENAME,
                gtmValue: 'click',
            },
            {
                //final crumb should always exclude href and not be clickable
                label: 'SEARCH',
                gtmValue: 'click',
            },
        ],
        title: 'Search | HP® Official Store',
    },
});

const loadData = async (urlParams, store, context) => {
    try {
        const urlWithQueryString =
            context.originalUrl &&
            context.originalUrl
                .split(new RegExp('^' + process.env.BASENAME + '/', 'i'))
                .pop()
                .replace(/\?/, '%2F');
        await dynamicLoadData(urlWithQueryString, store, context, { hash: 'ssr' });

        const { redirects } = store.getState();
        const { destination, status } = redirects;
        if (destination && status) return Promise.resolve({ status, destination, excludeRedirectQueryString: true });
    } catch (e) {}
};

const SRP = props => {
    return <SRPContainer {...props} />;
};

export default {
    component: SRP,
    loadData,
};
