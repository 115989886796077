export const MOXIE_HOST = process.env.MOXIE_HOST;

/**
 * Performs mpulse tracking on SPA route changes
 * @module MoxieConciergeAPI
 * @class
 * @internal
 */
class MoxieConciergeAPI {
    constructor(options = {}) {
        this.name = 'MOXIECONCIERGE tag';
        this.optOut = false;

        // set-up global configs
        window.MoxieData = window.MoxieData || {
            servicelevel: 'GOLD',
            store: 'USSTORE',
        };

        window.conciergeReady =
            window.conciergeReady ||
            new Promise((resolve, reject) => {
                const listener = event => {
                    // Remove both listeners as only one will fire
                    window.removeEventListener('GoMoxie:conciergeReady', listener);
                    window.removeEventListener('GoMoxie:conciergeFailedToLoad', listener);
                    if (event.type === 'GoMoxie:conciergeReady') {
                        resolve();
                    } else {
                        reject(new Error(event.type));
                    }
                };
                window.addEventListener('GoMoxie:conciergeReady', listener);
                window.addEventListener('GoMoxie:conciergeFailedToLoad', listener);
            })
                .then(() => {
                    console.log('Customer Concierge listener is ready');
                })
                .catch(function (e) {
                    console.error('Customer Concierge listener Error:', e);
                });

        window.conciergeReady.then(() => {
            window.startMoxieEngagement = rule => {
                var widget = 'chat';
                var hostUrl = MOXIE_HOST || 'https://h30665.www3.hp.com';
                try {
                    window.GoMoxie &&
                        window.GoMoxie.conciergeV2 &&
                        window.GoMoxie.conciergeV2.startEngagement({
                            ruleName: rule,
                            widget: widget,
                            portalId: '',
                            host: hostUrl,
                        });
                } catch (e) {
                    console.log('error in startMoxieEngagement', e);
                }
            };
        });
    }

    /**
     *
     * @method pageView
     * @param {String} eventName
     * @param {Object} params
     * @returns {Promise}
     * @internal
     */

    pageView(...args) {
        return this.track(...args);
    }

    /**
     *
     * @method track
     * @param {String} eventName
     * @param {Object} params
     * @returns {Promise}
     * @internal
     */
    track(eventName, params = {}) {
        if (params.event === 'e_pageView') {
            if (params.pageBusinessUnit === 'consumer') {
                window.MoxieData.isSMB = 'No';
            } else if (params.pageBusinessUnit === 'business') {
                window.MoxieData.isSMB = 'Yes';
            }
            window.MoxieData.IsPunchout = params.callCenter === 'wcs call center';
        }
    }
}

export default MoxieConciergeAPI;
