import merge from 'lodash/merge';
import { RECEIVE_MODEL_PRICES, RECEIVE_MODEL_PRICE_ERROR, RECEIVE_MODEL_PRICE_FETCH } from './model-actions';

export const parsePrices = prices => {
    return Object.keys(prices).reduce((acc, key) => {
        acc[key] = { ...prices[key], priceFetchFailed: false };
        return acc;
    }, {});
};

const PriceReducer = (state = {}, action) => {
    Object.freeze(state);
    switch (action.type) {
        case RECEIVE_MODEL_PRICES:
            return merge({}, state, parsePrices(action.prices));
        case RECEIVE_MODEL_PRICE_ERROR:
            // generate state update object
            const update = action.failedModelIds.reduce((acc, id) => {
                acc[id] = { priceFetchFailed: true };
                return acc;
            }, {});

            return merge({}, state, update);
        case RECEIVE_MODEL_PRICE_FETCH:
            /*Try to minimize extra price calls by set default values for inflight price calls */
            const fetching = action.modelIds.reduce((acc, id) => {
                acc[id] = { priceFetchFailed: true };
                return acc;
            }, {});
            return merge({}, state, fetching);
        default:
            return state;
    }
};

export default PriceReducer;
