import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';

import { updateCart, launchCartFlyout, closeCartFlyout } from '../../utility-actions';
import { showPopover } from '../../../page/components/header-footer/header-footer-actions';
import { Helpers } from '../../../core/src/helpers';
import ScrollEvent from '../../../shared/components/scroll-event';
import { withError } from '../../../shared/components/error-boundary';
import withWindowResize from '../../../ui/components/window-resize';
import AboveTheFoldOnlyServerRender from '../../../shared/components/above-the-fold-only-server-render';
import StickyCart from './sticky-cart';

import '../../css/cart-preview.less';
import '../../css/new-cart-preview.less';
import '../../css/added-to-cart-flyout.less';
import { Typography } from '@hpstellar/core';

const CartFlyOut = loadable(() => Helpers.retryFunc(() => import('./cart-flyout')));
const OldCartContent = loadable(() => Helpers.retryFunc(() => import('./cart-preview-content')));

const MAX_PRODUCT_LIST_ITEMS = 3;
const MAX_CART_ICON_ITEMS = 3;
const POPOVER_KEY = 'cartPreviewClassic';

const EmptyCart = ({ labels }) => {
    return (
        <Fragment>
            <div className="minicart_contents-empty minicart_contents">
                <Typography variant="titleM" tag="h3">
                    {labels.emptyCart}
                </Typography>
            </div>
        </Fragment>
    );
};

/**
 * Lazyload wrapper for cart content
 * @param {*} props
 * @returns
 */
const CartContentContainer = props => {
    return (
        <AboveTheFoldOnlyServerRender skip={true} placeholder={<div style={{ minHeight: '300px' }}></div>}>
            <OldCartContent {...props} />
        </AboveTheFoldOnlyServerRender>
    );
};

class CartPreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            recentlyAdded: [],
            previewSet: false,
            stickyCart: false,
            changingQty: false,
        };

        this.hoverTO;
        this.cartBtn;
        this.cartUrl;
        this.iCount;
        this.handleQtyChange = this.handleQtyChange.bind(this);
        this.handleHover = this.handleHover.bind(this);
        this.startCartTimeout = this.startCartTimeout.bind(this);
        this.hoverOff = this.hoverOff.bind(this);
    }

    componentWillUnmount() {
        clearTimeout(this.cartTimeout);
    }

    componentDidUpdate(prevProps) {
        let newItems = this.props.cartInfo.items;
        let oldItems = prevProps.cartInfo.items;

        if (prevProps.cartInfo.tQty != this.props.cartInfo.tQty) {
            this.updateCartIcon(this.props);
        }

        if (!newItems || !oldItems) {
            return;
        }

        if (newItems.length > oldItems.length) {
            const { addToCartResponse } = this.props.cartInfo.recentlyAdded || {};
            const { newOrderItemId_0, orderItemId = [] } = addToCartResponse || {};
            const oItemId = newOrderItemId_0 || orderItemId[0];
            this.setState({
                recentlyAdded: [newItems.filter(p => p.id === oItemId)[0]],
                stickyCart: true,
            });
            this.startCartTimeout();
        } else {
            for (let i = 0; i < Math.min(newItems.length, oldItems.length); i++) {
                if (newItems[i].amt.fg) continue;
                if (newItems[i].qty !== oldItems[i].qty) {
                    this.setState({
                        recentlyAdded: [newItems[i]],
                        stickyCart: true,
                    });
                    this.startCartTimeout();
                    break;
                }
            }
        }
    }

    startCartTimeout() {
        if (this.cartTimeout) clearTimeout(this.cartTimeout);
        this.cartTimeout = setTimeout(() => this.setState({ stickyCart: false }), 3000);
    }

    updateCartIcon(nextProps) {
        const currQty = nextProps.cartInfo.tQty;
        const displayPlus = currQty > MAX_CART_ICON_ITEMS;

        if (!this.iCount) {
            return;
        }

        if (currQty > 0) {
            this.iCount.classList.add('hf_cart_not_empty');
            this.iCount.innerText = Math.min(currQty, MAX_CART_ICON_ITEMS);
            this.iCount.innerText += displayPlus ? '+' : '';
        } else {
            this.iCount.classList.remove('hf_cart_not_empty');
            this.iCount.innerText = ' ';
        }
    }

    handleQtyChange = (qty, cartItem) => {
        this.setState({ changingQty: true });
        let _cItem = Object.assign({}, cartItem);

        _cItem.amt.nAmt = Helpers.convertPriceToNumber(_cItem.amt.nAmt);
        _cItem.amt.iPrice = Helpers.convertPriceToNumber(_cItem.amt.iPrice);
        _cItem.amt.uPrice = Helpers.convertPriceToNumber(_cItem.amt.uPrice);

        this.props.updateCart(_cItem, qty).then(() => {
            this.setState({ changingQty: false });
        });
    };

    handleHover() {
        const { launchPopover, localState } = this.props;
        this.hoverTO = setTimeout(this.setState({ previewSet: true }), 50);
        if (!localState) {
            launchPopover();
        }
    }

    handleCartBtnClick = () => {
        const { launchPopover, localState } = this.props;
        const { crtUrl, iCount } = this.props.cartInfo;
        if (iCount > 0) {
            window.location = Helpers.decodeHtml(crtUrl);
        } else if (!localState) {
            launchPopover();
        }
    };

    hoverOff() {
        const { closePopover } = this.props;
        closePopover();
        this.setState({ previewSet: false });
        clearTimeout(this.hoverTO);
    }

    closeClick = e => {
        const { closePopover } = this.props;
        e.preventDefault();
        this.setState({ previewSet: false });
        closePopover();
    };

    stickyMobileCart() {
        let cartQty = this.props.cartInfo.tQty;
        const cartUrl = Helpers.decodeHtml(this.props.cartInfo.crtUrl);
        if (!cartQty) cartQty = 0;
        else if (cartQty > MAX_CART_ICON_ITEMS) cartQty = MAX_CART_ICON_ITEMS + '+';
        if (!this.props.stickyCart) {
            return null;
        } else {
            return (
                <ScrollEvent enableDelta="continuous">
                    {(attachRef, scrollState) => {
                        const show =
                            scrollState.isSticky &&
                            this.props.cartInfo.tQty > 0 &&
                            (this.state.stickyCart || scrollState.delta < 0);
                        return <StickyCart show={show} cartUrl={cartUrl} qty={cartQty} ref={attachRef} />;
                    }}
                </ScrollEvent>
            );
        }
    }

    render() {
        const {
            closeCartFlyout,
            cartInfo,
            updateCart,
            pageType,
            device,
            isNewVersion,
            children,
            showPreview,
            localState,
            enableCartFlyout = true,
        } = this.props;
        const { recentlyAdded, changingQty, previewSet } = this.state;
        const { addToCartFlyoutVisible, tQty } = cartInfo;
        const isMobile = device === 'mobile';
        let displayCount = tQty > MAX_CART_ICON_ITEMS ? `${MAX_CART_ICON_ITEMS}+` : tQty;
        const displayCartPreview = showPreview || (localState && previewSet);
        if (cartInfo.labels) {
            return (
                <Fragment>
                    {children &&
                        children({
                            onHover: this.handleHover,
                            onClick: this.handleCartBtnClick,
                            count: tQty > 0 ? displayCount : '',
                        })}
                    {enableCartFlyout && (
                        <AboveTheFoldOnlyServerRender skip={true}>
                            <CartFlyOut
                                cartInfo={cartInfo}
                                recentlyAddedCartItem={recentlyAdded}
                                addToCartFlyoutVisible={addToCartFlyoutVisible}
                                isNewCartPreview={isNewVersion}
                                closeCartFlyout={closeCartFlyout}
                                updateCart={updateCart}
                                pageType={pageType}
                                showPreview={showPreview}
                            />
                        </AboveTheFoldOnlyServerRender>
                    )}
                    {isMobile && this.stickyMobileCart()}
                    <div style={{ position: 'absolute' }}>
                        {displayCartPreview && !isMobile && cartInfo.iCount !== undefined && (
                            <div
                                className={`minicart-container preview${displayCartPreview ? ' visible' : ''}${
                                    cartInfo.iCount === 0 ? ' empty-cart' : ''
                                }`}
                                onMouseLeave={this.hoverOff}
                            >
                                <div className="top_corner">&nbsp;</div>
                                <div className="shopping_container">
                                    <a href="" className="hf_close_btn" onClick={this.closeClick}></a>
                                    {cartInfo.iCount === 0 ? (
                                        <EmptyCart labels={cartInfo.labels} />
                                    ) : (
                                        <CartContentContainer
                                            cartInfo={cartInfo}
                                            showPreview={displayCartPreview}
                                            updateCart={updateCart}
                                            handleQtyChange={this.handleQtyChange}
                                            changingQty={changingQty}
                                            maxCartItems={MAX_PRODUCT_LIST_ITEMS}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </Fragment>
            );
        } else {
            return this.stickyMobileCart();
        }
    }
}

const mapStateToProps = state => {
    const { popover } = state.headerFooter;
    return {
        cartInfo: state.cartInfo,
        testFlags: state.testFlags,
        showPreview: popover === POPOVER_KEY,
    };
};

const mapDispatchToProps = dispatch => ({
    updateCart: (id, sku, qty, oldQty, price, name) => dispatch(updateCart(id, sku, qty, oldQty, price, name)),
    launchCartFlyout: flyoutVersion => dispatch(launchCartFlyout(flyoutVersion)),
    closeCartFlyout: flyoutVersion => dispatch(closeCartFlyout(flyoutVersion)),
    closePopover: () => dispatch(showPopover(null)),
    launchPopover: () => dispatch(showPopover(POPOVER_KEY)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withError(withWindowResize(CartPreview)));
