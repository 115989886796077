import React from 'react';
import '../css/modal.less';

class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidUpdate() {
        if (this.props.isOpen && this.closeBtn && typeof this.closeBtn.focus === 'function') {
            this.closeBtn.focus();
        }
    }

    openStyle() {
        if (this.props.isOpen) {
            return {
                display: 'block',
                ...this.props.openStyle,
            };
        } else {
            return { display: 'none' };
        }
    }

    closeModal(e) {
        const className = typeof e.target.className === 'string' ? e.target.className : '';
        if (className.indexOf('react-modal-bg') > -1 || className.indexOf('modal-close-btn') > -1) {
            e.stopPropagation();

            if (this.props.customCloseModal) {
                return this.props.customCloseModal(this.props.closeModal);
            }
            this.props.closeModal();
        }
    }

    render() {
        const modalContent = this.props.hasOwnProperty('modalContent') ? this.props.modalContent : this.props.children;
        return (
            <div
                className={`react-modal-bg${this.props.wrapperClassName ? ` ${this.props.wrapperClassName}` : ''}`}
                style={this.openStyle()}
                onClick={this.props.closeOnBGClick ? this.closeModal : undefined}
            >
                <div
                    className={`react-modal-container${this.props.className ? ` ${this.props.className}` : ''}`}
                    style={this.props.style}
                >
                    {this.props.disableCloseButton ? null : (
                        <button
                            aria-labelledby={'button'}
                            ref={closeBtn => (this.closeBtn = closeBtn)}
                            tabIndex={this.props.isOpen ? 0 : null}
                            className="modal-close-btn"
                            onClick={this.closeModal}
                        ></button>
                    )}
                    <div className="react-modal-overflow" id={this.props.overflowId}>
                        {modalContent}
                    </div>
                </div>
            </div>
        );
    }
}

Modal.defaultProps = {
    closeOnBGClick: true,
};

export default Modal;
