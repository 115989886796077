import { 
    getDerivedProductPageNameLevels,
    getReadReviewGtmProps
 } from '../metrics-helpers';
 
export default {
    productInitial: ({ component }) => {
        if(!component) return component;

        component.seeProductDetailsGtmttributes = {
            'data-gtm-category': 'linkClick',
            'data-gtm-id': 'review',
            'data-gtm-value': 'see-product-details'
        }

        component.readReviewGtmActions = getReadReviewGtmProps(component.name);

        return component;
    },
    pageView: ({ slugInfo }) => {
        try{
            const { components, analyticsData } = slugInfo || {};
            const { derivedAnalyticsData } = analyticsData || {};
            const { productInitial } = components || {};
            let derivedProductAnalytics = getDerivedProductPageNameLevels(productInitial, analyticsData, true);
            return {
                ...analyticsData || {},
                ...derivedAnalyticsData || {},
                ...derivedProductAnalytics
            }
        }catch(e){}
        
        return {};
    },
    pdpImages: ({ component }) => {
        if(!component) return component;

        component.openGalleryGtmActions = new Map([
                [
                    'openGallery',
                    {
                        gtmCategory: 'linkClick',
                        gtmId: 'gallery',
                        gtmValue: 'open'
                    }
                ]
            ]);

            // delay creation of GTM Map object until prices are available
            component.getGtmActions = function(){
                return new Map([
                    [
                        'addToCart',
                        {
                            gtmCategory: 'linkClick',
                            gtmId: 'gallery',
                            gtmValue: 'see-product-details'
                        }
                    ]
                ])
            }
            return component;
    },
}