import { Helpers } from '../core/src/helpers';

export const MAX_CALL_CENTER_QUANTITY = 999;
export const MAX_QUANTITY = 9;
export const PRE_ORDER_CHECKOUT_URL =
    'https://www.hp.com/us-en/shop/OrderShippingBillingView?calculationUsageId=-1&calculationUsageId=-4&catalogId=10051&orderId=.&updatePrices=1&storeId=10151&langId=-1&shipmentType=single';

/**
 * Mutates options object if product is preOrder
 * @param {*} product
 * @param {*} priceObj
 * @param {*} cartInfo
 * @param {*} options
 */
export const setPreOrderParameters = (product, priceObj, cartInfo, options) => {
    const { items = [], iCount, preOrderCart } = cartInfo;
    if (priceObj && priceObj.preOrder) {
        const catEntryId = product.catEntryId || product.itemId;
        options.isPreOrder = true;
        if (iCount && iCount > 0) {
            const clearCart = !!preOrderCart;
            //TODO: if
            const backupCart = items.reduce((r, ci) => {
                return r || ci.ceId !== catEntryId;
            }, false);
            if (clearCart || backupCart) {
                options.clearCart = clearCart;
                options.backupCart = backupCart;
            }
        }
    }
};

// helper function to add eCarepack option
export const _addElgblSku = (sku = '', index = null, serialNumber) => {
    const skuID = encodeURIComponent(`SKU=${sku}`);
    return serialNumber && sku ? { elgblSerialHwSku: sku, elgblSku: serialNumber, index } : { elgblSku: skuID, index };
};

export const parseElgblSku = ({ elgblSku }) => {
    try {
        const [prefix, sku] = decodeURIComponent(elgblSku).split('=');
        return sku;
    } catch (e) {}
};

export const parseCartResponse = resp => {
    try {
        return JSON.parse(resp.data.split('*')[1]);
    } catch (e) {
        return {};
    }
};

/**
 * Returns the cart item id of the associated sku
 * @param {*} product
 * @param {*} cartInfo
 * @returns
 */
export const getLinkedOrderItemID = (product, cartInfo) => {
    const { ordItmFld2 } = cartInfo;
    return Object.keys((ordItmFld2 || {})[product.sku] || {})[0];
};

/***
 * Gets the cart item linked to the provided product
 */
export const getLinkedOrderItem = (product, cartInfo) => {
    try {
        const { items } = cartInfo || {};
        const id = getLinkedOrderItemID(product, cartInfo);
        return (items || []).find(i => i.id === id);
    } catch (e) {}
};

export const getAddonProducts = (product, addOnProducts, productData, linkedOrderItem) => {
    let { addOns = {} } = productData;

    //own prop addOns take priority over addons from redux state
    let derivedAddOns = addOnProducts && addOnProducts.length > 0 ? addOnProducts : addOns[product.sku];

    //if add on product matches the existing linkedOrderItem then remove the add on.
    //There is already existing logic to keep these quantities in sync
    if (derivedAddOns && derivedAddOns.length > 0 && linkedOrderItem) {
        derivedAddOns = derivedAddOns.filter(({ product }) => !product || product.sku !== linkedOrderItem.pNum);
    }

    return derivedAddOns;
};

export const reduceAddOnQuantity = (product, addOns, primaryProductItem) => {
    return addOns.reduce(
        (result, ao, i) => {
            const { product: addOnProduct } = ao;
            const { itemId, catentryId, catEntryId, Devdependency } = addOnProduct;
            const id = itemId || catentryId || catEntryId;
            const aoQty = ao.qty;
            const isECP = Helpers.isECarepack(addOnProduct) && Devdependency !== 'NO';

            if (isECP) {
                //if the primary product already exists we can't add this care pack in the same add to cart call or else it creates a new line item
                //there should never be more than one e carepack in a single add to cart request as an addon since that is not supported in the back end
                if (primaryProductItem) {
                    result.followOnCarePackFromAddOn = addOnProduct;
                    return result;
                }
                result.addOnCarePacks.push(_addElgblSku(product.sku, i));
            }

            result.addOnId.push(id);
            result.addOnQty.push(aoQty);

            return result;
        },
        { addOnId: [], addOnQty: [], addOnCarePacks: [], followOnCarePackFromAddOn: null }
    );
};
