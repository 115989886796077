import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@hpstellar/core';

import { SearchContext } from './search-context';
import { getClearAllFiltersGtmAttributes } from '../../metrics/metrics-helpers';

/**
A component for clearing all filter options
@examples
```jsx
<SearchClearFilter
    watchFields={/(facets.*|price|rating)/}
/>
```
@component SearchClearFilter
@import SearchClearFilter
@example ../docs/SearchClearFilter.md
@returns {ReactElement} The rendered component
*/
export default class SearchClearFilter extends React.Component {
    static defaultProps = {
        className: '',
        autoHide: false,
        watchFields: new RegExp(),
    };

    static propTypes = {
        autoHide: PropTypes.bool,
        /**
         * Optional regex patter for facet slections that should be cleared
         */
        watchFields: PropTypes.object,
    };

    clearFilters = (query, setQuery) => {
        const { watchFields, onClick } = this.props;
        return () => {
            //if watchFields pass only clear those fields from query
            let newQuery = watchFields
                ? Object.keys(query).reduce((r, key) => {
                      if (!key.match(watchFields)) {
                          r[key] = query[key];
                      }
                      return r;
                  }, {})
                : query;
            setQuery(newQuery);
            onClick && onClick();
        };
    };

    render() {
        const { className, autoHide, watchFields } = this.props;
        return (
            <SearchContext.Consumer>
                {({ query, setQuery }) => {
                    if (
                        watchFields &&
                        Object.keys(query).filter(key => key.match(watchFields)).length === 0 &&
                        autoHide
                    ) {
                        return null;
                    }
                    return (
                        <Button
                            className="clear-filters"
                            variation="tertiary"
                            color="primary"
                            onClick={this.clearFilters(query, setQuery)}
                            endIcon={null}
                            {...getClearAllFiltersGtmAttributes()}
                        >
                            {this.props.children || 'CLEAR ALL'}
                        </Button>
                    );
                }}
            </SearchContext.Consumer>
        );
    }
}
