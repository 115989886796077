import React from 'react';

import { Helpers } from '../../core/src/helpers';
import withPageComponents from '../../page/components/with-page-components';

export default withPageComponents(({ profileData }) => {
    const { labels } = profileData;

    if (!labels?.customerCarePhone) {
        return null;
    }

    return (
        <div
            className="utility-etr-html"
            dangerouslySetInnerHTML={Helpers.createMarkup(Helpers.decodeHtml(labels.customerCarePhone))}
        />
    );
});
