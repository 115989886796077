class DealsAPI {
    constructor(apiConfig, http) {
        this.apiConfig = apiConfig;
        this.http = http;
    }

    get(catEntryId, pStoreID) {
        //include site params with add to cart specific params
        const { catalogId, langId, storeId } = this.apiConfig.siteInfo;
        let params = {
            catalogId,
            langId,
            storeId,
            pStoreID,
        };

        catEntryId = typeof catEntryId === 'string' ? [catEntryId] : catEntryId;

        if (catEntryId) {
            params.catentryId = catEntryId.join(',');
        }

        return this.http.get('DealsAPI', { params: params }).catch(e => {});
    }
}

export default DealsAPI;
