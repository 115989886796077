import { Helpers } from '../helpers';
const spechar = {
    '/': 'fwdslsh',
    '\\': 'bckslsh',
    '[': 'opnsqrbrckt',
    ']': 'clssqrbrckt',
    '(': 'opnbrckt',
    ')': 'clsbrckt',
    "'": 'singlequot',
    '"': 'dblequot',
    '-': 'hypn',
    _: 'undrscor',
};
/**
 * calculates the price difference, excluding the private store tier discount (if any)
 * @param {*} priceObj
 * @returns
 */
export const setPriceDifference = priceObj => {
    try {
        const { discountPercent, discount } = Helpers.getPriceDiscount(priceObj);
        priceObj.priceDifference = discount;
        priceObj.discountPercent = discountPercent;
    } catch (e) {}
    return priceObj;
};

export const formatSpecsForEmail = (specs = []) => {
    const specsStr = specs.map(({ title, value }) => `${title}:${value}`).join(' <br/> ');
    return specsStr
        .replace(/\//g, spechar['/'])
        .replace(/\\/g, spechar['\\'])
        .replace(/\[/g, spechar['['])
        .replace(/\]/g, spechar[']'])
        .replace(/\(/g, spechar['('])
        .replace(/\)/g, spechar[')'])
        .replace(/'/g, spechar["'"])
        .replace(/"/g, spechar['"'])
        .replace(/-/g, spechar['-'])
        .replace(/_/g, spechar['_']);
};
