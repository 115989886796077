import { 
    getReadReviewGtmProps,
    addGtmAddToCart,
    formatMetricValue,
    getDerivedProductPageNameLevels,
    addGtmPropsToBanners,
    getPaypalBanner,
    getShadowCtoGtmActions
 } from '../metrics-helpers';

 import { addAnalyticsDataToPdpAccessoriesComponent, addCarepackGtm } from '../../pdp/analytics'

export default {
    productInitial: ({ component, device, analyticsData }) => {
            if(!component) return component;
            let {colorCTOs, showPaypalBanner} = component || {};
            component.readReviewGtmActions = getReadReviewGtmProps(component.name);
            if(Array.isArray(colorCTOs) && colorCTOs.length > 0){
                component.colorCTOs = component.colorCTOs.map( colorCto => {
                    let { name, colorHex} = colorCto || {};
                    return {
                        ...colorCto || {},
                        linkProps: {
                            'data-gtm-category': 'linkClick',
                            'data-gtm-id': 'product',
                            'data-gtm-value': `color-picker-${colorHex}`
                        }
                    }
                })
            }

            return component;
    } ,
    pdpShadowCTO: ({ component }) => {
        if(!Array.isArray(component) || component.length === 0){
            return component;
        }

        return getShadowCtoGtmActions(component);
    },
    pdpImages: ({ component }) => {
        if(!component) return component;
        
        component.openGalleryGtmActions = new Map([
                [
                    'openGallery',
                    {
                        gtmCategory: 'linkClick',
                        gtmId: 'gallery',
                        gtmValue: 'open'
                    }
                ]
            ]);

            // delay creation of GTM Map object until prices are available
            component.getGtmActions = function({productInitial, cartId, quantity, selectedAddonSku, prices}){
                return addGtmAddToCart(
                    productInitial, 
                    { list: 'pdp-gallery', selectedAddonSku }, 
                    prices,
                    cartId,
                    quantity || 1
                );
            }
            return component;
    },
    pdpTechSpecs: ({ component })  => {
        if(!component) return component;
        const {translations} = component;
        component.gtmActions = new Map([
                [
                    'expandAction',
                    {
                        gtmCategory: 'linkClick',
                        gtmId: 'product',
                        gtmValue: translations && translations.see_complete_specs && formatMetricValue(translations.see_complete_specs)
                    }
                ]
            ])
            return component;
    },
    pdpSpecialOffers: ({ component, device, analyticsData }) => {
        if(!Array.isArray(component)) return component;
        return addGtmPropsToBanners(
            component, 
            device, 
            analyticsData, 
            'pdpSpecialOffers',  
            {
                promotionClickType: 'cta', 
                gtmActionKey: 'promotionClick'
            }
        );
    },
    pdpSimilarProductsLink: ({ component, slugInfo }) => {
        const { productInitial } = (slugInfo && slugInfo.components) || {}
        if(!component || !productInitial) return component;

        component.gtmAttributes = {
            'data-gtm-category': 'linkClick',
            'data-gtm-id': 'similar-product',
            'data-gtm-value': `model-open-${productInitial.sku}`
        }
        return component;
    },
    pdpCompatiblePrinters: ({ component }) => {
        if(!Array.isArray(component)) return component;

        return component.map( config => {
            const { products } = config || {};

            if(Array.isArray(products)){
                config.products = products.map( product => {
                    if(product){
                        product['data-gtm-category'] = 'linkClick';
                        product['data-gtm-id'] = 'compatibility-feature';
                        product['data-gtm-value'] = 'result-click';
                    }
                    return product;
                })
            }

            return config;
        });
    },
    pdpCarePackBundle: ({ component }) => {
        const {carepacks, serviceBundle} = component || {};
        if(!component || !Array.isArray(carepacks)) return component;

        const commonGtmAttributes = {
            gtmId: 'product',
            gtmCategory: 'linkClick'
        }

        component.gtmActions = new Map([
            [
                'optionClick',
                commonGtmAttributes
            ],
            [
                'learnMoreClick',
                {
                    ...commonGtmAttributes,
                    gtmValue: 'carepack-learn-more'
                }
            ]
        ])

        component.carepacks = carepacks.map(addCarepackGtm);

        if(serviceBundle && Array.isArray(serviceBundle.carepacks)){
            serviceBundle.carepacks = serviceBundle.carepacks.map(addCarepackGtm)
        }

        return component;
    },
    pdpAccessories: ({ component }) => {
        const {categories, products} = component || {};
        if(!Array.isArray(categories) || !products) return component;

        return addAnalyticsDataToPdpAccessoriesComponent(component);
    },
    pdpCTOConfiguration: ({ component }) => {
        const {configurations} = component || {};
        if(!Array.isArray(configurations)) return component;

        component.configurations = component.configurations.map(config => {
            let { sku } = config || {};
            return {
                ...config || {},
                gtmActions: new Map([
                    [
                        'radioClick',
                        {
                            gtmCategory: 'linkClick',
                            gtmId: 'product',
                            gtmValue:  `modify-sku-${sku}`
                        }
                    ]
                ])
            }
        })
        return component;
    },
    pageView: ({ slugInfo }) => {
        try{
            const { components, analyticsData } = slugInfo || {};
            const { derivedAnalyticsData } = analyticsData || {};
            const { asyncImpressionComponents } = derivedAnalyticsData || {};
            const { productInitial, pdpShadowCTO } = components || {};
            const { showPaypalBanner } = productInitial || {};
            let derivedProductAnalytics = getDerivedProductPageNameLevels(productInitial, analyticsData, true);

            // remove 'pdpPaypalBanner' impression if showPaypalBanner !== true
            let newAsyncImpressionComponents = asyncImpressionComponents && asyncImpressionComponents.slice();
            if(!showPaypalBanner && newAsyncImpressionComponents){
                newAsyncImpressionComponents = newAsyncImpressionComponents.filter(component => component !== 'pdpPaypalBanner');
            }

            let componentSpecificAnalyticsData = {};
            if(pdpShadowCTO){
                let { simple_title } = derivedAnalyticsData || analyticsData || {};
                componentSpecificAnalyticsData.simple_title = `SCTO-${simple_title}`;
            }

            return {
                ...analyticsData || {},
                ...derivedAnalyticsData || {},
                ...derivedProductAnalytics,
                ...componentSpecificAnalyticsData,
                asyncImpressionComponents: newAsyncImpressionComponents
            }
        }catch(e){}
        
        return {};
    }
}