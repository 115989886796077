import React, { useEffect, useState } from 'react';
import { HighlightBanner } from '@hpstellar/core';
import withPageComponents from '../with-page-components';

import { getHighlightBannerContent } from '../../../utility/setHtml';

import './value-prop-v2.less';

const ValuePropV2 = ({ valuePropContentSpot, globalValueProp, width }) => {
    const [timerProps, setTimerProps] = useState({});
    let { countdownTimerProps, countdownStartDate, ...banner } = valuePropContentSpot?.highlightBanner || {};
    const { endTime } = countdownTimerProps || {};

    //Set props on mount to avoid hydration mismatch
    useEffect(() => {
        if (endTime) {
            setTimerProps({
                countdownStartDate: new Date(countdownStartDate),
                countdownTimerProps: {
                    endTime: new Date(endTime),
                },
            });
        }
        return () => {};
    }, [endTime]);

    if (!valuePropContentSpot?.highlightBanner) return null;
    if (!banner) return null;

    //TODO: Temp code for PDP AB TEST
    if (!banner && globalValueProp && globalValueProp.valueProp) {
        const {
            title,
            body0: description,
            mobile: descriptionMobile,
            link: href,
            cta,
            ctaColor = 'white',
            ctaVariation = 'secondary',
        } = globalValueProp.valueProp;
        banner = {
            title,
            titleMobile: title,
            description,
            descriptionMobile,
            cta: {
                text: cta,
                ariaLabel: cta,
                props: {
                    color: ctaColor,
                    href,
                    variation: ctaVariation,
                },
            },
        };
    }

    const slim = !countdownTimerProps?.endTime && width < 1024;
    return (
        <HighlightBanner className="valuePropV2" {...getHighlightBannerContent(banner)} {...timerProps} slim={slim} />
    );
};

export default withPageComponents(ValuePropV2, {
    components: ['valuePropContentSpot', 'globalValueProp'],
    device: true,
});
