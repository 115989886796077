import { LOCATION_CHANGE } from 'connected-react-router';

let guxSpaReferrer, subsequentLoad;

/***
 * middleware for watching route changes
 */
export default function routerMiddleware({ getState }) {
    return next => action => {
        switch (action.type) {
            case LOCATION_CHANGE: {
                //add support for anchor scrolling
                try {
                    const { router, siteConfig } = getState();
                    const { basename } = siteConfig || {};
                    const { location: prevLocation } = router || {};
                    const { payload } = action;
                    const { location } = payload;
                    const { hash: prevHash, pathname: prevPath, search: prevSearch } = prevLocation;
                    const { hash, pathname, search } = location;

                    // it's important that the reouter reducer runs before the metrics reducer as currently implemented, so pageView always get the correct referrer
                    try {
                        if (subsequentLoad && (window.location.pathname !== prevPath || window.location.search !== prevSearch)) {
                            let domainPart = window.location.href.split(basename)[0];
                            let queryStrPart = prevSearch && prevSearch.length > 0 ? prevSearch : '';
                            let hashPart = prevHash && prevHash.length > 0 ? prevHash : '';
                            guxSpaReferrer = `${domainPart}${prevPath}${queryStrPart}${hashPart}`;
                            Object.defineProperty(document, 'referrer', {
                                get: function () {
                                    return guxSpaReferrer;
                                },
                            });
                        }
                        subsequentLoad = true;
                    } catch (e) {}

                    //if hash changes or if everything is the same and a router update triggered again
                    //assumes that someone click the hash link again
                    if (
                        hash !== '' &&
                        (prevHash !== hash || (prevHash === hash && prevPath === pathname && prevSearch === search))
                    ) {
                        // Push onto callback queue so it runs after the DOM is updated,
                        // this is required when navigating from a different page so that
                        // the element is rendered on the page before trying to getElementById.
                        setTimeout(() => {
                            const id = hash.replace('#', '');
                            const element = document.getElementById(id);
                            if (element) {
                                element.scrollIntoView({ behavior: 'smooth' });
                            }
                        }, 0);
                    }
                } catch (e) {}
            }
        }
        return next(action);
    };
}
