import ActionTypes from '../action-types';
import { GTM_EVENTS } from '../track-params';
import { Helpers } from '../../core/src/helpers';

const getAddToCartEvent = params => {
    let event;
    try {
        const { ecommerce, cartItems = [] } = params;
        const { currencyCode, add } = ecommerce || {};
        const { products = [] } = add || {};
        const [product = {}] = products;
        event = {
            name: 'Add to Cart',
            properties: {
                dyType: 'add-to-cart-v1',
                value: product.price * 1,
                currency: currencyCode,
                productId: product.id,
                quantity: 1,
                cart: cartItems.map(ci => ({
                    productId: ci.pNum,
                    quantity: ci.qty,
                    itemPrice: Helpers.convertPriceToNumber(ci.amt.uPrice),
                })),
            },
        };
    } catch (e) {}
    return event;
};

const getCartSyncEvent = params => {
    let event;
    try {
        const { ecommerce, cartItems = [] } = params;
        const { currencyCode, add } = ecommerce || {};
        event = {
            name: 'Sync cart',
            properties: {
                dyType: 'sync-cart-v1',
                currency: currencyCode,
                cart: cartItems.map(ci => ({
                    productId: ci.pNum,
                    quantity: ci.qty,
                    itemPrice: Helpers.convertPriceToNumber(ci.amt.uPrice),
                })),
            },
        };
    } catch (e) {}
    return event;
};

const getLoginEvent = params => {
    let event;
    try {
        const { ecommerce, cartItems = [] } = params;
        const { currencyCode, add } = ecommerce || {};
        event = {
            name: 'Login',
            properties: {
                dyType: 'login-v1',
                hashedEmail: '',
            },
        };
    } catch (e) {}
    return event;
};

/**
 * Performs event tracking from DY Personalization
 * @module DynamicYield
 * @class
 * @internal
 */
class DynamicYield {
    constructor(options = {}) {
        this.name = 'DY tag';
        this.optOut = false;
    }

    /**
     *
     * @method pageView
     * @param {String} eventName
     * @param {Object} params
     * @returns {Promise}
     * @internal
     */

    pageView(...args) {
        return this.track(...args);
    }

    /**
     *
     * @method track
     * @param {String} eventName
     * @param {Object} params
     * @param {Object} state
     * @returns {Promise}
     * @internal
     */

    track(eventName, params, state) {
        let dyEvent;
        const { event } = params || {};
        // switch statement to transform data into the accepted format.
        switch (event) {
            case GTM_EVENTS[ActionTypes.ADD_TO_CART]: {
                dyEvent = getAddToCartEvent(params);
                break;
            }
            case GTM_EVENTS[ActionTypes.REMOVE_FROM_CART]: {
                dyEvent = getCartSyncEvent(params);
                break;
            }
            case GTM_EVENTS[ActionTypes.UPDATE_CART]: {
                dyEvent = getCartSyncEvent(params);
                break;
            }
            /* Excluding this case since event requires email which we currently don't have access to from the React pages
           case GTM_EVENTS[ActionTypes.RECEIVE_UTILITY]: {
                dyEvent = getLoginEvent(params);
                break;
            }*/
            default: {
                break;
            }
        }
        if (dyEvent) {
            try {
                DY.API('event', dyEvent);
            } catch (e) {}
        }
    }
}

export default DynamicYield;
