import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { receiveHeaderFooter } from '../page/components/header-footer/header-footer-actions';
import ETRWCSApi from '../core/src/etr-wcs-api-client';
import { getPStoreID } from './useUserData';

export const parseHeaderContent = content => {
    try {
        // TODO: Move logic so it's an environment variable in beanstalk?
        const caasBasePath =
            process.env.NODE_ENV === 'production' ? 'https://www8.hp.com' : 'https://itg-live.www8.hp.com';

        return content.replace(/\[caasBasePath\]/g, caasBasePath);
    } catch (e) {}

    return content;
};

const getPStoreMenuNodeInnerText = (node, query) => {
    try {
        return query ? node.querySelector(query).innerText.trim() : node.innerText.trim();
    } catch (e) {}
    return null;
};

const getCategoryItemData = categoryItemContainer => {
    if (!categoryItemContainer) return;

    const link = categoryItemContainer.getAttribute('href');
    const name = getPStoreMenuNodeInnerText(categoryItemContainer);
    const analyticsLinkType = categoryItemContainer.getAttribute('data-link-type');
    const analyticsLinkID = categoryItemContainer.getAttribute('data-link-id');
    // validate before pushing to items array.
    if (link && name && link.length > 0 && name.length > 0) {
        return {
            link,
            name,
            analyticsLinkID,
            analyticsLinkType,
        };
    }

    return null;
};

const getItemDataCallback = items => categoryItemContainer => {
    const itemData = getCategoryItemData(categoryItemContainer);
    // validate before pushing to items array.
    if (itemData) {
        items.push(itemData);
    }
};

const getDesktopPStoreHeader = (containerNode, pStoreID) => {
    const nav = [];

    // get the navTabs
    const navTabs = containerNode.querySelectorAll('.wps-tablet .wpr-link-container .navbar-header-links');
    navTabs &&
        navTabs.forEach(navTab => {
            const navTabId = navTab && navTab.getAttribute('id');
            const navName = getPStoreMenuNodeInnerText(navTab, '.font-style-h5');

            const categories = [];

            // get the associated dropdown based on the id.
            const dropdownContainer = navTabId && containerNode.querySelector(`.wps-tablet #${navTabId}_drop`);
            const categoryContainers = dropdownContainer && dropdownContainer.querySelectorAll('.wpr-submenu-item');
            // get the categories associated with the nav tab
            categoryContainers &&
                categoryContainers.forEach(categoryContainer => {
                    if (!categoryContainer) return;
                    const categoryName = getPStoreMenuNodeInnerText(categoryContainer, '.wpr-submenu-heading');
                    const categoryItemContainers = categoryContainer.querySelectorAll('.sub-submenu-data a');
                    const items = [];
                    categoryItemContainers && categoryItemContainers.forEach(getItemDataCallback(items));

                    // validate before pushing to categories array.
                    if (categoryName && items && categoryName.length > 0 && items.length > 0) {
                        categories.push({
                            name: categoryName,
                            items,
                        });
                    }
                });

            // validate before pushing to nav array.
            if (navName && categories && navName.length > 0 && categories.length > 0) {
                nav.push({
                    name: navName,
                    categories,
                });
            }
        });

    const homeLogo = containerNode.querySelector('.wps-tablet .wpr-logo-header-holder a.wpr-main-logo-svg');
    const homeLogoLink = homeLogo.getAttribute('href');

    return {
        homeLogoLink,
        key: pStoreID,
        homeLogoLink,
        nav,
    };
};

const getMobilePStoreHeader = (containerNode, pStoreID) => {
    const nav = [];
    const navTabs = containerNode.querySelectorAll('.wps-mobile .wpr-navbar-container [id^=menuitem]');

    navTabs &&
        navTabs.forEach(navTab => {
            const navName = getPStoreMenuNodeInnerText(navTab, 'label');
            const navTabId = navTab && navTab.getAttribute('id');
            const sideNavContainer = navTabId && containerNode.querySelector(`.wps-mobile #${navTabId}_sub`);
            const categories = [];

            const categoryContainers =
                sideNavContainer && sideNavContainer.querySelectorAll('.wpr-submenu-item-mobile');
            // get the categories associated with the nav tab
            categoryContainers &&
                categoryContainers.forEach(categoryContainer => {
                    if (!categoryContainer) return;
                    const categoryName = getPStoreMenuNodeInnerText(categoryContainer, '.wpr-submenu-heading');
                    const categoryItemContainers = categoryContainer.querySelectorAll('.sub-submenu-data-mobile a');
                    const items = [];
                    categoryItemContainers && categoryItemContainers.forEach(getItemDataCallback(items));

                    // validate before pushing to categories array.
                    if (categoryName && items && categoryName.length > 0 && items.length > 0) {
                        categories.push({
                            name: categoryName,
                            items,
                        });
                    }
                });

            // validate before pushing to nav array.
            if (navName && categories && navName.length > 0 && categories.length > 0) {
                nav.push({
                    name: navName,
                    categories,
                });
            }
        });

    const homeLogo = containerNode.querySelector('.wps-tablet .wpr-logo-header-holder a.wpr-main-logo-svg');
    const homeLogoLink = homeLogo.getAttribute('href');

    return {
        homeLogoLink,
        key: pStoreID,
        homeLogoLink,
        nav,
    };
};

const parsePstoreHtmlToJson = (customHTML, pstoreID) => {
    try {
        const headerContainer = document.createElement('div');
        headerContainer.innerHTML = customHTML;
        const desktop = getDesktopPStoreHeader(headerContainer, pstoreID);
        const mobile = getMobilePStoreHeader(headerContainer, pstoreID);
        return {
            desktop,
            mobile,
        };
    } catch (e) {}
};

export default () => {
    const pStoreID = useSelector(state => state && getPStoreID(state.userData));
    const hasEtrHeader = useSelector(
        state =>
            !!(state && state.headerFooter && state.headerFooter.etrHeader && state.headerFooter.etrHeader[pStoreID])
    );
    const dispatch = useDispatch();

    useEffect(() => {
        if (pStoreID && !hasEtrHeader) {
            const componentKey = 'PSTORE-HEADER';
            ETRWCSApi.component.get('espot', componentKey, { path: encodeURIComponent('/'), pStoreID }).then(data => {
                const response = data && data[componentKey] && data[componentKey][0];
                const { title, content } = response || {};
                const customHTML =
                    Array.isArray(content) && typeof content[0] === 'string' && parseHeaderContent(content[0]);
                const headerData = parsePstoreHtmlToJson(customHTML, pStoreID);
                dispatch(
                    receiveHeaderFooter({
                        pStoreID,
                        title,
                        customHTML,
                        value: headerData,
                        etrHeader: true,
                    })
                );
            });
        }
    }, [pStoreID, hasEtrHeader]);
};
