import { combineReducers } from 'redux';
import modal from './compare-modal-reducer';
import products from './compare-products-reducer';
import results from './compare-results-reducer';
import resultsV2 from './compare-results-reducer-v2';
import errors from './compare-error-reducer';
import expanded from './compare-expanded-reducer';
import offers from './compare-offers-reducer';

export default combineReducers({
    modal,
    products,
    results,
    resultsV2,
    errors,
    expanded,
    offers,
});
