import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import jsonpAdapter from 'axios-jsonp';
import { connect } from 'react-redux';
import throttle from 'lodash/throttle';

import withWindowResize from '../../../ui/components/window-resize';
import ActionTextBox from './action-textbox';
import PopOver from './popover';
import ButtonIcon from './button-icon';
import { showPopover } from './header-footer-actions';

import SearchBarContainer from '../../../search/components/search-bar-container';

import './header-search.less';

const POPOVER_KEY = 'headerSearch';
export class HeaderSearch extends PureComponent {
    static propTypes = {
        /** viewport width */
        width: PropTypes.number,
        /** device type (desktop|mobile) */
        device: PropTypes.string.isRequired,
        /** option to display classic version of search */
        classic: PropTypes.bool,
        /** popover state open/close */
        popover: PropTypes.bool,
        /** action to open popover */
        openPopover: PropTypes.func,
    };

    static defaultProps = {
        classic: false,
        popover: false,
        width: 0,
        openPopover: () => console.log('NOT IMPLEMENTED'),
    };

    state = {
        showResults: false,
        searchResults: {},
        clearIcon: null,
        mounted: false
    };

    componentDidMount(){
        this.setState({ mounted: true});
    }

    onSearchTextChange = throttle(searchTerm => {
        const { openPopover, classic, setSearchKey, variation } = this.props;

        // hawksearch
        if(variation === 'hawksearch'){
            return setSearchKey && setSearchKey(searchTerm);
        }

        // non-hawksearch
        if (searchTerm.length === 0) {
            this.setState({ clearIcon: null, searchTerm, showResults: false });
            !classic && openPopover(null);
            return;
        }
        if (searchTerm.length <= 2) {
            this.setState({ clearIcon: 'icon-close-circle', searchTerm });
            return;
        }
        this.search(this.providers, searchTerm);

        this.setState({ showResults: true, searchTerm });
        openPopover(POPOVER_KEY);
    }, 200);

    onOpen = () => {
        const { popover, openPopover } = this.props;
        this.setState({ showResults: false, openMobileSearchBar: true });
        openPopover(popover ? null : POPOVER_KEY);
    };

    onClose = () => {
        const { classic, openPopover, variation, setSearchKey, setInputFocused } = this.props;
        this.setState({ clearIcon: null, searchTerm: '', showResults: false });
        !classic && openPopover(null);

        if(variation === 'hawksearch'){
            setSearchKey && setSearchKey(null);
            setInputFocused && setInputFocused(false);
        }
    };

    getSearchUrl = (searchTerm) => {
        let {searchUrl, searchQueryStringKey} = this.props;

        if(searchUrl && searchQueryStringKey){
            return `${this.props.searchUrl}?${this.props.searchQueryStringKey}=${searchTerm}`;
        }

        return (this.props.searchUrl || "").replace(/\$\{searchTerm\}/ig,searchTerm);
    }

    getSearchAutosuggestUrl = (searchTerm) => {
        return (this.props.searchAutosuggestUrl || "").replace(/\$\{searchTerm\}/ig,searchTerm)
    }

    onSearch = () => {
        const { searchTerm } = this.state;
        if (searchTerm) {
            window.location.href = this.getSearchUrl(searchTerm);
        }
    };

    providers = {
        hpstore: {
            title: 'HP STORE',
            message: 'Searching HP Store...',
            url: searchTerm => this.getSearchAutosuggestUrl(searchTerm),
            transformResults: ({ terms }) => terms.slice(0, 5).map(item => Object.keys(item)[0]),
            link: term => this.getSearchUrl(term),
        },
        allhp: {
            title: 'ALL of HP.com',
            message: 'Searching HP.com...',
            url: searchTerm =>
                `https://iapproltautocm.austin.hp.com/hp-iap-autocomplete/search?companyCode=HPI&lang=en&category=SS&country=us&q=${searchTerm}&cb=search_callback_allhp`,
            transformResults: ({ data }) => data.SuggestionItems['0'].list.map(term => term.term0).slice(0, 5),
            link: term => `https://www8.hp.com/us/en/hp-search/search-results.html?ajaxpage=1#/page=1&/qt=${term}`,
        },
    };

    search = (providers, searchTerm) => {
        Object.entries(providers).map(([key, provider]) => {
            const callbackName = `search_callback_${key}`;
            window[callbackName] = data => {
                const results = provider.transformResults(data);
                const { searchResults } = this.state;
                this.setState({
                    searchResults: {
                        ...searchResults,
                        [key]: results,
                    },
                });
            };
            return axios({
                url: provider.url(searchTerm),
                adapter: jsonpAdapter,
                callbackParamName: callbackName,
            }).then(resp => resp);
        });
    };

    render() {
        const { showResults, searchResults, clearIcon, searchTerm, openMobileSearchBar } = this.state;

        const { closeSearch, width, classic, popover, setSearchKey, setInputFocused, inputFocused, variation, onInputFocusOut, searchKey, isMobile } = this.props;

        const isHawksearch = variation === 'hawksearch';

        if(isHawksearch){
            return <SearchBarContainer closeSearch={closeSearch}/>;
        }

        const inputAriaProps = {
            'aria-expanded': inputFocused && typeof searchKey === 'string' && searchKey.length > 0
        };

        const hawksearchSearchBar = (
                <SearchBarContainer
                    placeholder="What are you looking for?"
                    actionIcon={inputFocused ? null : "icon-search"}
                    clearIcon='icon-close'
                    hideClearIconOnEmptyText
                    setSearchKey={setSearchKey}
                    setInputFocused={setInputFocused}
                    actionOnClick={this.onSearch}
                    onChange={this.onSearchTextChange}
                    value={searchTerm}
                    className="search-textbox"
                    closeButtonComponent={
                        (inputFocused || openMobileSearchBar) && 
                        <ButtonIcon
                            buttonClass='actionIcon'
                            icon='icon-close'
                            onClick={this.onClose}
                            ariaLabel="Close"
                        /> 
                    }
                    variation={variation}
                    ariaProps={inputAriaProps}
                    onInputFocusOut={onInputFocusOut}
                />
        );

        const isMobileWidth = width < 769;

        return (
            <div className={`search-header ${classic ? 'search-header-classic' : ''}`}>
                {   
                    isHawksearch && popover && 
                    (isMobileWidth || classic) &&
                    hawksearchSearchBar
                }
                {!isHawksearch && popover && (
                    <PopOver show={popover} onClose={this.onClose} arrowTop overlay>
                        <div className="popover-search-container">
                            {(isMobileWidth || classic) && (
                                <ActionTextBox
                                    className={`search-textbox ${showResults ? 'search-active' : ''}`}
                                    placeholder="Search HP.com"
                                    actionIcon="icon-search"
                                    actionOnClick={this.onSearch}
                                    clearIcon={clearIcon}
                                    clearOnClick={this.onClose}
                                    onChange={this.onSearchTextChange}
                                    value={searchTerm}
                                />
                            )}
                            {searchTerm && (
                                <ResultView
                                    searchTerm={searchTerm}
                                    showResults={showResults}
                                    searchResults={searchResults}
                                    providers={this.providers}
                                />
                            )}
                        </div>
                    </PopOver>
                )}

                {
                    isHawksearch
                    ? hawksearchSearchBar 
                    : (
                        <ActionTextBox
                            className="search-textbox"
                            placeholder="Search hp.com"
                            actionIcon="icon-search"
                            actionOnClick={this.onSearch}
                            clearIcon={clearIcon}
                            clearOnClick={this.onClose}
                            onChange={this.onSearchTextChange}
                            value={searchTerm}
                        />
                    )    
                }
            </div>
        );
    }
}

function ResultView({ searchTerm, searchResults, showResults, providers }) {
    const display = showResults ? 'block' : 'none';

    const highlight = (word, match) =>
        word.split(new RegExp(`(${match})`, 'gi')).map((part, i) => (
            <span key={i} className={part.toLowerCase() === match.toLowerCase() ? 'search-match' : ''}>
                {part}
            </span>
        ));
    // TODO: show message when no results are found
    return (
        <div className="result-view" style={{ display }}>
            {Object.entries(searchResults).map(([key, result]) => (
                <div key={key}>
                    {result.length !== 0 && <div className="search-title">{providers[key].title}</div>}
                    <ul className="result-list">
                        {/* TODO: when no results classis shows nothing,
                        need to add this when we use new header */}
                        {/* {result.length === 0 && (
                            <li className="result-loading">
                                {providers[key].message}
                            </li>
                        )} */}
                        {result.map((ritem, i) => (
                            <li className="result" key={i}>
                                <a href={providers[key].link(ritem)}>{highlight(ritem, searchTerm)}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    );
}

ResultView.propTypes = {
    searchResults: PropTypes.object.isRequired,
    showResults: PropTypes.bool.isRequired,
    searchTerm: PropTypes.string.isRequired,
    providers: PropTypes.object.isRequired,
};

const mapProps = state => {
    const { popover, header } = state.headerFooter;
    const { searchAutosuggestUrl, searchUrl } = header;
    return { popover: popover === POPOVER_KEY,searchAutosuggestUrl, searchUrl };
};

const mapActions = dispatch => ({
    openPopover: show => dispatch(showPopover(show)),
});

export default withWindowResize(connect(mapProps, mapActions)(HeaderSearch));
