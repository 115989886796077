import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { batchedSubscribe } from 'redux-batched-subscribe';
import { routerMiddleware } from 'connected-react-router';
import debounce from 'lodash/debounce';
import createRootReducer from '../root-reducer';

const debounceNotify = debounce(notify => {
    notify();
});

const configureStore = (history, preloadedState = {}, options = {}) => {
    let middleware = [routerMiddleware(history), thunk];
    const { enableLogging = process.env.NODE_ENV !== 'production' && !process.env.ISNODE } = options;

    if (enableLogging) {
        let logger = require('redux-logger').logger;
        middleware = [...middleware, logger];
    }

    if (!process.env.ISNODE) {
        const MetricsMiddleware = require('../metrics/metrics-middleware').default;
        const { default: persistedStoreMiddleware } = require('./persist-store');
        const { default: routerMiddleware } = require('./router-middleware');
        middleware = [...middleware, MetricsMiddleware, persistedStoreMiddleware, routerMiddleware];
    }

    let composeEnhancers = compose;
    if (typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
        composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    }
    const enhancer = composeEnhancers(applyMiddleware(...middleware), batchedSubscribe(debounceNotify));

    const store = createStore(createRootReducer(history), preloadedState, enhancer);

    //add cross tab syncronization for presisted store
    if (!process.env.ISNODE) {
        const { crossTabSync, dispatchPersistedStore } = require('./persist-store');
        crossTabSync(store);
        dispatchPersistedStore(store);
    }

    return store;
};

export default configureStore;
