import React from 'react';

import withPageComponents from '../with-page-components';

/**
 * Component to render NAV items from crawlery friendly SEO content
 */
const BotNav = withPageComponents(({ nav, isBot }) => {
    if (!isBot || !nav) return null;
    return (
        <div className="nav-links" style={{ display: 'none' }}>
            {nav.map((navItem, index) => {
                const { name, categories } = navItem;
                return (
                    <div className="nav-group" key={name}>
                        <span className="nav-title">{name}</span>
                        {categories?.map((category, index) => {
                            return (
                                <div className="nav-category" key={category.name}>
                                    <span className="nav-category-title">{category.name}</span>
                                    <ul>
                                        {category?.items?.map((item, index) => {
                                            const { link, name } = item;
                                            return (
                                                <li key={link}>
                                                    <a href={link}>{name}</a>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
});

export default BotNav;
