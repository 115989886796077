import React from 'react';
import { Helpers } from '../../core/src/helpers';


class JusticeModalControl extends React.Component {
    static defaultProps = {
        content: null,
        contentProps: {},
        modalProps: {}
    };

    constructor(props) {
        super(props);
        this.uniqueKey = Helpers.uuidv4();
    }

    openModal = (c, cP, mP) => { //TODO: arguments for legacy uses, remove after migration
        const { content, contentProps, modalProps } = this.props;
        this.props.openModal(content || c, contentProps || cP, modalProps || mP, this.uniqueKey);
    }

    componentDidUpdate(prevProps) {
        if(this.props.dynamicContent) {
            const { content, contentProps, modalProps } = this.props;
            this.props.updateProps && this.props.updateProps(content, contentProps, this.uniqueKey, modalProps);
        }
    }

    render() {
        const childrenProps = {
            openModal: this.openModal,
            isOpen: this.props.isOpen,
            closeModal: this.props.closeModal
        };
        return this.props.children(childrenProps);
    }

}


export default JusticeModalControl;
