import React, { useEffect } from 'react';
import usePage, { useQuery } from '../../hooks/usePage';
import useAsyncGlobalSettings from '../../hooks/useAsyncGlobalSettings';
import { withError } from '../../shared/components/error-boundary';

const getFullSlug = (pageDir, pageSlug) => {
    let slug = pageSlug && !['?', '/'].includes(pageSlug[0]) ? `/${[pageSlug]}` : pageSlug;
    return pageDir ? `${pageDir}${slug}` : '/';
};

const FORWARD_PARAM_WHITELIST = ['bvstate'];
/**
 * Forward select query parameters to async components API
 * @param {*} query
 * @returns
 */
const pickParams = (query = {}, whitelist = FORWARD_PARAM_WHITELIST) => {
    return whitelist.reduce((r, key) => {
        if (key in query) {
            r[key] = query[key];
        }
        return r;
    }, {});
};

/**
 * Checks slugInfo for async query chain and fetches
 */
export default withError(({ slugInfo, dir, slug }) => {
    const { nextQuery, gqlAsyncClientLoad, vanityUrl: lastQueryFullSlug } = slugInfo;
    const { fetchAsyncComponents } = usePage();
    const query = useQuery();
    const { hash } = nextQuery || {};
    useEffect(() => {
        const fullSlug = getFullSlug(dir, slug);
        //stop the progressive loading if the page has changed since the initial nextQuery request
        if (hash && !gqlAsyncClientLoad && lastQueryFullSlug.indexOf(fullSlug) === 0) {
            fetchAsyncComponents(fullSlug, hash, { params: pickParams(query) });
        }
    }, [hash, gqlAsyncClientLoad]);
    //fetch any async global settings
    useAsyncGlobalSettings();
    return null;
});
