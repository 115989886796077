import { CACHE_ADD, CACHE_DELETE, CACHE_BULK_ADD , CACHE_BULK_DELETE } from './cache-actions';

const NULL_STATE = {};

const CacheReducer = (state = NULL_STATE, action) => {
	Object.freeze(state);
	const { key, data, keys=[] } = action;
	let newState = {};
	switch(action.type) {
		case CACHE_ADD:
			//TEMP: don't allow override of exiting cacheKeys. avoid re-render for items already in cache
			if(key in state){
				return state;
			}
			newState[key] = data;
			return Object.assign({}, state, newState);
		case CACHE_BULK_ADD:
			newState = data.reduce((results, cacheItem)=>{
				if(key in state){
					return result;
				}
				result[cacheItem.key] = cacheItem.data;
				return result
			},{});
			return Object.assign({}, state, newState);
		case CACHE_DELETE:
			delete state[key];
			return state;
		case CACHE_BULK_DELETE:
			(keys).forEach((k) => delete state[k]);
			return state;
		default:
			return state;
	}
};

export default CacheReducer;
