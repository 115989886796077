import React, { Component } from 'react';
import loadable from '@loadable/component';

import { fetchSlugInfo, receiveSlugInfo } from '../page/page-actions';
import { searchBlogs, fetchArticleArchives, fetchRelatedBlogs, articleViewPageType } from './blog-list-actions';
import { doSearch } from '../search-filter/search-filter-actions';
import { isTechAtWork, TECH_AT_WORK_TAG } from './components/utils';
import withPage from '../page';
import BlogAdSenseScript from './components/ads/adsense/adsense-script';
import { LocalCache } from '../core';
import { Helpers } from '../core/src/helpers';

const HubAndSpoke = loadable(() => Helpers.retryFunc(() => import('./hub-and-spoke')));
const Blog = loadable(() => Helpers.retryFunc(() => import('./components-v2/blog')));

const PRIORITY_TAGS_LENGTH_MIN = 5;

class BlogContainer extends Component {
    render() {
        const { slugInfo, siteConfig, originCountryCode } = this.props;
        const {
            seo: { categories, tags, title, description, keywords, canonical, robots, wordCloud },
            components = {},
            _id,
            vanityUrl,
            css,
            error,
            updatedAt,
            key: blogKey,
            templateKey,
        } = slugInfo;
        const { productTab } = components;
        const { productGroups: spokePages } = productTab || {};
        const isHubAndSpoke = spokePages && spokePages.length > 0;

        const blog = components.blogPost;
        const author = components.author;
        const header = categories.indexOf('tech-at-work') >= 0 ? 'HP Tech@Work' : 'HP TECH TAKES /...';
        const subHeader =
            categories.indexOf('tech-at-work') >= 0
                ? "Today's trends for tomorrow's business"
                : "Exploring today's technology for tomorrow's possibilities";

        let blogProps = {
            tags,
            categories,
            blog,
            author,
            url: canonical,
            header,
            subHeader,
            title,
            canonical,
            description,
            keywords,
            robots,
            _id,
            vanityUrl,
            css,
            updatedAt,
            wordCloud,
            blogKey,
            originCountryCode,
            //TODO: site config shouldn't have template specific settings. It should only have universal page settings
            //we may want to considering storing this as part of the template and bringing it in along with the page components
            blogConfig: siteConfig.blog,
        };

        return (
            <React.Fragment>
                <BlogAdSenseScript />
                {isHubAndSpoke ? <HubAndSpoke {...blogProps} /> : <Blog {...blogProps} />}
            </React.Fragment>
        );
    }
}

const loadData = async (urlParams, store, context) => {
    const { query } = context;
    const { slug, filters } = urlParams;
    const filterObj = Helpers.parseFilters(filters);
    const { pg } = filterObj;
    const fullSlug = `tech-takes/${slug}`;
    const options = pg ? { ...query, pageKey: pg } : query;

    const filtersTopBlogs = { templateKey: 'blog', hosted: 'true', blogArchive: 'false' };
    const filtersArticleArchives = { templateKey: 'blog', hosted: 'true', blogArchive: 'true' };
    const post_filters = {};
    const aggregates = {
        all_years: 'year',
        all_months: 'month',
        all_categories: 'categories',
    };
    const aggregateFilters = Object.keys(aggregates);
    const sortTopBlogs = { visitCount: 'desc' };
    const sizeTopBlogs = 10;
    const from = 0;
    const bucketSize = 30;
    const sort = { blogDate: 'desc' };
    const size = 14;

    const promise = await Promise.all([
        store.dispatch(fetchSlugInfo(fullSlug, options)),
        store.dispatch(
            fetchArticleArchives(
                filtersArticleArchives,
                post_filters,
                aggregates,
                aggregateFilters,
                sort,
                size,
                from,
                bucketSize
            )
        ),
    ]);

    const storeData = store.getState();

    const needRedirect = !!storeData.slugInfo.error || storeData.redirects.count !== 0;
    let { destination, status = 301 } = storeData.redirects;
    if (needRedirect) return Promise.resolve({ status, destination: destination || '/tech-takes' });

    const { tags = [], categories = [] } = (storeData.slugInfo && storeData.slugInfo.seo) || {};

    let postSlugFetchPromises = [];
    const isTaw = isTechAtWork(tags, categories);
    if (tags.length) {
        const relatedBlogsFilters = {
            templateKey: 'blog',
            hosted: 'true',
            blogArchive: 'false',
        };

        const relatedBlogsPostFilters = { tags: isTaw ? [TECH_AT_WORK_TAG] : tags.slice(0, PRIORITY_TAGS_LENGTH_MIN) };
        const relatedBlogsAggregate = {};
        const relatedBlogsAggregateFilters = [];
        const relatedBlogsSort = { viewCount: 'desc' };
        const relatedBlogsSize = 15;
        const relatedBlogsFrom = 0;
        const relatedBlogsBucketSize = 15;

        postSlugFetchPromises.push(
            store.dispatch(
                fetchRelatedBlogs(
                    relatedBlogsFilters,
                    relatedBlogsPostFilters,
                    relatedBlogsAggregate,
                    relatedBlogsAggregateFilters,
                    relatedBlogsSort,
                    relatedBlogsSize,
                    relatedBlogsFrom,
                    relatedBlogsBucketSize
                )
            )
        );
    }

    const topBlogsPostFilters = isTaw
        ? {
              tags: [TECH_AT_WORK_TAG],
              categories: [TECH_AT_WORK_TAG],
          }
        : {};

    postSlugFetchPromises.push(
        store.dispatch(
            doSearch('topBlogs', () =>
                searchBlogs(
                    filtersTopBlogs,
                    topBlogsPostFilters,
                    aggregates,
                    aggregateFilters,
                    sortTopBlogs,
                    sizeTopBlogs,
                    from,
                    bucketSize,
                    articleViewPageType
                )
            )
        )
    );

    return Promise.all(postSlugFetchPromises);
};

export default {
    component: withPage(BlogContainer, {
        defaultRedirect: '/tech-takes',
        exitPopupProps: {
            shouldOpenPopup: () => {
                try {
                    const localCache = new LocalCache('techTakesEpOpened', 1);
                    const epOpened = localCache.get('opened', true);
                    if (!epOpened) {
                        localCache.set('opened', true);
                        return true;
                    }
                } catch (e) {}

                return false;
            },
        },
    }),
    loadData,
};
