import React from 'react';
import loadable from '@loadable/component';

import { dynamicLoadData } from '../page';
import { Helpers } from '../core/src/helpers';
import { derivedPath } from './routing';

const ConfigurePage = loadable(() => Helpers.retryFunc(() => import('./configure-container')));

export const loadData = async (urlParams, store, context = {}) => {
    const { query, headers } = context;
    const fullSlug = derivedPath({ params: urlParams }, query);
    await dynamicLoadData(fullSlug, store, context);

    const { slugInfo, redirects, basename } = store.getState();

    const needRedirect = !slugInfo || slugInfo.error || (redirects && redirects.count !== 0);

    const { destination = `${basename}/slp/weekly-deals`, status = 301 } = redirects;
    if (needRedirect) return Promise.resolve({ status, destination });

    return Promise.resolve(slugInfo);
};

const ConfigureContainer = props => {
    return <ConfigurePage {...props} />;
};

export default {
    component: ConfigureContainer,
    loadData,
};
