import React from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';

import {
    fetchSlugInfo,
    receiveSlugInfo,
    trackPageView,
    loadData,
    loadGraphQLData,
    dynamicLoadData,
} from './page-actions';
import slugInfoReducer, { getDefaultStaticSlugInfo } from './slug-info-reducer';
import redirectsReducer from './redirect-reducer';
import ScripLoader from './components/script-loader';
import headerFooterReducer from './components/header-footer/header-footer-reducer';
import scriptLoaderReducer from './components/script-loader/script-loader-reducer';
import { ModalProvider } from '../context/modal/modal-context';
import SeoMeta from './components/seo-meta';
import { Helpers } from '../core/src/helpers';
import { withHeaderFooter } from './components/header-footer';
import Breadcrumbs from './components/breadcrumbs';
import ErrorRedirect from '../main/components/error-redirect';
import MetricsMeta from '../metrics/components';
import GraphQLClient from './components/graphql-client';
import AsyncDataLoader from './components/async-data-loader';
import PagePlaceHolder from './components/page-placeholder';
import ScrollToButton from './components/scroll-up-button';
import PrivateStoreHeaderLoader from './components/header-footer/private-store-header-loader';
import DynamicYieldContext from '../third-party-tags/dynamic-yield-context';
import SchemaMarkup from './components/schema-markup';
import PagePrefetch from './components/page-prefetch';

import './css/page.less';

const PreviewOverlay = loadable(() => Helpers.retryFunc(() => import('./components/preview-overlay')));
const SEOLinks = loadable(() => Helpers.retryFunc(() => import('./components/seo-links/seo-links')));
const ExitPopup = loadable(() => Helpers.retryFunc(() => import('./components/exit-popup')));

const { memoize } = Helpers;

const parseUrlFilters = memoize(search => {
    return Helpers.getSearch(search);
});
const EMPTY_OBJ = {};

const hasValueProp = slugInfo => {
    try {
        return !!slugInfo?.components?.valuePropContentSpot?.highlightBanner?.title;
    } catch (e) {
        return false;
    }
};

const getFullSlug = (pageDir, pageSlug) => {
    let slug = pageSlug && !['?', '/'].includes(pageSlug[0]) ? `/${[pageSlug]}` : pageSlug;
    return pageDir ? `${pageDir}${slug}` : '/';
};

/**
 * Returns the page slug based on the router match
 * @param {*} match
 * @returns
 */
export const getDerivedSlug = match => {
    const {
        params: { dir, slug, slugTwo, filters },
    } = match;
    return dir && slug ? `${slug ? slug : ''}${slugTwo ? `/${slugTwo}` : ''}${filters ? `/${filters}` : ''}` : '/';
};

/**
 * Returns the pathname based on the router match
 * @param {*} match
 * @returns
 */
export const getDerivedPath = match => {
    const {
        params: { dir, slug, slugTwo },
    } = match;
    return dir && slug ? `${dir}/${slug}${slugTwo ? `/${slugTwo}` : ''}` : '/';
};

/**
 * @typedef {Object} WithPageProps
 * @property {boolean} customMetaData - True if page template will be defining it's own SEO meta data
 * @property {boolean} disablePageCall - True is page template does not need page API call automatically
 * @property {function} getStaticSlugInfo - Function that returns the slugInfo when disablePageCall is true
 * @property {string} wrapperClassName - custom className for wrapper element
 * @property {boolean} showWordCloud - Render SEO wordcloud
 * @property {boolean} useGraphQL - True if page should use graphql as data source
 * @property {function shouldClearSlugInfo(params)} shouldClearSlugInfo - custom function to control wether slug info should clear on route change
 * @property {function derivedSlug(params)} derivedSlug - custom function to format slug for API call
 * @property {function derivedPath(params)} derivedPath - custom function to format pathname for client side routing

 }}
 */

/**
 * HOC component that should wrap any page template. Handles SEO and page related API calls
 * @example
 * ```jsx
 * withPage(MyTemplateContainer,{ wrapperClassName: 'template-root' })
 * ```
 * @name withPage
 * @param {*} Component
 * @param {WithPageProps} props
 * @returns {ReactElement} The rendered component
 */
const withPage = (Component, options) => {
    let {
        customMetaData = false,
        disablePageCall = false,
        getStaticSlugInfo = getDefaultStaticSlugInfo,
        wrapperClassName,
        showWordCloud,
        defaultRedirect,
        disableMobileBreadcrumb,
        disablePlaceholder,
        useGraphQL = false,
        useV2 = false,
        noProps = false,
        breadcrumbProps,
        derivedSlug = getDerivedSlug,
        derivedPath = getDerivedPath,
        exitPopupProps,
        customFetchSlugInfo,
        shouldClearSlugInfo = () => true,
        forwardQueryParams = [],
        useSeoV2 = true,
        SeoMetaDataComponent,
        SchemaMarkupComponent,
        BreadcrumbComponent,
        disableBreadcrumbs,
    } = options || {};
    class WithPage extends React.PureComponent {
        static whyDidYouRender = true;
        componentDidMount() {
            const { slugInfo, search, match, dispatch, location } = this.props;
            const {
                params: { dir, slug, slugTwo },
            } = match;
            const pathName = derivedPath(match, search, location);
            const slugWithFilters = derivedSlug(match, search);
            //temp fix to prevent sitesearch from re-rendering
            if (dir === 'sitesearch' && slugInfo.vanityUrl === 'sitesearch' && hasValueProp(slugInfo)) {
                return;
            }
            if (!slugInfo.vanityUrl || decodeURIComponent(slugInfo.vanityUrl) !== decodeURIComponent(pathName)) {
                this.fetchPage(dir, slugWithFilters, search);
            }
        }

        componentDidUpdate(prevProps, prevState) {
            let { dir: currentDir, slug: currentSlug, slugTwo: currentSlugTwo } = prevProps.match.params;
            const { match, search, slugInfo } = this.props;
            const { slugInfo: prevSlugInfo } = prevProps;
            let { dir: newDir, slug: newSlug, slugTwo: newSlugTwo } = match.params;
            const slugWithFilters = derivedSlug(match, search);
            const { hash } = slugInfo.nextQuery || {};
            const { hash: prevHash } = prevSlugInfo.nextQuery || {};
            const slugTwoChanged = currentSlug === newSlug && newSlugTwo !== currentSlugTwo;
            //TODO: figure out a better way to detect and actual page change.
            //Right now we compare url parts, so that filter changes on vwa does't trigger this
            if (currentDir !== newDir || currentSlug !== newSlug || slugTwoChanged) {
                this.fetchPage(newDir, slugWithFilters);
            }
        }

        componentWillUnmount = () => {
            if (this.timerHandle) {
                clearTimeout(this.timerHandle);
                this.timerHandle = 0;
            }
        };

        fetchPage = (pageDir, pageSlug, search) => {
            const { derivedPath, slugInfo } = this.props;

            if (customFetchSlugInfo) {
                return customFetchSlugInfo(this.props);
            }

            const fullSlug = getFullSlug(pageDir, pageSlug);
            let options = { shouldClearSlugInfo };
            if (disablePageCall) {
                const staticSlugInfo = getStaticSlugInfo(this.props);
                return staticSlugInfo && this.props.receiveSlugInfo(staticSlugInfo);
            }
            //pass along preview parameter for preview mode
            if (search && search._preview) {
                options._preview = search._preview;
            }

            if (useGraphQL) {
                options.useGraphQL = true;
                if (search) {
                    options.params = {};
                    forwardQueryParams.forEach(param => {
                        if (param in search) {
                            options.params[param] = search[param];
                        }
                    });
                }
            }

            return this.props.fetchSlugInfo(fullSlug, options);
        };

        hasClientRedirect = () => {
            let { redirects } = this.props;
            let { destination } = redirects || {};
            return destination && destination.length > 0;
        };

        getBreadCrumbs = seo => {
            if (seo && seo.breadcrumbs && seo.breadcrumbs.length > 0) {
                const [firstBC] = seo.breadcrumbs;
                //TODO: temp fix for breadcrumbs in incorrect format
                if (firstBC.name && !firstBC.label) {
                    seo.breadcrumbs.forEach(bc => {
                        if (bc) {
                            bc.label = bc.name;
                        }
                    });
                }
                return seo.breadcrumbs;
            }
            return [];
        };

        getBreadcrumbProps = () => {
            let { slugInfo } = this.props;
            let { templateKey, components, seo } = slugInfo || {};
            if (templateKey === 'dlp') {
                return {
                    breadcrumbs:
                        seo && Array.isArray(seo.breadcrumbs)
                            ? seo.breadcrumbs.map(bc => ({
                                  ...(bc || {}),
                                  label: (bc && (bc.label || bc.text || bc.name)) || null,
                              }))
                            : null,
                };
            }
            return {};
        };

        render() {
            if (this.props.pageNotFound || this.hasClientRedirect()) {
                let { redirects } = this.props;
                let { status } = redirects || {};

                let redirectProps = defaultRedirect && status === 404 ? { to: defaultRedirect } : {};
                return <ErrorRedirect {...redirectProps} />;
            }

            const { slugInfo, search, match, siteConfig, pStoreID } = this.props;
            const { seo, previewMode, css, components = {}, templateKey, vanityUrl, fetching } = slugInfo || {};
            const {
                params: { dir },
            } = match;
            const { enableNewRoutes } = siteConfig || {};
            const { exitPopup } = components;
            const { activeExitPopup } = exitPopup || {};
            const { wordCloud } = seo || {};

            //TODO: If the new designs are approved this can just be for all pages
            const isV2 =
                templateKey in { 'filters-v2': 1, 'sale-v4': 1, 'compare-v2': 1, 'dlp-v2': 1, 'pdp-v2': 1 } ||
                enableNewRoutes;
            const isHomepage = templateKey === 'home' || slugInfo.vanityUrl === '/';
            const allBreadcrumbProps = {
                ...(breadcrumbProps || {}),
                ...this.getBreadcrumbProps(),
            };
            //TODO: change this from a boolean to maybe a list of props that are needed at the template level
            const pageProps = noProps
                ? { match, seo, templateKey, vanityUrl }
                : {
                      ...this.props,
                      slugInfo,
                      templateKey,
                      vanityUrl,
                  };
            if (fetching && !disablePlaceholder) {
                return <PagePlaceHolder />;
            }

            return (
                <>
                    <PagePrefetch />
                    <SchemaMarkup CustomSchemaComponent={SchemaMarkupComponent} />
                    <DynamicYieldContext slugInfo={slugInfo} />
                    <MetricsMeta />
                    <PrivateStoreHeaderLoader />
                    {!customMetaData &&
                        (SeoMetaDataComponent ? (
                            <SeoMetaDataComponent />
                        ) : (
                            <SeoMeta seo={seo} previewMode={previewMode} />
                        ))}
                    {css && <style dangerouslySetInnerHTML={Helpers.createMarkup(css)}></style>}
                    {activeExitPopup && <ExitPopup exitPopup={activeExitPopup} {...(exitPopupProps || {})} />}
                    <ModalProvider className={wrapperClassName} name={'content'} id={'pageContent'}>
                        {isV2 &&
                            !isHomepage &&
                            !disableBreadcrumbs &&
                            (BreadcrumbComponent ? (
                                <BreadcrumbComponent />
                            ) : (
                                <Breadcrumbs
                                    breadcrumbs={this.getBreadCrumbs(seo)}
                                    disableMobileBreadcrumb={disableMobileBreadcrumb}
                                    {...allBreadcrumbProps}
                                />
                            ))}
                        {slugInfo &&
                            useGraphQL &&
                            (pStoreID || slugInfo.gqlClientLoad || slugInfo.gqlAsyncClientLoad) && (
                                <GraphQLClient slugInfo={slugInfo} placeholder={<PagePlaceHolder ssr={true} />} />
                            )}
                        <AsyncDataLoader slugInfo={slugInfo} dir={dir} slug={derivedSlug(match, search)} />
                        <Component {...pageProps} />
                        {showWordCloud && wordCloud && wordCloud.length > 0 && (
                            <SEOLinks links={wordCloud} useDefaultLinks={true} className={isV2 ? 'v2' : ''} />
                        )}
                    </ModalProvider>
                    {/* Show this overlay when in preview mode */}
                    {previewMode && <PreviewOverlay slugInfo={slugInfo} previewParam={search && search._preview} />}
                    {isV2 && <ScrollToButton />}
                </>
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const { slugInfo: reduxSlugInfo, storeEnvironment, router, redirects, testFlags, siteConfig, userData } = state;
        //allow page template to override slugInfo in cases where there is no slugInfo to fetch from server
        //should be used in conjuction with customFetchSlugInfo option
        const { slugInfo: ownPropsSlugInfo } = ownProps || {};
        const slugInfo = ownPropsSlugInfo || reduxSlugInfo;
        const { originCountryCode, profileData } = userData || {};
        const { personData } = profileData || {};
        const { pStoreID } = personData || {};
        const { error } = slugInfo || {};
        const { location } = router;
        const search = parseUrlFilters(location.search);
        return {
            redirects,
            slugInfo,
            storeEnvironment,
            pathname: location.pathname,
            search,
            pageNotFound: !!error,
            siteConfig,
            originCountryCode,
            pStoreID,
        };
    };

    const mapDispatchToProps = dispatch => {
        return {
            fetchSlugInfo: (slug, options) => dispatch(fetchSlugInfo(slug, options)),
            receiveSlugInfo: slugInfo => dispatch(receiveSlugInfo(slugInfo)),
            trackPageView: slugInfo => dispatch(trackPageView(slugInfo)),
        };
    };

    return connect(mapStateToProps, mapDispatchToProps)(WithPage);
};

export default withPage;

export {
    SeoMeta,
    fetchSlugInfo,
    slugInfoReducer,
    redirectsReducer,
    scriptLoaderReducer,
    headerFooterReducer,
    withHeaderFooter,
    ScripLoader,
    loadData,
    loadGraphQLData,
    dynamicLoadData,
};
