import { ADD_REDIRECT, CLEAR_REDIRECTS } from './page-actions';
import { combineReducers } from 'redux';
import { matchRoutes } from 'react-router-config';

const basename = process.env.BASENAME || '';

const RedirectStatusReducer = (state = null, action) => {
    Object.freeze(state);
    switch (action.type) {
        case ADD_REDIRECT:
            return action.status || 301;
        case CLEAR_REDIRECTS:
            return null;
        default:
            return state;
    }
};

const RedirectCountReducer = (state = 0, action) => {
    Object.freeze(state);
    switch (action.type) {
        case ADD_REDIRECT:
            return (state += 1);
        case CLEAR_REDIRECTS:
            return 0;
        default:
            return state;
    }
};

const RedirectDestinationReducer = (state = null, action) => {
    Object.freeze(state);
    switch (action.type) {
        case ADD_REDIRECT:
            const path = action.destination;
            const routes = action.routes;
            const correctedPath = attemptToCorrectPath(path, routes);
            return correctedPath || path || null;
        case CLEAR_REDIRECTS:
            return null;
        default:
            return state;
    }
};

export default combineReducers({
    count: RedirectCountReducer,
    destination: RedirectDestinationReducer,
    status: RedirectStatusReducer,
});

/**
 * Checks whether a given path uses any of the previously used, but abandoned store basenames.
 *
 * @param {*} path path that's attempted to be used for redirect
 */
const usesOldBasename = path => {
    // map of previously used basenames that were deprecated and moved on from
    const abandonedBasenamesMap = {
        '/us-en/shop': ['/us/en'],
        '/ukstore': [],
    };

    // does the path use any of the abandoned basenames?
    let itDoes, whichOne;
    for (let nextBasename of abandonedBasenamesMap[basename]) {
        itDoes = path.startsWith(nextBasename);
        if (itDoes) {
            whichOne = nextBasename;
            break;
        }
    }

    return [itDoes, whichOne];
};

/**
 * Attempts to correct a given path by checking that the correct basename is present and more.
 * Checks the new path against the react-router routes, and if a match is found returns new path.
 * Otherwise returns false.
 *
 * @param {*} path path that's attempted to be used for redirect
 */
const attemptToCorrectPath = (srcPath, routes) => {
    let result = false;

    try {
        /**
         * Input grid:
         *
         * Absolute non-store URLs      : do nothing
         * Absolute store URLs          : attempt to match a CSR route, if no match do nothing
         * Relative non-store URLs      : do nothing, this is a mistake!
         * Relative store URLs          : make sure basename is present, then try to match a CSR route, if no match do nothing
         */

        const isAbsolute = /^https?/i.test(srcPath);
        const isStoreUrl = /hp\.com/i.test(srcPath);

        // react-router routes
        const pathRoutes = routes.filter(route => !!route.path);

        if (isAbsolute) {
            if (isStoreUrl) {
                /**
                 * Get relative url from absolute one, if absolute URL belongs to the hp store,
                 * and try to see if it matches any of our application routes.
                 *
                 * This way we'll be able to do redirect through react-router instead of making
                 * a full page reload.
                 */

                // regexp to match everything up to basename (exclusive)
                const regexp = new RegExp(`^https?\\:\\/\\/[a-zA-Z0-9.-]+(?:\\:[\\d]+)?\\/`);

                // strip everything until basename
                const relPath = srcPath.replace(regexp, '/');

                // try to match rel path against app routes
                const pathExists = !!matchRoutes(pathRoutes, relPath).length;

                // if match was found for relative url, replace absolute url with relative
                if (pathExists) {
                    result = relPath;
                }
            }
        } else {
            /**
             * If the url is not absolute, see if basename could be prepended to also try
             * to match any react-router routes and do a CSR redirect.
             * Intent is to add support for links like /slp/weekly-deals that do not contain
             * basename in them and for which matchRoutes returns 0 matches.
             */

            // start off with making sure that leading slash is present
            let correctedPath = srcPath.startsWith('/') ? srcPath : `/${srcPath}`;

            // see if path uses wrong basename, or none at all
            if (!correctedPath.startsWith(basename)) {
                const [itDoes, whichOne] = usesOldBasename(correctedPath);

                if (itDoes) {
                    // replace abandoned basename with the correct and current one
                    correctedPath = correctedPath.replace(whichOne, basename);
                } else {
                    // if not then just prepend current basename to try to match any react-router routes later
                    correctedPath = basename + correctedPath;
                }
            }

            // try to match new path against any existing react-router routes
            const pathExists = !!matchRoutes(pathRoutes, correctedPath).length;

            // if match was found for the corrected path, return it, otherwise leave it as is
            if (pathExists) {
                result = correctedPath;
            }
        }
    } catch (error) {
        return false;
    }

    return result;
};
