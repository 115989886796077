//initialize global abTest namespace for integrating with Opitmizely
window.optlyUtils = window.optlyUtils || {};

//do not redefine this, incase somehow optimizely loads first
if(!optlyUtils.abTestSubscribers){
	optlyUtils.abTestSubscribers = {};
	optlyUtils.abTestSubscribe = function(key, callback){
	  this.abTestSubscribers[key] = this.abTestSubscribers[key] || { subscribers: [], variationData: null};
	  var data = this.abTestSubscribers[key].variationData;
	  var isActive = this.abTestSubscribers[key].isActive;
	  var experimentID = this.abTestSubscribers[key].experimentID;
	  this.abTestSubscribers[key].subscribers.push(callback);
	  if(data){
	    callback(isActive(experimentID) ? data : {});
	  }
	};

	optlyUtils.publishVaration = function(key, varation, experimentID) {
	  try{
	  	  this.abTestSubscribers[key] = this.abTestSubscribers[key] || { subscribers: [], variationData: null};
		  this.abTestSubscribers[key].variationData = varation;
		  this.abTestSubscribers[key].experimentID = experimentID || null;
		  this.abTestSubscribers[key].isActive = experimentID  ? function(id){ 
		    var ids = optimizely.get('state').getActiveExperimentIds() || [];
		    for(var i = 0; i < ids.length; i++){
		      if(ids[i] === (id + "")){
		        return true;
		      }
		    }
		    return false; 
		  } : function(){ return true; };
		  var active = this.abTestSubscribers[key].isActive(experimentID);
		  // splice array then loop 
		  var curSubscibers = this.abTestSubscribers[key].subscribers; 

		  curSubscibers.forEach( function(subscriberCB) {
		    subscriberCB(active ? varation : {});
		  });
	  }catch(e){
	  	//pass
	  }
	};
}