// export const RECEIVE_CART = 'RECEIVE_CART';
export const CONFIRM_ADD_CART = 'CONFIRM_ADD_CART';
export const REMOVE_CONFIRM = 'REMOVE_CONFIRM';

export const receiveAddToCartConfirm = (confirmType, params) => ({
    type: CONFIRM_ADD_CART,
    confirmType,
    params,
});

/**
 *
 * @param {*} confirmType - the type of confirm modal that should be triggered
 * @param {*} params - The add to cart arguments
 * @returns
 */
export const confirmAddToCart = (confirmType, params) => (dispatch, getState) => {
    const { addToCartConfirmation } = getState();

    //TODO: for now there should only ever be one of these triggered at once. But we may need to handle a scenario where it's possible
    if (addToCartConfirmation && addToCartConfirmation.type === confirmType) {
        //put this failsafe here to force a re-render if the confirmType is the same
        dispatch(removeConfirm());
        setTimeout(() => {
            dispatch(receiveAddToCartConfirm(confirmType, params));
        }, 200);
    } else {
        dispatch(receiveAddToCartConfirm(confirmType, params));
    }
};

export const removeConfirm = () => dispatch => {
    return dispatch({ type: REMOVE_CONFIRM });
};
