export const LocalCache = function(cName, exdays) {
    var cname = cName;
    var that = this;
    var data;
    var exdate;
    var ex_key = "_expiration";
    let ex_days_key = "_expirationDay";

    try {
      data = JSON.parse(localStorage[cname]);
    } catch (e) {
      data = {};
    }

    if (exdays && (!data[ex_key] || data[ex_days_key] !== exdays)) {
      exdate = new Date();
      exdate = exdate.setTime(exdate.getTime() + (exdays * 24 * 60 * 60 * 1000));
      data[ex_key] = exdate;
      data[ex_days_key] = exdays;
    }

    function save() {
      try {
        localStorage.setItem(cname, JSON.stringify(data));
      } catch (e) {
        console.error("Error storing data in local storage", e);
      }
    }

    this.data = () => {
       const { [ex_key]: expiration, ...keys } = data;
       return keys;
    };

    this.get = function(k) {
      if (data[ex_key] && data[ex_key] <= Date.now()) {
        this.delete();
        return null;
      }
      return data[k];
    };

    this.set = function(k, v) {
      data[k] = v;
      save();
    };

    this.remove = function(keys) {
      //loop through list of keys and remove from cache
      for (var i = 0; i < keys.length; i++) {
        var k = keys[i];
        if (data[k]) {
          delete data[k];
        }
      }
      save();
    };

    this.delete = function() {
      try{
        data = {};
        delete localStorage[cname];
      }catch(e){

      }
    }

    this.getAllKeys = function() {
      return Object.keys(data);
    };

}