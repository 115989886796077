import { useSelector, useDispatch } from 'react-redux';

import { confirmAddToCart, removeConfirm } from '../utility/components/add-to-cart-confirm/add-to-cart-confirm-actions';

/**
 * Returns the add to cart confirm redux state
 * @name useAddToCartConfirm
 * @returns {cartInfo}
 */
export default function useAddToCartConfirm() {
    return useSelector(state => state.addToCartConfirmation);
}

/**
 * Returns the add to cart confirm redux actions
 * @name useAddToCartConfirm
 * @returns {cartInfo}
 */
export function useAddToCartConfirmActions() {
    const dispatch = useDispatch();
    return {
        confirmAddToCart: (product, type, params) => dispatch(confirmAddToCart(product, type, params)),
        removeConfirm: () => dispatch(removeConfirm()),
    };
}
