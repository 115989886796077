const utcToLocalTime = dateString => {
    try {
        //reformat yyyy-mm-dd to yyyy/mm/dd (not using regex to avoid replacing additional '-')
        dateString = dateString.replace('-', '/').replace('-', '/');
        if (!dateString.includes('UTC')) {
            dateString += ' UTC';
        }
        return new Date(dateString);
    } catch (e) {
        return null;
    }
};

const toDateString = (date, isAbbreviated) => {
    const monthNames = isAbbreviated
        ? ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        : [
              'January',
              'February',
              'March',
              'April',
              'May',
              'June',
              'July',
              'August',
              'September',
              'October',
              'November',
              'December',
          ];
    date = monthNames[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
    return date;
};

const etrDateFormat = date => {
    try {
        return date.toString().split(':')[0].replace(/\s/g, '').replace(',', '');
    } catch (e) {
        return null;
    }
};

const getDayDifference = (d1, d2) => {
    try {
        const date1 = typeof d1 === 'string' ? new Date(d1) : d1;
        const date2 = typeof d2 === 'string' ? new Date(d2) : d2;
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    } catch (e) {
        return null;
    }
};

export default {
    utcToLocalTime,
    toDateString,
    etrDateFormat,
    getDayDifference,
};
