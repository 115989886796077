import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import getRoutes from './routes';
import ABTestRedirect from './abtest/components/abtest-redirect';
import ThirdPartyTags from './third-party-tags';
import AdvancedScriptLoader from './third-party-tags/advanced-script-loader';
import IdleUser from './utility/components/idle-user';
import AboveTheFoldOnlyServerRender from './shared/components/above-the-fold-only-server-render';
import UserInputUpdater from './ui/components/user-input-type';
import SiteConfig from './page/site-config';
import Robots from './page/components/robots';
import FullPageLoader from './page/components/full-page-loader';
import StellarContext from './page/components/stellar-context';
import NewsLetter from './page/components/news-letter';
import AddToCartConfirm from './utility/components/add-to-cart-confirm';
import DispatchHydrationEvent from './third-party-tags/dispatch-hydration-event';
import GlobalMetrics from './metrics/components/global-metrics';
import AbTestConsentListener from './abtest/components/abtest-consent-listener';
import './App.less';
import '@hpstellar/global-styles/css/main.css';

/**
 * Note that this App entry point is not used for SSR.
 * So components added here will not be rendered until after hydration
 * See lib\web\client-server.js
 */
class App extends Component {
    render(props) {
        const { basename } = this.props;
        return (
            <StellarContext>
                <div className="App">
                    <FullPageLoader />
                    <Robots />
                    <UserInputUpdater />
                    <GlobalMetrics />
                    <AbTestConsentListener />
                    <DispatchHydrationEvent />
                    <Switch>
                        <ABTestRedirect>
                            {({ abTestRedirects }) => {
                                //put ab test redirect routes before normal routes
                                return renderRoutes(abTestRedirects.concat(getRoutes(basename)));
                            }}
                        </ABTestRedirect>
                    </Switch>
                    <AboveTheFoldOnlyServerRender showTimeout={6000} skip={true}>
                        {/*dont start tracking idle user until well after page load*/}
                        <IdleUser />
                    </AboveTheFoldOnlyServerRender>
                    <AboveTheFoldOnlyServerRender skip={true} robots={false}>
                        <ThirdPartyTags />
                    </AboveTheFoldOnlyServerRender>
                    <SiteConfig />
                    <AddToCartConfirm />
                    <AboveTheFoldOnlyServerRender showTimeout={1000} skip={true}>
                        <NewsLetter />
                    </AboveTheFoldOnlyServerRender>
                    <AdvancedScriptLoader />
                </div>
            </StellarContext>
        );
    }
}

export default App;
