import { RECEIVE_COMPARE_RESULTS } from './compare-actions';

const CompareResultsReducer = (state = null, action) => {
    Object.freeze(state);
    switch (action.type) {
        case RECEIVE_COMPARE_RESULTS:
            return action.compareResults;
        default:
            return state;
    }
};

export default CompareResultsReducer;             