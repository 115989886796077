import React from 'react';

import { StoreAppAPI } from '../core/src/storeapp-api-client';
import ETRWCSApi from '../core/src/etr-wcs-api-client';
import { Helpers } from '../core';

export const RECEIVE_COMPARE_RESULTS_V2 = 'RECEIVE_COMPARE_RESULTS_V2';
export const RECEIVE_COMPARE_RESULTS = 'RECEIVE_COMPARE_RESULTS';
export const TOGGLE_COMPARE_MODAL = 'TOGGLE_COMPARE_MODAL';
export const UPDATE_COMPARE_PRODUCTS = 'UPDATE_COMPARE_PRODUCTS';
export const CLEAR_COMPARE_PRODUCTS = 'CLEAR_COMPARE_PRODUCTS';
export const TOGGLE_COMPARE_DRAG = 'TOGGLE_COMPARE_DRAG';
export const SET_COMPARE_ERROR = 'SET_COMPARE_ERROR';
export const SET_COMPARE_MAXED = 'SET_COMPARE_MAXED';
export const SET_COMPARE_EXPANDED = 'SET_COMPARE_EXPANDED';
export const SET_VIEW_OFFERS = 'SET_VIEW_OFFERS';

export const setCompareExpanded = expanded => ({
    type: SET_COMPARE_EXPANDED,
    expanded,
});

export const setViewOffers = sku => ({ type: SET_VIEW_OFFERS, sku });

export const receiveCompareResults = compareResults => ({
    type: RECEIVE_COMPARE_RESULTS,
    compareResults,
});

//TODO: Remove this once all pages are on the new redesign template
export const receiveCompareResultsV2 = compareResults => ({
    type: RECEIVE_COMPARE_RESULTS_V2,
    compareResults,
});

export const toggleCompareModal = toggle => ({
    type: TOGGLE_COMPARE_MODAL,
    toggle,
});

export const toggleCompareDrag = isDragging => ({
    type: TOGGLE_COMPARE_DRAG,
    isDragging,
});

export const setCompareError = error => ({
    type: SET_COMPARE_ERROR,
    error,
});

export const setCompareMaxed = maxed => ({
    type: SET_COMPARE_MAXED,
    maxed,
});

export const updateCompareProducts = products => ({
    type: UPDATE_COMPARE_PRODUCTS,
    products,
});

//TODO: switch this for product catalog prop once we can run update job
const getTopCategory = product => {
    const map = {
        Gxc6P: 'Laptops',
        xm5DJ: 'Monitors',
        jRHnr: 'Accessories',
        'o-Q_Q': 'Carepacks',
        cGAFB: 'Desktops',
        bXmjy: 'Printers',
        '70m-W': 'Ink, toner and paper',
    };
    const { topCategory, categories } = product;
    return (
        topCategory ||
        (categories || []).reduce((catName, id) => {
            if (!catName && id in map) {
                catName = map[id];
            }
            return catName;
        }, undefined)
    );
};

export const toggleCompareProduct =
    (product, isV2 = false) =>
    (dispatch, getState) => {
        dispatch(setCompareExpanded(true));
        const errorTimer = (time = 2000, maxed) => {
            let errorFunc;
            if (maxed) errorFunc = setCompareMaxed;
            else errorFunc = setCompareError;

            setTimeout(() => {
                dispatch(errorFunc(false));
            }, time);
            return dispatch(errorFunc(true));
        };

        const { products } = getState().compare;
        dispatch(setCompareError(false));
        if (products.length > 0) {
            const attributes = products[0].attributes;
            const selectedAttributes = product.attributes;

            //if it's a StoreApp product type, check categories in a different way
            if (attributes && selectedAttributes && attributes?.pm_producttype === selectedAttributes?.pm_producttype) {
                if (
                    ['Accessories', 'Options and Accessories'].includes(attributes.pm_producttype) &&
                    attributes.pm_category !== selectedAttributes.pm_category
                ) {
                    return errorTimer();
                }
            }
            // product count error
            if (products.length > 3 && products.filter(x => x.sku === product.sku).length === 0) {
                return errorTimer(5000, true);
            }
            // product type error
            const category = getTopCategory(products[0]);
            if (category !== getTopCategory(product)) {
                return errorTimer();
            }
        }

        let newProducts = newCompareArray(product, products);
        dispatch(updateCompareProducts(newProducts));
    };

export const getProductCompare = () => (dispatch, getState) => {
    const { products } = getState().compare;
    if (products.length > 1) {
        dispatch(fetchCompare(products, 1, 16, true));
    }
};

export const getConfigCompare = catEntryId => (dispatch, getState) => {
    ETRWCSApi.cto.compare(catEntryId).then(resp => {
        dispatch(receiveCompareResultsV2(resp.specs));
        dispatch(updateCompareProducts(resp.products));
    });
};

export const clearCompareProducts = () => ({
    type: CLEAR_COMPARE_PRODUCTS,
});

export const fetchCompare =
    (products, skipCache = 1, maxSpecs = 16, v2 = false) =>
    dispatch => {
        const storeAppAPI = new StoreAppAPI();

        const compare = v2
            ? () =>
                  ETRWCSApi.component.get('product', 'compare', {
                      productIds: products
                          .map(product => product.productId || product.prodId || Helpers.getCatEntryId(product))
                          .join(','),
                  })
            : storeAppAPI.product.compare(products.map(product => product.sku));

        return compare(skipCache, maxSpecs)
            .then(resp => {
                dispatch(setCompareError(false));
                if (v2) {
                    const compareList = resp?.compare || [];

                    const index = {};

                    compareList.forEach((product, pos) => {
                        product.spec.forEach((attr, i) => {
                            if (!index[attr.name]) {
                                index[attr.name] = {
                                    order: i,
                                    name: attr.name,
                                    tooltip: attr.tooltip,
                                    value: new Array(compareList.length).fill([]),
                                };
                            }

                            index[attr.name].value[pos] = attr.value;
                        });
                    });

                    return dispatch(receiveCompareResultsV2(Object.values(index)));
                }

                return dispatch(receiveCompareResults(resp.data));
            })
            .catch(error => {
                dispatch(receiveCompareResultsV2([]));
                dispatch(setCompareError(true));
            });
    };

const newCompareArray = (product, oldState) => {
    let compareArray = oldState.slice(0);

    let index = compareArray.findIndex(cProduct => cProduct.sku === product.sku);
    if (index === -1) {
        compareArray.unshift(product);
    } else {
        compareArray.splice(index, 1);
    }
    if (compareArray.length > 4) compareArray.pop();
    return compareArray;
};

/**
 * Loops through products in compare state and removes React Node elements
 * @param {*} compare
 * @returns
 */
export const getCleanCompareState = compare => {
    const { products, ...rest } = compare;
    const cleanCompare = {
        ...rest,
        products: products.map(p => {
            try {
                //if can be stringified leave as is
                delete p.price;
                JSON.stringify(p);
                return p;
            } catch (e) {
                return Object.keys(p).reduce((r, k) => {
                    if (!React.isValidElement(p[k])) {
                        r[k] = p[k];
                    }
                    return r;
                }, {});
            }
        }),
    };
    return cleanCompare;
};
