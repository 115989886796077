export const LogAPI = function (apiConfig, http, helpers) {
    let self = this;
    let path = '/logs';
    let isLocal;
    try {
        isLocal = window.location.hostname.indexOf('localhost') > -1;
    } catch (e) {
        isLocal = false;
    }

    self.log = ({ message, info, level = 'info' }) => {
        if (!message) {
            console.log('No Message.');
        } else {
            let userAgent, screenSize, currentUrl, sessionId;
            try {
                sessionId = helpers.getCookie('s_vi');
                if (!sessionId) {
                    sessionId = `JSESSIONID:${helpers.getCookie('JSESSIONID')}`;
                } else {
                    sessionId = `ADOBE:${sessionId}`;
                }

                currentUrl = window.location.href;
                userAgent = window.navigator.userAgent;
                screenSize = `${window.screen.availWidth} x ${window.screen.availHeight}`;
            } catch (e) {
                userAgent = userAgent || 'N/A';
                screenSize = screenSize || 'N/A';
                currentUrl = currentUrl || 'N/A';
                sessionId = sessionId || 'NA';
            }

            const content = {
                userAgent,
                screenSize,
                currentUrl,
                message,
                timestamp: new Date(Date.now()).toString(),
                level,
                info,
                sessionId,
                source: `hpstore-front:${apiConfig.stage}`,
            };

            if (isLocal) {
                return level !== 'info' ? console.error(content) : console.log(content);
            } else {
                //TODO: replace this with a call to sentry
                return Promise.resolve();
            }
        }
    };
};
