import React from 'react';

import JsonLd from '../../../shared/components/json-ld';
import { AnswerWrapper, QuestionWrapper, QuestionNameWrapper } from './faqs';
import { HowToStartStep } from './how-tos';

const renderWrappedComponent = (wrapper, children) => <wrapper.component>{children}</wrapper.component>;
const getMicrodataWrapperComponent = ({itemType}) => ({ component: ({children}) => <div itemScope="true" itemType={itemType}>{children}</div> });

export const schemaModifiers = {
    'faqs': () => {
        let currIndex,
            currType,
            prevBlockType,
            prevFaqIndex,
            evaluatingFaq = false,
            questionNameWrapper,
            answerWrapper,
            questionWrapper;

        const componentListTypes = {
            'ul':1,
            'ol':1
        }

        const resetIterationVariables = () => (currType = currIndex = prevBlockType = evaluatingFaq = false);

        return {
            blockModifier: (blockData) => {
                try{
                    let modifiedBlocks =  blockData.map( (data, i) => {
                        let {faqIndex, faqType} = data;

                        if( prevBlockType === 'answer' && faqType !== 'answer' && evaluatingFaq && currIndex === prevFaqIndex){
                            evaluatingFaq = false;
                            currIndex = null;
                        }

                        if(faqType === 'question' && (!currIndex || currIndex === faqIndex)){
                            evaluatingFaq = true;
                            currIndex = faqIndex;
                            data.faqWrapper = 'QuestionName';
                        }
                        else if(faqType === 'answer' && currIndex === faqIndex && evaluatingFaq){
                            data.faqWrapper = 'Answer';
                        }
                        else if(evaluatingFaq){
                            data.faqWrapper = 'Question';
                            data.faqIndex = currIndex;
                        }

                        prevBlockType = faqType;
                        prevFaqIndex = faqIndex;
                        return data;
                    });
                    return modifiedBlocks;
                }catch(e){
                    return blockData;
                }
            },
            contentModifier: (content, blockMap) => {
                try{
                    if(content.length !== blockMap.length){
                        return { content, blockMap };
                    }
                    resetIterationVariables();
                    let listComponentAdded = false;
                    let updatedBlockMap = [];

                    const resetWrappers = () => {
                        questionWrapper = conditionallyInitializeWrapper(null, QuestionWrapper);
                        questionNameWrapper = conditionallyInitializeWrapper(null, QuestionNameWrapper);
                        answerWrapper = conditionallyInitializeWrapper(null, AnswerWrapper);
                    }
            
                    const getComponent = (component, index, currentBlockData) => {

                        // components are never arrays when it's a list component
                        if(componentListTypes[component.type] && !listComponentAdded){
                            listComponentAdded =true;
                            return component;
                        }
                        else if(componentListTypes[component.type] && listComponentAdded){
                            return null;
                        }

                        if(!(currentBlockData instanceof Array)) {
                            return component;
                        }

                        if(component instanceof Array){
                            return index < component.length ? component[index] : null;
                        }

                        return component;
                    }
            
                    const conditionallyInitializeWrapper = (wrapper, component) => {
                        return !wrapper
                               ? {component, children: []}
                               : wrapper;
                    }

                    const addToModifiedBlockMap = (blockData, index) => {
                        if(index < updatedBlockMap.length) updatedBlockMap[index] = updatedBlockMap[index].concat([blockData]);
                        else updatedBlockMap = updatedBlockMap.concat([[blockData]]);
                    }
            
                    const addAnswerComponent = () => {
                        const finalAnswerComp = renderWrappedComponent(answerWrapper, answerWrapper.children);
                        questionWrapper.children.push(finalAnswerComp);
                    }
            
                    const addQuestionNameComponent = () => {
                        const finalQuestionNameComp = renderWrappedComponent(questionNameWrapper, questionNameWrapper.children);
                        questionWrapper.children.push(finalQuestionNameComp);
                    }
            
                    const addQuestionComponentToComponentsArray = (allComponents) => {
                        const finalQuestionComponent = renderWrappedComponent(questionWrapper, questionWrapper.children);
                        allComponents.push(finalQuestionComponent);
                    }

                    let isFaqPage = false;
                    const completeQuestionMicrodataComponent = (allContent) => {
                        addAnswerComponent();
                        addQuestionComponentToComponentsArray(allContent);
                        evaluatingFaq = false;
                        isFaqPage = true;
                    }
            
                    function addToQuestionWrapperOrComponentsArray(shouldAddToWrapper, component, wrapper, componentsArray){
                        if(shouldAddToWrapper){
                            wrapper.children.push(component);
                        }
                        else{
                            componentsArray.push(component);
                        }
                    }

                    let prevBlockType;
                    let modifiedContent = content.reduce((allContent, nextContent, contentIndex) => {
                            const blockData = blockMap[contentIndex];
                            listComponentAdded=false;
                            let currentBlockData = blockData instanceof Array
                                                   ? blockData
                                                   : [blockData];

                            currentBlockData.forEach( (currentBlock, blockIndex) => {
                                    let { faqWrapper } = currentBlock || {};
                                    let currentComponent = getComponent(nextContent, blockIndex, blockData);

                                    // handle previous insertion based on wrappers
                                    if( prevBlockType === 'Answer' && faqWrapper !== 'Answer'){
                                        completeQuestionMicrodataComponent(allContent);
                                        resetWrappers();
                                    }
                                    else if(prevBlockType === 'QuestionName' && faqWrapper !== 'QuestionName'){
                                        addQuestionNameComponent();
                                    }
                
                                    if(faqWrapper === 'QuestionName'){
                                        
                                        evaluatingFaq = true;
                                        questionWrapper = conditionallyInitializeWrapper(questionWrapper, QuestionWrapper);
                                        questionNameWrapper = conditionallyInitializeWrapper(questionNameWrapper, QuestionNameWrapper);
                                        questionNameWrapper.children.push(currentComponent);
                                    }
                                    else if(faqWrapper === 'Answer'){
                                        let isLastBlock = blockIndex === blockData.length -1 && contentIndex === content.length - 1;
                                        answerWrapper = conditionallyInitializeWrapper(answerWrapper, AnswerWrapper);
                                        answerWrapper.children.push(currentComponent);
                                        if(isLastBlock){
                                            completeQuestionMicrodataComponent(allContent);
                                        }
                                    }
                                    else{
                                       addToQuestionWrapperOrComponentsArray(evaluatingFaq, currentComponent, questionWrapper, allContent);
                                    }
                                    
                                    prevBlockType = faqWrapper;
                                    addToModifiedBlockMap(currentBlock, allContent.length);
                                });

                            return allContent;
                        }, []);
                        
                        // if evaluatingFaq still set to true, then return the original contents/blockMap
                        // because this indicates mistake in creating the FAQ markup
                        if(evaluatingFaq || !isFaqPage){
                            return {
                                content,
                                blockMap
                            }
                        }   
                        let wrapper = getMicrodataWrapperComponent({itemType:"https://schema.org/FAQPage"});

                        return {
                            content: modifiedContent,
                            blockMap: updatedBlockMap,
                            wrapper
                        };
                    }catch(e){
                        // fallback: no microdata, return the original rendered component
                        return { content, blockMap };
                    }
            }
        }
    },
    'how-tos': () => {
        let foundSteps = {};
        let jsonLd;
        return {
            /*
              This is used to collect data from the original draftjs object.
              This is needed for how-to schema to ease implementation of the jsonld
              since going through the entity alone may require complex logic for getting the texts within
              nested inline styles. The jsonLd is generated through merch-ui.
            */
            dataAggregator: (parsed) => {
              try{
                jsonLd = parsed.jsonLd['how-tos'];
              }catch(e){}
            },
            blockModifier: (blockData) => blockData,
            contentModifier: (content, blockMap) => {
              try{                
                if(jsonLd && jsonLd instanceof Array && jsonLd.length === 1){
                  let jsonLdType = 'how-tos';
                  let jsonldComponent = <JsonLd key={`${jsonLdType}-jsonld`} data={jsonLd[0]} type={jsonLdType} shouldNotUseDefaults={true}/>
                  content.push(jsonldComponent);
                  blockMap.push({});
                }
              }catch(e){}

              return {
                content,
                blockMap
              }
            },
            entityModifier: (entityChildren, entityData, rest, component, parsed) => {

                try{
                  let {nestedEntity : {howtoStepIndex, howtoMeta} = {}} = entityData || {};
                  if(jsonLd && howtoStepIndex && !foundSteps[howtoStepIndex] && howtoMeta.urlId && howtoMeta.url ){
                    foundSteps[howtoStepIndex] = 1;
                    return <HowToStartStep howtoMeta={howtoMeta}>{component}</HowToStartStep>
                  }
                }catch(e){}
                
                return component;
            }
        }
    }
};

export const isTechAtWork = (tags, categories) => (
    (tags instanceof Array && tags.find(tag => tag === 'tech-at-work')) ||
    (categories instanceof Array && categories.find(cat => cat === 'tech-at-work'))
);

export const TECH_AT_WORK_TAG = 'tech-at-work';