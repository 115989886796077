import { createMetrics } from 'react-metrics';

import {
    _addToCart,
    _removeFromCart,
    _updateCart,
    _compare,
    _pageView,
    _sort,
    _variantsSelection,
    _productClick,
    parseMetricParams,
    getDerivedPageViewParameters,
} from './track-params';
import {
    getDerivedProductMetricsV2,
    addCartProductMap,
    templateAnalytics,
    getProductMap,
    parseAnalyticsParameters,
    getCartGtmId,
} from './metrics-helpers';

import ActionTypes from './action-types';
import { Helpers } from '../core/src/helpers';
import MetricsConfig from './metrics.config';
import { ADD_REDIRECT } from '../page/page-actions';
import ETRWCSApi from '../core/src/etr-wcs-api-client';

const metrics = createMetrics(MetricsConfig);

const CUR = 'USD'; // currency
const CATEGORY_MAP = {
    accessories: 'accessories',
    carepack: 'care packs',
    desktops: 'desktops',
    monitor: 'monitor',
    notebooks: 'laptops',
    printers: 'printers',
    supplies: 'ink, tonerpaper',
    tablets: 'laptops',
};
const XSELL_TYPE_MAP = {
    tabs: '3up-tabs',
    sixup: '6up',
};
const SMB_SUBCAT = {
    desktopcommercial: 1,
    notebookcommercial: 1,
};

const TEMPLATE_KEY_PAGE_NAMES = {
    pageNameL5: {
        dlp: 'dynamic',
        blog: 'article',
        'blog-list': 'list',
    },
    pageNameL6: {
        dlp: 'dlp',
        blog: 'tech-takes',
        'blog-list': 'tech-takes',
        filters: 'plp',
        'sale-v3': 'slp',
        'gift-guide': 'gift-guide',
    },
};
const PAGE_VIEW_EVENT_TYPE = 'e_pageView';

// Tracks the previous url where the page view event fired. This is needed to prevent double page views when switching between templates and page tracking is delayed
let TRACKED_PAGE = { url: null };
let PAGE = { RECEIVED: false };
let USER = { RECEIVED: false };
let PRODUCTS;
let prev_location = '';
let pg_redirect_location = null; // used to prevent double page views when making a PG redirect

let prev_pdp = '';

// legacy
const pdpPageView = (product, getState, vanityUrl) => {
    let params = {};
    let {
        name,
        listPrice: price,
        attributes,
        sku: id,
        slug: pageName,
        isSmb = 'No',
        prdClass,
        variantSelected,
        variants = [],
        pdpConfig = {},
    } = product;

    // prevents double page view when navigating between pdps (v2->v1, v2->v2, v1->v2, v2->v2)
    if (prev_pdp === id) {
        return;
    }
    prev_pdp = id;

    let {
        facet_subbrand,
        pm_producttype,
        subbrandname,
        plcode: category,
        product_line,
        3211896: tppBrand,
    } = attributes || {};

    let { analytics, templateKey } = (product && product.pdpConfig) || {};
    let { pageNameL5, pageNameL6, pageNameL7, businessUnit: pdpBusinessUnit } = analytics || {};

    // get brand name
    let brand = facet_subbrand || subbrandname || tppBrand || pm_producttype;
    let [pageFunction] = [prdClass !== 'MMD' ? 'pdp' : 'mmd'];

    if (prdClass === 'PRT' && variants.length > 0 && !variantSelected) {
        return;
    }

    let analyticsCategory = category || product_line;
    let pageCategory = CATEGORY_MAP[Helpers.getMappedCat(analyticsCategory)] || Helpers.getMappedCat(analyticsCategory);
    let businessUnit = isSmb === 'Yes' ? 'business' : 'consumer';

    PAGE.pageNameL5 = !!pageNameL5 ? pageNameL5 : pageCategory;
    PAGE.pageNameL6 = !!pageNameL6 ? pageNameL6 : pageFunction;
    PAGE.pageNameL7 = !!pageNameL7 ? pageNameL7 : pageName;
    PAGE.businessUnit = !!pdpBusinessUnit ? pdpBusinessUnit : businessUnit;
    PAGE.templateKey = templateKey;
    PAGE.RECEIVED = true;

    // going from homepage, cat, mlp or mdp may add these fields to PAGE. These shouldn't be included in the PDP Page views.
    if (PAGE.market_segment) delete PAGE.market_segment;
    if (PAGE.lifecycle) delete PAGE.lifecycle;
    if (PAGE.target_country) delete PAGE.target_country;
    if (PAGE.pageNameL8) delete PAGE.pageNameL8;

    // convert price to string
    price += '';

    PRODUCTS = [
        {
            id,
            name,
            price,
            category,
            brand,
        },
    ];

    if (USER.RECEIVED) {
        // set up page params
        params = _pageView(PAGE, USER, PRODUCTS, getState());
        // track page view
        metrics.api.pageView(params);

        TRACKED_PAGE.url = vanityUrl || `pdp/${pageName}`;
    }
};

export default function metricsMiddleware({ getState, dispatch }) {
    return next => action => {
        const returnValue = next(action);
        let params = {};
        switch (action.type) {
            case ADD_REDIRECT: {
                /* Intercept redirect actions to prevent double pageviews. The only time isPGRedirect is true is when
				   a product group tab that doesn't exist is visited. In this case, the only page view event triggered is in
				   RECEIVE_SLUG_INFO, and the extra page view in LOCATION_CHANGE is prevented 
				   (pg_redirect_location===location condition check in LOCATION_CHANGE makes sure the extra page view doesn't occur)
				*/
                try {
                    let { destination, isPGRedirect } = action;
                    pg_redirect_location = isPGRedirect ? destination : null;
                } catch (e) {}
                break;
            }
            case ActionTypes.ADD_TO_CART: {
                // init product variable
                let { product = {}, addOns, qty, cartData = {}, baseProductSku, xsellType, metricParams } = action;
                let { useCustomMetrics } = metricParams || {};

                let xsellMethod = XSELL_TYPE_MAP[xsellType] || xsellType || (product && product.xsellMethod);

                let productPrices = {};
                try {
                    productPrices = getState().productData.productInfo.productPrices;
                } catch (e) {}

                // convert to empty array if null
                addOns = addOns || [];

                // TODO: May need to refactor this so metricParams is always in the action object.
                const productMetricParams = product && product.metricParams;
                // get cart data
                const products = product instanceof Array ? product : [{ product, qty }, ...addOns];

                product = products.reduce((prds, cur) => {
                    // update multi products format
                    cur = cur.product ? cur : { product: cur, qty };

                    let {
                        product: { name, listPrice, price, sku: id, attributes, category: categoryV2, brand: brandV2 },
                        qty: quantity,
                    } = cur;
                    let { facet_subbrand, pm_producttype, subbrandname, plcode, 3211896: tppBrand } = attributes || {};
                    let { category: derivedCategory } = getDerivedProductMetricsV2(product);
                    let { salePrice, regularPrice } = price || {};
                    let hpservicesPrice = productPrices && productPrices[id];
                    let {
                        plAndFcetSbbrnd,
                        facet_subbrand: hpservicesFacetSubbrand,
                        regularPrice: hpservicesRegPrice,
                        salePrice: hpservicesSalePrice,
                    } = hpservicesPrice || {};
                    // get brand name
                    let brand = hpservicesFacetSubbrand || facet_subbrand || subbrandname || tppBrand || pm_producttype;
                    let category = plcode;

                    let metricPrice =
                        metricParams &&
                        metricParams.price &&
                        (metricParams.price.salePrice || metricParams.price.regularPrice);
                    let productPrice =
                        hpservicesSalePrice || hpservicesRegPrice || salePrice || regularPrice || price || metricPrice;

                    // convert price to string
                    productPrice += '';

                    prds.push({
                        // brandV2 and categoryV2 are values from getDerivedProductMetricsV2()
                        brand: brandV2 || brand,
                        category: categoryV2 || category,
                        derivedCategory,
                        id,
                        name,
                        price: productPrice,
                        quantity,
                        xsellProdInfo: baseProductSku || product.xsellProdInfo,
                        xsellMethod,
                    });

                    return prds;
                }, []);

                let state = getState();
                // set add to cart params

                params = _addToCart(product, CUR, cartData.id, productMetricParams || metricParams, state);

                // set up pageLoad analytic call
                try {
                    if (params.ecommerce.add.products.length)
                        metrics.api.pageView({ ...params, useCustomMetrics, cartItems: cartData.items });
                } catch (e) {}
                break;
            }
            // case ActionTypes.REMOVE_FROM_CART: {
            //     // TODO: need to find a way to pass category on cart remove
            //     // get cart data
            //     let { product, qty: quantity, cartData = {} } = action;
            //     let {
            //         name,
            //         amt: { uPrice: price },
            //         pNum: id,
            //         ceId,
            //     } = product || {};

            //     // convert price to string
            //     price += '';

            //     let productGtm = getProductMap(getProductMapCartKey(product)) || getProductMap(id);

            //     let promise = productGtm
            //         ? Promise.resolve()
            //         : ETRWCSApi.component.get('product', 'product-initial', { productId: ceId });

            //     promise.then(productInitialResp => {
            //         let productIntial = productInitialResp && productInitialResp.product;
            //         let productMetrics = productIntial ? addCartProductMap(product, productIntial) : productGtm;
            //         // set up product
            //         let products = [
            //             {
            //                 id,
            //                 name,
            //                 price,
            //                 quantity,
            //                 ...(productMetrics || {}),
            //             },
            //         ];

            //         // set remove from cart params
            //         params = { ..._removeFromCart(products, CUR), cartItems: cartData.items };

            //         // set up pageLoad analytic call

            //         metrics.api.pageView(params);
            //     });
            //     break;
            // }
            // case ActionTypes.UPDATE_CART: {
            //     let { cartData = {} } = action;
            //     metrics.api.pageView(_updateCart(cartData));
            //     break;
            // }
            // ignore for now since users have to re-initiate the page when they log-in (Move from APP > ETR > APP).
            // case ActionTypes.FETCH_UTILITY:{
            // 	USER.RECEIVED = false;

            // 	break;
            // }
            case ActionTypes.RECEIVE_UTILITY: {
                // track on first time user data is loaded
                if (USER.RECEIVED) {
                    return;
                }

                // get users data
                let {
                    personData: { agentLogonId, userTier, userType, loyalty, pStoreID, analytic_hemid, profileType },
                } = action.utilityInfo.profileData;
                let analyticHemidValue = !analytic_hemid ? Helpers.getCookie('hpmmd.user.hemid') : analytic_hemid;
                const customerSegment = profileType === 'C' ? 'consumer' : profileType === 'B' ? 'business' : undefined;

                if (analyticHemidValue) USER.userID = analyticHemidValue;
                else if (USER.userID) delete USER.userID;
                // set users data
                USER.logged_in = (!!userType && userType.toLowerCase()) === 'r' ? true : false;
                // previously, if not logged in, USER.tier = gen. If logged and no tier, USER.tier=registered
                USER.tier = !!agentLogonId ? 'call center' : USER.logged_in ? 'general' : 'guest';
                let discountTiers = {
                    epp: 1,
                    hpepp: 1,
                };
                USER.tier =
                    USER.tier === 'general' && pStoreID ? discountTiers[pStoreID] || `pri-${pStoreID}` : USER.tier;
                // isCallCenter updated 6/18/21 (previously it was = !!agentLogonId ? 'wcs call center' : 'wcs web')
                USER.isCallCenter = !!agentLogonId;
                USER.RECEIVED = true;

                // if customerSegment derived from HP services use it, otherwise only use customerSegment when logged in
                USER.customerSegment = customerSegment
                    ? customerSegment
                    : USER.logged_in
                      ? !!pStoreID
                          ? 'business'
                          : 'consumer'
                      : undefined;

                if (PAGE.RECEIVED) {
                    const state = getState();
                    const { vanityUrl } = (state && state.slugInfo) || {};
                    TRACKED_PAGE.url = vanityUrl;

                    // set up page params
                    params = _pageView(PAGE, USER, PRODUCTS, state, dispatch);

                    // track page view

                    metrics.api.pageView(params);
                }
                break;
            }
            /* TEMP: seems like no tracking event is firing on select <option> elements. My assumption is select is firing onChange event,
			   and not a click event; therefore, not triggering react-metrics.
			   TODO: Build a dropdown component using divs and anchor tags. PROS: event handling, CSS style modification. CONS: lost select api, and event handling. */
            // case ActionTypes.TRACK_SORT:
            // case ActionTypes.SORT_PRODUCTS: {
            //     let { sortValue, analyticsLabel } = action;
            //     params = _sort(analyticsLabel || sortValue);

            //     metrics.api.pageView(params);

            //     break;
            // }
            /* There are multiable elements/components that trigger the compare modal, couldn't think of a way to manage track attributes
			   for all componanets, rather than subscriping to MODAL_TOGGLE and fire tracking values when compare modal opens */
            case ActionTypes.TOGGLE_COMPARE_MODAL: {
                let { toggle } = action;
                // TODO: CHECK IF THIS REQUIRES PAGE VIEW
                toggle && (params = _compare(getState().compare.products)) && metrics.api.pageView(params);

                break;
            }
            case ActionTypes.FETCH_SLUG_INFO: {
                PAGE.RECEIVED = false;

                break;
            }
            // TRACK_PAGE_VIEW action is only dispatched from the usePageView() hook which is responsible for generating other data for page view.
            case ActionTypes.TRACK_PAGE_VIEW: {
                let { vanityUrl, urlParams = {}, analytics, templateKey, components, analyticsData } = action.slugInfo;
                let {
                    simple_title,
                    page_level,
                    product_type,
                    family,
                    bu,
                    segment,
                    lifecycle,
                    country,
                    derivedAnalyticsData,
                } = analyticsData || {};

                // prevents double page views when Page component remounts,
                // and on first mount in MetricsMeta, the previous slugInfo is the prop, causing another page view
                if (TRACKED_PAGE.url === vanityUrl) {
                    break;
                }

                // reset prev_pdp when going to different page type. this prevents double page views on pdps
                if (templateKey && !/pdp/.test(templateKey)) {
                    prev_pdp = '';
                }
                // for pdp-v2 tracking // legacy
                // if(templateKey === 'pdp-v2'){
                // 	let { productInitial: product} = components || {};
                // 	pdpPageView(product, getState, vanityUrl);
                // 	break;
                // }

                let [directory, pageName] = vanityUrl.split('/');

                let [type, category, businessUnit] = [
                    vanityUrl === 'plp/accessories' ? 'accessories' : 'static',
                    'landing',
                    'consumer',
                ]; // move to vanity/merch ui ?

                let { pageNameL5, pageNameL6, pageNameL7, businessUnit: analyticsBusinessUnit } = analytics || {};

                let template = directory === 'tech-takes' && !templateKey ? 'blog-list' : templateKey;
                type = TEMPLATE_KEY_PAGE_NAMES.pageNameL5[template] || type;
                category = TEMPLATE_KEY_PAGE_NAMES.pageNameL6[template] || category;

                PAGE.pageNameL5 =
                    templateKey === 'sale-v3'
                        ? 'slp'
                        : derivedAnalyticsData.page_level || page_level || (!!pageNameL5 ? pageNameL5 : type);
                PAGE.pageNameL6 =
                    derivedAnalyticsData.product_type || product_type || (!!pageNameL6 ? pageNameL6 : category);
                PAGE.pageNameL7 =
                    derivedAnalyticsData.family || family || (!!pageNameL7 ? pageNameL7 : pageName || directory);

                PAGE.pageNameL8 = derivedAnalyticsData.simple_title || simple_title;
                PAGE.market_segment = derivedAnalyticsData.segment || segment;
                PAGE.lifecycle = derivedAnalyticsData.lifecycle || lifecycle;
                PAGE.target_country = derivedAnalyticsData.country || country;

                PAGE.businessUnit =
                    derivedAnalyticsData.bu || bu || (!!analyticsBusinessUnit ? analyticsBusinessUnit : businessUnit);
                PAGE.urlParams = urlParams;
                PAGE.templateKey = templateKey;
                PAGE.RECEIVED = true;

                // search results page
                if (PAGE.pageNameL5 === 'search') {
                    let { metrics } = getState() || {};
                    let { customMetrics } = metrics || {};
                    let { searchResults } = customMetrics || {};
                    PAGE.postPageViewActionParameters = PAGE.postPageViewActionParameters || {};
                    PAGE.postPageViewActionParameters.searchResults = searchResults;
                } else if (PAGE.postPageViewActionParameters) {
                    delete PAGE.postPageViewActionParameters;
                }

                // // prop29 for homepage, mlp, mdp and cat pages
                // PAGE.customerSegment = typeof vanityUrl === 'string' && /^(\/|mlp|mdp|cat)/.test(vanityUrl)  ? 'consumer' : undefined;
                // PAGE.productImpressions // some productImpressions should be in the page view events, if they are avaialble on page load.
                //PAGE.productCategory = '' // evar31, it's on pdps, Ex: 1N:Ink/Toner/Paper/Printer Supplies

                let state = getState();

                let { promotionImpressions, productImpressions, products, configuratorStartProducts } =
                    getDerivedPageViewParameters(action.slugInfo, state) || {};
                if (promotionImpressions) PAGE.promotionImpressions = promotionImpressions;
                else if (PAGE.promotionImpressions) delete PAGE.promotionImpressions;

                if (productImpressions) PAGE.productImpressions = productImpressions;
                else if (PAGE.productImpressions) delete PAGE.productImpressions;

                if (products) PAGE.products = products;
                else if (PAGE.products) delete PAGE.products;

                if (USER.RECEIVED) {
                    // set up page params
                    params = _pageView(PAGE, USER, undefined, getState(), dispatch);

                    if (configuratorStartProducts && params) {
                        params.postPageViewActionParameters = {
                            configuratorStartProducts,
                        };
                    }
                    // track page view
                    metrics.api.pageView(params);

                    TRACKED_PAGE.url = vanityUrl;
                }
                break;
            }
            // legacy
            // case ActionTypes.TRACK_PDP_PAGE_VIEW:
            // case ActionTypes.RECEIVE_VARIANT_INFO:
            // case ActionTypes.RECEIVE_PDP_INFO: {
            // 	let { pdpInfo, variantInfo } = action;
            // 	let product = pdpInfo || variantInfo;
            // 	pdpPageView(product, getState);
            // 	break;
            // }
            // case ActionTypes.LOCATION_CHANGE:{
            // 	let { location = {} } = action.payload;
            // 	let { pathname: next_location = ""} = location;
            // 	let { directory: prev_directory, slug: prev_slug, filters: prev_filters = {} } = Helpers.parseUrl(prev_location, false, process.env.BASENAME);
            // 	let { directory: next_directory, slug: next_slug, filters: next_filters = {} } = Helpers.parseUrl(next_location, false, process.env.BASENAME);
            // 	let { pg: prev_pg } = prev_filters;
            // 	let { pg: next_pg } = next_filters;

            // 	let TRACK_LOCATION_CHANGE = (next_directory === prev_directory) && (next_slug === prev_slug) && (next_pg !== prev_pg);

            // 	// prevent extra page view event due to going to a product group tab that doesn't exist
            // 	// in this case, the only page view triggered is for the root url, in RECEIVE_SLUG_INFO.
            // 	let preventPageView = pg_redirect_location === next_location || (typeof next_location === 'string' && /^\/us\-en\/shop\/pdp/.test(next_location));

            // 	if (PAGE.RECEIVED && USER.RECEIVED && TRACK_LOCATION_CHANGE && !preventPageView) {
            // 		// append tab name on location change
            // 		PAGE.pageNameL7 = `${next_slug}:productTab:${next_pg}`;

            // 		// set up page params
            // 		params = _pageView(PAGE, USER, undefined, getState());

            // 		// track page view
            // 		metrics.api.pageView(params);

            // 		TRACKED_PAGE.url = next_location;
            // 	 };

            // 	// reset the product group redirect location whenever the location changes.
            // 	pg_redirect_location = null;

            // 	// prevent double tracking when url params change
            // 	prev_location = next_location;

            // 	break;
            // }

            case ActionTypes.RECEIVE_REORDER_INFO: {
                const { orderDate, source } = action;
                const [pageFunction] = ['reorder'];

                PAGE.pageNameL5 = pageFunction;
                PAGE.templateKey = 'reorder';

                if (source) {
                    //source could be loi|web|pdp, loi|web|clp, or loi|email|marketing
                    const [srcValue = 'reorder'] = source;
                    let [id = '', , srcPageType = ''] = srcValue.toLowerCase().split('|');
                    PAGE.pageNameL6 = `${id}-${srcPageType}`;
                } else {
                    try {
                        // check if reorderInfo has valid content
                        PAGE.pageNameL6 = !orderDate && !source ? '' : 'reorder';
                    } catch (e) {
                        // log
                        // reorderInfo had wrong data type
                    }
                }

                PAGE.RECEIVED = true;

                if (USER.RECEIVED) {
                    // set up page params
                    params = _pageView(PAGE, USER, PRODUCTS, getState());

                    // track page view
                    metrics.api.pageView(params);
                }
                break;
            }
            // when MetricsElement can't be used for custom analytics
            case ActionTypes.TRACK_CUSTOM_METRIC: {
                try {
                    let { eventName, params } = action.payload || {};
                    const parsedParams = parseAnalyticsParameters(eventName, params, getState());
                    metrics.api.track(eventName, parsedParams);
                } catch (e) {}
            }
        }

        return returnValue;
    };
}
