import { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import useSiteConfig from './useSiteConfig';
import { fetchOfferPromos } from '../product/promo-actions';
import { Helpers } from '../core/src/helpers';

const getProductIds = products => {
    const productMap = {};
    const productIds = products.map(p => {
        const id = Helpers.getCatEntryId(p);
        productMap[id] = p.sku;
        return id;
    });
    return { productMap, productIds };
};

/**
 * Selects a memoizes promos. Remaps them to sku rather than id for easier reference
 */
export const selectPromos = Helpers.memoize(
    (productIds, promos, productMap = {}) => {
        return (productIds || []).reduce((r, id) => {
            if (id in promos && promos[id]) {
                r[productMap[id] || id] = promos[id];
            }
            return r;
        }, {});
    },
    (productIds, promos) => `${(productIds || []).map(p => `${p}-${p in promos && promos[p]}`)}`
);

export default function useProductPromo(products) {
    const dispatch = useDispatch();
    const { productPromo } = useSelector(state => state.productData.productInfo);
    //TODO: Add temp flag through site config in case we need to disable after launch since this will generate a lot more API calls
    const { productOffersBatchSize = 12 } = useSiteConfig();
    const { productMap, productIds } = getProductIds(products);
    useEffect(() => {
        const missing = productIds.filter(p => p && !productPromo[p] && p.indexOf('USER_DEFINED') < 0);
        if (missing?.length > 0 && productOffersBatchSize > 0) {
            dispatch(fetchOfferPromos(missing, productOffersBatchSize));
        }
    }, [productIds]);

    return selectPromos(productIds, productPromo, productMap);
}
