import { Helpers } from '../core/src/helpers';

/**
 * Check window for pStoreID
 * (which is set lib\web\app\core\src\etr-api-client\services.jsx from the HPServices response)
 * @param {*} path
 * @returns
 */
const pathInterceptor = (path, siteConfig = {}) => {
    try {
        const { basename } = siteConfig;
        if (window.pStoreID && path.indexOf(`pStoreID=${window.pStoreID}`) < 0) {
            path = Helpers.addUrlParam(path, { pStoreID: window.pStoreID }, true);
        }
        //if it's a relative path without the basename, add the basename
        if (basename && path.indexOf('/') === 0 && path.indexOf(basename) < 0) {
            path = `${basename}${path}`;
        }
    } catch (e) {}
    return path;
};

/**
 * Monkey patch history (don't think we need to add the pStoreID on replace)
 * @param {*} originalHistory
 * @returns
 */
const historyEnhancer = (originalHistory, siteConfig) => {
    try {
        let lastLocation = null;
        originalHistory.listen(location => {
            lastLocation = location;
        });
        const { enableHistoryOptimizer } = siteConfig || {};
        const prevHistoryPush = originalHistory.push;
        return {
            ...originalHistory,
            push: (pathname, state = {}, ...args) => {
                if (!enableHistoryOptimizer) {
                    prevHistoryPush(pathInterceptor(pathname, siteConfig), state, ...args);
                } else {
                    //prevent pushing duplicate links to imrpove browser back/foward button behaivor
                    try {
                        if (
                            lastLocation === null ||
                            pathname !== lastLocation.pathname + lastLocation.search + lastLocation.hash ||
                            JSON.stringify(state) !== JSON.stringify(lastLocation.state)
                        ) {
                            prevHistoryPush(pathInterceptor(pathname, siteConfig), state, ...args);
                        }
                    } catch (e) {
                        prevHistoryPush(pathInterceptor(pathname, siteConfig), state, ...args);
                    }
                }
            },
        };
    } catch (e) {
        return originalHistory;
    }
};

export default historyEnhancer;
