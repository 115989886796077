export const parseDlpProducts = (products, productPrices) => products && products.map(prd => {
    let { facet_subbrand, pm_producttype, pm_category, facet_formfactor, name, sku, itemId, gtmUniqueId } = prd || {};
    let { facet_subbrand: hpservicesFacetSubbrand, plcode } = (productPrices && (productPrices[sku] || productPrices[itemId])) || {}
    return {
        facet_subbrand: facet_subbrand || hpservicesFacetSubbrand, 
        pm_producttype, 
        pm_category, 
        facet_formfactor, 
        name, 
        sku,
        plcode,
        catentryId: itemId,
        gtmUniqueId
    }
})