import cloneDeep from 'lodash/cloneDeep';

/**
 * Takes the banner props and removes the mobile values. This is a useful work around to render the correct
 * banner with SSR. Because stellar components are mobile first and use device width which are not available on the server
 * @param {*} bannerProps props object for @hpstellar-core/Banners
 * @returns {*} Banner props with the mobile values removed
 */
export const removeMobileBannerProps = bannerProps =>
    bannerProps instanceof Array
        ? bannerProps.map(banner => {
              let newBanner = cloneDeep(banner);
              newBanner.titleMobile = null;
              newBanner.descriptionMobile = null;
              if (newBanner.image && newBanner.image.url) {
                  newBanner.image.url.mobile = null;
              }

              return newBanner;
          })
        : bannerProps;

export const elementIsOffscreen = (el, horizontalOffset = 0, verticalOffset = 0) => {
    try {
        let rect = el.getBoundingClientRect();
        return (
            rect.x + rect.width + horizontalOffset < 0 ||
            rect.y + rect.height + verticalOffset < 0 ||
            rect.x + horizontalOffset > window.innerWidth ||
            rect.y + verticalOffset > window.innerHeight
        );
    } catch (e) {}
};

export const clickOnEnter = e => {
    let charCode = e.which || e.charCode;
    if (charCode === 13 && e.currentTarget === e.target) {
        e.currentTarget.click();
    }
};
