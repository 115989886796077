import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { MetricsElement } from 'react-metrics';

import Link from '../../../shared/components/hyperlink';
import { getGlobalNavGtm } from '../../../metrics/metrics-helpers';

import './css/sub-menu.less';

const getLink = (link, storeEnvironment) => {
    try {
        if (storeEnvironment !== 'production') {
            const prodLink = 'https://www.hp.com/us-en/shop';
            return link.indexOf(prodLink) === 0 ? link.replace(prodLink, '/us-en/shop') : link;
        }
    } catch (e) {}
    return link;
};

export function SubMenu({ title, items, closeMenu }) {
    const storeEnvironment = useSelector(state => state.storeEnvironment);
    return (
        <div role="navigation" className="submenu-links">
            <div className="title">{title}</div>
            <MetricsElement element="ul" role="menu">
                {items.map((item, itemKey) => {
                    return (
                        <li key={itemKey} role="menuitem" onClick={closeMenu}>
                            <Link
                                data-metrics-event-name="linkClick"
                                data-metrics-event={item.analyticsLinkType}
                                data-metrics-link-id={item.analyticsLinkID}
                                data-metrics-link-placement={'header'}
                                {...getGlobalNavGtm(title, item.name, 2)}
                                to={getLink(item.link, storeEnvironment)}
                            >
                                {item.name}
                            </Link>
                        </li>
                    );
                })}
            </MetricsElement>
        </div>
    );
}

SubMenu.propTypes = {
    title: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    closeMenu: PropTypes.func.isRequired,
};

export default SubMenu;
