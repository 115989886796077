import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Helpers } from '../../core/src/helpers';
import { getSrpSeoData } from '../../search/util';
const EMPTY_OBJ = {};

const getSeoData = (defaultSeo, props) => {
    let { slugInfo } = props || {};
    let { vanityUrl } = slugInfo || {};
    if (vanityUrl === 'sitesearch') {
        return getSrpSeoData(defaultSeo, props);
    }
    return defaultSeo;
};

const getCanonicalQueryString = (query, queryStringWhitelist) => {
    return (queryStringWhitelist || [])
        .reduce((r, key) => {
            const value = query[key];
            if (value) {
                return r + `&${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
            }
            return r;
        }, '')
        .replace(/^\&/, '?');
};

class SeoMeta extends React.PureComponent {
    getCanonical = (canonical, queryString = '') => {
        const { storeDomain, pathname, proxyHost } = this.props;
        //safety check to make sure proxyHost is an hp domain
        const domain =
            proxyHost && /hp.com/gi.test(proxyHost) && /(hp.com|hpicloud.net)$/.test(proxyHost)
                ? proxyHost
                : storeDomain;
        const protocol = /^(?:(ht|f)tp(s?)\:\/\/)?/.test(domain) ? '' : 'https://';
        //if no canonical provided, references self (remove trailing slash)
        return decodeURI(
            Helpers.mergeQueryStrings(canonical || `${protocol}${domain}${pathname}` || '', queryString).replace(
                /\/$/,
                ''
            )
        );
    };

    render() {
        const { seo, search } = this.props;
        const { title, canonical, description, keywords, queryStringWhitelist } = getSeoData(
            seo || EMPTY_OBJ,
            this.props
        );
        const query = Helpers.getSearch(search);
        return (
            <Helmet>
                <meta charSet="utf-8" />
                <title>{title}</title>
                <link
                    rel="canonical"
                    href={this.getCanonical(canonical, getCanonicalQueryString(query, queryStringWhitelist))}
                />
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />
            </Helmet>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { storeDomain, router, proxyHost, slugInfo } = state;
    const { location } = router;
    const { pathname, search } = location;
    return {
        storeDomain,
        proxyHost,
        pathname,
        search,
        slugInfo,
    };
};

export default connect(mapStateToProps, undefined)(SeoMeta);
