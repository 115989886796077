import { useSelector } from 'react-redux';

import { HawksearchAPI } from '../core/src/hawksearch-client';
import { SupportAPI } from '../core/src/support-client';
import { Helpers } from '../core/src/helpers';

const getAPIClient = Helpers.memoize((id, config) => {
    if(id === 'support'){
        return new SupportAPI(config);
    }
    return new HawksearchAPI(config);
});

const selectConfig = Helpers.memoize((id, searchEndPoints) => {
    return (searchEndPoints || {})[id];
});

export const getSearchAPIClientFromState = (id, state) => {
    if (!state) {
        return new HawksearchAPI();
    }
    const config = selectConfig(id, state.searchEndPoints);
    const apiClient = getAPIClient(id, config);
    return apiClient;
};

export default (id = 'search') => {
    const config = useSelector(state => selectConfig(id, state.searchEndPoints));
    const apiClient = getAPIClient(id, config);
    return [apiClient];
};
