import React from 'react';
export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        //TODO: log caught errors here probably to New Relic
        console.error(error);
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            return this.props.ErrorComponent || <span className="component-error"></span>;
        }
        return this.props.children;
    }
}

export function withError(Component, ErrorComponent) {
    return class extends React.Component {
        render() {
            return (
                <ErrorBoundary ErrorComponent={ErrorComponent}>
                    <Component {...this.props} />
                </ErrorBoundary>
            );
        }
    };
}
