import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import usePageView from '../../hooks/usePageView';
import useUserData from '../../hooks/useUserData';
import { getPrivateStoreID } from '../../utility/utility-actions';
import { formatMetricValue } from '../../metrics/metrics-helpers';

const MetricsMeta = () => {
    const slugInfo = useSelector(state => state && state.slugInfo);
    const { userData } = useUserData();

    const { analyticsData } = slugInfo || {};
    const { profileData } = userData || {};
    const { personData } = profileData || {};
    const { pstoreId } = profileData || {};
    const pStoreID = pstoreId || (personData && personData.pStoreID);
    const queryStringPrivateStoreId = getPrivateStoreID();
    const validPrivateStoreId = pStoreID === queryStringPrivateStoreId;

    let { page_level, product_type, family, bu, segment, lifecycle, simple_title } = analyticsData || {};
    let { batchedImpressions }= (analyticsData && analyticsData.derivedAnalyticsData) || {};

    const derivedSlugInfo = usePageView({ 
        slugInfo, 
        hasBatchedImpressions: !!batchedImpressions // if true, page view is debounced as impressions are being determined.
    }); 

    const { derivedAnalyticsData } = ( derivedSlugInfo && derivedSlugInfo.analyticsData ) || {};
    
    return analyticsData || derivedAnalyticsData ? (
        <Helmet>
            <meta name="simple_title" content={ derivedAnalyticsData.simple_title || simple_title || '' } />
            <meta name="page_level" content={ derivedAnalyticsData.page_level || page_level || ''} />
            <meta name="product_type" content={derivedAnalyticsData.product_type|| product_type || ''} />
            <meta name="family" content={derivedAnalyticsData.family || family || ''} />
            <meta name="bu" content={derivedAnalyticsData.bu || bu || ''} />
            <meta name="segment" content={derivedAnalyticsData.segment || segment || ''} />
            <meta name="lifecycle" content={derivedAnalyticsData.lifecycle || lifecycle || ''} />
            {validPrivateStoreId && <meta name="store_type" content={formatMetricValue(pStoreID, '_')}/>}
        </Helmet>
    ) : null;
};

export default MetricsMeta;