class Financing {
    constructor(apiConfig, http) {
        const { siteInfo } = apiConfig;
        const { storeId } = siteInfo;
        this.apiConfig = apiConfig;
        this.http = http;
        this.path = `/wcs/resources/store/${storeId}/hpfinanceservice`;
    }

    prequalified(data, config = {}) {
        return this.http(`${this.path}/prequalified`, {
            method: 'POST',
            data,
            ...config,
        });
    }
}

export default Financing;
