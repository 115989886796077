import merge from 'lodash/merge';
import remove from 'lodash/remove';

import { SORT_PRODUCTS } from './product-actions';

const sortMetric = (sortValue, p1, p2, prices) => {
    let p1Price = prices[p1.sku],
        p2Price = prices[p2.sku];

    if (!p1Price || p1Price.priceFetchFailed) {
        p1Price = { regularPrice: p1.listPrice, salePrice: p1.salePrice };
    }
    if (!p2Price || p2Price.priceFetchFailed) {
        p2Price = { regularPrice: p2.listPrice, salePrice: p2.salePrice };
    }

    let p1Metric;
    let p2Metric;
    switch (sortValue) {
        case 'rank.recommended':
            p1Metric = p1.rank.recommended;
            p2Metric = p2.rank.recommended;
            break;
        case 'rank.default':
            p1Metric = p1.rank.default;
            p2Metric = p2.rank.default;
            break;
        case 'price':
            p1Metric = p1Price.salePrice;
            p2Metric = p2Price.salePrice;
            break;
        case '-price':
            p1Metric = p1Price.salePrice * -1;
            p2Metric = p2Price.salePrice * -1;
            break;
        case '-discount':
            p1Metric = p1Price.salePrice - p1Price.regularPrice;
            p2Metric = p2Price.salePrice - p2Price.regularPrice;
            break;
        case '-percent_discount':
            p1Metric = (p1Price.salePrice - p1Price.regularPrice) / p1Price.regularPrice;
            p2Metric = (p2Price.salePrice - p2Price.regularPrice) / p2Price.regularPrice;
            break;
        case 'name':
            p1Metric = p1.name.toLowerCase();
            p2Metric = p2.name.toLowerCase();
            break;
        case '-rating':
            p1Metric = p1.rating * -1;
            p2Metric = p2.rating * -1;
            break;
        default:
            p1Metric = p1.rank.recommended;
            p2Metric = p2.rank.recommended;
    }
    return [p1Metric, p2Metric];
};

const sortProducts = (products, sortValue, prices) => {
    let sortedProducts = products.slice(0);
    let udfInd = [];
    let udfCards = remove(sortedProducts, (product, i) => {
        if (product.prdClass == 'USER_DEFINED') {
            udfInd.push(i);
            return true;
        }
        return false;
    });
    sortedProducts.sort((p1, p2) => {
        let [p1Metric, p2Metric] = sortMetric(sortValue, p1, p2, prices);
        if (p1Metric < p2Metric) return -1;
        if (p1Metric > p2Metric) return 1;
        return 0;
    });
    udfCards.forEach((card, i) => {
        sortedProducts.splice(udfInd[i], 0, card);
    });
    return sortedProducts;
};

const SortReducer = (state = {}, action) => {
    Object.freeze(state);
    switch (action.type) {
        case SORT_PRODUCTS:
            let newState = merge({}, state);
            let metaData = state.productList[action.pg].metaData;
            let sortedProducts = sortProducts(
                state.productList[action.pg].products,
                action.sortValue,
                state.productPrices
            );
            newState.productList[action.pg] = { products: sortedProducts, sortValue: action.sortValue, metaData };
            return newState;
        default:
            return state;
    }
};

export default SortReducer;
