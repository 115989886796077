import cloneDeep from 'lodash/cloneDeep';

import { addGtmPropsToBanners, setPromotion, addCategoryTilesGtmActions } from '../metrics-helpers';

export default {
    homeHeroBanner: ({ component, device, analyticsData }) => {
        if (!Array.isArray(component)) return component;
        return addGtmPropsToBanners(cloneDeep(component), device, analyticsData, 'homeHeroBanner', {
            promotionClickType: 'cta',
        });
    },
    homeBannerThree: ({ component, device, analyticsData }) => {
        if (!Array.isArray(component)) return component;

        let newComponent = cloneDeep(component);
        newComponent = addGtmPropsToBanners(component, device, analyticsData, 'homeBannerThree', {
            promotionClickType: 'cta',
        });
        return newComponent;
    },
    homeBanner: ({ component, device }) => {
        const { tileLayouts } = component || {};
        const { tiles } = tileLayouts || {};

        if (!tiles) return component;

        const newComponent = cloneDeep(component);
        newComponent.tileLayouts.tiles = tiles.map(tile => {
            let gtmActions = new Map([['tileClick', addCategoryTilesGtmActions(tile, device)]]);

            return {
                ...tile,
                gtmActions,
            };
        });

        return newComponent;
    },
    secondaryBanner: ({ component, device, analyticsData }) => {
        if (!component) return component;

        const newComponent = cloneDeep(component);
        const { banners } = newComponent || {};
        // TODO: Refactor so this is not decoupled from the usePromotionImpression hook
        const promotionAnalyticsGtmParams = { device, id: 'secondaryBanner', type: 'promotionClick', useTitle: true };
        if(Array.isArray(banners)){
            newComponent.banners = banners.map(banner => {
                return setPromotion(
                    banner,
                    promotionAnalyticsGtmParams,
                    analyticsData
                )
            })

            return newComponent;
        }

        return setPromotion(
            newComponent,
            promotionAnalyticsGtmParams,
            analyticsData
        );
    },
    homeShoppingAssistant: ({ component, device, analyticsData }) => {
        if (!component) return component;
        return addGtmPropsToBanners(component, device, analyticsData, 'homeShoppingAssistant', {
            promotionClickType: 'cta',
        });
    },
};
