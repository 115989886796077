import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { toggleHeaderFooterDrawer } from './header-footer/header-footer-actions';

import './drawer.less';

export function Drawer({ open, overlay, onClose, children, position, push, toggleDrawer, freeze, collapse, className, title, lazyLoad }) {
    //toggleDrawer(open, freeze, push);
    const [ isLazyLoad, setLazyLoad ] = useState(lazyLoad);
    useEffect(() => {
        if(open && isLazyLoad){
            setLazyLoad(false)
        }
    });
    /**
     * Return null if lazyLoad is set
     */
    if(isLazyLoad){
        return null;
    }

    return (
        <Fragment>
            <div
                className={`drawer drawer-${position}${className ? ` ${className}` : ''} ${open ? 'open' : 'closed'} ${
                    collapse && open ? 'collapse' : ''
                }`}
            >
                {title && <div className='drawer-title'>
                            <h2>{title}</h2>
                        </div>}
                {children}
            </div>

            {overlay && (
                <div
                    role={open ? 'presentation' : undefined}
                    className="overlay"
                    onClick={onClose}
                    style={{
                        display: open ? 'flex' : 'none',
                        backgroundColor: 'rgba(0,0,0,.38)',
                    }}
                />
            )}
        </Fragment>
    );
}

Drawer.defaultProps = {
    overlay: false,
    position: 'left',
    push: false,
    freeze: false,
    onClose: () => {
        console.log('onclose not implemented.');
    },
    lazyLoad: false
};

Drawer.propTypes = {
    /** drawer open or closed state */
    open: PropTypes.bool.isRequired,
    /** overlay other content */
    overlay: PropTypes.bool,
    /** option to freeze body */
    freeze: PropTypes.bool,
    /** on drawer close event */
    onClose: PropTypes.func,
    /** content within drawer layout */
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    /** position of drawer top, left, right, bottom */
    position: PropTypes.string,
    /** option if drawer should push content or overlap it */
    push: PropTypes.bool,
    /** toggles open state, handled by connected store */
    toggleDrawer: PropTypes.func.isRequired,
    /** optional title for the drawer */
    title: PropTypes.string,
    /** Lazy load will not render drawer until the first time it's open */
    lazyLoad: PropTypes.bool
};

const mapDispatchToProps = dispatch => ({
    toggleDrawer: (open, freeze, push) => dispatch(toggleHeaderFooterDrawer(open, freeze, push)),
});

export default connect(null, mapDispatchToProps)(Drawer);
